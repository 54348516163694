import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ApiHelper } from 'src/app/shared/common/ApiHelper';
import { ApiResponse } from 'src/app/shared/models/ApiResponse';
import { ListParameters, ListResponse, transformParameters } from '../../api';
import { Annotation, AnnotationConfig } from '../../employee/models/File';
import { SwalHelper } from '../../shared/common/SwalHelper';
import { DocumentTemplate } from '../models/DocumentTemplate';
import { DocumentTemplateVocabulary } from '../models/DocumentTemplateVocabulary';

@Injectable({
    providedIn: 'root'
})
export class DocumentTemplateService {
    public constructor(
        private _apiHelper: ApiHelper,
        private _http: HttpClient,
        private _loaderService: NgxUiLoaderService,
        private _swalHelper: SwalHelper
    ) { }

    public getTemplates(withInactive = false, scope?: string): Observable<any> {
        let params = new HttpParams()
            .set('withInactive', withInactive);

        if (scope) {
            params = params.set('scope', scope);
        }

        return this._http.get('/api/document-templates/get-all', {params});
    }

    public getTemplatesList(params: ListParameters<DocumentTemplate>, filterParameters?: {}): Observable<ListResponse<DocumentTemplate>> {
        const p = transformParameters(params);

        return this._http.post<ListResponse<DocumentTemplate>>('/api/document-templates/list', {
            ...p,
            filter: filterParameters
        });
    }

    public getTemplateByKey(key: string, externalURL = false): Observable<DocumentTemplate> {
        let params = new HttpParams();

        if (key) {
            params = params.set('key', key);
        }
        if (externalURL) {
            params = params.set('external_link', externalURL);
        }

        return this._http.get<DocumentTemplate>('/api/document-templates/get-by-key', {params});
    }

    public getDocumentsByTemplateKey(key: string): Observable<any> {
        const params = new HttpParams()
            .set('key', key);
        return this._http.get('/api/document-templates/get-documents-by-template-key', {params});
    }

    public saveDocumentFromTemplate(employeeIds: Array<number>, documentTemplateKey: string): Observable<any> {
        const body = {
            employeeIds,
            documentTemplateKey
        };
        return this._http.post<Array<any>>('/api/document-templates/employees-required-values', body)
            ;
    }

    public createDocumentTemplate(form: any): Promise<'done'> {
        this._loaderService.startLoader('master');

        return new Promise(resolve => {
            this._http.post<ApiResponse>('/api/document-templates/create', form)
                .subscribe(
                    response => {
                        this._loaderService.stopLoader('master');

                        this._apiHelper.handleSuccessResponse(response);

                        resolve('done');
                    },
                    error => {
                        this._loaderService.stopLoader('master');

                        this._apiHelper.handleErrorResponse(error);
                    }
                );
        });
    }

    public editDocumentTemplate(form: any, documentTemplateID: number): Promise<'done'> {
        this._loaderService.startLoader('master');

        return new Promise(resolve => {
            this._http.post<ApiResponse>(`/api/document-templates/${documentTemplateID}`, form)
                .subscribe(
                    response => {
                        this._loaderService.stopLoader('master');

                        this._apiHelper.handleSuccessResponse(response);

                        resolve('done');
                    },
                    error => {
                        this._loaderService.stopLoader('master');

                        this._apiHelper.handleErrorResponse(error);
                    }
                );
        });
    }

    public deleteDocumentTemplate(documentTemplateID: number): Promise<'done'> {
        return new Promise(async (resolve, reject) => {
            const deleteConfirmed = await this._swalHelper.showConfirmDeleteDialog('swal.delete_text', 'swal.delete_title');
            if (deleteConfirmed) {
                this._http.delete<ApiResponse>(`/api/document-templates/${documentTemplateID}`)
                    .subscribe((response: ApiResponse) => {
                        this._loaderService.stopLoader('master');
                        this._apiHelper.handleSuccessResponse(response);
                        resolve('done');
                    }, error => {
                        this._loaderService.stopLoader('master');
                        this._apiHelper.handleErrorResponse(error);
                    });
            } else {
                reject('error');
            }
        });
    }

    public generateToManyEmployees(data): Promise<'done'> {
        const apiUrl = '/api/document-templates/generate-to-many-employees';

        return new Promise(resolve => {
            this._http.post<ApiResponse>(apiUrl, data)
                .subscribe(
                    response => {
                        this._apiHelper.handleSuccessResponse(response);

                        this._loaderService.stopLoader('master');

                        resolve('done');
                    },
                    error => {
                        this._apiHelper.handleErrorResponse(error);

                        this._loaderService.stopLoader('master');
                    }
                );
        });
    }

    public getVocabulary(): Observable<Array<DocumentTemplateVocabulary>> {
        return this._http.get<Array<DocumentTemplateVocabulary>>('/api/document-templates/vocabulary');
    }

    public sendSignatureNotifications(documentTemplateID: number): Observable<any> {
        this._loaderService.startLoader('master');
        return this._http.get<any>(`/api/document-templates/${documentTemplateID}/signature-notify`)
            .pipe(tap((response) => {
                this._loaderService.stopLoader('master');
                this._apiHelper.handleSuccessResponse(response);
            }));
    }

    public saveAnnotations(documentTemplateID: number, fields: Annotation[], config: AnnotationConfig): Promise<'done'> {
        return new Promise(resolve => {
            this._http.post<ApiResponse>(`/api/document-templates/${documentTemplateID}/pdf-overlay`, {fields, config})
                .subscribe(
                    response => {
                        this._apiHelper.handleSuccessResponse(response);

                        this._loaderService.stopLoader('master');

                        resolve('done');
                    },
                    error => {
                        this._apiHelper.handleErrorResponse(error);

                        this._loaderService.stopLoader('master');
                    }
                );
        });
    }

    public setHTMLcontent(form: any, documentTemplateID: number): Promise<'done'> {
        return new Promise(resolve => {
            this._http.post<ApiResponse>(`/api/document-templates/${documentTemplateID}/set-html-content`, form)
                .subscribe(
                    response => {
                        this._apiHelper.handleSuccessResponse(response);

                        this._loaderService.stopLoader('master');

                        resolve('done');
                    },
                    error => {
                        this._apiHelper.handleErrorResponse(error);

                        this._loaderService.stopLoader('master');
                    }
                );
        });
    }
}
