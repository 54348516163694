<div *ngIf="subscriptionStats$ | async; let subscription;" xmlns="http://www.w3.org/1999/html">
  <ng-template
    #preActivationModal
    let-modal
  >
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title font-16 mb-0 text-dark d-block">
          <span>{{ 'subscription.pre_activation_check_title' | translate }}</span>
        </p>
        <button
      (click)="modal.dismiss()"
      aria-label="Close"
      class="close"
    >
      <span
        aria-hidden="true"
        class="font-24 text-muted"
      >
        <i class="mdi mdi-close"></i>
      </span>
    </button>
      </div>

      <div class="modal-body">
        <ng-container *ngIf="companySettings$ | async; let settings">
          <div class="row">
            <div class="col-12 col-md-6">{{ 'employees.company_name' | translate }}</div>
            <div class="col-12 col-md-6 font-weight-bold">
              <app-value-or-dash>{{ settings.name }}</app-value-or-dash>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-md-6">{{ 'employees.in' | translate }}</div>
            <div class="col-12 col-md-6 font-weight-bold">
              <app-value-or-dash>{{ settings.in }}</app-value-or-dash>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-md-6">{{ 'employees.tin' | translate }}</div>
            <div class="col-12 col-md-6 font-weight-bold">
              <app-value-or-dash>{{ settings.tin }}</app-value-or-dash>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-md-6">{{ 'employees_addresses.addr_line_1' | translate }}</div>
            <div class="col-12 col-md-6 font-weight-bold">
              <app-value-or-dash>{{ settings.address?.addr_line_1 }}</app-value-or-dash>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-md-6">{{ 'employees_addresses.addr_line_2' | translate }}</div>
            <div class="col-12 col-md-6 font-weight-bold">
              <app-value-or-dash>{{ settings.address?.addr_line_2 }}</app-value-or-dash>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-md-6">{{ 'employees_addresses.city' | translate }}</div>
            <div class="col-12 col-md-6 font-weight-bold">
              <app-value-or-dash>{{ settings.address?.city }}</app-value-or-dash>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-md-6">{{ 'employees_addresses.postal_code' | translate }}</div>
            <div class="col-12 col-md-6 font-weight-bold">
              <app-value-or-dash>{{ settings.address?.postal_code }}</app-value-or-dash>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-md-6">{{ 'employees_addresses.country_code' | translate }}</div>
            <div class="col-12 col-md-6 font-weight-bold">
              <app-value-or-dash>{{ settings.address?.country_code }}</app-value-or-dash>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-12">
              <label
                class="d-flex align-items-center"
                for="terms_1"
              >
                <input
                  #terms1="ngModel"
                  [ngModel]="false"
                  id="terms_1"
                  name="terms_1"
                  type="checkbox"
                >

                <span class="ml-1">{{ 'subscription.agreement_prefix' | translate }}</span>

                <a
                  (click)="$event.stopPropagation()"
                  [attr.href]="'subscription.agreement_terms_1_anchor' | translate"
                  class="ml-1"
                  target="_blank"
                >{{ 'subscription.agreement_terms_1_anchor_text' | translate }}</a>
              </label>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-12">
              <div class="d-flex align-items-center justify-content-between">
                <a [routerLink]="['/setting/general']">
                  <span>{{ 'subscription.to_settings_general_anchor' | translate }}</span>
                </a>

                <button
                  (click)="activateSubscription({ terms_1: terms1.value })"
                  [disabled]="(subscriptionProcessingLoading$ | async) || (terms1.value) === false"
                  class="btn btn-primary"
                >
                  <span
                    *ngIf="subscriptionProcessingLoading$ | async; else actionButtonTextTemplate"
                    class="d-flex align-items-center justify-content-center"
                  >
                    <span
                      aria-hidden="true"
                      class="spinner-border spinner-border-sm mr-2"
                      role="status"
                    ></span>

                    <span class="visually-hidden">{{ 'global.processing' | translate }}</span>
                  </span>
                  <ng-template #activateSubscriptionTemplate>
                    <span>{{ 'subscription.activate_subscription' | translate }}</span>
                  </ng-template>

                  <ng-template #actionButtonTextTemplate>
                    <ng-container [ngTemplateOutlet]="activateSubscriptionTemplate"></ng-container>
                  </ng-template>
                </button>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-template>

  <ng-template #yesTemplate>
    <span>{{ 'global.YES' | translate }}</span>
  </ng-template>

  <ng-template #userTableRowTemplate let-listType="listType" let-user>
    <tr>
      <td class="table-user">
        <div class="d-flex align-items-center">
          <span
            *ngIf="user.changed"
            class="entity-indicator-dot badge-primary"
          >&nbsp;</span>
          <app-employee-box-avatar [avatar]="user.avatar" [canView]="true" [employeeID]="user.employee_ID" [fullname]="user.fullname" [position]="user.position"></app-employee-box-avatar>
        </div>
      </td>

      <td class="table-user">
        <span>{{ user.email }}</span>
      </td>

      <td style="width: 200px;">
        <h5 *ngIf="user?.type == 'user'">{{ 'subscription.table_user' | translate }}</h5>
        <h5 *ngIf="user?.type == 'employee'">{{ 'subscription.table_employee' | translate }}</h5>
        <span class="font-13 text-truncate text-muted">{{ user.role }}</span>
      </td>

        <td>
            <span class="font-13 text-truncate text-muted">{{ user.last_logged_at | date:'dd.MM.yyyy HH:mm' }}</span>
        </td>

        <td>
            <i class="cursor-pointer" *ngIf="user.employee_ID" (click)="archiveEmployee(user, subscription)"> 🗃 </i>
        </td>

      <td class="text-right">
        <div
          *ngIf="!user?.is_admin; else yesTemplate"
          class="d-flex align-items-center justify-content-end"
        >
          <ng-select
            (change)="inviteChanged($event, user, listType)"
            *ngIf="!user.canBeInvitedDirectly && input.checked"
            [clearable]="false"
            [ngModel]="user.withInvite"
            class="invite-select"
            name="type"
          >
            <ng-option [value]="true">
              <span>{{ 'subscription.invite_send' | translate }}</span>
            </ng-option>

            <ng-option [value]="false">
              <span>{{ 'subscription.invite_do_not_send' | translate }}</span>
            </ng-option>
          </ng-select>
          <div *ngIf="user.canBeInvitedDirectly" class="mr-4">
            <u (click)="sendInvite(user)" class="d-block text-left text-nowrap pb-1">{{ 'subscription.invite_send' | translate }}</u>
            <u (click)="sendNewPassword(user)" class="d-block text-left text-nowrap">{{ 'subscription.send_new_password' | translate }}</u>
          </div>

          <div [ngbTooltip]="user.is_demo_user ? ('subscription.is_demo_user_explanation' | translate) : ''"
               container="body"
               placement="bottom-end"
               class="">
            <input #input
              (change)="toggleActiveUser(user, listType)"
              [ngModel]="user.is_active"
              data-switch="success"
              id="is_active-{{ user.type + user.user_ID }}"
              name="is_active-{{ user.type + user.user_ID }}"
              type="checkbox"
              [disabled]="user.is_demo_user"
            >

            <label for="is_active-{{ user.type + user.user_ID }}" [ngClass]="{'pointer-events-none': user.is_demo_user}" class="mb-0">&nbsp;</label>
          </div>
        </div>
      </td>
    </tr>
  </ng-template>

  <div class="row justify-content-center">
    <div class="col-xl-12">
        <!-- Boxes -->
        <div *ngIf="subscription?.subscription.status === 'TRIALING'" class="card card-pricing shadow p-2">
            <p class="m-0">
                {{ 'subscription.trial_alert' | translate }} {{subscription.trial_ends_string}}.
            </p>
        </div>
        <div *ngIf="subscription?.demo_mode" class="alert alert-warning mb-3 shadow w-100 pl-2">
            <div class="m-0">
                <div class="badge badge-warning mr-1 font-12 py-1">
                    <span>DEMO</span>
                </div>
                <span>{{ 'subscription.alert_demo_mode' | translate }}</span>
            </div>
        </div>
        <div class="card card-pricing shadow p-2 d-block" *ngIf="subscription?.subscription?.plan">
            <span>
                {{'subscription.alert_plan' | translate}}
                {{ subscription?.subscription?.package }}</span>
            <ng-container *ngIf="subscription?.subscription?.current_period_start || subscription?.subscription?.current_period_end; else dotTemplate">
                <span>
                    {{ 'subscription.alert_plan_starts' | translate }}
                    {{ subscription?.subscription?.current_period_start | date: 'dd.MM.yyyy' }}
                    {{ 'subscription.alert_plan_ends' | translate }}
                    {{ subscription?.subscription?.current_period_end | date: 'dd.MM.yyyy' }}.
                </span>
            </ng-container>
            <ng-template #dotTemplate>
                <span>.
                </span>
            </ng-template>
            <span>
                {{ 'subscription.alert_plan_price' | translate }}
            </span>
            <span class="text-decoration-line-through" *ngIf="subscription?.subscription?.total_price !== subscription?.subscription?.total_price_discounted">
               {{subscription?.subscription?.total_price | currency: (subscription?.subscription?.plan?.currency?.toUpperCase()) : (subscription?.subscription?.plan?.currency === 'czk' ? 'Kč ' : (subscription?.subscription?.plan?.currency === 'usd' ? '$ ' : '€ ')) : '1.0-2' : (subscription?.subscription?.plan?.currency === 'czk' ? 'cs' : 'en' )}}</span>
            <span>
                {{ subscription?.subscription?.total_price_discounted | currency: (subscription?.subscription?.plan?.currency?.toUpperCase()) : (subscription?.subscription?.plan?.currency === 'czk' ? 'Kč ' : (subscription?.subscription?.plan?.currency === 'usd' ? '$ ' : '€ ')) : '1.0-2' : (subscription?.subscription?.plan?.currency === 'czk' ? 'cs' : 'en' )}}.
                {{'subscription.alert_quantity' | translate}}
                {{ subscription?.subscription?.quantity ?? 0}}.
            </span>
            <button (click)="redirectToBillingPortal()"
                    [disabled]="loadingBillingPortal"
                    class="btn btn-link p-0">
                      <span *ngIf="loadingBillingPortal"
                            aria-hidden="true"
                            class="spinner-border spinner-border-sm mr-1 text-dark" role="status"
                            style="width: 20px; height: 20px;"
                      ></span>
                <span> {{ 'subscription.action_billing' | translate }} </span>
            </button>
        </div>
        <div class="card card-pricing shadow p-2" *ngIf="subscription?.subscription?.promo && !subscription?.subscription?.plan">
            {{'subscription.alert_promo_1' | translate}}{{ subscription?.subscription?.promo }}{{'subscription.alert_promo_2' | translate}}
        </div>

      <!-- products -->
      <div *ngIf="!subscription.subscription.is_stripe_disabled" class="row mb-3">
          <ng-container *ngIf="stripeProducts$ | async as stripeProducts; else loadingTemplate">
            <div *ngFor="let product of stripeProducts" class="col-md-4 mb-4 mb-md-0">
              <div class="card card-pricing shadow h-100 ribbon-box ">
                <div class="card-header d-xl-flex align-items-center justify-content-between">
                  <div class="d-flex align-items-center">
                    <h3 class="text-dark"> {{ ("subscription.product_"+(product.name))  | translate }} </h3>
                    <div *ngIf="subscription[product.name]?.paid || subscription[product.name]?.pending || subscription[product.name]?.selected"
                         [ngClass]="{'badge badge-success ml-2 px-2 font-12 py-1' : subscription[product.name]?.selected}"
                         class="font-weight-bold"
                    >
                      <span *ngIf="subscription[product.name]?.selected">{{ 'subscription.ribbon_paid' | translate }}</span>
                      <span *ngIf="subscription[product.name]?.pending">{{ 'subscription.ribbon_pending' | translate }}</span>
                    </div>
                  </div>
                  <div *ngIf="subscription[product.name]?.paid">
                    <button (click)="redirectToBillingPortal()"
                            [disabled]="loadingBillingPortal"
                            class="btn btn-link p-0">
                      <span *ngIf="loadingBillingPortal"
                            aria-hidden="true"
                            class="spinner-border spinner-border-sm mr-1 text-dark" role="status"
                            style="width: 20px; height: 20px;"
                      ></span>
                      <span> {{ 'subscription.action_billing' | translate }} </span>
                    </button>
                  </div>
                </div>
                <div
                  class="card-body d-flex flex-column h-100"
                  disabled="true"
                >
                  <ul class="card-pricing-features">
                    <li *ngFor="let feature of ('subscription.features.' + product.name | translate)">{{ feature }}</li>
                  </ul>
                </div>
                  <div *ngIf="product.name === 'BASIC' && product.not_available_reason !== '' && product.not_available_reason !== null" class="row mb-4">
                      <div class="col-12 px-4 pb-3">
                          <span class="text-danger">{{product.not_available_reason}}</span>
                      </div>
                  </div>
                <!--Try for free-->
                  <div *ngIf="subscription?.subscription?.status === 'TRIALING'" class="d-flex justify-content-center mb-3">
                      <button [disabled]="subscription[product.name].selected || product.is_available === false" class="btn btn-primary" (click)="activateSubscription(null, product.plans[0]?.plan_ID, false)"> {{ 'subscription.try_package_for_free' | translate }} </button>
                  </div>
                <!-- plans -->

                  <div *ngIf="product?.plans" class="mt-auto">
                    <div *ngFor="let plan of product?.plans; let i = index" class="">
                      <div *ngIf="product.name === 'BASIC' || (product.name !== 'BASIC' && plan.amount > 0)"
                           class="card-footer footer-plans d-flex align-items-center position-relative px-0">
                        <div class="m-2">
                          <div (click)="subscription?.subscription?.plan?.plan_ID !== plan.plan_ID ? activateSubscription(null, plan.plan_ID) : null"
                               [ngClass]="{'subscription-card-row--active' : subscription?.subscription?.plan?.plan_ID === plan.plan_ID, 'disabled opacity-1' : product.is_available === false}"
                               class="subscription-card-row my-1 px-2 d-flex w-100 flex-row align-items-center"
                          >
                            <span class="subscription-card-row__check subscription-card-row__check--active text-green pr-3 font-30"><i class="mdi mdi-check-circle-outline"></i></span>
                            <span class="subscription-card-row__check text-muted pr-3 font-30"><i class="mdi mdi-checkbox-blank-circle-outline"></i></span>
                            <div class="">
                              <ng-container>
                                <p class="py-0 my-1 font-13 text-muted"> {{ plan.interval === 'month' ? ('subscription.monthly_facturation' | translate) : ('subscription.yearly_facturation' | translate) }} </p>
                                <h5 class="font-24 my-0">{{ (plan.interval === 'month' ? plan.amount / 100 : plan.amount / 100 / 12) | currency: (plan.currency?.toUpperCase()) : (plan.currency === 'czk' ? 'Kč ' : (plan.currency === 'usd' ? '$ ' : '€ ')) : '1.0-2' : (plan.currency === 'czk' ? 'cs' : 'en' ) }}
                                  <!--                            <span>{{ 'subscription.per_user' | translate }}</span>-->
                                </h5>
                                <p class="py-0 my-1 font-13 text-muted">
                                  {{ 'subscription.per_user_per_month' | translate }} <!-- + ' ' + (plan.amount / 100 | currency: 'CZK' : 'Kč' : '1.0-2' : 'cs')) -->
                                </p>
                              </ng-container>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="(subscriptionProcessingLoading$ | async) && selectedPlanID === plan.plan_ID"
                             class="position-absolute left-0 top-0 w-100 h-100 d-flex justify-content-between align-items-center">
                              <span
                                      aria-hidden="true"
                                      class="spinner-border spinner-border-sm mx-auto" role="status"
                                      style="width: 30px; height: 30px;"
                              ></span>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
      </div>
    </div> <!-- end col-->
  </div>

  <!-- features settings -->
  <app-subscription-settings-features></app-subscription-settings-features>

  <!-- start Storage space -->
  <div class="row justify-content-center">
    <div class="col-12">
      <h4 class="text-dark page-title mb-3">
        <span>{{ 'subscription.storage_space' | translate }}</span>
      </h4>

      <div class="card shadow">
        <app-help name="subscription.used_space"></app-help>
        <div class="card-body">
          <div class="row align-items-center">
            <div class="col-12">
              <h3 class="my-2 py-1">
                <span>{{ 'subscription.storage_space_used' | translate }}</span>
                <span>&nbsp;</span>
                <span>{{ subscription.storage_size || 0 }}</span>
                <span>MB / </span>
                <span>{{ subscription.max_storage_size }}</span>
                <span>MB</span>
              </h3>

              <div class="progress mb-2">
                <div
                        [attr.aria-valuenow]="subscription.storage_size_percent"
                        [style.width.%]="subscription.storage_size_percent"
                        aria-valuemax="100"
                        aria-valuemin="0"
                        class="progress-bar progress-bar-striped"
                        role="progressbar"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end Storage space -->

  <!-- start Ai credits -->
  <div class="row justify-content-center">
    <div class="col-12">
        <h4 class="text-dark page-title mb-3">
            <span>{{ 'subscription.ai_credits' | translate }}</span>
        </h4>

        <div class="card shadow">
            <app-help name="subscription.used_credits"></app-help>
            <div class="card-body">
                <div class="row align-items-center">
                    <div class="col-12">
                        <h3 class="my-2 py-1">
                            <span>{{ 'subscription.ai_credits_used' | translate }}</span>
                            <span>&nbsp;</span>
                            <span>{{ subscription.ai.total_credits - subscription.ai.available_credits }}</span>
                            <span> / </span>
                            <span>{{ subscription.ai.total_credits }}</span>
                            <span> {{ 'subscription.credits' | translate }}</span>
                        </h3>

                        <div class="progress mb-2">
                            <div
                                [attr.aria-valuenow]="((subscription.ai.total_credits - subscription.ai.available_credits) / subscription.ai.total_credits) * 100"
                                [style.width.%]="((subscription.ai.total_credits - subscription.ai.available_credits) / subscription.ai.total_credits) * 100"
                                aria-valuemax="100"
                                aria-valuemin="0"
                                class="progress-bar progress-bar-striped"
                                role="progressbar"
                            ></div>
                        </div>
                    </div>
                    <div class="col-12 text-right">
                        <small class="text-muted">
                            <span> {{ 'subscription.credits_info_part_1' | translate}} </span>
                            <span> {{subscription.ai.anchor_date | date: 'dd.MM.yyyy'}}.</span>
                            <span> {{ 'subscription.credits_info_part_2' | translate}} </span>
                            <a href="mailto:support@speybl.com">support&#64;speybl.com</a>.
                        </small>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
  <!-- end Ai credits -->

  <div [id]="'users'" class="row">
    <div class="col-12">
      <h4 class="text-dark page-title mb-3">
        <span>{{ 'subscription.users' | translate }}</span>
      </h4>

      <div class="card shadow mb-0">
        <app-help name="subscription.users_list"></app-help>
        <div class="card-body">
            <div class="row">
                <div class="col pr-0">
                    <input #searchInput
                        class="form-control form-control-sm"
                        (keyup)="onSearchChange($event)"
                        placeholder="{{ 'data_tables.search' | translate }}"
                        type="text"
                    >
                </div>
                <div class="col-auto">
                    <button
                        (click)="addUser()"
                        *ngIf="(canCreateUser$ | async)"
                        [createButton]="'subscription.action_create_user' | translate"
                    ></button>
                </div>
            </div>


          <div class="table-responsive">
            <table class="table table-centered mb-0">
              <ng-container *ngIf="(usersNew$ | async); let newUsers">
                <ng-container *ngIf="newUsers.length">
                  <tr>
                    <td
                      [attr.colspan]="subscription.showPrice ? '4' : '5'"
                      class="font-weight-bold text-center"
                    >
                      <span>{{ 'subscription.users_new' | translate }}</span>
                    </td>
                  </tr>

                  <tr>
                    <th>{{ 'subscription.table_user' | translate }}</th>
                    <th>{{ 'subscription.table_email' | translate }}</th>
                    <th>{{ 'subscription.table_type' | translate }}</th>
                    <th>{{ 'subscription.table_action' | translate }}</th>
                    <th class="text-right">{{ 'subscription.table_activate' | translate }}</th>
                  </tr>

                  <ng-container
                    *ngFor="let user of newUsers"
                    [ngTemplateOutletContext]="{ $implicit: user, listType: 'new' }"
                    [ngTemplateOutlet]="userTableRowTemplate"
                  ></ng-container>
                </ng-container>
              </ng-container>

              <tr>
                <th>{{ 'subscription.table_user' | translate }}</th>
                <th>{{ 'subscription.table_email' | translate }}</th>
                <th>{{ 'subscription.table_type' | translate }}</th>
                  <th>{{ 'subscription.last_logged_in' | translate }}</th>
                <th>{{ 'subscription.table_action' | translate }}</th>
                <th class="text-right">{{ 'subscription.table_active' | translate }}</th>
              </tr>

              <ng-container
                *ngFor="let user of usersCurrent$ | async"
                [ngTemplateOutletContext]="{ $implicit: user, listType: 'current' }"
                [ngTemplateOutlet]="userTableRowTemplate"
              ></ng-container>
            </table>
          </div>

        </div>
      </div>
    </div>
  </div>
  <div *ngIf="(activeUsersChange$ | async) && (canEdit$ | async)"
       class="quantity-button shadow px-1 px-sm-3 px-md-3 pr-lg-4 pl-lg-4"
  >
    <p class="text-dark m-0 d-flex align-items-center text-left mr-2 mr-md-0">
      <span class="mr-1"> {{ 'subscription.changes_were_applied' | translate }} </span>
      <span class="mr-1" *ngIf="subscription.subscription.per_seats_pricing"> {{ 'subscription.subscription_price_may_change' | translate }} </span>
      <span class="mr-1" *ngIf="activeUsersChangeWithInvite$ | async"> {{ 'subscription.subscription_price_may_change_invite' | translate }} </span>
    </p>
    <button (click)="updateSubscription()"
            [disabled]="loadingUsersChange"
            class="btn btn-primary">
      <span *ngIf="loadingUsersChange"
            aria-hidden="true"
            class="spinner-border spinner-border-sm mr-1" role="status"
            style="width: 20px; height: 20px;"
      ></span>
      {{ 'subscription.action_apply_changes' | translate }}
    </button>
  </div>
</div>

<ng-template #loadingTemplate>
    <div *ngFor="let number of [0, 1, 2]" class="col-12 col-lg-4">
        <div class="card shadow p-3">
            <div class="item">
                <ngx-skeleton-loader [theme]="{
                    'margin': '15px 0'
                  }" appearance="line" count="15">
                </ngx-skeleton-loader>
            </div>
        </div>
    </div>
</ng-template>
