<ng-template
  #content
  let-modal
>
  <div class="modal-content">
    <div class="modal-header">
      <p class="modal-title font-16 mb-0 text-dark">
        <span>{{ 'employees_benefits.assign' | translate }}</span>
      </p>

      <button
        aria-label="Close"
        class="close"
        (click)="modal.dismiss()"
      >
        <span
          aria-hidden="true"
          class="font-24 text-muted"
        >
          <i class="mdi mdi-close"></i>
        </span>
      </button>
    </div>

    <div class="modal-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card mb-0 shadow-none">
            <div class="card-body p-0">
              <form
                autocomplete="off"
                [formGroup]="assignBenefitsForm"
                (ngSubmit)="onSubmitBenefit()"
              >
                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <label class="control-label">
                        <span>{{ 'employees_benefits.benefits' | translate }}</span>
                      </label>

                      <ng-select
                        bindLabel="name"
                        bindValue="benefit_ID"
                        class="custom"
                        formControlName="benefit_ID"
                        [items]="benefits$ | async"
                        [placeholder]="'employees_benefits.without_benefit' | translate"
                      ></ng-select>

                      <div *ngIf="submittedBenefits && f.benefit_ID.errors">
                        <p
                          *ngIf="f.benefit_ID.errors.required"
                          class="text-danger validation-text"
                        >
                          <span>{{ 'angular_validation.field' | translate }} </span>
                          <span>{{ 'employees_benefits.benefits' | translate }} </span>
                          <span>{{ 'angular_validation.required' | translate }}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  *ngIf="canCreateBenefit$ | async"
                  class="row"
                >
                  <div class="col-12 text-right">
                    <button
                      class="btn btn-link p-0"
                      type="button"
                      (click)="openBenefitCreateModal()"
                    >{{ 'employees_benefits.action_create' | translate }}</button>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <label class="control-label">
                        <span>{{ 'benefits.handover_date' | translate }}</span>
                      </label>

                      <input (flatpickrClose)="fpHelper.applyAltInputValue($event, false)"
                        mwlFlatpickr [disableMobile]="true"
                        allowInput="false"
                        altFormat="d.m.Y"
                        altInput="true"
                        class="form-control"
                        dateFormat="Y-m-d"
                        formControlName="handover_date"
                        name="handover_date"
                        type="text"
                        [locale]="locale$ | async"
                      >

                      <div *ngIf="submittedBenefits && f.handover_date.errors">
                        <p
                          *ngIf="f.handover_date.errors.required"
                          class="text-danger validation-text"
                        >
                          <span>{{ 'angular_validation.field' | translate }} </span>
                          <span>{{ 'benefits.handover_date' | translate }} </span>
                          <span>{{ 'angular_validation.required' | translate }}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row mt-2">
                  <div class="col-6">
                    <button class="btn btn-primary">
                      <span>{{ 'employees_benefits.assign' | translate }}</span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-container *ngIf="assignedBenefits$ | async as benefits">
  <div class="row">
    <div class="col-12">
      <div class="card shadow">
        <app-help name="employee.detail.benefits"></app-help>

        <div class="card-header py-3">
          <div class="row">
            <div class="col-sm-6 text-left my-auto">
              <p class="modal-title font-16 pt-2 pb-2 text-dark">
                <span>{{ 'benefits.page_title_index' | translate }}</span>
              </p>
            </div>

            <div
              *ngIf="canEditBenefit$ | async"
              class="col-sm-6 text-right my-auto"
            >
              <button
                [createButton]="'employees_benefits.assign' | translate"
                (click)="openBenefitAssignModal(content)"
              ></button>
            </div>
          </div>
        </div>

        <div class="card-body px-4">
          <div class="row">
            <div class="col-12">
              <div class="table-responsive">
                <app-data-table
                  [columnsToFilterIn]="['employee_cost', 'employer_cost', 'name']"
                  [rows]="benefits"
                  [trackByFn]="trackByBenefit"
                >
                  <ng-template #headerRow>
                    <th
                      appDataTableHeaderCell
                      [disableSort]="true"
                    >{{ 'employees_benefits.name' | translate }}</th>

                    <th
                      appDataTableHeaderCell
                      [disableSort]="true"
                    >{{ 'employees_benefits.employee_cost' | translate }}</th>

                    <th
                      *ngIf="canEditBenefit$ | async"
                      appDataTableHeaderCell
                      [disableSort]="true"
                    >{{ 'employees_benefits.employer_cost' | translate }}</th>

                    <th
                      appDataTableHeaderCell
                      [disableSort]="true"
                    >{{ 'employees_benefits.handover_date' | translate }}</th>

                    <th
                      appDataTableHeaderCell
                      [disableSort]="true"
                    >{{ 'employees_benefits.removal_date' | translate }}</th>

                    <th
                      appDataTableHeaderCell
                      class="text-right"
                      [disableSort]="true"
                    >{{ 'employees_benefits.table_action' | translate }}</th>
                  </ng-template>

                  <ng-template
                    #dataRow
                    let-row
                  >
                    <td appDataTableDataCell>{{ row.name }}</td>

                    <td appDataTableDataCell>
                      <span>{{ row.employee_cost | localizedCurrency }}</span>
                    </td>

                    <td appDataTableDataCell
                        *ngIf="canEditBenefit$ | async">
                      <span>{{ row.employer_cost | localizedCurrency }}</span>
                    </td>

                    <td appDataTableDataCell>{{ row.handover_date | date:'dd.MM.yyyy' }}</td>

                    <td appDataTableDataCell>{{ row.removal_date | date:'dd.MM.yyyy' }}</td>

                    <td
                      appDataTableDataCell
                      class="text-right"
                    >
                      <a
                        *ngIf="canEditBenefit$ | async"
                        class="btn btn-white btn-icon btn-sm mr-1"
                        title="{{ 'employees_benefits.action_view' | translate }}"
                        [routerLink]="['/benefit-bonus', 'benefit', 'overview', row.benefit_ID]"
                      >
                        <i class="mdi mdi-eye-outline mdi-24px text-muted"></i>
                      </a>
                    </td>
                  </ng-template>
                </app-data-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
