import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject } from 'rxjs';
import { ImportService } from 'src/app/import/state/import.service';
import { Employee } from '../../../../employee/models/Employee';
import { EmployeeService } from '../../../../employee/services/employee.service';
import { SalaryService } from '../../../../employee/services/salary.service';
import { ApiHelper } from '../../../../shared/common/ApiHelper';
import { SwalHelper } from '../../../../shared/common/SwalHelper';

@Component({
    selector: 'app-import-overview',
    templateUrl: './import-real-wages.component.html',
    styleUrls: ['./import-real-wages.component.css']
})
export class ImportRealWagesComponent implements OnInit {

    public processFileQueue$: Observable<void>;
    public clearFileQueue$: Observable<void>;
    public employees: Array<Employee>;
    public unselectedEmployees: Array<Employee>;
    public payrollFileAdditionalData: object;
    public employeePayrolls: Array<any>;
    public payrollsFormValue: any;
    public defaultEmployees = {};
    public defaultDate: { month: string, year: string };
    public employeeDuplicates = [];
    public filesSize = 0;
    public filesCount = 0;
    public isCollapsed = true;
    public submitted = false;
    public uploadingFiles = false;

    private _clearFileQueue$ = new Subject<void>();
    private _processFileQueue$ = new Subject<void>();

    public constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _salaryService: SalaryService,
        private _employeeService: EmployeeService,
        private _importService: ImportService,
        private _route: ActivatedRoute,
        private _router: Router,
        private _modalService: NgbModal,
        private _formBuilder: UntypedFormBuilder,
        private _apiHelper: ApiHelper,
        private _swalHelper: SwalHelper
    ) { }

    public ngOnInit(): void {

        this.clearFileQueue$ = this._clearFileQueue$;
        this.processFileQueue$ = this._processFileQueue$;
    }

    public addedFile($event: File): void {
        this._salaryService.importRealWages($event)
            .then(() => {},
                (error: any) => {});
    }

    public success(): void {

    }
}
