<ng-container *ngIf="activeOnboardings; else loadingtemplate">
  <ng-template
    #content
    let-modal
>
  <div class="modal-content">
    <div class="modal-header">
      <p class="modal-title font-16 mb-0 text-dark">
        <span>{{ 'onboardings.assign_employee' | translate }}</span>
      </p>

      <button
          (click)="modal.dismiss()"
          aria-label="Close"
          class="close"
          type="button"
      >
          <span
              aria-hidden="true"
              class="font-24 text-muted"
          >
            <i class="mdi mdi-close"></i>
          </span>
      </button>
    </div>

    <div class="modal-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card mb-0 shadow-none">
            <div class="card-body p-0">
              <form
                  #f="ngForm"
                  (ngSubmit)="onSubmit(f.form)"
              >
                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <label class="control-label">
                        <span>{{ 'onboardings.start_date' | translate }}</span>
                      </label>

                      <input [locale]="locale$ | async"
                             allowInput="false"
                             altFormat="d.m.Y"
                             altInput="true"
                             class="form-control"
                             dateFormat="Y-m-d"
                             mwlFlatpickr [disableMobile]="true"
                             name="start_date"
                             ngModel
                             type="text"
                      >
                    </div>
                  </div>
                  <div class="col-12 text-right">
                    <small class="text-muted">
                      {{ 'onboardings.start_date_description' | translate }}
                    </small>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <label class="control-label">
                        <span>{{ 'onboardings.onboardings' | translate }}</span>
                      </label>

                      <ng-select
                          [closeOnSelect]="false"
                          [items]="(data$ | async)?.onboardings"
                          [multiple]="true"
                          bindLabel="name"
                          bindValue="onboarding_ID"
                          class="custom"
                          name="onboardingsIDs"
                          ngModel
                          required
                      ></ng-select>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <label class="control-label">
                        <span>{{ 'onboardings.employees' | translate }}</span>
                      </label>

                      <ng-select
                          [closeOnSelect]="false"
                          [items]="(data$ | async)?.employees"
                          [multiple]="true"
                          bindLabel="fullname"
                          bindValue="employee_ID"
                          class="custom"
                          name="employeesIDs"
                          ngModel
                          required
                      ></ng-select>
                    </div>
                  </div>
                </div>

                <div class="row mt-2">
                  <div class="col-12 text-right">
                    <button [disabled]="f.form.invalid" submitButton class="btn btn-primary">
                      <span>{{ 'onboardings.action_save' | translate }}</span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

  <div class="row">
    <div class="col-12">
    <div class="card shadow">
      <app-help name="dashboard.active_onboardings"></app-help>
      <div class="card-body">
        <div *ngIf="(canCreate$ | async)" class="row mb-2">
          <div class="col-12 text-right">
            <button
                (click)="openAssignModal(content)"
                [createButton]="'onboardings.action_create' | translate"
                class="ml-2"
            ></button>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="table-responsive">
              <app-data-table
              [customNoDataMessage]="'onboardings.empty_onboardings_widget_error' | translate"
              [disableInPlaceSorting]="true"
              [rows]="activeOnboardings"
              [showSearch]="false"
            >
              <ng-template #headerRow>
                <th [disableSort]="true"
                  appDataTableHeaderCell="is_completed"
                  >{{ 'onboardings.employee' | translate }}</th>

                <th [disableSort]="true"
                  appDataTableHeaderCell="name"
                  >{{ 'onboardings.onboarding' | translate }}</th>

                <th [disableSort]="true"
                  appDataTableHeaderCell="name"
                  >{{ 'onboardings.progress' | translate }}</th>

                <th
                [disableSort]="true"
                appDataTableHeaderCell
                class="text-right"
                >{{ 'onboardings.action_view_onboarding' | translate }}</th>
              </ng-template>

              <ng-template
                #dataRow
                let-row
              >
              <td appDataTableDataCell class="w-sm-15">
                <app-employee-box-avatar [employee]="row.employee"></app-employee-box-avatar>
              </td>

                <td appDataTableDataCell>
                  <div>
                  {{ row.name }}
                  </div>
                </td>

                <td appDataTableDataCell>
                  <div class="progress w-100 mb-2">
                    <div
                      [style.width.%]="row.percent_progress"
                      aria-valuemax="100"
                      aria-valuemin="0"
                      aria-valuenow="progressBarWidth"
                      class="progress-bar"
                      role="progressbar"
                    ></div>
                  </div>
                    <div class="text-center">
                        <span *ngIf="row.first_task_to_be_done_datetime"
                              [ngClass]="{'text-danger': isAfterDeadline(row.first_task_to_be_done_datetime)}"
                              [ngbTooltip]="('onboardings.first_task_to_be_done' | translate) + (row.first_task_to_be_done_datetime | date:'dd.MM.yyyy')"
                              container="body"
                              placement="top"> {{ row.first_task_to_be_done_datetime | date:'dd.MM.yyyy' }}</span>
                    </div>
                </td>

                <td
                  appDataTableDataCell
                  class="text-right"
                >
                  <a
                    (click)="openEmployeeOnboardingOverviewModal(row.employee_onboarding_ID, row.employee.employee_ID)"
                    class="btn btn-white btn-icon btn-sm mr-1"
                    title="{{ 'todo.action_view' | translate }}"
                  >
                    <i class="mdi mdi-eye-outline mdi-24px text-muted"></i>
                  </a>
                </td>
              </ng-template>
            </app-data-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
</ng-container>
<ng-template #loadingtemplate>
  <div class="row">
    <div class="col-12">
      <div class="card shadow p-3">
        <div class="item">
          <ngx-skeleton-loader [theme]="{
        'margin': '15px 0'
      }" appearance="line" count="7"></ngx-skeleton-loader>
        </div>
      </div>
    </div>
  </div>
</ng-template>
