import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable } from 'rxjs';
import { ListParameters, ListResponse, transformParameters } from '../../api';
import { ApiHelper } from '../common/ApiHelper';
import { ActivityLog, ActivityLogFilter } from '../models/ActivityLog';

@Injectable({
    providedIn: 'root'
})
export class ActivityLogService {

    constructor(
        private _http: HttpClient,
        private _apiHelper: ApiHelper,
        private _loaderService: NgxUiLoaderService
    ) { }

    public getList(params: ListParameters<ActivityLog>, filterParameters?: {}, mode = 'simple', scope = null): Observable<ListResponse<ActivityLog>> {
        const body: any = {
            ...transformParameters(params),
            filter: filterParameters,
            mode
        };

        return this._http.post<ListResponse<ActivityLog>>('/api/activity-log/list', body, {params: {scope}});
    }

    public getFilters(mode = 'simple'): Observable<ActivityLogFilter> {
        return this._http.get<any>(`/api/activity-log/filters?mode=${mode}`);
    }

    public async setProcessedUnprocessed(activityIDs: number[], process = true): Promise<any> {
        const url = process ? '/api/activity-log/set-processed' : '/api/activity-log/set-unprocessed';
        return new Promise(resolve => {
            this._http.post<any>(url, {activity_IDs: activityIDs})
                .subscribe(
                    response => {
                        this._loaderService.stopLoader('master');

                        resolve(response);
                    },
                    error => {
                        this._apiHelper.handleErrorResponse(error);

                        this._loaderService.stopLoader('master');
                    }
                );
        });
    }
}
