<div class="modal-content">
    <div class="modal-header">
        <p class="modal-title font-16 mb-0 text-dark">
            <span>{{ 'employees.action_terminate' | translate }}</span>
        </p>

        <button
                (click)="activeModal.dismiss()"
                aria-label="Close"
                class="close"
        >
      <span
              aria-hidden="true"
              class="font-24 text-muted"
      >
        <i class="mdi mdi-close"></i>
      </span>
        </button>
    </div>

    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <div class="card mb-0 shadow-none">
                    <div class="card-body p-0">
                        <form
                                [formGroup]="terminationEditForm"
                                autocomplete="off"
                        >
                            <div *ngIf="employeeTerminated" class="alert alert-danger mb-3 w-100 shadow">
                                <span *ngIf="!isTerminatedInPast()"> {{ 'employees.terminate_alert_text' | translate }} </span>
                                <span *ngIf="isTerminatedInPast()"> {{ 'employees.terminated_alert_text' | translate }} </span>
                                <span> {{ employee.termination.termination_end_date | date: 'dd.MM.yyyy' }}. </span>
                            </div>

                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label class="control-label">
                                            <span>{{ 'employees.termination_end_date' | translate }}</span>
                                        </label>

                                        <input
                                                (flatpickrClose)="fpHelper.applyAltInputValue($event, false)"
                                                [locale]="locale$ | async"
                                                allowInput="false"
                                                altFormat="d.m.Y"
                                                altInput="true"
                                                class="form-control"
                                                dateFormat="Y-m-d"
                                                formControlName="termination_end_date"
                                                mwlFlatpickr [disableMobile]="true"
                                                name="termination_end_date"
                                                type="text"
                                        >

                                        <div *ngIf="submitted && f.termination_end_date.errors">
                                            <p
                                                    *ngIf="f.termination_end_date.errors.required"
                                                    class="text-danger validation-text"
                                            >
                                                <span>{{ 'angular_validation.field' | translate }} </span>
                                                <span>{{ 'employees.termination_end_date' | translate }} </span>
                                                <span>{{ 'angular_validation.required' | translate }}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label class="control-label">
                                            <span>{{ 'employees.termination_reason' | translate }}</span>
                                        </label>

                                        <ng-select
                                                [clearable]="false"
                                                class="custom"
                                                formControlName="termination_reason"
                                                name="termination_reason"
                                        >
                                            <ng-option value="RESIGNATION">
                                                <span>{{ 'employees.termination_reason_RESIGNATION' | translate }}</span>
                                            </ng-option>

                                            <ng-option value="DENIED">
                                                <span>{{ 'employees.termination_reason_DENIED' | translate }}</span>
                                            </ng-option>

                                            <ng-option value="SURPLUS">
                                                <span>{{ 'employees.termination_reason_SURPLUS' | translate }}</span>
                                            </ng-option>

                                            <ng-option value="AGREEMENT">
                                                <span>{{ 'employees.termination_reason_AGREEMENT' | translate }}</span>
                                            </ng-option>

                                            <ng-option value="TEST_TIME_EMPLOYEE">
                                                <span>{{ 'employees.termination_reason_TEST_TIME_EMPLOYEE' | translate }}</span>
                                            </ng-option>

                                            <ng-option value="TEST_TIME_COMPANY">
                                                <span>{{ 'employees.termination_reason_TEST_TIME_COMPANY' | translate }}</span>
                                            </ng-option>

                                            <ng-option value="PARAGRAPH49">
                                                <span>{{ 'employees.termination_reason_PARAGRAPH49' | translate }}</span>
                                            </ng-option>

                                            <ng-option value="OTHER">
                                                <span>{{ 'employees.termination_reason_OTHER' | translate }}</span>
                                            </ng-option>
                                        </ng-select>

                                        <div *ngIf="submitted && f.termination_reason.errors">
                                            <p
                                                    *ngIf="f.termination_reason.errors.required"
                                                    class="text-danger validation-text"
                                            >
                                                <span>{{ 'angular_validation.field' | translate }} </span>
                                                <span>{{ 'employees.termination_reason' | translate }} </span>
                                                <span>{{ 'angular_validation.required' | translate }}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label class="control-label">
                                            <span>{{ 'employees.termination_description' | translate }}</span>
                                        </label>

                                        <input
                                                class="form-control"
                                                formControlName="termination_description"
                                                name="termination_description"
                                                type="text"
                                        >

                                        <div *ngIf="submitted && f.termination_description.errors">
                                            <p
                                                    *ngIf="f.termination_description.errors.required"
                                                    class="text-danger validation-text"
                                            >
                                                <span>{{ 'angular_validation.field' | translate }} </span>
                                                <span>{{ 'employees.termination_description' | translate }} </span>
                                                <span>{{ 'angular_validation.required' | translate }}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <label class="control-label">
                                        <span>{{ 'employees.termination_attachments' | translate }}</span>
                                    </label>

                                    <app-upload
                                            [allowTakingPicture]="false"
                                            [modelName]="'employee_ID'"
                                            [modelValue]="employee.employee_ID"
                                            [secondaryModelName]="'file_type'"
                                            [secondaryModelValue]="'termination_file'"
                                    ></app-upload>
                                </div>
                            </div>

                            <div class="row mt-3">
                                <div class="col-12 text-right">
                                        <small *ngIf="f.termination_end_date.value" class="text-muted">
                                            {{ 'employees.termination_disclaimer_part_1' | translate }}
                                            {{ f.termination_end_date.value | date: 'dd. MM. YYYY' }}
                                            {{ 'employees.termination_disclaimer_part_2' | translate }}
                                        </small>
                                    <br>
                                        <small *ngIf="(subscription$ | async)?.subscription.per_seats_pricing" class="text-muted">
                                            {{ 'subscription.subscription_price_may_change' | translate }}
                                        </small>
                                </div>
                            </div>

                            <div class="row mt-2 justify-content-end">
                                <div *ngIf="employeeTerminated" class="col-6 text-right my-auto">
                                    <button (click)="onSubmit(true)"
                                            class="btn btn-danger">
                                        <span>{{ 'employees.action_cancel_termination' | translate }}</span>
                                    </button>
                                </div>
                                <div class="col-6 text-right">
                                    <button (click)="onSubmit(false)"
                                            [disabled]="employeeTerminated"
                                            class="btn btn-success">
                                        <span>{{ 'employees.action_confirm_termination' | translate }}</span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
