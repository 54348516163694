import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { cacheable } from '@datorama/akita';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiHelper } from 'src/app/shared/common/ApiHelper';
import { SwalHelper } from 'src/app/shared/common/SwalHelper';
import { ApiResponse } from 'src/app/shared/models/ApiResponse';
import { File } from '../../employee/models/File';
import { EmployeeReview } from './employee-review.model';
import { EmployeeReviewStore } from './employee-review.store';

@Injectable({
    providedIn: 'root'
})
export class EmployeeReviewService {
    public constructor(
        private _apiHelper: ApiHelper,
        private _employeeReviewStore: EmployeeReviewStore,
        private _http: HttpClient,
        private _swalHelper: SwalHelper,
    ) { }

    public async deleteReview(employeeID: number, reviewID: number | string, modalRef: NgbActiveModal): Promise<void> {
        const deleteConfirmed = await this._swalHelper.showConfirmDeleteDialog();

        if (deleteConfirmed) {
            this._http.delete<ApiResponse>(`/api/employees/${employeeID}/evaluations/reviews/${reviewID}`, {})
                .subscribe(
                    response => {
                        this._employeeReviewStore.remove(reviewID);

                        this._apiHelper.handleSuccessResponse(response);

                        modalRef.close();
                    },
                    error => this._apiHelper.handleErrorResponse(error)
                );
        }
    }

    public get(employeeID: number): Observable<void> {
        const request = this._http.get<Array<EmployeeReview>>(`/api/employees/${employeeID}/evaluations/reviews`)
            .pipe(
                map(response => this._employeeReviewStore.add(response))
            );

        return cacheable(this._employeeReviewStore, request);
    }

    public getAllFilesByReview(employeeID: number, reviewID: number): Observable<Array<File>> {
        return this._http.get<Array<File>>(`/api/employees/${employeeID}/evaluations/reviews/${reviewID}/files`);
    }

    public getRadarForEmployeeKPI(employeesIDs?: Array<number>, kpisIDs?: Array<any>, employeeReviewIDs?: Array<any>, latest?: boolean, from?: string, to?: string): Observable<Array<any>> {
        let params = new HttpParams();

        if (employeesIDs) {
            params = params.set('employees_IDs', employeesIDs.join(','));
        }

        if (kpisIDs) {
            params = params.set('kpis_IDs', kpisIDs.join(','));
        }

        if (employeeReviewIDs) {
            params = params.set('employee_review_IDs', employeeReviewIDs.join(','));
        }

        if (latest) {
            params = params.set('latest', latest);
        }

        if (from && to) {
            params = new HttpParams()
                .set('from', from)
                .set('to', to);
        }

        return this._http.get<Array<any>>(`/api/employees/evaluations/overview`, {params});
    }

    public saveManyReviews(form: any, kpiID: number | string): void {
        const body = {
            employees: form.employees,
            date: form.date
        };
        this._http.post<ApiResponse>(`/api/evaluations/${kpiID}/reviews`, body)
            .subscribe(
                response => this._apiHelper.handleSuccessResponse(response),
                error => this._apiHelper.handleErrorResponse(error)
            );
    }

    public saveReview(form: any, employeeID: number, modalRef: NgbActiveModal): void {
        this._http.post<ApiResponse & { review: EmployeeReview; }>(`/api/employees/${employeeID}/evaluations/reviews`, form)
            .subscribe(
                response => {
                    this._apiHelper.handleSuccessResponse(response);

                    this._employeeReviewStore.add(response.review);

                    modalRef.close();
                },
                error => this._apiHelper.handleErrorResponse(error)
            );
    }
}
