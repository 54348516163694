<!--<editor-fold desc="MODALY">-->
<ng-template #filterFormOptions let-modal>
    <div class="modal-content">
        <div class="modal-header">
            <p class="modal-title font-16 mb-0 text-dark">
                <span>{{ 'employees_datetimes.adjust_filters' | translate }}</span>
            </p>
            <button (click)="modal.dismiss()" aria-label="Close" class="close">
        <span aria-hidden="true" class="font-24 text-muted">
          <i class="mdi mdi-close"></i>
        </span>
            </button>
        </div>

        <div class="modal-body">
            <form (ngSubmit)="onSubmit()" [formGroup]="filterForm" class="filter-form">
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label>{{ 'employees_datetimes.filter_date_from' | translate }}</label>
                            <input [locale]="locale$ | async" allowInput="false" altFormat="d.m.Y"
                                   altInput="true"
                                   class="form-control"
                                   dateFormat="Y-m-d"
                                   formControlName="from"
                                   mwlFlatpickr [disableMobile]="true"
                                   (flatpickrClose)="fpHelper.applyAltInputValue($event, false)"
                                   type="text">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label>{{ 'employees_datetimes.filter_date_to' | translate }}</label>

                            <input [locale]="locale$ | async" allowInput="false" altFormat="d.m.Y"
                                   altInput="true"
                                   class="form-control"
                                   dateFormat="Y-m-d"
                                   formControlName="to"
                                   mwlFlatpickr [disableMobile]="true"
                                   (flatpickrClose)="fpHelper.applyAltInputValue($event, false)"
                                   type="text">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label class="form-group">{{ 'employees_datetimes.filter_type' | translate }}</label>
                            <div>
                                <ng-select
                                    [closeOnSelect]="false"
                                    [items]="datetimeTypes$ | async"
                                    [multiple]="true"
                                    bindLabel="name"
                                    bindValue="employee_datetime_type_ID"
                                    class="custom"
                                    formControlName="types"
                                >
                                </ng-select>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label>{{ 'employees_datetimes.filter_employee' | translate }}</label>

                            <ng-select
                                [bindValue]="'employee_ID'"
                                [bindLabel]="'fullname'"
                                [clearable]="false"
                                [closeOnSelect]="false"
                                [items]="employees$ | async"
                                [multiple]="true"
                                class="custom"
                                formControlName="employee_IDs"
                            >
                            </ng-select>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 text-right">
                        <button class="btn btn-primary">
                            <span>{{ 'analysis.action_filter' | translate }}</span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>
<!--</editor-fold desc="MODALY">-->

<div class="card shadow position-static">
    <app-help name="employee.employee_plan_chart"></app-help>
    <div class="card-header py-3">
        <div class="row">
            <div class="col-auto text-left my-auto">
                <p class="modal-title font-16 text-dark pt-2 pb-2">
                    {{ 'employees_datetimes.plan_title' | translate }}
                </p>
            </div>
            <div class="col text-right">
                <ng-container *ngIf="showSwitch">
                    <div class="btn-group btn-group-toggle mr-2" data-toggle="buttons">
                        <label class="btn btn-primary" style="min-width: 100px !important;" [class.active]="false">
                            <input type="radio" (change)="switchType('gannt')">{{ 'employees_datetimes.gantt' | translate }}
                        </label>
                        <label class="btn btn-primary" style="min-width: 100px !important;" [class.active]="false">
                            <input type="radio" (change)="switchType('calendar')">{{ 'employees_datetimes.full_calendar' | translate }}
                        </label>
                        <label class="btn btn-primary" style="min-width: 100px !important;" [class.active]="true">
                            <input type="radio" checked>{{ 'employees_datetimes.daily_summary' | translate }}
                        </label>
                    </div>
                </ng-container>
                <button (click)="openFilterFormOptions()" [formGroup]="filterForm" class="btn btn-primary btn-icon" type="button">
                    <i class="mdi mdi-filter-outline"></i>
                </button>
            </div>
        </div>
    </div>
    <div class="card-body">
        <ng-container *ngIf="planCategories.length && !chartLoading; else loadingTemplate">
            <div class="table-fixed-column-wrapper">
                <div class="table-responsive">
                    <table class="table table-fixed table-bordered">
                        <thead>
                            <tr *ngIf="planCategories">
                                <th class="fixed-column"></th>
                                <th *ngFor="let category of planCategories" class="head-day text-left position-relative text-nowrap text-center">
                                    {{ category.formatted_short }} {{ category.formatted }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-template #axesTemplate>
                                <svg [attr.height]="svgSetting.height" [attr.width]="svgSetting.width">
                                    <g class="axes">
                                        <line [attr.x2]="svgSetting.width" [attr.y1]="svgSetting.topY + ((svgSetting.bottomY)-(svgSetting.topY)) / 2" [attr.y2]="svgSetting.topY + ((svgSetting.bottomY) - (svgSetting.topY)) / 2" stroke="rgba(223, 226, 230, .3)" stroke-width="1" x1="0"/>

                                        <ng-container *ngFor="let axis of svgSetting.axes">
                                            <line [attr.strokeWidth]="axis.primary ? 2 : 1" [attr.stroke]="axis.primary ? '#eef2f7' : 'rgba(223, 226, 230, .3)'" [attr.x1]="axis.x" [attr.x2]="axis.x" [attr.y1]="svgSetting.topY + (axis.primary ? 0 : 4)" [attr.y2]="svgSetting.bottomY - (axis.primary ? 0 : 4)"/>
                                            <text *ngIf="axis.primary" [attr.font-size]="svgSetting.fontSize" [attr.x]="axis.x" [attr.y]="svgSetting.fontSize + 2" fill="#ccc" text-anchor="middle">{{axis.label}}</text>
                                        </ng-container>
                                    </g>
                                </svg>
                            </ng-template>
                            <ng-container *ngIf="planData">
                                <ng-container *ngFor="let data of usedCompanyGroups">
                                    <ng-container *ngFor="let employeeData of planData[data.company_group_ID]">
                                        <tr>
                                            <th class="fixed-column">
                                                <app-employee-box-avatar [canView]="employeeData.employee.permissions.view" [employeeID]="employeeData.employee.employee_ID" [fullname]="employeeData.employee.label" class="pr-1"></app-employee-box-avatar>
                                            </th>

                                            <ng-container *ngFor="let dayData of employeeData.data; let index = index">
                                                <ng-template #popContent>
                                                    <div class="{{ dayData.duration ? 'mt-1' : '' }}">
                                                        <ng-container *ngIf="dayData.show_tooltip">
                                                            <p *ngFor="let employeeDatetimePart of dayData.parts" class="mb-0" [innerHTML]="employeeDatetimePart.label ? employeeDatetimePart.label : 'global.gantt_tooltip_label' | translate"></p>
                                                        </ng-container>
                                                    </div>
                                                    <div *ngIf="dayData.duration" class="w-100 position-absolute m-0 px-2" style="top: 25px; left: 0; background-color: #f1f3fa; border-bottom: 2px solid #dee3f3;">
                                                        <span style="font-size: 10px;">{{ 'employees_datetimes_types.work' | translate }} {{ dayData.duration_worktime }}, {{ 'employees_datetimes_types.absence' | translate }} {{ dayData.duration_absence }}, {{ 'global.total' | translate }} {{ dayData.duration_total }}</span>
                                                    </div>
                                                </ng-template>

                                                <td class="p-0">
                                                    <div [ngbPopover]="popContent" class="position-relative" container="body"
                                                         placement="top"
                                                         popoverTitle="{{planCategories[index]?.formatted_short + ' ' + planCategories[index]?.formatted}}"
                                                         triggers="mouseenter:mouseleave">
                                                        <svg [attr.height]="svgSetting.height" [attr.width]="svgSetting.width">
                                                            <ng-container *ngTemplateOutlet="axesTemplate"></ng-container>
                                                            <g class="datetimes">
                                                                <rect (click)="openDatetimeEditModal({type: 'EMPTY', start_timestamp: planCategories[index]?.start_timestamp, end_timestamp: planCategories[index]?.end_timestamp}, employeeData.employee, employeeData.highlight_employee, employeeData.showModal)"
                                                                      [attr.height]="svgSetting.height"
                                                                      [attr.width]="svgSetting.width"
                                                                      [attr.x]="0"
                                                                      [attr.y]="0"
                                                                      [style.fill]="'transparent'">
                                                                </rect>
                                                                <ng-container *ngFor="let part of dayData.parts">
                                                                    <ng-container>
                                                                        <ng-container *ngIf="part.type !== 'EMPTY'">
                                                                            <pattern  *ngIf="part.is_shift" height="1" id="diaHatch{{part.employee_datetime_ID}}" patternTransform="rotate(45)" patternUnits="userSpaceOnUse" width="4">
                                                                                <rect [style.fill]="getStripeColor(part.color)" height="1" width="2" x="0" y="0"/>
                                                                            </pattern>
                                                                            <rect [attr.fill]="hexToSemiTransparent(part.color, 0.5)"
                                                                                  [attr.height]="svgSetting.plannedHeight"
                                                                                  [attr.rx]="svgSetting.datetimeBoxRoundedCorner"
                                                                                  [attr.ry]="svgSetting.datetimeBoxRoundedCorner"
                                                                                  [attr.strokeWidth]="svgSetting.datetimeBoxStrokeThickness"
                                                                                  [attr.stroke]="part.color"
                                                                                  [attr.width]="calculateWidth(planCategories[index].start_timestamp, planCategories[index].end_timestamp, part.start_timestamp, part.end_timestamp)"
                                                                                  [attr.x]="calculateX(planCategories[index].start_timestamp, planCategories[index].end_timestamp, part.start_timestamp)"
                                                                                  [attr.y]="svgSetting.plannedTopY">
                                                                            </rect>
                                                                            <rect [attr.fill]="'url(#diaHatch'+part.employee_datetime_ID+')'"
                                                                                  [attr.height]="svgSetting.plannedHeight - 2 * svgSetting.datetimeBoxStrokeThickness"
                                                                                  [attr.rx]="svgSetting.datetimeBoxRoundedCorner"
                                                                                  [attr.ry]="svgSetting.datetimeBoxRoundedCorner"
                                                                                  [attr.width]="calculateWidth(planCategories[index].start_timestamp, planCategories[index].end_timestamp, part.start_timestamp, part.end_timestamp) - 2 * svgSetting.datetimeBoxStrokeThickness"
                                                                                  [attr.x]="calculateX(planCategories[index].start_timestamp, planCategories[index].end_timestamp, part.start_timestamp) + svgSetting.datetimeBoxStrokeThickness"
                                                                                  [attr.y]="svgSetting.plannedTopY + svgSetting.datetimeBoxStrokeThickness">
                                                                            </rect>
                                                                            <rect (click)="openDatetimeEditModal(part, employeeData.employee, employeeData.highlight_employee, employeeData.showModal)"
                                                                                  [attr.height]="svgSetting.plannedHeight"
                                                                                  [attr.width]="calculateWidth(planCategories[index].start_timestamp, planCategories[index].end_timestamp, part.start_timestamp, part.end_timestamp)"
                                                                                  [attr.x]="calculateX(planCategories[index].start_timestamp, planCategories[index].end_timestamp, part.start_timestamp)"
                                                                                  [attr.y]="svgSetting.plannedTopY"
                                                                                  [style.fill]="'transparent'">
                                                                            </rect>
                                                                        </ng-container>
                                                                    </ng-container>
                                                                </ng-container>
                                                                <ng-container *ngFor="let part of dayData.parts">
                                                                    <ng-container *ngIf="!part.planned">
                                                                        <ng-container *ngIf="part.type !== 'EMPTY'">
                                                                            <rect [attr.fill]="hexToSemiTransparent(part.color, 1)"
                                                                                  [attr.height]="svgSetting.actualHeight"
                                                                                  [attr.rx]="svgSetting.datetimeBoxRoundedCorner"
                                                                                  [attr.ry]="svgSetting.datetimeBoxRoundedCorner"
                                                                                  [attr.strokeWidth]="svgSetting.datetimeBoxStrokeThickness"
                                                                                  [attr.stroke]="part.color"
                                                                                  [attr.width]="calculateWidth(planCategories[index].start_timestamp, planCategories[index].end_timestamp, part.start_timestamp, part.end_timestamp)"
                                                                                  [attr.x]="calculateX(planCategories[index].start_timestamp, planCategories[index].end_timestamp, part.start_timestamp)"
                                                                                  [attr.y]="svgSetting.actualTopY">
                                                                            </rect>
                                                                            <rect (click)="openDatetimeEditModal(part, employeeData.employee, employeeData.highlight_employee, employeeData.showModal)"
                                                                                  [attr.height]="svgSetting.actualHeight"
                                                                                  [attr.width]="calculateWidth(planCategories[index].start_timestamp, planCategories[index].end_timestamp, part.start_timestamp, part.end_timestamp)"
                                                                                  [attr.x]="calculateX(planCategories[index].start_timestamp, planCategories[index].end_timestamp, part.start_timestamp)"
                                                                                  [attr.y]="svgSetting.actualTopY"
                                                                                  [style.fill]="'transparent'">
                                                                            </rect>
                                                                        </ng-container>
                                                                    </ng-container>
                                                                </ng-container>
                                                            </g>
                                                        </svg>
                                                    </div>
                                                </td>
                                            </ng-container>
                                        </tr>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="plan-chart-legend">
                    <span *ngFor="let t of datetimeTypes$ | async" class="color-box-wrap">
                        <span [style.background-color]="t?.color" class="color-box"></span>
                        <span class="color-box-label">{{ t?.name }}</span>
                    </span>
                <span *ngIf="isShift" class="color-box-wrap">
                        <span class="color-box">
                            <svg class="bar-group rounded-sm" height="20" width="20">
                                <pattern height="1" id="diaHatchForLegend" patternTransform="rotate(45)" patternUnits="userSpaceOnUse" width="4">
                                    <rect [style.fill]="'rgba(0,0,0,0.3)'" height="1" width="2" x="0" y="0"/>
                                </pattern>
                                <rect [style.fill]="'url(#diaHatchForLegend)'" class="bar-progress" height="20" width="20" x="0" y="0"></rect>
                            </svg>
                        </span>
                        <span class="color-box-label">{{ 'employees_datetimes.attendance_type_shift' | translate }}</span>
                    </span>
            </div>
        </ng-container>
        <ng-template #loadingTemplate>
            <div class="item">
                <ngx-skeleton-loader [theme]="{
        'margin': '15px 0'
      }" appearance="line" count="7"></ngx-skeleton-loader>
            </div>
        </ng-template>
    </div>
</div>
