import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { ListParameters, ListResponse, transformParameters } from 'src/app/api';
import { ApiHelper } from 'src/app/shared/common/ApiHelper';
import { SwalHelper } from 'src/app/shared/common/SwalHelper';
import { ApiResponse } from 'src/app/shared/models/ApiResponse';
import { CompanyEmployeePolicy } from '../models/CompanyEmployeePolicy';
import { CompanyEmployeePolicyAdjustement } from '../models/CompanyEmployeePolicyAdjustement';

@Injectable({
    providedIn: 'root'
})
export class PolicyService {
    public policies$ = this.getAllPolicies().pipe(shareReplay());

    public constructor(
        private _apiHelper: ApiHelper,
        private _http: HttpClient,
        private _loaderService: NgxUiLoaderService,
        private _swalHelper: SwalHelper
    ) { }

    public deleteAdjustement(employeeID: number, employeePolicyAdjustementID: number): Promise<'done'> {
        return new Promise(async (resolve, reject) => {
            const deleteConfirmed = await this._swalHelper.showConfirmDeleteDialog();

            if (deleteConfirmed) {
                this._loaderService.startLoader('master');

                this._http.delete<ApiResponse>(`/api/employees/${employeeID}/policies-adjustements/${employeePolicyAdjustementID}`)
                    .subscribe(
                        response => {
                            this._loaderService.stopLoader('master');

                            this._apiHelper.handleSuccessResponse(response);

                            resolve('done');
                        },
                        error => {
                            this._loaderService.stopLoader('master');

                            this._apiHelper.handleErrorResponse(error);
                        }
                    );
            } else {
                reject('error');
            }
        });
    }

    public deletePolicy(companyEmployeePolicyID: number): Promise<'done'> {
        return new Promise(async (resolve, reject) => {
            const deleteConfirmed = await this._swalHelper.showConfirmDeleteDialog();

            if (deleteConfirmed) {
                this._loaderService.startLoader('master');

                this._http.delete<ApiResponse>('/api/company-employee-policies/' + companyEmployeePolicyID)
                    .subscribe(
                        response => {
                            this._loaderService.stopLoader('master');

                            this._apiHelper.handleSuccessResponse(response);

                            resolve('done');
                        },
                        error => {
                            this._loaderService.stopLoader('master');

                            this._apiHelper.handleErrorResponse(error);
                        }
                    );
            } else {
                reject('error');
            }
        });
    }

    public getAllPolicies(): Observable<Array<CompanyEmployeePolicy>> {
        return this._http.get<Array<CompanyEmployeePolicy>>('/api/company-employee-policies');
    }

    public getTimeframes(): Observable<Array<string>> {
        return this._http.get<Array<string>>('/api/company-employee-policies/timeframes');
    }

    public getAllPolicyAdjustementsByEmployeeID(employeeID: number): Observable<Array<CompanyEmployeePolicyAdjustement>> {
        return this._http.get<Array<CompanyEmployeePolicyAdjustement>>(`/api/employees/${employeeID}/policies-adjustements`);
    }

    public getPolicyByID(companyEmployeePolicyID: number): Observable<CompanyEmployeePolicy> {
        return this._http.get<CompanyEmployeePolicy>(`/api/company-employee-policies/${companyEmployeePolicyID}`);
    }

    public getPolicyByEmployeeID(employeeID: number): Observable<Array<CompanyEmployeePolicy>> {
        return this._http.get<Array<CompanyEmployeePolicy>>(`/api/employees/${employeeID}/policies`);
    }

    public getPoliciesList(params: ListParameters<CompanyEmployeePolicy>): Observable<ListResponse<CompanyEmployeePolicy>> {
        return this._http.post<ListResponse<CompanyEmployeePolicy>>('/api/company-employee-policies/list', {...transformParameters(params)});
    }

    public savePolicy(form: CompanyEmployeePolicy, companyEmployeePolicyID?: number): Promise<'done'> {
        this._loaderService.startLoader('master');

        return new Promise(resolve => {
            let apiUrl = '/api/company-employee-policies';

            if (companyEmployeePolicyID) {
                apiUrl += `/${companyEmployeePolicyID}`;
            }

            this._http.post<ApiResponse>(apiUrl, form)
                .subscribe(
                    response => {
                        this._apiHelper.handleSuccessResponse(response);

                        resolve('done');

                        this._loaderService.stopLoader('master');
                    },
                    error => {
                        this._apiHelper.handleErrorResponse(error);

                        this._loaderService.stopLoader('master');
                    }
                );
        });
    }

    public savePolicyAdjustement(
        form: CompanyEmployeePolicyAdjustement,
        employeeID: number,
        employeePolicyAdjustmentID: number
    ): Promise<'done'> {
        this._loaderService.startLoader('master');

        return new Promise(resolve => {
            let apiUrl = `/api/employees/${employeeID}/policies-adjustements`;

            if (employeePolicyAdjustmentID) {
                apiUrl += `/${employeePolicyAdjustmentID}`;
            }

            this._http.post<ApiResponse>(apiUrl, form)
                .subscribe(
                    response => {
                        resolve('done');

                        this._apiHelper.handleSuccessResponse(response);

                        this._loaderService.stopLoader('master');
                    },
                    error => {
                        this._apiHelper.handleErrorResponse(error);

                        this._loaderService.stopLoader('master');
                    }
                );
        });
    }

    public savePolicytoEmployee(employeeID: number, companyEmployeePolicyID: number): Promise<'done'> {
        return new Promise(resolve => {
            this._loaderService.startLoader('master');

            this._http.post<ApiResponse>('/api/employees/' + employeeID + '/policies/assign-policy', {company_employee_policy_ID: companyEmployeePolicyID})
                .subscribe(
                    response => {
                        this._loaderService.stopLoader('master');

                        this._apiHelper.handleSuccessResponse(response);

                        resolve('done');
                    },
                    error => {
                        this._loaderService.stopLoader('master');

                        this._apiHelper.handleErrorResponse(error);
                    }
                );
        });
    }
}
