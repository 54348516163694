<form [formGroup]="form" autocomplete="off">
    <div class="row">
        <div class="col-lg-12">
            <div class="form-group">
                <label class="control-label">{{ 'employees_salaries.amount' | translate }}</label>
                <div [ngClass]="{'hidden-sensitive-value-input': hiddenInputs}">
                    <input formControlName="amount" currencyMask type="text" class="form-control" name="amount">
                </div>
                <div *ngIf="submitted && f.amount.errors">
                    <p *ngIf="f.amount.errors.required" class="text-danger validation-text">
                        {{ 'angular_validation.field' | translate }} {{'employees_salaries.amount' | translate }} {{'angular_validation.required' | translate }}
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <div class="form-group">
                <label class="control-label required-label" for="employees_salaries_frequency">
                    <span>{{ 'employees_salaries.frequency' | translate }}</span>
                </label>
                <ng-select id="employees_salaries_frequency" class="custom" formControlName="frequency" [clearable]="false" [class.is-invalid]="submitted && f.frequency.errors">
                    <ng-option *ngFor="let frequency of employeesSalariesFrequencies" [value]="frequency">
                        {{ ('employees_salaries.frequency_' + frequency) | translate }}
                    </ng-option>
                </ng-select>
                <div *ngIf="submitted && f.frequency.errors">
                    <p *ngIf="f.frequency.errors.required" class="text-danger validation-text">
                        <span>{{ 'angular_validation.field' | translate }} </span>
                        <span>{{ 'internal_documents.type' | translate }} </span>
                        <span>{{ 'angular_validation.required' | translate }}</span>
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <div class="form-group">
                <label class="control-label required-label">{{ 'employees_salaries.from' | translate }}</label>
                <input formControlName="from" type="text" mwlFlatpickr [disableMobile]="true"
                       allowInput="false"
                       altInput="true"
                       altFormat="d.m.Y"
                       dateFormat="Y-m-d"
                       [locale]="locale$ | async"
                       (flatpickrClose)="fpHelper.applyAltInputValue($event, false)" name="from" class="form-control">
                <div *ngIf="submitted && f.from.errors">
                    <p *ngIf="f.from.errors.required" class="text-danger validation-text">
                        {{ 'angular_validation.field' | translate }} {{'employees_salaries.from' | translate }} {{'angular_validation.required' | translate }}
                    </p>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="form-group">
                <label class="control-label">{{ 'employees_salaries.to' | translate }}</label>
                <input formControlName="to" type="text" mwlFlatpickr [disableMobile]="true"
                       allowInput="false"
                       altInput="true"
                       altFormat="d.m.Y"
                       dateFormat="Y-m-d"
                       [locale]="locale$ | async"
                       (flatpickrClose)="fpHelper.applyAltInputValue($event, false)" name="to" class="form-control">
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <div class="form-group">
                <label class="control-label">{{ 'employees_salaries.variable_amount' | translate }}</label>
                <div [ngClass]="{'hidden-sensitive-value-input': hiddenInputs}">
                    <input formControlName="variable_amount" currencyMask type="text" class="form-control" name="variable_amount">
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <div class="form-group">
                <label class="control-label">{{ 'employees_salaries.hour_cost' | translate }}</label>
                <div [ngClass]="{'hidden-sensitive-value-input': hiddenInputs}">
                    <input formControlName="hour_cost" currencyMask type="text" class="form-control" name="hour_cost">
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <div class="form-group">
                <label class="control-label">{{ 'employees_salaries.month_cost' | translate }}</label>
                <div [ngClass]="{'hidden-sensitive-value-input': hiddenInputs}">
                    <input formControlName="month_cost" currencyMask type="text" class="form-control" name="month_cost">
                </div>
            </div>
        </div>
    </div>

    <hr/>

    <h4 class="mb-3">
        {{'employees_salaries.taxes_pensions' | translate}}
    </h4>

    <div class="row">
        <div class="col-6">
            <label class="control-label">{{ 'employees_salaries.has_pension' | translate }}</label>
        </div>
        <div class="col-6 text-right align-self-center">
            <div [ngClass]="{'hidden-sensitive-value-checkbox': hiddenInputs}">
                <input formControlName="has_pension" type="checkbox" id="has_pension_salary" name="has_pension" data-switch="success">
                <label for="has_pension_salary">&nbsp;</label>
            </div>
        </div>
    </div>

    <div
        #collapseTenantID="ngbCollapse"
        [(ngbCollapse)]="!form.value.has_pension"
        *ngIf="!hiddenInputs"
    >
        <div class="row">
            <div class="col-12">
                <div class="form-group">
                    <label class="control-label">{{ 'employees_salaries.pension_type' | translate }}</label>
                    <div [ngClass]="{'hidden-sensitive-value-input': hiddenInputs}">
                        <input formControlName="pension_type" type="text" class="form-control" id="pension_type" name="pension_type">
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="form-group">
                    <label class="control-label">{{ 'employees_salaries.pension_amount' | translate }}</label>
                    <div [ngClass]="{'hidden-sensitive-value-input': hiddenInputs}">
                        <input formControlName="pension_amount" currencyMask type="text" class="form-control" id="pension_amount" name="pension_amount">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <hr class="mt-0">

    <div class="row">
        <div class="col-6">
            <label class="control-label">{{ 'employees_salaries.has_salary_deduction' | translate }}</label>
        </div>
        <div class="col-6 text-right align-self-center">
            <div [ngClass]="{'hidden-sensitive-value-checkbox': hiddenInputs}">
                <input formControlName="has_salary_deduction" type="checkbox" id="salary_deduction" name="has_salary_deduction" data-switch="success">
                <label for="salary_deduction">&nbsp;</label>
            </div>
        </div>
    </div>

    <div
         #collapseTenantID="ngbCollapse"
         [(ngbCollapse)]="!form.value.has_salary_deduction"
        *ngIf="!hiddenInputs"
    >
        <div class="row">
            <div class="col-12">
                <div class="form-group">
                    <label class="control-label">{{ 'employees_salaries.salary_deduction_type' | translate }}</label>
                    <div [ngClass]="{'hidden-sensitive-value-input': hiddenInputs}">
                        <input formControlName="salary_deduction_type" type="text" class="form-control" id="salary_deduction_type" name="salary_deduction_type">
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="form-group">
                    <label class="control-label">{{ 'employees_salaries.salary_deduction_amount' | translate }}</label>
                    <div [ngClass]="{'hidden-sensitive-value-input': hiddenInputs}">
                        <input formControlName="salary_deduction_amount" currencyMask type="text" class="form-control" id="salary_deduction_amount" name="salary_deduction_amount">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <hr class="mt-0">

    <div class="row">
        <div class="col-6">
            <label class="control-label mb-0">{{ 'employees_salaries.is_student' | translate }}</label>
        </div>
        <div class="col-6 text-right align-self-center">
            <div [ngClass]="{'hidden-sensitive-value-checkbox': hiddenInputs}">
                <input formControlName="is_student" type="checkbox" id="student" name="is_student" data-switch="success">
                <label for="student">&nbsp;</label>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <label class="control-label">{{ 'employees_salaries.second_pension_pillar' | translate }}</label>
        </div>
        <div class="col-6 text-right align-self-center">
            <div [ngClass]="{'hidden-sensitive-value-checkbox': hiddenInputs}">
                <input formControlName="second_pension_pillar" type="checkbox" id="second_pension_pillar" name="second_pension_pillar" data-switch="success">
                <label for="second_pension_pillar">&nbsp;</label>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <label class="control-label">{{ 'employees_salaries.number_of_children' | translate }}</label>
            <div [ngClass]="{'hidden-sensitive-value-input': hiddenInputs}">
                <ng-select
                    formControlName="number_of_children"
                    [clearable]="false"
                    class="custom"
                >
                    <ng-container *ngFor="let i of rangeFunc(11, 0)">
                        <ng-option value="{{ i }}"> {{ i }}</ng-option>
                    </ng-container>
                </ng-select>
            </div>
            <div *ngIf="submitted && f.number_of_children.errors">
                <p *ngIf="f.number_of_children.errors.required" class="text-danger validation-text">
                    {{ 'angular_validation.field' | translate }} {{'employees_salaries.number_of_children' | translate }} {{'angular_validation.required' | translate }}
                </p>
            </div>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-12">
            <label class="control-label">{{ 'employees_salaries.number_of_dependents' | translate }}</label>
            <div [ngClass]="{'hidden-sensitive-value-input': hiddenInputs}">
                <ng-select
                    formControlName="number_of_dependents"
                    [clearable]="false"
                    class="custom"
                >
                    <ng-container *ngFor="let i of rangeFunc(11, 0)">
                        <ng-option value="{{ i }}"> {{ i }}</ng-option>
                    </ng-container>
                </ng-select>
            </div>
            <div *ngIf="submitted && f.number_of_dependents.errors">
                <p *ngIf="f.number_of_dependents.errors.required" class="text-danger validation-text">
                    {{ 'angular_validation.field' | translate }} {{'employees_salaries.number_of_dependents' | translate }} {{'angular_validation.required' | translate }}
                </p>
            </div>
        </div>
    </div>
    <hr>
    <div class="row mt-2">
        <div class="col-lg-12">
            <div class="form-group">
                <label class="control-label">
                    <span>{{ 'employees_salaries.note' | translate }}</span>
                </label>

                <div [ngClass]="{'hidden-sensitive-value-input': hiddenInputs}">
                    <textarea
                        class="form-control"
                        formControlName="note"
                        name="note"
                        type="text"
                        rows="3"
                    ></textarea>
                </div>
            </div>
        </div>
    </div>
    <app-custom-fields [customFields]="salary?.custom_fields"
                       [widget]="'EMPLOYEE_SALARY'"
                       [sensitive]="true"
                       [submitted]="submitted"
                       [fieldset]="false"
                       [hr]="true"
                       [columns]="1"
                       [form]="form">
    </app-custom-fields>
</form>
