<div class="modal-content">
	<div class="modal-header">
			<p class="modal-title font-16 mb-0 text-dark">{{'employees_receivables.page_title_edit' | translate}}</p>
		<button (click)="activeModal.dismiss()" aria-label="Close" class="close" type="button">
			<span aria-hidden="true" class="font-24 text-muted"><i class="mdi mdi-close"></i></span>
		  </button>
	</div>
	<div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <div class="card mb-0 shadow-none">
                    <div class="card-body p-0">
                        <form (ngSubmit)="onSubmit()" [formGroup]="receivableEditForm" autocomplete="off">
                            <div class="row">
								<div class="col-lg-12">
									<div class="form-group">
										<label class="control-label">{{ 'employees_receivables.name' | translate }}</label>
										<input class="form-control" formControlName="name" name="name" type="text">
										<div *ngIf="submitted && f.name.errors">
											<p *ngIf="f.name.errors.required" class="text-danger validation-text">
												{{ 'angular_validation.field' | translate }} {{ 'employees_receivables.name' | translate }}
												{{ 'angular_validation.required' | translate }}
											</p>
										</div>
									</div>
								</div>
							</div>
                            <div class="row">
								<div class="col-lg-12">
									<div class="form-group">
										<label class="control-label">{{ 'employees_receivables.from' | translate }}</label>
										<input [locale]="locale$ | async" allowInput="false" altFormat="d.m.Y"
                                        altInput="true"
										class="form-control"
										dateFormat="Y-m-d"
										formControlName="from" mwlFlatpickr [disableMobile]="true" name="from" type="text">
										<div *ngIf="submitted && f.from.errors">
											<p *ngIf="f.from.errors.required" class="text-danger validation-text">
												{{ 'angular_validation.field' | translate }} {{ 'employees_receivables.from' | translate }}
												{{ 'angular_validation.required' | translate }}
											</p>
										</div>
									</div>
								</div>
							</div>
                            <div class="row">
								<div class="col-lg-12">
									<div class="form-group">
										<label class="control-label">{{ 'employees_receivables.to' | translate }}</label>
										<input [locale]="locale$ | async" allowInput="false" altFormat="d.m.Y"
                                        altInput="true"
										class="form-control"
										dateFormat="Y-m-d"
										formControlName="to" mwlFlatpickr [disableMobile]="true" name="to" type="text">
									</div>
								</div>
							</div>
                            <div class="row">
								<div class="col-lg-12">
									<div class="form-group">
										<label class="control-label">{{ 'employees_receivables.amount' | translate }}</label>
										<input class="form-control" currencyMask formControlName="amount" name="amount" type="text">
										<div *ngIf="submitted && f.amount.errors">
											<p *ngIf="f.amount.errors.required" class="text-danger validation-text">
												{{ 'angular_validation.field' | translate }} {{ 'employees_receivables.amount' | translate }}
												{{ 'angular_validation.required' | translate }}
											</p>
										</div>
									</div>
								</div>
							</div>
                            <div class="row">
								<div class="col-lg-12">
									<div class="form-group">
										<label class="control-label">{{ 'employees_receivables.paid_amount' | translate }}</label>
										<input class="form-control" currencyMask formControlName="paid_amount" name="paid_amount" type="text">
										<div *ngIf="submitted && f.paid_amount.errors">
											<p *ngIf="f.paid_amount.errors.required" class="text-danger validation-text">
												{{ 'angular_validation.field' | translate }} {{ 'employees_receivables.paid_amount' | translate }}
												{{ 'angular_validation.required' | translate }}
											</p>
										</div>
									</div>
								</div>
							</div>
                            <div class="row">
								<div class="col-lg-12">
									<div class="form-group">
										<label class="control-label">{{ 'employees_receivables.type' | translate }}</label>
										<ng-select
											[clearable]="false"
											class="custom"
											formControlName="type"
											name="type"
										>
											<ng-option value="EXECUTION_PRIORITY">{{ 'employees_receivables.type_EXECUTION_PRIORITY' | translate }}</ng-option>
											<ng-option value="EXECUTION_NON_PRIORITY">{{ 'employees_receivables.type_EXECUTION_NON_PRIORITY' | translate }}</ng-option>
											<ng-option value="INSOLVENCY_PRIORITY">{{ 'employees_receivables.type_INSOLVENCY_PRIORITY' | translate }}</ng-option>
										</ng-select>
										<div *ngIf="submitted && f.type.errors">
											<p *ngIf="f.type.errors.required" class="text-danger validation-text">
												{{ 'angular_validation.field' | translate }} {{ 'employees_receivables.type' | translate }}
												{{ 'angular_validation.required' | translate }}
											</p>
										</div>
									</div>
								</div>
							</div>
                            <div class="row">
								<div class="col-lg-12">
									<div class="form-group">
										<label class="control-label">{{ 'employees_receivables.state' | translate }}</label>
										<ng-select
											[clearable]="false"
											class="custom"
											formControlName="state"
											name="state"
										>
											<ng-option value="RUNNING">{{ 'employees_receivables.state_RUNNING' | translate }}</ng-option>
											<ng-option value="PAUSED">{{ 'employees_receivables.state_PAUSED' | translate }}</ng-option>
											<ng-option value="DONE">{{ 'employees_receivables.state_DONE' | translate }}</ng-option>
										</ng-select>
										<div *ngIf="submitted && f.state.errors">
											<p *ngIf="f.state.errors.required" class="text-danger validation-text">
												{{ 'angular_validation.field' | translate }} {{ 'employees_receivables.state' | translate }}
												{{ 'angular_validation.required' | translate }}
											</p>
										</div>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-lg-12">
									<div class="form-group">
										<label class="control-label">{{ 'employees_receivables.recipient_info' | translate }}</label>
										<input class="form-control" formControlName="recipient_info" name="recipient_info" type="text">
										<div *ngIf="submitted && f.recipient_info.errors">
											<p *ngIf="f.recipient_info.errors.required" class="text-danger validation-text">
												{{ 'angular_validation.field' | translate }} {{ 'employees_receivables.recipient_info' | translate }}
												{{ 'angular_validation.required' | translate }}
											</p>
										</div>
									</div>
								</div>
							</div>
                            <div class="row">
								<div class="col-lg-12">
									<div class="form-group">
										<label class="control-label">{{ 'employees_receivables.bank_account' | translate }}</label>
										<input class="form-control" formControlName="bank_account" name="bank_account" type="text">
										<div *ngIf="submitted && f.bank_account.errors">
											<p *ngIf="f.bank_account.errors.required" class="text-danger validation-text">
												{{ 'angular_validation.field' | translate }} {{ 'employees_receivables.bank_account' | translate }}
												{{ 'angular_validation.required' | translate }}
											</p>
										</div>
									</div>
								</div>
							</div>
                            <div class="row">
								<div class="col-lg-12">
									<div class="form-group">
										<label class="control-label">{{ 'employees_receivables.variable_symbol' | translate }}</label>
										<input class="form-control" formControlName="variable_symbol" name="variable_symbol" type="text">
										<div *ngIf="submitted && f.variable_symbol.errors">
											<p *ngIf="f.variable_symbol.errors.required" class="text-danger validation-text">
												{{ 'angular_validation.field' | translate }} {{ 'employees_receivables.variable_symbol' | translate }}
												{{ 'angular_validation.required' | translate }}
											</p>
										</div>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-6">
									<label class="control-label mb-0">{{'employees_receivables.deposit' | translate}}</label>
								</div>
								<div class="col-6 text-right align-self-center">
									<div>
										<input data-switch="success" formControlName="deposit" id="deposit" name="deposit" type="checkbox">
										<label for="deposit">&nbsp;</label>
									</div>
								</div>
							</div>
                            <div class="row">
								<div class="col-lg-12">
									<div class="form-group">
										<label class="control-label">{{ 'employees_receivables.description' | translate }}</label>
										<input class="form-control" formControlName="description" name="description" type="text">
										<div *ngIf="submitted && f.description.errors">
											<p *ngIf="f.description.errors.required" class="text-danger validation-text">
												{{ 'angular_validation.field' | translate }} {{ 'employees_receivables.description' | translate }}
												{{ 'angular_validation.required' | translate }}
											</p>
										</div>
									</div>
								</div>
							</div>

                            <div class="row mt-2">
                                <div class="col-6 text-left">
                                    <button class="btn btn-success" type="submit">{{'employees_receivables.action_save' | translate}}</button>
                                </div>
                                <div class="col-6 text-right my-auto">
									<u (click)="deleteReceivable()" *ngIf="receivable" class="text-muted">{{'employees_receivables.action_delete' | translate}}</u>
								</div>
                            </div>
                        </form>
						<ng-container *ngIf="receivable">
							<p class="modal-title font-16 mb-2 mt-3 text-dark">{{'documents.states_history' | translate}}</p>
							<div class="table-responsive">
								<table class="table table-centered table-hover">
									<thead>
										<tr>
											<th>
												{{'documents.date' | translate}}
											</th>
											<th>
												{{'documents.redeemed' | translate}}
											</th>
											<th>
												{{'documents.left_to_redeem' | translate}}
											</th>
											<th class="text-right">
												{{'documents.deposited' | translate}}
											</th>
										</tr>
									</thead>
									<tbody>
										<tr *ngFor="let h of receivable.history">
											<td>
												{{h.date | date: 'dd.MM.yyyy'}}
											</td>
											<td>
												{{h.amount | localizedCurrency }}
											</td>
											<td >
												{{h.amount_to_be_paid | localizedCurrency }}
											</td>
											<td class="text-right">
												{{h.deposit ? 'ANO' : 'NE'}}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</ng-container>

                    </div>
                </div>
            </div>
        </div>
	</div>
</div>
