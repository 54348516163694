import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable } from 'rxjs';
import { ListParameters, ListResponse, transformParameters } from 'src/app/api';
import { ApiHelper } from 'src/app/shared/common/ApiHelper';
import { SwalHelper } from 'src/app/shared/common/SwalHelper';
import { ApiResponse } from 'src/app/shared/models/ApiResponse';
import { PromptResponse } from '../../chat/state/chat.model';
import { Advertisement } from '../models/Advertisement';
import { Recruitment } from '../models/Recruitment';

@Injectable({
    providedIn: 'root'
})
export class RecruitmentService {
    public constructor(
        private _apiHelper: ApiHelper,
        private _http: HttpClient,
        private _loaderService: NgxUiLoaderService,
        private _swalHelper: SwalHelper,
    ) { }

    public saveRecruitment(form: Recruitment, recruitmentID?: number): Promise<'done'> {
        this._loaderService.startLoader('master');

        let apiUrl = '/api/recruitments';

        if (recruitmentID) {
            apiUrl += `/${recruitmentID}`;
        }

        return new Promise(resolve => {
            this._http.post<ApiResponse>(apiUrl, form)
                .subscribe(
                    response => {
                        this._apiHelper.handleSuccessResponse(response);

                        resolve('done');

                        this._loaderService.stopLoader('master');
                    },
                    error => {
                        this._apiHelper.handleErrorResponse(error);

                        this._loaderService.stopLoader('master');
                    }
                );
        });
    }

    public archiveRecruitment(recruitmentID: number): Promise<'done'> {
        return new Promise(async (resolve, reject) => {
            const deleteConfirmed = await this._swalHelper.showConfirmDeleteDialog();

            if (deleteConfirmed) {

                this._loaderService.startLoader('master');

                this._http.get<ApiResponse>(`/api/recruitments/${recruitmentID}/toggle-archive`)
                    .subscribe(
                        response => {
                            this._loaderService.stopLoader('master');

                            this._apiHelper.handleSuccessResponse(response);

                            resolve('done');
                        },
                        error => {
                            this._loaderService.stopLoader('master');

                            this._apiHelper.handleErrorResponse(error);
                        }
                    );
            } else {
                reject('error');
            }
        });
    }

    public deleteRecruitment(recruitmentID: number): Promise<'done'> {
        return new Promise(async (resolve, reject) => {
            const deleteConfirmed = await this._swalHelper.showConfirmDeleteDialog();

            if (deleteConfirmed) {

                this._loaderService.startLoader('master');

                this._http.delete<ApiResponse>('/api/recruitments/' + recruitmentID)
                    .subscribe(
                        response => {
                            this._loaderService.stopLoader('master');

                            this._apiHelper.handleSuccessResponse(response);

                            resolve('done');
                        },
                        error => {
                            this._loaderService.stopLoader('master');

                            this._apiHelper.handleErrorResponse(error);
                        }
                    );
            } else {
                reject('error');
            }
        });
    }

    public getRecruitmentsList(params: ListParameters<Recruitment>, filterParams?: {}): Observable<ListResponse<Recruitment>> {
        let p = transformParameters(params);
        p = {
            ...p,
            filter: filterParams
        };

        return this._http.post<ListResponse<Recruitment>>('/api/recruitments/list', {...p});
    }

    public getAdvertisementsList(recruitmentID: number, params: ListParameters<Recruitment>, filterParams?: {}): Observable<ListResponse<Recruitment>> {
        let p = transformParameters(params);
        p = {
            ...p,
            ...filterParams
        };

        return this._http.post<ListResponse<Recruitment>>(`/api/recruitments/${recruitmentID}/advertisements/list`, {...p});
    }

    public getAllAdvertisements(recruitmentID: number): Observable<Advertisement[]> {
        return this._http.get<Advertisement[]>(`/api/recruitments/${recruitmentID}/advertisements?scope=select`);
    }

    public getRecruitmentByID(recruitmentID: number): Observable<Recruitment> {
        return this._http.get<Recruitment>(`/api/recruitments/${recruitmentID}`);
    }

    public deleteAdvertisement(recruitmentID: number, advertisementID: number): any {
        return new Promise(async (resolve, reject) => {
            const deleteConfirmed = await this._swalHelper.showConfirmDeleteDialog();

            if (deleteConfirmed) {
                this._loaderService.startLoader('master');

                this._http.delete<ApiResponse>(`/api/recruitments/${recruitmentID}/advertisements/${advertisementID}`)
                    .subscribe(
                        response => {
                            this._loaderService.stopLoader('master');

                            this._apiHelper.handleSuccessResponse(response);

                            resolve('done');
                        },
                        error => {
                            this._loaderService.stopLoader('master');

                            this._apiHelper.handleErrorResponse(error);
                        }
                    );
            } else {
                reject('error');
            }
        });
    }

    public saveAdvertisement(form: Advertisement, recruitmentID: number, advertisementID: number): Promise<'done'> {
        this._loaderService.startLoader('master');

        let apiUrl = `/api/recruitments/${recruitmentID}/advertisements`;

        if (advertisementID) {
            apiUrl += `/${advertisementID}`;
        }

        return new Promise(resolve => {
            this._http.post<ApiResponse>(apiUrl, form)
                .subscribe(
                    response => {
                        this._apiHelper.handleSuccessResponse(response);

                        resolve('done');

                        this._loaderService.stopLoader('master');
                    },
                    error => {
                        this._apiHelper.handleErrorResponse(error);

                        this._loaderService.stopLoader('master');
                    }
                );
        });
    }

    public async generateAdvertisementContent(additionalMessage: string, recruitmentID: number): Promise<PromptResponse> {
        return new Promise(resolve => {
            this._http.post<PromptResponse>(`/api/recruitments/${recruitmentID}/advertisements/generate-content`, {context: additionalMessage})
                .subscribe(
                    response => {
                        this._loaderService.stopLoader('master');

                        resolve(response);
                    },
                    error => {
                        this._apiHelper.handleErrorResponse(error);

                        this._loaderService.stopLoader('master');
                    }
                );
        });
    }

    public async findBestApplicant(recruitmentID: number): Promise<PromptResponse> {
        this._loaderService.startLoader('master');
        return new Promise(resolve => {
            this._http.get<PromptResponse>(`/api/recruitments/${recruitmentID}/applicants/find-best`)
                .subscribe(
                    response => {
                        this._loaderService.stopLoader('master');

                        resolve(response);
                    },
                    error => {
                        this._apiHelper.handleErrorResponse(error);

                        this._loaderService.stopLoader('master');
                    }
                );
        });
    }

    public async evaluateApplicant(recruitmentID: number, employeeID: number): Promise<PromptResponse> {
        return new Promise(resolve => {
            this._http.post<PromptResponse>(`/api/recruitments/${recruitmentID}/applicants/${employeeID}/evaluate`, {})
                .subscribe(
                    response => {
                        this._loaderService.stopLoader('master');

                        resolve(response);
                    },
                    error => {
                        this._apiHelper.handleErrorResponse(error);

                        this._loaderService.stopLoader('master');
                    }
                );
        });
    }

    public async proposeEvaluation(recruitmentID: number): Promise<PromptResponse> {
        return new Promise(resolve => {
            this._http.post<PromptResponse>(`/api/recruitments/${recruitmentID}/propose-evaluation`, {})
                .subscribe(
                    response => {
                        this._loaderService.stopLoader('master');

                        resolve(response);
                    },
                    error => {
                        this._apiHelper.handleErrorResponse(error);

                        this._loaderService.stopLoader('master');
                    }
                );
        });
    }

    public attachDetachEmployee(form: any, recruitmentID: number): Promise<PromptResponse> {
        return new Promise(resolve => {
            this._http.post<PromptResponse>(`/api/recruitments/${recruitmentID}/applicants/sync`, form)
                .subscribe(
                    response => {
                        this._loaderService.stopLoader('master');

                        resolve(response);
                    },
                    error => {
                        this._apiHelper.handleErrorResponse(error);

                        this._loaderService.stopLoader('master');
                    }
                );
        });
    }
}
