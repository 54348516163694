<ng-container *ngIf="customFields?.length > 0 && customFieldControlsCount > 0">
    <hr *ngIf="hr">
    <fieldset class="{{fieldset ? 'fieldset-bordered py-2': ''}}">
        <legend *ngIf="fieldset"> {{ 'global.custom_fields' | translate }} </legend>
        <ng-container [formGroup]="form">
            <ng-container formGroupName="custom_fields">
                <div class="row">
                    <ng-container *ngFor="let field of customFields">
                        <div *ngIf="f(field.custom_field_definition_ID)" class="col-12" [ngClass]="{'col-lg-6': columns === 2}">
                            <div class="form-group">
                            <label class="control-label" [ngClass]="{'required-label': f(field.custom_field_definition_ID).hasValidator(Validators.required)}">
                                <span>{{ field.label }}</span>
                            </label>
                            <div [ngClass]="{'hidden-sensitive-value-input': hiddenInputs && sensitive}">
                                <input *ngIf="field.type === 'TEXT'"
                                       class="form-control"
                                       [formControlName]="field.custom_field_definition_ID"
                                       [name]="field.custom_field_definition_ID"
                                       type="text"
                                >
                                <textarea *ngIf="field.type === 'TEXTAREA'"
                                       class="form-control"
                                       [formControlName]="field.custom_field_definition_ID"
                                       [name]="field.custom_field_definition_ID"
                                       type="text">
                                </textarea>
                                <input *ngIf="field.type === 'RICHTEXT'"
                                       [ngxSummernote]="summernoteHelper.getSummernoteConfig()"
                                       class="form-control"
                                       [formControlName]="field.custom_field_definition_ID"
                                       [name]="field.custom_field_definition_ID"
                                       type="text"
                                >
                                <input *ngIf="field.type === 'NUMBER'"
                                       class="form-control"
                                       [formControlName]="field.custom_field_definition_ID"
                                       [name]="field.custom_field_definition_ID"
                                       type="number"
                                       onlyNumber
                                >
                                <input *ngIf="field.type === 'PERCENT' || field.type === 'AMOUNT'"
                                       class="form-control"
                                       [formControlName]="field.custom_field_definition_ID"
                                       [name]="field.custom_field_definition_ID"
                                       type="text"
                                       currencyMask [options]="field.type === 'PERCENT' ? {'suffix': ' %', 'precision': 0, 'min': 0} : {'prefix': '', 'precision': 2, 'min': 0}"
                                >
                                <input *ngIf="field.type === 'DATE' || field.type === 'DATETIME' || field.type === 'TIME'"
                                        (flatpickrClose)="fpHelper.applyAltInputValue($event, field.type === 'DATETIME')"
                                        [locale]="locale$ | async"
                                        allowInput="false"
                                        [altFormat]="field.type === 'DATETIME' ? 'd.m.Y H:i' : field.type === 'TIME' ? 'H:i' : 'd.m.Y'"
                                        [enableTime]="field.type === 'DATETIME' || field.type === 'TIME'"
                                        [time24hr]="field.type === 'DATETIME' || field.type === 'TIME'"
                                        [noCalendar]="field.type === 'TIME'"
                                        altInput="true"
                                        class="form-control"
                                        [dateFormat]="field.type === 'DATETIME' ? 'Y-m-d H:i:ss' : field.type === 'TIME' ? 'H:i:ss' : 'Y-m-d'"
                                        [formControlName]="field.custom_field_definition_ID"
                                        mwlFlatpickr [disableMobile]="true"
                                        [name]="field.custom_field_definition_ID"
                                        type="text"
                                >
                                <ng-container *ngIf="field.type === 'TOGGLE'">
                                    <input data-switch="success" [formControlName]="field.custom_field_definition_ID" [id]="field.custom_field_definition_ID" [name]="field.custom_field_definition_ID" type="checkbox">
                                    <label [for]="field.custom_field_definition_ID">&nbsp;</label>
                                </ng-container>
                                <ng-select *ngIf="field.type === 'SELECT' || field.type === 'MULTISELECT'"
                                           [closeOnSelect]="field.type === 'SELECT'"
                                           [multiple]="field.type === 'MULTISELECT'"
                                           [clearable]="field.type === 'MULTISELECT'"
                                           [items]="field.options"
                                           bindLabel="value"
                                           bindValue="value"
                                           class="custom"
                                           [formControlName]="field.custom_field_definition_ID">
                                </ng-select>
                            </div>
                            <div *ngIf="submitted && f(field.custom_field_definition_ID).errors">
                                <p *ngIf="f(field.custom_field_definition_ID).errors.required" class="text-danger validation-text">
                                    {{ 'angular_validation.field' | translate }} {{ field.label }}
                                    {{ 'angular_validation.required' | translate }}
                                </p>
                            </div>
                        </div>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
        </ng-container>
    </fieldset>
    <hr class="mt-0" *ngIf="hr">
</ng-container>
