import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable, ReplaySubject } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';
import { ApiHelper } from 'src/app/shared/common/ApiHelper';
import { ApiResponse } from 'src/app/shared/models/ApiResponse';
import { MenuItem } from '../../menu/state/menu-item.model';
import { MenuItemService } from '../../menu/state/menu-item.service';
import { SwalHelper } from '../../shared/common/SwalHelper';
import { IntegrationSettingsType } from '../integration-settings.type';
import { AvailableWidgets } from '../models/AvailableWidgets';
import { BillingSetting } from '../models/BillingSetting';
import { CompanySetting } from '../models/CompanySetting';
import { SecuritySetting } from '../models/SecuritySetting';

@Injectable({
    providedIn: 'root'
})
export class CompanyService {

    public availableWidgets$: Observable<AvailableWidgets>;
    private _availableWidgets$ = new ReplaySubject<AvailableWidgets>(1);

    public constructor(
        private _apiHelper: ApiHelper,
        private _http: HttpClient,
        private _loaderService: NgxUiLoaderService,
        private _swalHelper: SwalHelper,
        private _menuService: MenuItemService,
    ) {
        this.availableWidgets$ = this._availableWidgets$;
    }

    public getBillingSetting(): Observable<any> {
        return this._http.get('/api/billing-settings');
    }

    public getCompanySetting(branch: string = null): Observable<any> {
        if (branch) {
            const params = new HttpParams().set('branch', branch);
            return this._http.get('/api/company-settings', {params});
        }
        return this._http.get('/api/company-settings');
    }

    public updateAvailableWidgets(body: { key: string, value: string }): Promise<'done'> {
        return new Promise(resolve => {
            this._http.post<any>('/api/company-settings/available-widgets', body).subscribe(
                response => {
                    this._apiHelper.handleSuccessResponse(response);
                    resolve('done');
                },
                error => {
                    this._apiHelper.handleErrorResponse(error);
                }
            );
        });
    }

    public updateSecuritySetting(data: any, type: string): void {
        this._http.post<ApiResponse>('/api/company-settings/security/update', {data, type}).subscribe(
            response => {
                this._apiHelper.handleSuccessResponse(response);
            },
            error => {
                this._apiHelper.handleErrorResponse(error);
            }
        );
    }

    public getCompanyCurrencySetting(): Observable<any> {
        return this._http.get('/api/company-settings/currency');
    }

    public getDueDateDays(): Observable<any> {
        return this._http.get('/api/company-settings/due-date-days');
    }

    public getSecuritySetting(): Observable<SecuritySetting> {
        return this._http.get<SecuritySetting>('/api/company-settings/security');
    }

    public getAvailableWidgets(): Observable<AvailableWidgets> {
        return this._http.get<AvailableWidgets>('/api/company-settings/available-widgets')
            .pipe(
                tap(widgets => this._availableWidgets$.next(widgets)),
                shareReplay()
            );
    }

    public getLogo(width?: string, height?: string): Observable<any> {
        const params = new HttpParams()
            .set('width', width)
            .set('height', height);
        return this._http.get('/api/workspace/logo', {params});
    }

    public deleteLogo(): Promise<'done'> {
        this._loaderService.startLoader('master');

        return new Promise(resolve => {
            this._http.post<ApiResponse>('/api/workspace/logo/delete', {})
                .subscribe(
                    response => {
                        this._loaderService.stopLoader('master');

                        this._apiHelper.handleSuccessResponse(response);

                        resolve('done');
                    },
                    error => {
                        this._loaderService.stopLoader('master');

                        this._apiHelper.handleErrorResponse(error);
                    }
                );
        });
    }

    public saveBillingSetting(form: BillingSetting): Promise<'done'> {
        this._loaderService.startLoader('master');

        return new Promise(resolve => {
            this._http.post<ApiResponse>('/api/billing-settings', form)
                .subscribe(
                    response => {
                        this._loaderService.stopLoader('master');

                        this._apiHelper.handleSuccessResponse(response);

                        resolve('done');
                    },
                    error => {
                        this._loaderService.stopLoader('master');

                        this._apiHelper.handleErrorResponse(error);
                    }
                );
        });
    }

    public saveCompanySetting(form: CompanySetting | IntegrationSettingsType): Promise<'done'> {
        this._loaderService.startLoader('master');

        return new Promise(resolve => {
            this._http.post<ApiResponse>('/api/company-settings', form)
                .subscribe(
                    response => {
                        this._loaderService.stopLoader('master');

                        this._apiHelper.handleSuccessResponse(response);

                        resolve('done');
                    },
                    error => {
                        this._loaderService.stopLoader('master');

                        this._apiHelper.handleErrorResponse(error);
                    }
                );
        });
    }

    public deleteTenant(cancelDeletion = false): Promise<'done'> {
        if (!cancelDeletion) {
            return new Promise(async (resolve, reject) => {
                const deleteConfirmed = await this._swalHelper.showConfirmDeleteDialog('settings.company.info.delete_tenant_text', 'settings.company.info.delete_tenant_title');
                if (deleteConfirmed) {
                    this._http.post('/api/company-settings/delete-tenant', {}).subscribe((response: ApiResponse) => {
                        this._loaderService.stopLoader('master');
                        this._apiHelper.handleSuccessResponse(response);
                        resolve('done');
                    }, error => {
                        this._loaderService.stopLoader('master');
                        this._apiHelper.handleErrorResponse(error);
                    });
                } else {
                    reject('error');
                }
            });
        } else {
            return new Promise(async (resolve, reject) => {
                this._http.post('/api/company-settings/delete-tenant', {cancel_deletion: true}).subscribe((response: ApiResponse) => {
                    this._loaderService.stopLoader('master');
                    this._apiHelper.handleSuccessResponse(response);
                    resolve('done');
                }, error => {
                    this._loaderService.stopLoader('master');
                    this._apiHelper.handleErrorResponse(error);
                });
            });
        }
    }

    public getMenuVisibility(): Observable<MenuItem[]> {
        return this._http.get<MenuItem[]>('/api/menu/get-visibility');
    }

    public saveMenuSettings(form: any): Promise<'done'> {
        this._loaderService.startLoader('master');

        return new Promise(resolve => {
            this._http.post<ApiResponse>('/api/menu/set-visibility', form)
                .subscribe(
                    response => {
                        this._loaderService.stopLoader('master');
                        this._menuService.refetch();
                        this._apiHelper.handleSuccessResponse(response);
                        resolve('done');
                    },
                    error => {
                        this._loaderService.stopLoader('master');
                        this._apiHelper.handleErrorResponse(error);
                    }
                );
        });
    }

    public getSettingsByKeys(keys: string): Observable<any> {
        return this._http.get<any>('/api/company-settings/get?keys=' + keys);
    }

    public saveSettingsByKeys(values: any): Promise<'done'> {
        this._loaderService.startLoader('master');

        return new Promise(resolve => {
            this._http.post<ApiResponse>('/api/company-settings/set', values)
                .subscribe(
                    response => {
                        this._loaderService.stopLoader('master');

                        this._apiHelper.handleSuccessResponse(response);

                        resolve('done');
                    },
                    error => {
                        this._loaderService.stopLoader('master');

                        this._apiHelper.handleErrorResponse(error);
                    }
                );
        });
    }
}
