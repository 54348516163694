<div class="modal-content">
  <div class="modal-header">
    <p class="modal-title font-16 mb-0 text-dark">
      <span *ngIf="!bonus">{{ 'employees_bonuses.page_title_create' | translate }}</span>
      <span *ngIf="bonus">{{ 'employees_bonuses.page_title_edit' | translate }}</span>
    </p>

    <button
      aria-label="Close"
      class="close"
      (click)="activeModal.dismiss()"
    >
      <span
        aria-hidden="true"
        class="font-24 text-muted"
      >
        <i class="mdi mdi-close"></i>
      </span>
    </button>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <div class="card mb-0 shadow-none">
          <div class="card-body p-0">
            <form
              autocomplete="off"
              [formGroup]="bonusEditForm"
              (ngSubmit)="onSubmit()"
            >
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label class="control-label">
                      <span>{{ 'employees_bonuses.name' | translate }}</span>
                    </label>

                    <input
                      class="form-control"
                      formControlName="name"
                      name="name"
                      type="text"
                    >

                    <div *ngIf="submitted && f.name.errors">
                      <p
                        *ngIf="f.name.errors.required"
                        class="text-danger validation-text"
                      >
                        <span>{{ 'angular_validation.field' | translate }} </span>
                        <span>{{ 'employees_bonuses.name' | translate }} </span>
                        <span>{{ 'angular_validation.required' | translate }}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label class="control-label">
                      <span>{{ 'employees_bonuses.date' | translate }}</span>
                    </label>

                    <input
                      mwlFlatpickr [disableMobile]="true"
                      allowInput="false"
                      altFormat="d.m.Y"
                      altInput="true"
                      class="form-control"
                      dateFormat="Y-m-d"
                      formControlName="date"
                      name="date"
                      type="text"
                      [locale]="locale$ | async"
                    >

                    <div *ngIf="submitted && f.date.errors">
                      <p
                        *ngIf="f.date.errors.required"
                        class="text-danger validation-text"
                      >
                        <span>{{ 'angular_validation.field' | translate }} </span>
                        <span>{{ 'employees_bonuses.date' | translate }} </span>
                        <span>{{ 'angular_validation.required' | translate }}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label class="control-label">
                      <span>{{ 'employees_bonuses.amount' | translate }}</span>
                    </label>

                      <div [ngClass]="{'hidden-sensitive-value-input': hiddenInputs}">
                        <input currencyMask class="form-control" formControlName="amount" name="amount" type="text">
                      </div>

                    <div *ngIf="submitted && f.amount.errors">
                      <p
                        *ngIf="f.amount.errors.required"
                        class="text-danger validation-text"
                      >
                        <span>{{ 'angular_validation.field' | translate }} </span>
                        <span>{{ 'employees_bonuses.amount' | translate }} </span>
                        <span>{{ 'angular_validation.required' | translate }}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label class="control-label">
                      <span>{{ 'employees_bonuses.note' | translate }}</span>
                    </label>

                    <input
                      class="form-control"
                      formControlName="note"
                      name="note"
                      type="text"
                    >

                    <div *ngIf="submitted && f.amount.errors">
                      <p
                        *ngIf="f.note.errors.required"
                        class="text-danger validation-text"
                      >
                        <span>{{ 'angular_validation.field' | translate }} </span>
                        <span>{{ 'employees_bonuses.note' | translate }} </span>
                        <span>{{ 'angular_validation.required' | translate }}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div
                *ngIf="canEditOrDelete$ | async"
                class="row mt-2"
              >
                <div class="col-6 text-right my-auto">
                  <u
                    *ngIf="bonus && !hiddenInputs"
                    class="text-muted"
                    (click)="deleteBonus()"
                  >{{ 'employees_bonuses.action_delete' | translate }}</u>
                </div>
                <div class="col-6 text-right">
                  <button class="btn btn-success" [disabled]="hiddenInputs">
                    <span>{{ 'employees_bonuses.action_save' | translate }}</span>
                  </button>
                </div>

              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
