import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { FlatpickrLocaleService } from 'src/app/shared/services/flatpickr-locale.service';
import { InputVisibilityService } from '../../../../shared/services/InputVisibilityService';
import { RealWage } from '../../../models/RealWage';
import { SalaryService } from '../../../services/salary.service';

@Component({
    selector: 'app-create-edit-employee-real-wage-modal',
    templateUrl: './create-edit-employee-real-wage-modal.component.html',
    styleUrls: ['./create-edit-employee-real-wage-modal.component.css']
})
export class CreateEditEmployeeRealWageModalComponent implements OnInit {
    public realWage: RealWage;
    public realWageEditForm: UntypedFormGroup;
    public canEditOrDelete$: Observable<boolean>;
    public employeeID: number;
    public locale$ = this._flatpickrLocale.currentFlatpickrLocale$;
    public submitted = false;

    public constructor(
        public activeModal: NgbActiveModal,
        private _authService: AuthenticationService,
        private _salaryService: SalaryService,
        private _fb: UntypedFormBuilder,
        private _flatpickrLocale: FlatpickrLocaleService,
        private _inputVisibilityService: InputVisibilityService,
    ) { }

    public get hiddenInputs(): boolean {
        return this._inputVisibilityService.hiddenInputs;
    }

    public get f(): { [formControlName: string]: AbstractControl; } {
        return this.realWageEditForm.controls;
    }

    public ngOnInit(): void {
        this.realWageEditForm = this._fb.group({
            fix: ['', Validators.required],
            variable: ['', Validators.required],
            levy: ['', Validators.required],
            other: ['', Validators.required],
            date: [null, Validators.required],
            note: ['']
        });

        if (this.realWage) {
            this.realWageEditForm.patchValue(this.realWage);
        }

        if (this.hiddenInputs) {
            this.realWageEditForm.disable();
        }

        this.canEditOrDelete$ = combineLatest([
            this._authService.hasPermissionTo('employeeRealWage.edit').pipe(map(permission => permission.can)),
            this._authService.hasPermissionTo('employeeRealWage.editStructure').pipe(map(permission => permission.can)),
        ])
            .pipe(
                map(([canEdit, canEditStructure]) => canEdit || canEditStructure),
                tap(can => can ? null : this.realWageEditForm.disable())
            );
    }

    public deleteRealWage(): void {
        this._salaryService.deleteRealWageByID(this.employeeID, this.realWage.employee_real_wage_ID)
            .then(() => this.activeModal.close('deleted'),
                () => { });
    }

    public onSubmit(): void {
        this.submitted = true;

        if (!this.realWageEditForm.valid) {
            return;
        }

        const realWage = this.realWageEditForm.value;
        realWage.employee_real_wage_ID = this.realWage?.employee_real_wage_ID;

        this._salaryService.saveRealWageByEmployeeID(realWage, this.employeeID)
            .then(() => this.activeModal.close('saved'),
                () => this.activeModal.close('error'));
    }
}
