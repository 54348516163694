<ng-template
    #filterFormAttendanceOptions
    let-modal
>
    <div class="modal-content">
        <div class="modal-header">
            <p class="modal-title font-16 mb-0 text-dark">
                <span>{{ 'analysis.adjust_filters' | translate }}</span>
            </p>

            <button
                (click)="modal.dismiss()"
                aria-label="Close"
                class="close"
            >
        <span
            aria-hidden="true"
            class="font-24 text-muted"
        >
          <i class="mdi mdi-close"></i>
        </span>
            </button>
        </div>

        <div class="modal-body">
            <form
                (ngSubmit)="onAttendanceFilterFormSubmit()"
                [formGroup]="attendanceFilterForm"
                class="filter-form"
            >
                <fieldset>
                    <legend>{{ 'analysis.filter_section_time' | translate }}</legend>

                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label>{{ 'analysis.filter_date_from' | translate }}</label>

                                <input
                                    [locale]="locale$ | async"
                                    allowInput="false"
                                    altFormat="d.m.Y"
                                    altInput="true"
                                    class="form-control"
                                    dateFormat="Y-m-d"
                                    formControlName="from"
                                    mwlFlatpickr [disableMobile]="true"
                                    type="text"
                                    (flatpickrClose)="fpHelper.applyAltInputValue($event, false)"
                                >
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label>{{ 'analysis.filter_date_to' | translate }}</label>

                                <input
                                    [locale]="locale$ | async"
                                    allowInput="false"
                                    altFormat="d.m.Y"
                                    altInput="true"
                                    class="form-control"
                                    dateFormat="Y-m-d"
                                    formControlName="to"
                                    mwlFlatpickr [disableMobile]="true"
                                    type="text"
                                    (flatpickrClose)="fpHelper.applyAltInputValue($event, false)"
                                >
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label>{{ 'analysis.filter_period' | translate }}</label>

                                <ng-select
                                    [clearable]="false"
                                    [items]="attendancePeriodTypes"
                                    bindLabel="label"
                                    bindValue="value"
                                    class="custom"
                                    formControlName="periodType"
                                ></ng-select>
                            </div>
                        </div>
                    </div>
                </fieldset>

                <fieldset>
                    <legend>{{ 'analysis.filter_section_category' | translate }}</legend>

                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label>
                                    <span>{{ 'analysis.filter_categories' | translate }}</span>
                                </label>

                                <ng-select
                                    [clearable]="false"
                                    [closeOnSelect]="false"
                                    [items]="attendanceCategories$ | async"
                                    [multiple]="true"
                                    bindLabel="label"
                                    bindValue="value"
                                    class="custom"
                                    formControlName="categoryValues"
                                    notFoundText="{{ 'employees_analysis.chart_filter_no_items_attendance' | translate }}"
                                >
                                    <ng-template ng-multi-label-tmp>
                    <span
                        class="ng-placeholder"
                        style="display: block;"
                    >
                      <span>{{ 'employees_analysis.chart_filter_placeholder_attendance' | translate }}</span>
                      <ng-container *ngIf="attendanceFilterForm.value.categoryValues?.length; let categoryCount">
                        <span> ({{ categoryCount }})</span>
                      </ng-container>
                    </span>
                                    </ng-template>

                                    <ng-template
                                        let-index="index"
                                        let-item="item"
                                        let-item$="item$"
                                        ng-option-tmp
                                    >
                                        <input
                                            [checked]="item$.selected"
                                            id="item-{{index}}"
                                            type="checkbox"
                                        />

                                        <span>&nbsp;</span>

                                        <span>{{ item.label }}</span>
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label>
                                    <span>{{ 'employees_salaries.aggregate' | translate }}</span>
                                </label>

                                <div class="mt-1">
                                    <input
                                        data-switch="success"
                                        formControlName="groupByCategory"
                                        id="groupByCategory"
                                        name="groupByCategory"
                                        type="checkbox"
                                    >

                                    <label for="groupByCategory">&nbsp;</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>

                <div class="row">
                    <div class="col-12 text-right">
                        <button class="btn btn-primary">
                            <span>{{ 'analysis.action_filter' | translate }}</span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>

<ng-template
    #filterFormKPIOptions
    let-modal
>
    <div class="modal-content">
        <div class="modal-header">
            <p class="modal-title font-16 mb-0 text-dark">
                <span>{{ 'analysis.adjust_filters' | translate }}</span>
            </p>

            <button
                (click)="modal.dismiss()"
                aria-label="Close"
                class="close"
            >
        <span
            aria-hidden="true"
            class="font-24 text-muted"
        >
          <i class="mdi mdi-close"></i>
        </span>
            </button>
        </div>

        <div class="modal-body">
            <form
                (ngSubmit)="onKPIFilterFormSubmit()"
                [formGroup]="kpiFilterForm"
                class="filter-form"
            >
                <fieldset>
                    <legend>{{ 'analysis.filter_section_time' | translate }}</legend>

                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label>{{ 'analysis.filter_date_from' | translate }}</label>

                                <input
                                    [locale]="locale$ | async"
                                    allowInput="false"
                                    altFormat="d.m.Y"
                                    altInput="true"
                                    class="form-control"
                                    dateFormat="Y-m-d"
                                    formControlName="from"
                                    mwlFlatpickr [disableMobile]="true"
                                    type="text"
                                    (flatpickrClose)="fpHelper.applyAltInputValue($event, false)"
                                >
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label>{{ 'analysis.filter_date_to' | translate }}</label>

                                <input
                                    [locale]="locale$ | async"
                                    allowInput="false"
                                    altFormat="d.m.Y"
                                    altInput="true"
                                    class="form-control"
                                    dateFormat="Y-m-d"
                                    formControlName="to"
                                    mwlFlatpickr [disableMobile]="true"
                                    type="text"
                                    (flatpickrClose)="fpHelper.applyAltInputValue($event, false)"
                                >
                            </div>
                        </div>
                    </div>
                </fieldset>

                <fieldset *ngIf="kpiStats$ | async as info">
                    <legend>{{ 'analysis.filter_section_category' | translate }}</legend>

                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label>{{ 'analysis.filter_categories' | translate }}</label>

                                <ng-select
                                    [clearable]="false"
                                    [closeOnSelect]="false"
                                    [items]="info.kpis"
                                    [multiple]="true"
                                    bindLabel="kpi_name"
                                    bindValue="kpi_ID"
                                    class="custom"
                                    formControlName="categoryValues"
                                    notFoundText="{{ 'employees_analysis.chart_filter_no_items_kpi' | translate }}"
                                >
                                    <ng-template ng-multi-label-tmp>
                    <span
                        class="ng-placeholder"
                        style="display: block;"
                    >
                      <span>{{ 'employees_analysis.chart_filter_placeholder_kpi' | translate }}</span>
                      <ng-container *ngIf="kpiFilterForm.value.categoryValues?.length; let categoryCount">
                        <span> ({{ categoryCount }})</span>
                      </ng-container>
                    </span>
                                    </ng-template>

                                    <ng-template
                                        let-index="index"
                                        let-item="item"
                                        let-item$="item$"
                                        ng-option-tmp
                                    >
                                        <input
                                            [checked]="item$.selected"
                                            id="item-{{index}}"
                                            type="checkbox"
                                        /> {{ item.kpi_name }}
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                    </div>
                </fieldset>

                <div class="row">
                    <div class="col-12 text-right">
                        <button class="btn btn-primary">
                            <span>{{ 'analysis.action_filter' | translate }}</span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>

<ng-template
    #filterFormSalaryOptions
    let-modal
>
    <div class="modal-content">
        <div class="modal-header">
            <p class="modal-title font-16 mb-0 text-dark">
                <span>{{ 'analysis.adjust_filters' | translate }}</span>
            </p>

            <button
                (click)="modal.dismiss()"
                aria-label="Close"
                class="close"
            >
        <span
            aria-hidden="true"
            class="font-24 text-muted"
        >
          <i class="mdi mdi-close"></i>
        </span>
            </button>
        </div>

        <div class="modal-body">
            <form
                (ngSubmit)="onSalaryFilterFormSubmit()"
                [formGroup]="salaryFilterForm"
                class="filter-form"
            >
                <fieldset>
                    <legend>{{ 'analysis.filter_section_time' | translate }}</legend>

                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label>{{ 'analysis.filter_date_from' | translate }}</label>

                                <input
                                    [locale]="locale$ | async"
                                    allowInput="false"
                                    altFormat="d.m.Y"
                                    altInput="true"
                                    class="form-control"
                                    dateFormat="Y-m-d"
                                    formControlName="from"
                                    mwlFlatpickr [disableMobile]="true"
                                    type="text"
                                    (flatpickrClose)="fpHelper.applyAltInputValue($event, false)"
                                >
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label>{{ 'analysis.filter_date_to' | translate }}</label>

                                <input
                                    [locale]="locale$ | async"
                                    allowInput="false"
                                    altFormat="d.m.Y"
                                    altInput="true"
                                    class="form-control"
                                    dateFormat="Y-m-d"
                                    formControlName="to"
                                    mwlFlatpickr [disableMobile]="true"
                                    type="text"
                                    (flatpickrClose)="fpHelper.applyAltInputValue($event, false)"
                                >
                            </div>
                        </div>
                    </div>
                </fieldset>

                <fieldset>
                    <legend>{{ 'analysis.filter_section_category' | translate }}</legend>

                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label>{{ 'analysis.filter_categories' | translate }}</label>

                                <ng-select
                                    [clearable]="false"
                                    [closeOnSelect]="false"
                                    [items]="salaryCategories"
                                    [multiple]="true"
                                    bindLabel="label"
                                    bindValue="value"
                                    class="custom"
                                    formControlName="categoryValues"
                                    notFoundText="{{ 'employees_analysis.chart_filter_no_items_salary' | translate }}"
                                >
                                    <ng-template ng-multi-label-tmp>
                    <span
                        class="ng-placeholder"
                        style="display: block;"
                    >
                      <span>{{ 'employees_analysis.chart_filter_placeholder_salary' | translate }}</span>
                      <ng-container *ngIf="salaryFilterForm.value.categoryValues?.length; let categoryCount">
                        <span> ({{ categoryCount }})</span>
                      </ng-container>
                    </span>
                                    </ng-template>

                                    <ng-template
                                        let-index="index"
                                        let-item="item"
                                        let-item$="item$"
                                        ng-option-tmp
                                    >
                                        <input
                                            [checked]="item$.selected"
                                            id="item-{{index}}"
                                            type="checkbox"
                                        /> {{ item.label }}
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label>
                                    <span>{{ 'employees_salaries.aggregate' | translate }}</span>
                                </label>

                                <div class="mt-1">
                                    <input
                                        data-switch="success"
                                        formControlName="groupByCategory"
                                        id="groupByCategory"
                                        name="groupByCategory"
                                        type="checkbox"
                                    >

                                    <label for="groupByCategory">&nbsp;</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>

                <div class="row">
                    <div class="col-12 text-right">
                        <button class="btn btn-primary">
                            <span>{{ 'analysis.action_filter' | translate }}</span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>

<ng-container *ngIf="checkPermission('analyse.viewCosts') && checkPermission('employeeSalary') && checkPermission('employeeBonus') && (availableWidgets$ | async)?.employee?.analysis?.costs_chart">
    <div *ngIf="(salaryStats$ | async) as info; else loadingTemplate" class="row">
        <div class="col-12">
            <div class="card shadow">
                <app-help name="employee.detail.analysis.costs_chart"></app-help>

                <div class="card-header py-3">
                    <div class="row">
                        <div class="col-6">
                            <p class="modal-title font-16 text-dark pt-2 pb-2">
                                <span>{{ 'employees_analysis.chart_headline_salary' | translate }}</span>
                            </p>
                        </div>

                        <div class="col-6 text-right">
                            <button
                                (click)="openFilterFormSalaryOptions()"
                                [formGroup]="salaryFilterForm"
                                class="btn btn-primary btn-icon"
                                type="button"
                            >
                                <i class="mdi mdi-filter-outline"></i>
                            </button>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <div
                            *ngIf="info.line?.series"
                            class="row"
                        >
                            <div class="col-lg-12">
                                <app-line-chart
                                    [labels]="info.line.labels"
                                    [series]="info.line.series"
                                    [yaxisFormat]="'CURRENCY'"
                                    type="line"
                                ></app-line-chart>
                            </div>
                        </div>

                        <div
                            *ngIf="!info.line?.series"
                            class="row"
                        >
                            <div class="col-12">
                                <div class="card">
                                    <div class="card-body">
                                        <h5 class="text-muted font-weight-normal pt-5 pb-5 text-center mt-0">
                                            <span>{{ 'global.not_enough_data' | translate }}</span>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="checkPermission('analyse.viewReviews') && checkPermission('employeeReview') && (availableWidgets$ | async)?.employee?.analysis?.kpi_chart">
    <div *ngIf="(kpiStats$ | async) as info; else loadingTemplate" class="row">
        <div class="col-12">
            <div class="card shadow">
                <app-help name="employee.detail.analysis.kpi_chart"></app-help>

                <div class="card-header py-3">
                    <div class="row">
                        <div class="col-6">
                            <p class="modal-title font-16 text-dark pt-2 pb-2">
                                <span>{{ 'employees_analysis.chart_headline_kpi' | translate }}</span>
                            </p>
                        </div>

                        <div class="col-6 text-right">
                            <button
                                (click)="openFilterFormKPIOptions()"
                                [formGroup]="kpiFilterForm"
                                class="btn btn-primary btn-icon"
                                type="button"
                            >
                                <i class="mdi mdi-filter-outline"></i>
                            </button>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <div
                            *ngIf="info.line?.series"
                            class="row"
                        >
                            <div class="col-lg-12">
                                <app-bar-chart
                                    [labels]="info.line.labels"
                                    [series]="info.line.series"
                                    [title]="null"
                                    [yaxisFormat]="null"
                                ></app-bar-chart>
                            </div>
                        </div>

                        <div
                            *ngIf="!info.line?.series"
                            class="row"
                        >
                            <div class="col-12">
                                <div class="card">
                                    <div class="card-body">
                                        <h5 class="text-muted font-weight-normal pt-5 pb-5 text-center mt-0">
                                            <span>{{ 'global.not_enough_data' | translate }}</span>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    *ngFor="let d of info.radial"
                    class="row"
                >
                    <div class="col-12">
                        <div class="card-header">
                            <h4 class="text-dark page-title mb-3">{{ d.name }}</h4>
                        </div>

                        <div class="row">
                            <div
                                *ngFor="let ch of d.charts"
                                class="col-md-4"
                            >
                                <app-radial-chart
                                    [absoluteValue]="ch.absolute_value"
                                    [labelFormat]="'ABSOLUTE_VALUE_MAX_VALUE'"
                                    [labels]="ch.labels"
                                    [maxValue]="ch.max_value"
                                    [series]="ch.series"
                                    [title]="null"
                                ></app-radial-chart>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="checkPermission('analyse.viewReviews') && checkPermission('employeeReview') && (availableWidgets$ | async)?.employee?.analysis?.kpi_evaluation">
    <div *ngIf="(kpiStats$ | async) as info; else loadingTemplate" class="row">
        <div class="col-12">
            <div class="card shadow">
                <app-help name="employee.detail.analysis.kpi_evaluation"></app-help>

                <div class="card-header py-3">
                    <div class="row">
                        <div class="col-6 col-sm-6 col-md-7">
                            <p class="modal-title font-16 text-dark pt-2 pb-2">
                                <span>{{ 'employees_analysis.kpi_rating' | translate}}</span>
                            </p>
                        </div>
                    </div>
                </div>

                <div class="card-body">
                    <ng-container *ngIf="info.kpis?.length; else noKpisTemplate">
                        <ng-container *ngFor="let k of info.kpis">
                            <h3 class="font-16">{{ k.kpi_name }}</h3>

                            <div class="table-responsive">
                                <table class="table table-centered table-hover">
                                    <thead>
                                        <tr>
                                            <th>{{ 'employees_analysis.chart_kpis_employee' | translate }}</th>
                                            <th>{{ 'employees_analysis.chart_kpis_min' | translate }}</th>
                                            <th>{{ 'employees_analysis.chart_kpis_max' | translate }}</th>
                                            <th>{{ 'employees_analysis.chart_kpis_score_absolute' | translate }}</th>
                                            <th>{{ 'employees_analysis.chart_kpis_score_percentage' | translate }}</th>
                                            <th>{{ 'employees_analysis.chart_kpis_date' | translate }}</th>
                                            <th>{{ 'employees_analysis.chart_kpis_action' | translate }}</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr *ngFor="let e of k.table">
                                            <td> <app-employee-box-avatar [employee]="e"></app-employee-box-avatar> </td>

                                            <td>{{ e.min_score | number: '1.0-2' }}</td>

                                            <td>{{ e.max_score | number: '1.0-2' }}</td>

                                            <td>{{ e.score | number: '1.0-2' }}</td>

                                            <td>
                      <span
                          *ngIf="e.threshold_count == 2"
                          [ngClass]="{
                                'text-danger': e.percent_score <= e.threshold_1,
                                'text-warning': e.percent_score > e.threshold_1 && e.percent_score <= e.threshold_2,
                                'text-green': e.percent_score > e.threshold_2
                              }"
                      >{{ e.percent_score | number: '1.0-2' }}%</span>

                                                <span
                                                    *ngIf="e.threshold_count == 1"
                                                    [ngClass]="{
                                'text-danger': e.percent_score <= e.threshold_1,
                                'text-green': e.percent_score > e.threshold_1
                              }"
                                                >{{ e.percent_score | number: '1.0-2' }}%</span>

                                                <span *ngIf="e.threshold_count == 0">{{ e.percent_score | number: '1.0-2' }}%</span>
                                            </td>

                                            <td class="text-right">{{ e.date | date: 'dd.MM.yyyy' }}</td>

                                            <td>
                                                <button
                                                    (click)="openKPIReviewEditModal(e.review)"
                                                    class="btn btn-white btn-icon btn-sm mr-1"
                                                >
                                                    <i class="mdi mdi-eye-outline mdi-24px text-muted"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </ng-container>
                    </ng-container>

                    <ng-template #noKpisTemplate>
                        <div class="row">
                            <div class="col-12 text-center">
                                <span>{{ 'global.empty_widget_error' | translate }}</span>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="checkPermission('analyse.viewAttendance') && checkPermission('employeeDatetime') && (availableWidgets$ | async)?.employee?.analysis?.attendance_chart">
    <div *ngIf="(canRenderAttendance$ | async); else loadingTemplate" class="row">
        <div class="col-12">
            <div class="card shadow">
                <app-help name="employee.detail.analysis.attendance_chart"></app-help>

                <div class="card-header py-3">
                    <div class="row">
                        <div class="col-6">
                            <p class="modal-title font-16 text-dark pt-2 pb-2">
                                <span>{{ 'employees_analysis.chart_headline_attendance' | translate }}</span>
                            </p>
                        </div>

                        <div class="col-6 text-right">
                            <button
                                (click)="openFilterFormAttendanceOptions()"
                                [formGroup]="attendanceFilterForm"
                                class="btn btn-primary btn-icon"
                                type="button"
                            >
                                <i class="mdi mdi-filter-outline"></i>
                            </button>
                        </div>
                    </div>
                </div>

                <div
                    class="card-body"
                >
                    <app-employee-analysis-attendance
                        [categoryIds]="attendanceSelectedCategories"
                        [employeeIds]="[employeeID]"
                        [from]="attendanceFrom"
                        [groupByCategory]="attendanceGroupByCategory"
                        [periodType]="attendancePeriodType"
                        [to]="attendanceTo"
                    ></app-employee-analysis-attendance>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-template #loadingTemplate>
    <div class="row">
        <div class="col-12">
            <div class="card shadow p-3">
                <div class="card-body">
                    <div class="item">
                        <ngx-skeleton-loader [theme]="{
      'margin': '11px 0'
    }" appearance="line" count="5"></ngx-skeleton-loader>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
