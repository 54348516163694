<!--<editor-fold desc="MODALS">-->
<ng-template #content id="PropertyThumbnailModal" let-modal>
  <div class="modal-content">
    <div class="modal-header">
      <p class="modal-title font-16 mb-0 text-dark">
        <span>{{ 'inventory_items.thumbnail' | translate }}</span>
      </p>

      <button
        (click)="modal.dismiss()"
        aria-label="Close"
        class="close"
        type="button"
      >
        <span
          class="font-24 text-muted"
        >
          <i class="mdi mdi-close"></i>
        </span>
      </button>
    </div>

    <div class="modal-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card mb-0 shadow-none">
            <div class="card-body p-0 text-center">
              <img
                [attr.src]="selectedProperty?.thumbnail.url"
                alt="image"
                class="img-fluid"
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template
  #filterFormOptions
  let-modal
>
  <div class="modal-content">
    <div class="modal-header">
      <p class="modal-title font-16 mb-0 text-dark">
        <span>{{ 'employees_events.adjust_filters' | translate }}</span>
      </p>

      <button
        (click)="modal.dismiss()"
        aria-label="Close"
        class="close"
      >
        <span
          class="font-24 text-muted"
        >
          <i class="mdi mdi-close"></i>
        </span>
      </button>
    </div>

    <div class="modal-body">
      <form
        (ngSubmit)="onFilterSubmit()"
        [formGroup]="filterForm"
        class="filter-form"
      >
        <fieldset>
          <legend>{{ 'properties.filter_section_time' | translate }}</legend>

          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label>{{ 'properties.filter_date_from' | translate }}</label>

                <input
                  [locale]="locale$ | async"
                  allowInput="false"
                  altFormat="d.m.Y"
                  altInput="true"
                  class="form-control"
                  dateFormat="Y-m-d"
                  formControlName="from"
                  mwlFlatpickr [disableMobile]="true"
                  (flatpickrClose)="fpHelper.applyAltInputValue($event, false)"
                  type="text"
                >
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label>{{ 'properties.filter_date_to' | translate }}</label>

                <input
                  [locale]="locale$ | async"
                  allowInput="false"
                  altFormat="d.m.Y"
                  altInput="true"
                  class="form-control"
                  dateFormat="Y-m-d"
                  formControlName="to"
                  mwlFlatpickr [disableMobile]="true"
                  (flatpickrClose)="fpHelper.applyAltInputValue($event, false)"
                  type="text"
                >
              </div>
            </div>
          </div>
        </fieldset>

        <fieldset>
          <legend>{{ 'properties.filter_section_state' | translate }}</legend>

          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label>{{ 'properties.filter_state' | translate }}</label>

                <ng-select
                  [clearable]="false"
                  [closeOnSelect]="false"
                  [items]="selectFilterValues"
                  [multiple]="true"
                  [searchable]="false"
                  bindLabel="name"
                  bindValue="value"
                  class="custom"
                  formControlName="states"
                ></ng-select>

              </div>
            </div>
          </div>
        </fieldset>

        <fieldset>
          <legend>{{ 'properties.filter_section_category' | translate }}</legend>

          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label>{{ 'properties.filter_categories' | translate }}</label>

                <ng-select
                  [bindLabel]="'name'"
                  [bindValue]="'property_category_ID'"
                  [clearable]="false"
                  [closeOnSelect]="false"
                  [items]="categories$ | async"
                  [multiple]="true"
                  class="custom"
                  formControlName="categoriesIDs"
                >
                  <ng-template ng-multi-label-tmp>
                    <span
                      class="ng-placeholder"
                      style="display: block;"
                    >
                      <span>{{ 'properties.filter_categories_count' | translate }}</span>
                      <span> (</span>
                      <span>{{ categoriesIDsCount }}</span>
                      <span>)</span>
                    </span>
                  </ng-template>

                  <ng-template
                    let-index="index"
                    let-item="item"
                    let-item$="item$"
                    ng-option-tmp
                  >
                    <input
                      [checked]="item$.selected"
                      id="item-{{index}}"
                      type="checkbox"
                    />

                    <span>&nbsp;</span>
                    <span>{{ item.name }}</span>
                  </ng-template>
                </ng-select>
              </div>
            </div>
          </div>
        </fieldset>

        <fieldset>
          <legend>{{ 'properties.filter_section_employee' | translate }}</legend>

          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label>{{ 'properties.filter_employee' | translate }}</label>

                <ng-select
                  [bindValue]="'employee_ID'"
                  [clearable]="false"
                  [closeOnSelect]="false"
                  [items]="employees$ | async"
                  [multiple]="true"
                  class="custom"
                  formControlName="employeesIDs"
                >
                  <ng-template ng-multi-label-tmp>
                    <span
                      class="ng-placeholder"
                      style="display: block;"
                    >
                      <span>{{ 'properties.filter_employees_count' | translate }}</span>
                      <span> (</span>
                      <span>{{ EmployeesCount }}</span>
                      <span>)</span>
                    </span>
                  </ng-template>

                  <ng-template
                    let-index="index"
                    let-item="item"
                    let-item$="item$"
                    ng-option-tmp
                  >
                    <input
                      [checked]="item$.selected"
                      id="item-{{index}}"
                      type="checkbox"
                    />

                    <span>&nbsp;</span>
                    <span>{{ item.fullname }}</span>
                  </ng-template>
                </ng-select>
              </div>
            </div>

            <div class="col-12">
              <div>
                <label>{{ 'properties.filter_unassigned_employee' | translate }}</label>
              </div>
              <div>
                <input
                  (change)="setUnassignedEmployee()"
                  data-switch="success"
                  formControlName="unassignedEmployee"
                  id="unassignedEmployee"
                  name="unassignedEmployee"
                  type="checkbox"
                  >
                <label for="unassignedEmployee">&nbsp;</label>
              </div>
            </div>
          </div>
        </fieldset>

        <div class="row">
          <div class="col-12 text-right">
            <button class="btn btn-primary">
              <span>{{ 'employees_events.action_filter' | translate }}</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>
<!--</editor-fold desc="MODALS">-->

<div *ngIf="rows && (availableWidgets$ | async)?.property_table?.columns as availableWidgets; else loadingtemplate" class="row">
  <div class="col-12">
    <div class="card shadow">
      <app-help name="property.list"></app-help>
      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <div [ngClass]="{'table-compact': (availableWidgets$ | async)?.property_table?.settings?.is_compact}" class="table-responsive overflow-visible">
              <app-data-table
                (searchChanged)="searchChanged($event)"
                (sortChanged)="sortChanged($event)"
                [colsConfig]="{width: setColumnsWidth(availableWidgets)}"
                [compactModeClass]="'table-compact-1-2-3'"
                [disableInPlaceFiltering]="true"
                [disableInPlaceSorting]="true"
                [loading]="loading"
                [rows]="rows"
                [search]="search"
                [showSearch]="true"
                [sort]="sort"
                [trackByFn]="trackByFn"
              >
                <ng-template #headerButton>

                  <app-export *ngIf="canExport$ | async" [stream$]="properties$"></app-export>
                  <button
                      (click)="openFilterFormOptions()"
                      [formGroup]="filterForm"
                      class="btn btn-primary btn-icon ml-2"
                      type="button"
                    >
                      <i class="mdi mdi-filter-outline"></i>
                  </button>

                  <button
                    (click)="openEditModal(rows)"
                    *ngIf="canCreate$ | async"
                    [createButton]="'properties.action_create' | translate"
                    class="ml-2"
                  ></button>

                </ng-template>

                <ng-template #headerRow>
                  <th>
                    <div class="form-check">
                        <input (click)="checkUncheckAll($event)" [(ngModel)]="checkedWholePage" aria-label="..." class="form-check-input position-static"
                               id="blankCheckbox" type="checkbox" value="option1">
                    </div>
                  </th>

                  <th *ngIf="availableWidgets?.category" appDataTableHeaderCell="category.name">{{ 'properties.category_name' | translate }}</th>

                  <th appDataTableHeaderCell="name">{{ 'properties.name' | translate }}</th>

                  <th *ngIf="availableWidgets?.image"
                      [disableSort]="true"
                      appDataTableHeaderCell
                  >{{ 'inventory_items.thumbnail' | translate }}</th>

                  <th *ngIf="availableWidgets?.state" appDataTableHeaderCell="code">{{ 'properties.state' | translate }}</th>

                  <th *ngIf="availableWidgets?.code" appDataTableHeaderCell="code">{{ 'properties.code' | translate }}</th>

                  <th *ngIf="availableWidgets?.serial_number" appDataTableHeaderCell="serial_number">{{ 'properties.serial_number' | translate }}</th>

                  <th *ngIf="availableWidgets?.employee" appDataTableHeaderCell="employee.fullname">{{ 'properties.employee' | translate }}</th>

                  <th *ngIf="availableWidgets?.date"
                      appDataTableHeaderCell="date_of_registration"
                      name="date_of_registration"
                  >{{ 'properties.date_of_registration' | translate }}</th>

                    <th *ngFor="let column of customFieldColumns" [appDataTableHeaderCell]="'custom_field.' + column.custom_field_definition_ID">{{ column.label }}</th>

                  <th [disableSort]="true"
                      appDataTableHeaderCell
                      class="text-right"
                  >{{ 'properties.table_action' | translate }}</th>
                </ng-template>

                <ng-template
                  #dataRow
                  let-row
                >
                  <td>
                    <div class="form-check">
                        <input (click)="rowCheckBoxChecked($event, row.property_ID)" [checked]="checkedPropertyIds.includes(row.property_ID)" aria-label="..."
                               class="form-check-input position-static" type="checkbox" value="1">
                    </div>
                  </td>

                  <td *ngIf="availableWidgets?.category" appDataTableDataCell>
                    <span *ngIf="row.category">{{ row.category?.name }}</span>
                    <span *ngIf="!row.category">--</span>
                  </td>

                  <td appDataTableDataCell>{{ row.name }}</td>

                  <td *ngIf="availableWidgets?.image"
                    appDataTableDataCell
                    class="text-center"
                  >
                    <app-value-or-dash>
                      <button
                        *ngIf="row.thumbnail"
                        class="btn btn-link"
                      >
                        <img
                          (click)="openThumbnailModal(content, row)"
                          [attr.src]="row.thumbnail.url"
                          alt="image"
                          class="img-fluid avatar-xs"
                        >
                      </button>
                    </app-value-or-dash>
                  </td>

                  <td *ngIf="availableWidgets?.state" appDataTableDataCell>{{ 'properties.states.'+row.state |translate }}</td>

                  <td *ngIf="availableWidgets?.code" appDataTableDataCell>{{ row.code }}</td>

                  <td *ngIf="availableWidgets?.serial_number" appDataTableDataCell>
                    <app-value-or-dash>{{ row.serial_number }}</app-value-or-dash>
                  </td>

                  <td *ngIf="availableWidgets?.employee" appDataTableDataCell>
                    <app-value-or-dash>{{ row.employee?.fullname }}</app-value-or-dash>
                  </td>

                  <td *ngIf="availableWidgets?.date" appDataTableDataCell>{{ row.date_of_registration_formatted }}</td>

                    <td *ngFor="let column of row?.custom_fields" appDataTableDataCell>
                        <ng-container *ngIf="column.type !== 'TEXTAREA' && column.type !== 'RICHTEXT'">
                            <ng-container [ngSwitch]="column.type">
                                <span *ngSwitchCase="'PERCENT'"> {{ column.value }} %</span>
                                <span *ngSwitchCase="'AMOUNT'"> {{ column.value | localizedCurrency }}</span>
                                <span *ngSwitchCase="'DATE'"> {{ column.value | date: 'dd.MM.yyyy' }}</span>
                                <span *ngSwitchCase="'DATETIME'"> {{ column.value | date: 'dd.MM.yyyy HH:mm' }}</span>
                                <span *ngSwitchCase="'TOGGLE'">  {{ (column.value ? 'global.YES' : 'global.NO') | translate }}</span>
                                <span *ngSwitchDefault>{{ column.value }}</span>
                            </ng-container>
                        </ng-container>
                    </td>

                  <td
                    appDataTableDataCell
                    class="text-right"
                  >
                    <a
                      [routerLink]="['/property', 'detail', row.property_ID]"
                      class="btn btn-white btn-icon btn-sm mr-1"
                      title="{{ 'properties.action_view' | translate }}"
                    >
                      <i class="mdi mdi-eye-outline mdi-24px text-muted"></i>
                    </a>
                  </td>
                </ng-template>
              </app-data-table>
            </div>
          </div>
        </div>

        <div class="row mt-3 flex-column-reverse flex-sm-row">
          <div class="col-12 col-sm-2">
            <app-items-per-page-select
              (itemsPerPageChanged)="itemsPerPageChanged($event)"
              [itemsPerPage]="itemsPerPage"
              [total]="itemsTotal"
              [steps]="[10, 20, 50, 1000]"
            ></app-items-per-page-select>
          </div>

          <div class="col-12 col-sm-10">
            <app-pagination
              (pageChanged)="pageChanged($event)"
              [itemsPerPage]="itemsPerPage"
              [page]="page"
              [total]="itemsTotal"
            ></app-pagination>
          </div>
        </div>
        <div *ngIf="checkedPropertyIds.length" class="row mt-3 d-flex justify-content-between">
          <span class="ml-1">{{ 'employees_properties.selected_count' | translate }}: {{ checkedPropertyIds.length }}</span>
          <div>
              <button (click)="openMultiUnassignModal()" class="btn btn-primary btn-sm mr-1"
                      title="{{ 'properties.action_attach' | translate }}">
                  {{ 'properties.action_attach' | translate }}
              </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<ng-template #loadingtemplate>
    <div class="row">
        <div class="col-12">
            <div class="card shadow p-3">
                <div class="item">
                    <ngx-skeleton-loader [theme]="{
        'margin': '15px 0'
      }" appearance="line" count="7"></ngx-skeleton-loader>
                </div>
            </div>
        </div>
    </div>
</ng-template>
