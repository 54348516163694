import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable, ReplaySubject } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';
import { ListParameters, ListResponse, transformParameters } from '../../api';
import { AuthenticationService } from '../../core/services/authentication.service';
import { Employee } from '../../employee/models/Employee';
import { ApiHelper } from '../common/ApiHelper';
import { SwalHelper } from '../common/SwalHelper';
import { camelToSnakeCase } from '../functions';
import { ApiResponse } from '../models/ApiResponse';
import { WidgetTemplate } from '../models/WidgetTemplate';

@Injectable({
    providedIn: 'root'
})
export class WidgetTemplatesService {
    public defaultWidgetTemplate$: Observable<WidgetTemplate>;
    public defaultWidgetTemplate: WidgetTemplate;

    private _defaultWidgetTemplate$$ = new ReplaySubject<WidgetTemplate>(1);

    public constructor(
        private _apiHelper: ApiHelper,
        private _http: HttpClient,
        private _loaderService: NgxUiLoaderService,
        private _swalHelper: SwalHelper,
        private _authService: AuthenticationService
    ) {
        this.defaultWidgetTemplate$ = this._defaultWidgetTemplate$$;
    }

    public getWidgetTemplateByID(id = 'default'): Observable<WidgetTemplate> {
        return this._http.get<WidgetTemplate>(`/api/widget-templates/${id}`)
            .pipe(
                tap(template => {
                    this._defaultWidgetTemplate$$.next(template);
                    this.defaultWidgetTemplate = template;
                }),
                shareReplay()
            );
    }

    public getAllTemplates(scope: string): Observable<Array<WidgetTemplate>> {
        const params = new HttpParams().set('scope', scope);
        return this._http.get<Array<WidgetTemplate>>('/api/widget-templates', {params});
    }

    public getWidgetTemplateList(params: ListParameters<WidgetTemplate>): Observable<ListResponse<WidgetTemplate>> {
        const p = transformParameters(params);

        return this._http.post<ListResponse<WidgetTemplate>>('/api/widget-templates/list', {
            ...p,
        });
    }

    public saveWidgetTemplate(form: WidgetTemplate, widgetTemplateID?: number): Promise<'done'> {
        return new Promise(resolve => {
            this._loaderService.startLoader('master');

            if (widgetTemplateID) {
                this._http.post<ApiResponse>('/api/widget-templates/' + widgetTemplateID, form)
                    .subscribe(
                        response => {
                            this._apiHelper.handleSuccessResponse(response);

                            resolve('done');

                            this._loaderService.stopLoader('master');
                        },
                        error => {
                            this._apiHelper.handleErrorResponse(error);

                            this._loaderService.stopLoader('master');
                        }
                    );
            } else {
                this._http.post<ApiResponse>('/api/widget-templates', form)
                    .subscribe(
                        response => {
                            this._apiHelper.handleSuccessResponse(response);

                            resolve('done');

                            this._loaderService.stopLoader('master');
                        },
                        error => {

                            this._apiHelper.handleErrorResponse(error);

                            this._loaderService.stopLoader('master');
                        }
                    );
            }
        });
    }

    public deleteWidgetTemplate(widgetTemplateID: number): Promise<'done'> {
        return new Promise(async (resolve, reject) => {
            const deleteConfirmed = await this._swalHelper.showConfirmDeleteDialog();

            if (deleteConfirmed) {
                this._http.delete('/api/widget-templates/' + widgetTemplateID)
                    .subscribe(
                        (response: any) => {
                            this._loaderService.stopLoader('master');

                            this._apiHelper.handleSuccessResponse(response);

                            resolve('done');
                        },
                        error => {
                            this._loaderService.stopLoader('master');

                            this._apiHelper.handleErrorResponse(error);
                        }
                    );
            } else {
                reject('error');
            }
        });
    }

    public canSeeButton(entity: 'Address' | 'ContactPerson' | 'PersonalData', action: 'Edit' | 'Create', permissionsForCurrentEmployee?: Employee['permissions']): boolean {
        // new way to check permissions - only for personal data widget
        if (permissionsForCurrentEmployee) {
            return permissionsForCurrentEmployee[action.toLowerCase() + entity] || permissionsForCurrentEmployee[action.toLowerCase() + entity + 'WithApproval'];
        }
        if (this._authService.checkPermission(['employee' + entity + '.' + action.toLowerCase(), 'employee' + entity + '.' + action.toLowerCase() + 'Structure'])) {
            return true;
        }

        const entitySnakeCase = camelToSnakeCase(entity).replace('_', '');
        return this.defaultWidgetTemplate[entitySnakeCase] !== false;
    }

    public presetFormByConfig(form: UntypedFormGroup, config: { [key: string]: string }): void {
        const keys = Object.keys(config);
        for (const key of keys) {
            if (config[key] === 'HIDDEN') {
                form.removeControl(key);
            }
            if (config[key] === 'READONLY') {
                form.get(key).disable();
            }
            if (config[key] === 'REQUIRED') {
                form.get(key).setValidators([Validators.required]);
            }
        }
    }
}

