<form
  *ngIf="settings$ | async"
  autocomplete="off"
  [formGroup]="form"
  (ngSubmit)="onSubmit()"
>
  <div class="row">
    <div class="col-12">
      <div class="card shadow">
        <app-help name="settings.attendance.shift_duration"></app-help>

        <div class="card-header py-3">
          <div class="row">
            <div class="col-sm-6 text-left my-auto">
              <p class="modal-title font-16 text-dark pt-2 pb-2">
                <span>{{ 'settings.page_title_view_attendance_settings_shift_duration' | translate }}</span>
              </p>
            </div>
          </div>
        </div>

        <div class="card-body">
          <div class="row">
            <div class="col-6">
                <label class="control-label">{{ 'settings.use_shift_duration' | translate }}</label>
            </div>
            <div class="col-6 text-right align-self-center">
                <div>
                    <input data-switch="success" formControlName="company.attendance.use_shift_duration" id="company.attendance.use_shift_duration" name="company.attendance.use_shift_duration" type="checkbox">
                    <label for="company.attendance.use_shift_duration">&nbsp;</label>
                </div>
            </div>
          </div>

            <div [ngbCollapse]="!f['company.attendance.use_shift_duration'].value">
              <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <label class="control-label">
                            <span>{{ 'settings.shift_duration_excluded_employees' | translate }}</span>
                        </label>

                        <ng-select
                            [items]="employees$ | async"
                            [multiple]="true"
                            bindLabel="fullname"
                            bindValue="employee_ID"
                            [closeOnSelect]="false"
                            class="custom"
                            formControlName="company.attendance.shift_duration_excluded_employees"
                            name="company.attendance.shift_duration_excluded_employees"
                        >
                        </ng-select>
                    </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <label class="control-label">
                            <span>{{ 'settings.shift_duration_excluded_datetime_types' | translate }}</span>
                        </label>
                        <ng-select
                            class="custom"
                            formControlName="company.attendance.shift_duration_excluded_datetime_types"
                            name="company.attendance.shift_duration_excluded_datetime_types"
                            [clearable]="false"
                            [items]="datetimeTypes$ | async"
                            bindLabel="name"
                            bindValue="employee_datetime_type_ID"
                            [multiple]="true"
                            [closeOnSelect]="false"
                        >
                        </ng-select>
                    </div>
                </div>
              </div>
            </div>

          <div class="row mt-2">
            <div class="col d-flex justify-content-end">
              <button class="btn btn-success">
                <span>{{ 'global.action_save' | translate }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
