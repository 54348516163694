import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable } from 'rxjs';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { Workspace } from 'src/app/shared/models/Workspace';
import { WorkspaceService } from 'src/app/shared/services/workspace.service';
import { WindowRef } from 'src/app/ui/window';

@Component({
    selector: 'app-switch-workspace-modal',
    templateUrl: './switch-workspace-modal.component.html',
    styleUrls: ['./switch-workspace-modal.component.css']
})
export class SwitchWorkspaceModalComponent {
    public availableWorkspaces$: Observable<Array<Workspace>>;

    public loggedInUser$ = this._authService.loggedUser$;

    public constructor(
        public activeModal: NgbActiveModal,
        private _authService: AuthenticationService,
        private _domSanitizer: DomSanitizer,
        private _loaderService: NgxUiLoaderService,
        private _router: Router,
        private _windowRef: WindowRef,
        private _workspaceService: WorkspaceService
    ) {
        this._init();
    }

    public switch(workspace: Workspace): void {
        this._loaderService.startLoader('master');
        this._workspaceService.switch(workspace)
            .subscribe((switched: any) => {
                this._authService.magicLinkLogin(this._workspaceService.processMagicLink(switched.magic_link));
                this.activeModal.close();
            });
        this._loaderService.stopLoader('master');
    }

    public trackByValue(index: number, workspace: Workspace): string {
        return workspace.value;
    }

    getSafeSrc(logoBase64: string) {
        return this._domSanitizer.bypassSecurityTrustUrl(logoBase64);
    }

    private _init(): void {
        this.availableWorkspaces$ = this._workspaceService.available$;
    }
}
