<div class="row">
    <div class="col-12 text-right">
        <div class="d-inline-block" ngbDropdown>
            <button class="ml-2 btn btn-icon btn-light downloadButton" id="dropdownBasic1" ngbDropdownToggle type="button"></button>
            <div aria-labelledby="dropdownBasic1" ngbDropdownMenu>
                <button (click)="exportData('xlsx')" ngbDropdownItem>Excel</button>
                <button (click)="exportData('csv')" ngbDropdownItem>CSV</button>
            </div>
        </div>
    </div>
</div>
