<div class="card shadow">
  <app-help name="employee.detail.position"></app-help>

  <div class="card-header py-3">
    <div class="row">
      <div class="col-6 col-sm-6 col-md-7">
        <p class="modal-title font-16 text-dark pt-2 pb-2">
          <span>{{ 'employees_positions.position' | translate }}</span>
        </p>
      </div>

      <div class="col-6 col-sm-6 col-md-5 text-right align-self-center">
        <button
          (click)="openPositionCreateModal(null)"
          *ngIf="employee?.permissions.createPosition"
          [createButton]="'employees_positions.action_create' | translate"
          class="ml-2"
        ></button>
      </div>
    </div>
  </div>

  <div class="card-body">
    <ng-container *ngIf="positions$ | async; let positions; else loadingTemplate">
      <ng-container *ngIf="positions?.length; else noDataTemplate">
        <div class="table-responsive">
          <table class="table table-hover table-centered mb-0">
            <tbody>
              <tr *ngFor="let position of positions">
                <td>
                  <div>{{ position.name }}</div>
                  <small *ngIf="position.company_branch_name" class="text-muted">{{ position.company_branch_name }}</small>
                </td>

                <td>
                  <span>{{ position.from | date:'dd.MM.yyyy' }} </span>
                  <span *ngIf="position.to">- {{ position.to | date:'dd.MM.yyyy' }}</span>
                </td>

                  <td>
                      <span [ngbTooltip]="'employees_positions.hours_per_week' | translate">{{ position.hours_per_week_string }} </span>
                  </td>

                <td>{{ ('employees_positions.'+(position.type)) | translate}}</td>

                <td class="text-right">
                  <a
                    (click)="openPositionCreateModal(position)"
                    class="btn btn-white btn-icon btn-sm mr-1"
                    title="{{ 'employees_positions.action_edit' | translate }}"
                  >
                    <i class="mdi mdi-eye-outline mdi-24px text-muted"></i>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ng-container>
    </ng-container>

    <ng-template #loadingTemplate>
      <app-row-loader></app-row-loader>
    </ng-template>

    <ng-template #noDataTemplate>
      <div class="text-center text-muted">{{ 'global.empty_widget_error' | translate }}</div>
    </ng-template>
  </div>
</div>
