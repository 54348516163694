<div
    *ngIf="data$ | async as data; else loadingTemplate"
    class="row"
>
    <ng-container *ngIf="data.prev_next">
        <div class="prev-next-employee">
                <span *ngIf="data.prev_next?.prev as prev">
                    <i class="mdi mdi-chevron-left"></i>
                    <span (click)="navigateToEntity(prev.employee_ID)">{{prev.fullname}}</span>
                </span>
            <span *ngIf="data.prev_next?.prev && data.prev_next?.next" class="mr-3 space"></span>
            <span *ngIf="data.prev_next?.next as next">
                    <span (click)="navigateToEntity(next.employee_ID)">{{next.fullname}}</span>
                    <i class="mdi mdi-chevron-right"></i>
                </span>
        </div>
    </ng-container>
    <div class="col-xl-9 mb-3">
        <div class="card h-100 shadow">
            <app-help name="attendance.documents.detail.employee.info_table"></app-help>
            <div class="card-header py-3">
                <div class="d-flex justify-content-between">
                    <div class="">
                        <p class="modal-title font-16 text-dark pt-2 pb-2">
                            <a class="anchor-unstyled employee-name underline-on-hover" [routerLink]="['/employee', data.employee.employee_ID, 'detail', 'overview']"> <span>{{ data.employee.fullname }} </span></a>
                            <span>{{ year }}</span>
                            <span>/</span>
                            <span>{{ month }}</span>
                        </p>
                    </div>
                    <div>
                        <button (click)="goToMonthOverview(prevYear, prevMonth)" class="btn btn-primary mr-2">< {{ prevYear+'/'+prevMonth }}</button>
                        <button (click)="goToMonthOverview(nextYear, nextMonth)" class="btn btn-primary" [disabled]="!nextActive">{{ nextYear+'/'+nextMonth }} ></button>
                        <app-protect-unprotect-datetimes [state]="data.summary.protection_state.protected_ratio === 0" [from]="from" [to]="to" [employees]="employeeID" (stateChanged)="init()"></app-protect-unprotect-datetimes>
                        <a
                            (click)="recalculateDatetimes()"
                            class="btn btn-light btn-icon mx-2"
                            title="{{ 'employees_datetimes.recalculate_attendance' | translate }}"
                        >
                            <i class="mdi mdi-timer-refresh-outline mdi-24px text-muted"></i>
                        </a>
                        <button (click)="downloadAsPDF(year, month, data)" class="btn btn-primary btn-icon"><span class="mdi mdi-file-pdf-box"></span></button>
                    </div>
                </div>
            </div>

            <div class="card-body px-4">
                <div class="table-responsive">
                    <app-data-table
                        [customNoDataMessage]="'global.empty_widget_error' | translate"
                        [disableInPlaceSorting]="true"
                        [rows]="data.dates"
                        [showSearch]="false"
                    >
                        <ng-template #headerRow>
                            <th appDataTableHeaderCell [disableSort]="true">{{ 'employees_datetimes.day' | translate }}</th>
                            <th appDataTableHeaderCell [disableSort]="true">{{ 'employees_datetimes.work_start' | translate }}</th>
                            <th appDataTableHeaderCell [disableSort]="true">{{ 'employees_datetimes.work_end' | translate }}</th>
                            <th appDataTableHeaderCell [disableSort]="true">{{ 'employees_datetimes.detail' | translate }}</th>
                            <th appDataTableHeaderCell [disableSort]="true">{{ 'employees_datetimes.Total' | translate }}</th>
                            <th *ngIf="data.employees_datetimes_projects" appDataTableHeaderCell [disableSort]="true">{{ 'employees_datetimes.project' | translate }}</th>
                            <th appDataTableHeaderCell [disableSort]="true">{{ 'employees_datetimes.absence_start' | translate }}</th>
                            <th appDataTableHeaderCell [disableSort]="true">{{ 'employees_datetimes.absence_end' | translate }}</th>
                            <th appDataTableHeaderCell [disableSort]="true">{{ 'employees_datetimes.detail' | translate }}</th>
                            <th appDataTableHeaderCell [disableSort]="true">{{ 'employees_datetimes.Total' | translate }}</th>
                            <th *ngIf="!data.is_uneven" appDataTableHeaderCell [disableSort]="true">{{ 'employees_datetimes.saldo' | translate }}</th>
                            <th *ngIf="data.meal_vouchers" appDataTableHeaderCell [disableSort]="true">{{ 'employees_datetimes.meal_vouchers' | translate }}</th>
                        </ng-template>

                        <ng-template
                            #dataRow
                            let-row
                        >
                            <td [ngClass]="{'bg-light': row.is_holiday || row.is_weekend}" appDataTableDataCell>
                                {{ row.date | date: 'dd.MM.yyyy' }}
                            </td>

                            <td [ngClass]="{'bg-light': row.is_holiday || row.is_weekend}" appDataTableDataCell>
                                <div *ngFor="let from of row.work.from | keyvalue">
                                    <span *ngIf="from.value">{{ from.value }}</span>
                                </div>
                            </td>

                            <td [ngClass]="{'bg-light': row.is_holiday || row.is_weekend}" appDataTableDataCell>
                                <div *ngFor="let to of row.work.to | keyvalue">
                                    <span *ngIf="to.value">{{ to.value }}</span>
                                </div>
                            </td>

                            <td [ngClass]="{'bg-light': row.is_holiday || row.is_weekend}" appDataTableDataCell>
                                <div *ngFor="let datetimeId of row.work.datetime_ids | keyvalue">
                                    <span *ngIf="datetimeId.value"
                                            class="cursor-pointer"
                                            title="{{ 'employees.action_view' | translate }}"
                                            (click)="openDatetimeModal(datetimeId.value)"
                                    >
                                        <i class="mdi mdi-eye-outline mdi-18px text-muted" style="line-height: 18px"></i>
                                    </span>
                                </div>
                            </td>

                            <td [ngClass]="{'bg-light': row.is_holiday || row.is_weekend}" appDataTableDataCell>
                                {{ row.work.total }}
                            </td>

                            <td *ngIf="data.employees_datetimes_projects" [ngClass]="{'bg-light': row.is_holiday || row.is_weekend}" appDataTableDataCell>
                                {{ row.work.datetime_project_names.join(', ') }}
                            </td>

                            <td [ngClass]="{'bg-light': row.is_holiday || row.is_weekend}" appDataTableDataCell>
                                <div *ngFor="let from of row.absence.from | keyvalue">
                                    <span *ngIf="from.value">{{ from.value }}</span>
                                </div>
                            </td>

                            <td [ngClass]="{'bg-light': row.is_holiday || row.is_weekend}" appDataTableDataCell>
                                <div *ngFor="let to of row.absence.to | keyvalue">
                                    <span *ngIf="to.value">{{ to.value }}</span>
                                </div>
                            </td>

                            <td [ngClass]="{'bg-light': row.is_holiday || row.is_weekend}" appDataTableDataCell>
                                <div *ngFor="let datetimeId of row.absence.datetime_ids | keyvalue">
                                    <a *ngIf="datetimeId.value"
                                        [routerLink]="['/employee', data.employee.employee_ID, 'attendance', 'detail', datetimeId.value]"
                                        class=""
                                        title="{{ 'employees.action_view' | translate }}"
                                    >
                                        <i class="mdi mdi-eye-outline mdi-18px text-muted" style="line-height: 18px"></i>
                                    </a>
                                </div>
                            </td>

                            <td [ngClass]="{'bg-light': row.is_holiday || row.is_weekend}" appDataTableDataCell>
                                {{ row.absence.total }}
                            </td>

                            <td *ngIf="!data.is_uneven" [ngClass]="{'bg-light': row.is_holiday || row.is_weekend}" appDataTableDataCell>
                                {{ row.saldo }}
                            </td>

                            <td *ngIf="data.meal_vouchers" [ngClass]="{'bg-light': row.is_holiday || row.is_weekend}" appDataTableDataCell>
                                {{ 'global.' + (row.meal_vouchers ? 'YES' : 'NO') | translate }}
                            </td>
                        </ng-template>
                    </app-data-table>
                </div> <!-- end table-responsive-->
            </div>
        </div>
    </div>

    <div class="col-xl-3 mb-3">
        <div class="card h-100 shadow">
            <app-help name="attendance.documents.detail.employee.info_summary"></app-help>
            <div class="card-header py-3">
                <div class="row">
                    <div class="col-12">
                        <p class="modal-title font-16 text-dark pt-2 pb-2">
                            <span>{{ 'employees_datetimes.summary' | translate }} </span>
                        </p>
                    </div>
                </div>
            </div>

            <div class="card-body px-4">
                <div class="table-responsive">
                    <table class="table table-centered mb-0">
                        <tbody>
                            <tr> <td><strong>{{ 'employees_datetimes.whole_month' | translate }} </strong></td><td></td></tr>
                            <tr> <td>{{ 'employees_datetimes.expected' | translate }}</td> <td class="text-right">{{ data.summary.whole_month.expected }} </td></tr>
                            <tr> <td>{{ 'employees_datetimes.expected_with_holidays' | translate }}</td> <td class="text-right">{{ data.summary.whole_month.expected_with_holidays }} </td></tr>
                            <tr> <td>{{ 'employees_datetimes.real' | translate }}</td> <td class="text-right">{{ data.summary.whole_month.real }}</td> </tr>
                            <tr> <td>{{ 'employees_datetimes.saldo' | translate }}</td> <td class="text-right">{{ data.summary.whole_month.saldo }}</td> </tr>
                            <tr> <td>{{ 'employees_datetimes.holidays_work' | translate }}</td> <td class="text-right">{{ data.summary.whole_month.holidays_work }}</td> </tr>
                            <tr> <td>{{ 'employees_datetimes.weekends_work' | translate }}</td> <td class="text-right">{{ data.summary.whole_month.weekends_work }}</td> </tr>
                            <tr *ngIf="data.summary.whole_month.meal_vouchers">
                                <td>{{ 'employees_datetimes.meal_vouchers' | translate }}</td>
                                <td class="text-right">{{ data.summary.whole_month.meal_vouchers }}</td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="data.summary.whole_month.worktime_summaries">
                            <tr> <td><strong>{{ 'employees_datetimes.worktime_summaries' | translate }} </strong></td><td></td></tr>
                            <tr *ngFor="let worktime of data.summary.whole_month.worktime_summaries">
                                <td>{{ worktime.key }}</td>
                                <td class="text-right">{{ worktime.value }}</td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="data.summary.whole_month.absence_summaries.length">
                            <tr> <td><strong>{{ 'employees_datetimes.absence_summaries' | translate }} </strong></td><td></td></tr>
                            <tr *ngFor="let absence of data.summary.whole_month.absence_summaries">
                                <td>{{ absence.key }}</td>
                                <td class="text-right">{{ absence.value }}</td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="data.summary.whole_month.neutral_summaries.length">
                            <tr> <td><strong>{{ 'employees_datetimes.neutral_summaries' | translate }} </strong></td><td></td></tr>
                            <tr *ngFor="let absence of data.summary.whole_month.neutral_summaries">
                                <td>{{ absence.key }}</td>
                                <td class="text-right">{{ absence.value }}</td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="data.employees_datetimes_projects">
                            <tr> <td><strong>{{ 'employees_datetimes.projects.label' | translate }} </strong></td><td></td></tr>
                            <tr *ngFor="let project of data.summary.project_summaries">
                                <td>{{ project.name }}</td>
                                <td class="text-right">{{ project.total }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div> <!-- end table-responsive-->
            </div>
        </div>
    </div>
</div>


<ng-template #loadingTemplate>
    <div class="row">
        <div class="col-12 col-lg-8">
            <div class="card shadow p-3">
                <div class="item">
                    <ngx-skeleton-loader [theme]="{
                        'margin': '13px 0'
                      }" appearance="line" count="10"></ngx-skeleton-loader>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-4">
            <div class="card shadow p-3">
                <div class="item">
                    <ngx-skeleton-loader [theme]="{
                        'margin': '13px 0'
                      }" appearance="line" count="10"></ngx-skeleton-loader>
                </div>
            </div>
        </div>
    </div>
</ng-template>
