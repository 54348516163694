<div *ngIf="checkPermission('employeeSalary') && (availableWidgets$ | async)?.employee?.['salaries-payrolls']?.salary" class="row">
  <div class="col-lg-12">
    <app-employee-salaries
      (refetchSalaries)="refreshEmployeeSalaries()"
      *ngIf="employeeSalaries$"
      [employeeID]="employeeID"
      [employee]="employee$ | async"
      [salaries$]="employeeSalaries$"
    ></app-employee-salaries>
  </div>
</div>

<div *ngIf="checkPermission('employeeBonus') && (availableWidgets$ | async)?.employee?.['salaries-payrolls']?.bonus" class="row">
  <div class="col-12">
    <div class="card shadow">
      <app-help name="employee.detail.bonus"></app-help>

      <div class="card-header py-3">
        <div class="row">
          <div class="col-sm-6 text-left my-auto">
            <p class="modal-title font-16 pt-2 pb-2 text-dark">
              <span>{{ 'employees_bonuses.page_title_index' | translate }}</span>
            </p>
          </div>

          <div
            *ngIf="canEditBonus$ | async"
            class="col-sm-6 text-right"
          >
            <button
              (click)="openBonusEditModal(null)"
              [createButton]="'employees_bonuses.action_add' | translate"
            ></button>
          </div>
        </div>
      </div>

      <div class="card-body px-4">
        <div class="row">
          <div class="col-12">
            <div class="table-responsive">
              <app-data-table
                [columnsToFilterIn]="['amount', 'date', 'name']"
                [rows]="bonuses$ | async"
                [trackByFn]="trackByBonus"
              >
                <ng-template #headerRow>
                  <th
                    [disableSort]="true"
                    appDataTableHeaderCell
                  >{{ 'employees_bonuses.name' | translate }}</th>

                  <th
                    [disableSort]="true"
                    appDataTableHeaderCell
                  >{{ 'employees_bonuses.amount' | translate }}</th>

                  <th
                    [disableSort]="true"
                    appDataTableHeaderCell
                  >{{ 'employees_bonuses.date' | translate }}</th>

                  <th
                    [disableSort]="true"
                    appDataTableHeaderCell
                    class="text-right"
                  >{{ 'employees_bonuses.table_action' | translate }}</th>
                </ng-template>

                <ng-template
                  #dataRow
                  let-row
                >
                  <td appDataTableDataCell>{{ row.name }}</td>

                  <td appDataTableDataCell>
                    <span [ngClass]="{'hidden-sensitive-value': hiddenInputs}">{{ row.amount | localizedCurrency }}</span>
                  </td>

                  <td appDataTableDataCell>
                    <span>{{ row.date | date: 'dd.MM.yyyy' }}</span>
                  </td>

                  <td
                    appDataTableDataCell
                    class="text-right"
                  >
                    <a
                      (click)="openBonusEditModal(row)"
                      class="btn btn-white btn-icon btn-sm mr-1"
                      title="{{ 'employees.action_view' | translate }}"
                    >
                      <i class="mdi mdi-eye-outline mdi-24px text-muted"></i>
                    </a>
                  </td>
                </ng-template>
              </app-data-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="checkRealWagePermission() && (availableFeatures$ | async)?.real_wages && (availableWidgets$ | async)?.employee?.['salaries-payrolls']?.real_wages" class="row">
    <div class="col-12">
        <div class="card shadow">
            <app-help name="employee.detail.real_wage"></app-help>

            <div class="card-header py-3">
                <div class="row">
                    <div class="col-sm-6 text-left my-auto">
                        <p class="modal-title font-16 pt-2 pb-2 text-dark">
                            <span>{{ 'employees_real_wages.page_title_index' | translate }}</span>
                        </p>
                    </div>

                    <div
                        *ngIf="canEditRealWage$ | async"
                        class="col-sm-6 text-right"
                    >
                        <button
                            (click)="openRealWageModal(null)"
                            [createButton]="'employees_real_wages.action_add' | translate"
                        ></button>
                    </div>
                </div>
            </div>

            <div class="card-body px-4">
                <div class="row">
                    <div class="col-12">
                        <div class="table-responsive">
                            <app-data-table
                                [rows]="employeeRealWages$ | async"
                                [trackByFn]="trackByBonus"
                            >
                                <ng-template #headerRow>
                                    <th
                                        [disableSort]="true"
                                        appDataTableHeaderCell
                                    >{{ 'employees_real_wages.fix' | translate }}</th>

                                    <th
                                        [disableSort]="true"
                                        appDataTableHeaderCell
                                    >{{ 'employees_real_wages.variable' | translate }}</th>

                                    <th
                                        [disableSort]="true"
                                        appDataTableHeaderCell
                                    >{{ 'employees_real_wages.levy' | translate }}</th>

                                    <th
                                        [disableSort]="true"
                                        appDataTableHeaderCell
                                    >{{ 'employees_real_wages.other' | translate }}</th>

                                    <th
                                        [disableSort]="true"
                                        appDataTableHeaderCell
                                    >{{ 'employees_real_wages.date' | translate }}</th>

                                    <th
                                        [disableSort]="true"
                                        appDataTableHeaderCell
                                        class="text-right"
                                    >{{ 'employees_real_wages.table_action' | translate }}</th>
                                </ng-template>

                                <ng-template
                                    #dataRow
                                    let-row
                                >
                                    <td appDataTableDataCell>
                                        <span [ngClass]="{'hidden-sensitive-value': hiddenInputs}">{{ row.fix | localizedCurrency }}</span>
                                    </td>

                                    <td appDataTableDataCell>
                                        <span [ngClass]="{'hidden-sensitive-value': hiddenInputs}">{{ row.variable | localizedCurrency }}</span>
                                    </td>

                                    <td appDataTableDataCell>
                                        <span [ngClass]="{'hidden-sensitive-value': hiddenInputs}">{{ row.levy | localizedCurrency }}</span>
                                    </td>

                                    <td appDataTableDataCell>
                                        <span [ngClass]="{'hidden-sensitive-value': hiddenInputs}">{{ row.other | localizedCurrency }}</span>
                                    </td>

                                    <td appDataTableDataCell>
                                        <span>{{ row.date | date: 'dd.MM.yyyy' }}</span>
                                    </td>

                                    <td
                                        appDataTableDataCell
                                        class="text-right"
                                    >
                                        <a
                                            (click)="openRealWageModal(row)"
                                            class="btn btn-white btn-icon btn-sm mr-1"
                                            title="{{ 'employees.action_view' | translate }}"
                                        >
                                            <i class="mdi mdi-eye-outline mdi-24px text-muted"></i>
                                        </a>
                                    </td>
                                </ng-template>
                            </app-data-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
