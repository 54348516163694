import { DatePipe, registerLocaleData } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import localeCs from '@angular/common/locales/cs';
import localeEn from '@angular/common/locales/en';
import { APP_INITIALIZER, ErrorHandler, isDevMode, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MsalModule } from '@azure/msal-angular';
import { PublicClientApplication } from '@azure/msal-browser';
import { akitaConfig } from '@datorama/akita';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { createErrorHandler, TraceService } from '@sentry/angular-ivy';
import { initializeApp } from 'firebase/app';
import { NgxEchartsModule } from 'ngx-echarts';
import { NgxPendoModule } from 'ngx-pendo';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BodyComponent } from './body.component';
import { EmployeeAttendancePlanChartComponent } from './components/sections/employee-attendance-plan-chart/employee-attendance-plan-chart.component';
import { CoreModule } from './core/core.module';
import { CreateDocumentByTemplateModalComponent } from './employee/components/partials/create-document-by-template-modal/create-document-by-template-modal.component';
import { GlobalErrorHandler } from './global-error-handler';
import { LastActiveService } from './shared/services/last-active.service';
import { PwaService } from './shared/services/pwa.service';
import { SharedModule } from './shared/shared.module';
import { HttpLoaderFactory } from './translation-loader';

akitaConfig({resettable: true});

registerLocaleData(localeCs, 'cs');
registerLocaleData(localeEn, 'en');

initializeApp(environment.firebase);

@NgModule({
    declarations: [
        CreateDocumentByTemplateModalComponent,
        AppComponent,
        BodyComponent,
        EmployeeAttendancePlanChartComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        CoreModule,
        SharedModule,
        NgxSkeletonLoaderModule,
        MsalModule.forRoot(new PublicClientApplication({
            auth: {
                clientId: 'd18e24ab-8ec6-4f0b-84d3-c5ebb9c46efd',
                authority: 'https://login.microsoftonline.com/common',
                redirectUri: environment.microsoftRedirectUrl || ''
            },
            cache: {
                cacheLocation: 'localStorage'
            }
        }), null, null),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        environment.production ? [] : AkitaNgDevtools.forRoot(),
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: !isDevMode(),
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        }),
        NgbModule,
        NgxPendoModule.forRoot({
            pendoApiKey: '8bdc8a74-02a3-49fe-57ac-30b787236408',
            pendoIdFormatter: (pendoID: string) => pendoID.toLowerCase()
        }),
        NgxEchartsModule.forRoot({
            /**
             * This will import all modules from echarts.
             * If you only need custom modules,
             * please refer to [Custom Build] section.
             */
            echarts: () => import('echarts'), // or import('./path-to-my-custom-echarts')
        }),
        NgxUiLoaderModule,
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            multi: true,
            deps: [LastActiveService, PwaService],
            useFactory: (lastActiveService: LastActiveService, pwaService: PwaService) => () => {
                lastActiveService.setUp();
                pwaService.start();
            }
        },
        {
            provide: ErrorHandler,
            useValue: createErrorHandler({
                showDialog: false, // show modal when error appears
            }),
        },
        {
            provide: TraceService,
            deps: [Router],
        },
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler
        },
        DatePipe,
    ],
    bootstrap: [BodyComponent]
})
export class AppModule {
    constructor(trace: TraceService) {
    }
}
