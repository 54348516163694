<ng-template
    #filterFormOptions
    let-modal
>
    <div class="modal-content">
        <div class="modal-header">
            <p class="modal-title font-16 mb-0 text-dark">
                <span>{{ 'employees.adjust_filters' | translate }}</span>
            </p>

            <button
                (click)="modal.dismiss()"
                aria-label="Close"
                class="close"
            >
        <span
            aria-hidden="true"
            class="font-24 text-muted"
        >
          <i class="mdi mdi-close"></i>
        </span>
            </button>
        </div>

        <div class="modal-body">
            <form
                (ngSubmit)="onSubmittedFilterForm()"
                [formGroup]="filterForm"
                class="filter-form"
            >
                <div class="row">
                    <div class="col-6">
                        <label class="control-label">{{ 'document_templates.active' | translate }}</label>
                    </div>
                    <div class="col-6 text-right align-self-center">
                        <div>
                            <input data-switch="success" formControlName="active" id="active" name="active" type="checkbox">
                            <label for="active">&nbsp;</label>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                        <label class="control-label">{{ 'document_templates.signable' | translate }}</label>
                            <ng-select
                                class="custom"
                                [multiple]="false"
                                formControlName="signable"
                                id="signable"
                                name="signable"
                                type="text"
                                [clearable]="true"
                                [closeOnSelect]="true"
                                [items]="selectOptions"
                                bindValue="value"
                                bindLabel="label"
                            >
                            </ng-select>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label class="control-label">{{ 'document_templates.bulk_generate' | translate }}</label>
                            <ng-select
                                class="custom"
                                [multiple]="false"
                                formControlName="bulk_generate"
                                id="bulk_generate"
                                name="bulk_generate"
                                type="text"
                                [clearable]="true"
                                [closeOnSelect]="true"
                                [items]="selectOptions"
                                bindValue="value"
                                bindLabel="label"
                            >
                            </ng-select>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 text-right">
                        <button class="btn btn-primary">
                            <span>{{ 'employees.action_filter' | translate }}</span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>

<section id="templates">
    <div *ngIf="(availableFeatures$ | async)?.document_generations" class="card shadow">
        <app-help name="settings.documents.templates.list"></app-help>

        <div class="card-header py-3">
            <div class="row">
                <div class="col-6 text-left my-auto">
                    <p class="modal-title font-16 text-dark pt-2 pb-2">
                        <span>{{ 'settings.company.documents_templates.header' | translate }}</span>
                    </p>
                </div>
                <div *ngIf="canCreateTemplate$ | async" class="col-6 text-right">
                    <button (click)="openFilterFormOptions()"
                            class="btn btn-primary btn-icon ml-2"
                            type="button"
                            [formGroup]="filterForm"
                    >
                        <i class="mdi mdi-filter-outline"></i>
                    </button>
                    <button
                        (click)="openCreateModal()"
                        [createButton]="'settings.company.documents_templates.create_action' | translate"
                        class="btn btn-primary btn-icon ml-2"
                    >
                    </button>
                </div>
            </div>
        </div>

        <div class="card-body">
            <div class="row">
                <div class="col-12">
                    <div class="table-responsive">
                        <app-data-table
                            [colsConfig]="{width: ['25%', '35%', '15%', '15%', '10%']}"
                            [rows]="rows"
                            [loading]="loading"
                            [search]="search"
                            [sort]="sort"
                            (searchChanged)="searchChanged($event)"
                            (sortChanged)="sortChanged($event)"
                        >
                            <ng-template #headerRow>
                                <th appDataTableHeaderCell="name">{{ 'settings.company.documents_templates.name' | translate }}</th>

                                <th appDataTableHeaderCell="description">{{ 'settings.company.documents_templates.description' | translate }}</th>

                                <th appDataTableHeaderCell="documents_to_be_signed">{{ 'settings.company.documents_templates.documents_to_be_signed' | translate }}</th>

                                <th appDataTableHeaderCell="active">{{ 'settings.company.documents_templates.active' | translate }}</th>

                                <th
                                    appDataTableHeaderCell
                                    class="text-right"
                                    [disableSort]="true"
                                >{{ 'forms.table_action' | translate }}</th>
                            </ng-template>

                            <ng-template
                                #dataRow
                                let-isFirst="isFirst"
                                let-isLast="isLast"
                                let-row
                            >
                                <td [innerHtml]="row.name" appDataTableDataCell> </td>

                                <td [innerHtml]="row.description" appDataTableDataCell> </td>

                                <td appDataTableDataCell
                                >
                                    {{ row.signable ? ('settings.company.documents_templates.documents_to_be_signed' | translate) + ': ' + row.to_be_signed_count : '' }}
                                </td>

                                <td appDataTableDataCell>
                                    <span *ngIf="row.active"> {{ 'global.YES' | translate }}</span>
                                    <span *ngIf="!row.active"> {{ 'global.NO' | translate }}</span>
                                </td>

                                <td
                                    appDataTableDataCell
                                    class="text-right"
                                >
                                    <a
                                        [routerLink]="['/setting', 'document', row.key, 'detail']"
                                        class="btn btn-white btn-icon btn-sm mr-1"
                                        title="{{ 'employees.action_view' | translate }}"
                                    >
                                        <i class="mdi mdi-eye-outline mdi-24px text-muted"></i>
                                    </a>
                                </td>
                            </ng-template>
                        </app-data-table>
                    </div>
                </div>
            </div>

            <div class="row mt-3 flex-column-reverse flex-sm-row">
                <div class="col-12 col-sm-2">
                    <app-items-per-page-select
                        (itemsPerPageChanged)="itemsPerPageChanged($event)"
                        [itemsPerPage]="itemsPerPage"
                        [total]="itemsTotal"
                    ></app-items-per-page-select>
                </div>

                <div class="col-12 col-sm-10">
                    <app-pagination
                        (pageChanged)="pageChanged($event)"
                        [itemsPerPage]="itemsPerPage"
                        [page]="page"
                        [total]="itemsTotal"
                    ></app-pagination>
                </div>
            </div>
        </div>
    </div>
</section>
