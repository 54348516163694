<div class="modal-content">
  <div class="modal-header">
    <p class="modal-title font-16 mb-0 text-dark">
      <span *ngIf="!realWage">{{ 'employees_real_wages.page_title_create' | translate }}</span>
      <span *ngIf="realWage">{{ 'employees_real_wages.page_title_edit' | translate }}</span>
    </p>

    <button
      aria-label="Close"
      class="close"
      (click)="activeModal.dismiss()"
    >
      <span
        aria-hidden="true"
        class="font-24 text-muted"
      >
        <i class="mdi mdi-close"></i>
      </span>
    </button>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <div class="card mb-0 shadow-none">
          <div class="card-body p-0">
            <form
              autocomplete="off"
              [formGroup]="realWageEditForm"
              (ngSubmit)="onSubmit()"
            >
                <div class="row">
                    <div class="col-lg-12">
                        <div class="form-group">
                            <label class="control-label">
                                <span>{{ 'employees_real_wages.fix' | translate }}</span>
                            </label>

                            <div [ngClass]="{'hidden-sensitive-value-input': hiddenInputs}">
                                <input currencyMask class="form-control" formControlName="fix" name="fix" type="text">
                            </div>

                            <div *ngIf="submitted && f.fix?.errors">
                                <p
                                    *ngIf="f.fix.errors.required"
                                    class="text-danger validation-text"
                                >
                                    <span>{{ 'angular_validation.field' | translate }} </span>
                                    <span>{{ 'employees_real_wages.fix' | translate }} </span>
                                    <span>{{ 'angular_validation.required' | translate }}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-12">
                        <div class="form-group">
                            <label class="control-label">
                                <span>{{ 'employees_real_wages.variable' | translate }}</span>
                            </label>

                            <div [ngClass]="{'hidden-sensitive-value-input': hiddenInputs}">
                                <input currencyMask class="form-control" formControlName="variable" name="variable" type="text">
                            </div>

                            <div *ngIf="submitted && f.variable?.errors">
                                <p
                                    *ngIf="f.variable.errors.required"
                                    class="text-danger validation-text"
                                >
                                    <span>{{ 'angular_validation.field' | translate }} </span>
                                    <span>{{ 'employees_real_wages.variable' | translate }} </span>
                                    <span>{{ 'angular_validation.required' | translate }}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-12">
                        <div class="form-group">
                            <label class="control-label">
                                <span>{{ 'employees_real_wages.levy' | translate }}</span>
                            </label>

                            <div [ngClass]="{'hidden-sensitive-value-input': hiddenInputs}">
                                <input currencyMask class="form-control" formControlName="levy" name="levy" type="text">
                            </div>

                            <div *ngIf="submitted && f.levy?.errors">
                                <p
                                    *ngIf="f.levy.errors.required"
                                    class="text-danger validation-text"
                                >
                                    <span>{{ 'angular_validation.field' | translate }} </span>
                                    <span>{{ 'employees_real_wages.levy' | translate }} </span>
                                    <span>{{ 'angular_validation.required' | translate }}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-12">
                        <div class="form-group">
                            <label class="control-label">
                                <span>{{ 'employees_real_wages.other' | translate }}</span>
                            </label>

                            <div [ngClass]="{'hidden-sensitive-value-input': hiddenInputs}">
                                <input currencyMask class="form-control" formControlName="other" name="other" type="text">
                            </div>

                            <div *ngIf="submitted && f.other?.errors">
                                <p
                                    *ngIf="f.other.errors.required"
                                    class="text-danger validation-text"
                                >
                                    <span>{{ 'angular_validation.field' | translate }} </span>
                                    <span>{{ 'employees_real_wages.other' | translate }} </span>
                                    <span>{{ 'angular_validation.required' | translate }}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label class="control-label">
                      <span>{{ 'employees_real_wages.date' | translate }}</span>
                    </label>

                    <input
                      mwlFlatpickr [disableMobile]="true"
                      allowInput="false"
                      altFormat="d.m.Y"
                      altInput="true"
                      class="form-control"
                      dateFormat="Y-m-d"
                      formControlName="date"
                      name="date"
                      type="text"
                      [locale]="locale$ | async"
                    >

                    <div *ngIf="submitted && f.date?.errors">
                      <p
                        *ngIf="f.date.errors.required"
                        class="text-danger validation-text"
                      >
                        <span>{{ 'angular_validation.field' | translate }} </span>
                        <span>{{ 'employees_real_wages.date' | translate }} </span>
                        <span>{{ 'angular_validation.required' | translate }}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label class="control-label">
                      <span>{{ 'employees_real_wages.note' | translate }}</span>
                    </label>

                    <input
                      class="form-control"
                      formControlName="note"
                      name="note"
                      type="text"
                    >
                  </div>
                </div>
              </div>

              <div
                *ngIf="canEditOrDelete$ | async"
                class="row mt-2"
              >
                <div class="col-6 text-right my-auto">
                  <u
                    *ngIf="realWage?.employee_real_wage_ID && !hiddenInputs"
                    class="text-muted"
                    (click)="deleteRealWage()"
                  >{{ 'global.action_delete' | translate }}</u>
                </div>
                <div class="col-6 text-right">
                  <button class="btn btn-success" [disabled]="hiddenInputs">
                    <span>{{ 'global.action_save' | translate }}</span>
                  </button>
                </div>

              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
