import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class RedirectService {
    public constructor() { }

    public checkForRedirectAction(): void {
        const relativeUrl = window.location.pathname + window.location.search;

        if (relativeUrl.includes('/qrcode?action=propertyDetail&property_id=')) {
            const propertyId = new URLSearchParams(relativeUrl).get('property_id');
            window.location.href = `/property/detail/${propertyId}`;
        }
    }
}
