<ng-template
  #optionsDialogTemplate
  let-modal
>
  <div class="modal-content">
    <div class="modal-header">
      <p class="modal-title font-16 mb-0 text-dark">
        <span>{{ 'data_tables.options' | translate }}</span>
      </p>

      <button
        aria-label="Close"
        class="close"
        type="button"
        (click)="modal.dismiss()"
      >
        <span
          aria-hidden="true"
          class="font-24 text-muted"
        >
          <i class="mdi mdi-close"></i>
        </span>
      </button>
    </div>

    <div class="modal-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card mb-0 shadow-none">
            <div class="card-body p-0">
              <form
                [formGroup]="optionsForm"
                (ngSubmit)="submitOptions()"
              >
                <div class="row">
                  <div class="col-lg-12">
                    <div class="form-group">
                      <label class="control-label">
                        <span>{{ 'data_tables.group_by' | translate }}</span>
                      </label>

                      <ng-select
                        bindLabel="name"
                        class="custom"
                        formControlName="groupBy"
                        [compareWith]="compareColumns"
                        [items]="columnsForSelect"
                        [placeholder]="'data_tables.group_by_select' | translate"
                      ></ng-select>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-12">
                    <div class="form-group">
                      <label class="control-label">
                        <span>{{ 'data_tables.summary' | translate }}</span>
                      </label>

                      <ng-select
                        bindLabel="name"
                        class="custom"
                        formControlName="summaries"
                        [closeOnSelect]="false"
                        [compareWith]="compareColumns"
                        [items]="columnsForSelect"
                        [placeholder]="'data_tables.summary_select' | translate"
                        [multiple]="true"
                      ></ng-select>
                    </div>
                  </div>
                </div>

                <div
                  class="row"
                  *ngIf="showNonResponsiveButton$ | async"
                >
                  <div class="col-lg-12">
                    <div class="form-group">
                      <label class="control-label">
                        <span>{{ 'data_tables.view_compact' | translate }}</span>
                      </label>

                      <div>
                        <input
                          data-switch="success"
                          formControlName="viewCompact"
                          id="viewCompact"
                          type="checkbox"
                        >

                        <label for="viewCompact">&nbsp;</label>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row mt-2">
                  <div class="col d-flex justify-content-end">
                    <button class="btn btn-success">
                      <span>{{ 'data_tables.action_options_save' | translate }}</span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #loadingTemplate>
  <tr>
    <td
      *ngIf="columnCount$ | async; let columnCount"
      class="border-bottom-0"
      [attr.colspan]="columnCount"
    >
      <content-loader>
        <svg:rect
          x="0"
          y="0"
          rx="3"
          ry="3"
          width="100%"
          height="10"
        />
        <svg:rect
          x="0"
          y="20"
          rx="3"
          ry="3"
          width="100%"
          height="10"
        />
        <svg:rect
          x="0"
          y="40"
          rx="3"
          ry="3"
          width="100%"
          height="10"
        />
        <svg:rect
          x="0"
          y="60"
          rx="3"
          ry="3"
          width="100%"
          height="10"
        />
        <svg:rect
          x="0"
          y="80"
          rx="3"
          ry="3"
          width="100%"
          height="10"
        />
        <svg:rect
          x="0"
          y="100"
          rx="3"
          ry="3"
          width="100%"
          height="10"
        />
      </content-loader>
    </td>
  </tr>
</ng-template>

<ng-template #tableHeaderTemplate>
  <thead
    *ngIf="showHeader"
    [class.d-none]="cssHideHeader"
  >
    <tr #tableHeaderRow>
      <ng-template [ngTemplateOutlet]="headerRowTemplate"></ng-template>
    </tr>
  </thead>
</ng-template>

<ng-template #tableFooterTemplate>
  <tfoot>
    <tr #tableFooterRow>
      <ng-template [ngTemplateOutlet]="footerRowTemplate"></ng-template>
    </tr>
  </tfoot>
</ng-template>

<ng-template
  #pipeTemplate
  let-type="type"
  let-value="value"
>
  <ng-container
    *ngIf="value !== ''"
    [ngSwitch]="type"
  >
    <ng-container *ngSwitchCase="'localizedCurrency'">
      <span [ngClass]="{'hidden-sensitive-value': hiddenInputs$ | async}"> {{ value | localizedCurrency }}</span>
      <!-- <span>{{ value }}</span> -->
    </ng-container>

    <ng-container *ngSwitchCase="'date'">
      <span>{{ value | date: 'dd.MM.yyyy' }}</span>
    </ng-container>

    <ng-container *ngSwitchCase="'datetime'">
      <span>{{ value | date: 'dd.MM.yyyy h:mm' }}</span>
    </ng-container>

    <ng-container *ngSwitchCase="'percent'">
      <span>{{ value | percent: '1.0-0' : 'cs' }}</span>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <span>{{ value }}</span>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template
  #tableEachRowTemplate
  let-row
  let-count="count"
  let-even="even"
  let-first="first"
  let-index="index"
  let-last="last"
  let-odd="odd"

><!-- Group by rows -->
  <ng-container *ngIf="groupBy && metadata">
    <tr
      class="group-row"
      *ngIf="metadata[getObjectValueByAccessor(row, groupBy.key)].index === index"

    >
      <td (click)="setCollapse(metadata[getObjectValueByAccessor(row, groupBy.key)])" [attr.colspan]="columnCount$ | async">
        <i
        *ngIf="!isCollapsed[index]"
        class="mdi mdi-menu-down mdi-24px d-inline-block mr-2 menu-arrow-collapse"
        ></i>
        <i
        *ngIf="isCollapsed[index]"
        class="mdi mdi-menu-up mdi-24px d-inline-block mr-2 menu-arrow-collapse"
        ></i>
        <ng-container
          [ngTemplateOutlet]="pipeTemplate"
          [ngTemplateOutletContext]="{
            type: groupBy.type,
            value: getObjectValueByAccessor(row, groupBy.key)
          }"
        >

        </ng-container>

      </td>
    </tr>
  </ng-container>

  <!-- data rows -->
    <tr #tableDataRow
    [ngClass]="(isCollapsed[index] && groupBy) ? 'data-row-collapsed' : 'data-row'">
      <ng-template
        [ngTemplateOutlet]="dataRowTemplate"
        [ngTemplateOutletContext]="{
          $implicit: row,
          count: count,
          index: index,
          isEven: even,
          isFirst: first,
          isOdd: odd,
          isLast: last
        }"
      ></ng-template>
    </tr>

  <ng-container *ngIf="groupBy && metadata && summaries?.length">
    <tr
      class="summary-row"
      *ngIf="metadata[getObjectValueByAccessor(row, groupBy.key)].lastIndex === index"
    >
      <ng-container *ngFor="let col of columns; let n = index; trackBy:trackByColumnKey">
        <td>
          <ng-container *ngIf="col">
            <ng-container
              [ngTemplateOutlet]="pipeTemplate"
              [ngTemplateOutletContext]="{
                type: metadata[getObjectValueByAccessor(row, groupBy.key)].sums[col.key]?.type,
                value: metadata[getObjectValueByAccessor(row, groupBy.key)].sums[col.key]?.value
              }"
            ></ng-container>
          </ng-container>
        </td>
      </ng-container>
    </tr>
  </ng-container>
</ng-template>

<form
  class="d-flex"
  [formGroup]="searchForm"
  *ngIf="allowGrouping || showSearch"
>
  <div class="flex-1">
    <label
      *ngIf="showSearch"
      class="w-100"
    >
      <input
        class="form-control form-control-sm"
        formControlName="search"
        placeholder="{{ 'data_tables.search' | translate }}"
        type="search"
        (keydown.enter)="$event.preventDefault()"
      >
    </label>
  </div>

  <div class="d-inline">
    <div class="btn-group">
      <ng-template [ngTemplateOutlet]="headerButtonTemplate"></ng-template>

      <button
        *ngIf="allowGrouping"
        class="btn btn-icon btn-light ml-1"
        (click)="showOptionsDialog()"
      >
        <i class="mdi mdi-cogs"></i>
      </button>
    </div>
  </div>
</form>

<cdk-virtual-scroll-viewport
  *ngIf="virtualScroll; else tableTemplate"
  class="virtually-scrollable-area"
  itemSize="75"
>
  <table
    class="table table-centered mb-0"
    [class.table-hover]="!noData"
    [ngClass]="compactModeClass"
  >
    <ng-template [ngTemplateOutlet]="tableHeaderTemplate"></ng-template>




  <!--  <ng-container *ngIf="colsConfig && colsConfig.width.length">
        <colgroup>
            <col *ngFor="let colWidth of colsConfig.width" [style]="'width:' + colWidth">
        </colgroup>
    </ng-container>-->
    <tbody>
      <ng-container *ngIf="!loading; else loadingTemplate">
        <ng-template
          *cdkVirtualFor="
            let row of rows$ | async;
            count as count;
            even as even;
            first as first;
            index as index;
            last as last;
            odd as odd;
            trackBy: trackByFn;
          "
          [ngTemplateOutlet]="tableEachRowTemplate"
          [ngTemplateOutletContext]="{
            $implicit: row,
            count: count,
            index: index,
            isEven: even,
            isFirst: first,
            isOdd: odd,
            isLast: last
          }"
        ></ng-template>
      </ng-container>
    </tbody>

    <ng-template [ngTemplateOutlet]="tableFooterTemplate"></ng-template>
  </table>
</cdk-virtual-scroll-viewport>

<ng-template #tableTemplate>
  <div class="table-wrap">
    <table
      class="table table-centered mb-0"
      [class.table-hover]="!noData"
      [ngClass]="compactModeClass"
    >
      <ng-template [ngTemplateOutlet]="tableHeaderTemplate"></ng-template>
      <!--<ng-container *ngIf="colsConfig && colsConfig.width.length">
        <col *ngFor="let colWidth of colsConfig.width" [style]="'width:' + colWidth">
      </ng-container>-->

      <tbody>
        <ng-container *ngIf="!loading; else loadingTemplate">
          <ng-template
            *ngFor="
              let row of rows$ | async;
              count as count;
              even as even;
              first as first;
              index as index;
              last as last;
              odd as odd;
              trackBy: trackByFn;
            "
            [ngTemplateOutlet]="tableEachRowTemplate"
            [ngTemplateOutletContext]="{
              $implicit: row,
              count: count,
              index: index,
              isEven: even,
              isFirst: first,
              isOdd: odd,
              isLast: last
            }"
          ></ng-template>
        </ng-container>
      </tbody>

      <ng-template [ngTemplateOutlet]="tableFooterTemplate"></ng-template>
    </table>
  </div>
</ng-template>

<div
  *ngIf="noData && !loading"
  class="mt-3 text-center text-muted"
>
  <ng-container *ngIf="!search; else searchConductedTemplate">
    <span *ngIf="customNoDataMessage; else genericMessageTemplate">{{ customNoDataMessage }}</span>

    <ng-template #genericMessageTemplate>
      <span>{{ 'global.empty_widget_error' | translate }}</span>
    </ng-template>
  </ng-container>

  <ng-template #searchConductedTemplate>
    <span>{{ 'data_tables.no_search_results' | translate }}</span>
  </ng-template>
</div>
