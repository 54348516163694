<ng-container *ngIf="activeGrowths; else loadingtemplate">
    <ng-template
        #content
        let-modal
    >
      <div class="modal-content">
        <div class="modal-header">
          <p class="modal-title font-16 mb-0 text-dark">
            <span>{{ 'growths.assign_employee' | translate }}</span>
          </p>

          <button
              (click)="modal.dismiss()"
              aria-label="Close"
              class="close"
              type="button"
          >
          <span
              aria-hidden="true"
              class="font-24 text-muted"
          >
            <i class="mdi mdi-close"></i>
          </span>
          </button>
        </div>

        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div class="card mb-0 shadow-none">
                <div class="card-body p-0">
                  <form
                      #f="ngForm"
                      (ngSubmit)="onSubmit(f.form)"
                  >
                    <div class="row">
                      <div class="col-12">
                        <div class="form-group">
                          <label class="control-label">
                            <span>{{ 'growths.growths' | translate }}</span>
                          </label>

                          <ng-select
                              [closeOnSelect]="true"
                              [items]="(data$ | async)?.growths"
                              [multiple]="false"
                              bindLabel="name"
                              class="custom"
                              name="growth"
                              ngModel
                              required
                          ></ng-select>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-12">
                        <div class="form-group">
                          <label class="control-label">
                            <span>{{ 'growths.employees' | translate }}</span>
                          </label>

                          <ng-select
                              [closeOnSelect]="false"
                              [items]="(data$ | async)?.employees"
                              [multiple]="true"
                              bindLabel="fullname"
                              bindValue="employee_ID"
                              class="custom"
                              name="employeesIDs"
                              ngModel
                              required
                          ></ng-select>
                        </div>
                      </div>
                    </div>

                    <div class="row mt-2">
                      <div class="col-12 text-right">
                        <button [disabled]="f.form.invalid" class="btn btn-primary">
                          <span>{{ 'growths.action_save' | translate }}</span>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>

    <div class="row">
      <div class="col-12">
        <div class="card shadow">
          <app-help name="dashboard.active_growths"></app-help>
          <div class="card-body">
            <div *ngIf="(canCreate$ | async)" class="row mb-2">
              <div class="col-12 text-right">
                <button
                    (click)="openAssignModal(content)"
                    [createButton]="'growths.action_create' | translate"
                    class="ml-2"
                ></button>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="table-responsive">
                  <app-data-table
                      [customNoDataMessage]="'growths.empty_growths_widget_error' | translate"
                      [disableInPlaceSorting]="true"
                      [rows]="activeGrowths"
                      [showSearch]="false"
                  >
                    <ng-template #headerRow>
                      <th [disableSort]="true"
                          appDataTableHeaderCell="is_completed"
                      >{{ 'growths.employee' | translate }}</th>

                      <th [disableSort]="true"
                          appDataTableHeaderCell="name"
                      >{{ 'growths.growth' | translate }}</th>

                      <th [disableSort]="true"
                          appDataTableHeaderCell="name"
                      >{{ 'growths.progress' | translate }}</th>

                      <th
                          [disableSort]="true"
                          appDataTableHeaderCell
                          class="text-right"
                      >{{ 'growths.action_view_growth' | translate }}</th>
                    </ng-template>

                    <ng-template
                        #dataRow
                        let-row
                    >
                      <td appDataTableDataCell class="w-sm-15">
                        <app-employee-box-avatar [employee]="row.employee"></app-employee-box-avatar>
                      </td>

                      <td appDataTableDataCell>
                        <div>
                          {{ row.name }}
                        </div>
                      </td>

                      <td appDataTableDataCell>
                        <div class="progress w-100">
                          <div
                              [style.width.%]="row.percent_progress"
                              aria-valuemax="100"
                              aria-valuemin="0"
                              aria-valuenow="progressBarWidth"
                              class="progress-bar"
                              role="progressbar"
                          ></div>
                        </div>
                      </td>

                      <td
                          appDataTableDataCell
                          class="text-right"
                      >
                        <a
                            (click)="openEmployeeGrowthOverviewModal(row.employee_growth_ID, row.employee.employee_ID)"
                            class="btn btn-white btn-icon btn-sm mr-1"
                            title="{{ 'growths.action_view' | translate }}"
                        >
                          <i class="mdi mdi-eye-outline mdi-24px text-muted"></i>
                        </a>
                      </td>
                    </ng-template>
                  </app-data-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </ng-container>
  <ng-template #loadingtemplate>
    <div class="row">
      <div class="col-12">
        <div class="card shadow p-3">
          <div class="item">
            <ngx-skeleton-loader [theme]="{
        'margin': '15px 0'
      }" appearance="line" count="7"></ngx-skeleton-loader>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

