<div class="row">
    <div class="col-12">
        <div class="card mb-0">
            <div class="row mb-0">
                <div class="col-12">
                    <ul class="nav nav-tabs nav-justified nav-bordered">
                        <li class="nav-item">
                            <a
                                [routerLink]="['/employee', 'attendance', 'shifts', 'chart']"
                                aria-expanded="false"
                                class="nav-link"
                                data-toggle="tab"
                                routerLinkActive="active"
                            >
                                <i class="mdi mdi-24px mdi-calendar-month-outline d-block"></i>

                                <span class="d-none d-sm-block">{{ 'employees_datetimes.page_title_index_chart' | translate }}</span>
                            </a>
                        </li>

                        <li class="nav-item">
                            <a
                                [routerLink]="['/employee', 'attendance', 'shifts', 'history']"
                                aria-expanded="false"
                                class="nav-link"
                                data-toggle="tab"
                                routerLinkActive="active"
                            >
                                <i class="mdi mdi-24px mdi-clock-outline d-block"></i>

                                <span class="d-none d-sm-block">{{ 'employees_datetimes.page_title_index_history' | translate }}</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row">
  <div class="col-12 position-static">
    <app-gantt-chart *ngIf="(chartType | async) === 'gannt'"
                     [allowBulkMode]="true"
                     [defaultFromDate]="from"
                     [isDashboard]="false"
                     [showSwitch]="true"
                     [isShift]="true"
                     [isStatic]="true"
                     [defaultInterval]="'MONTH'"
    ></app-gantt-chart>

    <app-plan-chart *ngIf="(chartType | async) === 'plan'"
                    [from]="from"
                    [showSwitch]="true"
                    [isShift]="true"
                    [to]="to">
    </app-plan-chart>
    <app-full-calendar *ngIf="(chartType | async) === 'calendar'"
                       [isShift]="true">
    </app-full-calendar>
  </div>
</div>
