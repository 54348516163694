<ng-container *ngIf="canViewCompanyEmployeePolicies">
  <section id="policy">
    <app-company-policy-list></app-company-policy-list>
  </section>
</ng-container>

<ng-container *ngIf="canViewCompanySettings">
  <section id="datetimeType">
    <app-datetime-types-list></app-datetime-types-list>
  </section>
</ng-container>

<ng-container *ngIf="canViewDatetimeProject">
    <section id="projects">
        <app-datetime-projects-list></app-datetime-projects-list>
    </section>
</ng-container>

<ng-container *ngIf="canViewCompanySettings">
  <section id="settings">
    <app-attendance-settings></app-attendance-settings>
  </section>
</ng-container>

<ng-container *ngIf="canViewCompanySettings">
  <section id="absence">
    <app-absence-settings></app-absence-settings>
  </section>
</ng-container>

<ng-container *ngIf="canViewCompanySettings">
    <section id="indicator">
        <app-attendance-indicator-settings></app-attendance-indicator-settings>
    </section>
</ng-container>

<ng-container *ngIf="canViewCompanySettings && (availableFeatures$ | async)?.shifts">
    <section id="shiftDuration">
        <app-shift-duration-setting></app-shift-duration-setting>
    </section>
</ng-container>

<ng-container *ngIf="canViewCompanySettings">
    <section id="mealVouchers">
        <app-meal-vouchers-setting></app-meal-vouchers-setting>
    </section>
</ng-container>

