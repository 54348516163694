import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NEVER, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { DomainService } from 'src/app/core/services/domain.service';
import { environment } from 'src/environments/environment';

const DO_NOT_PREPEND_TENANT_TO_THESE_ENDPOINTS = [
    '/api/login',
    '/api/register',
    '/api/login/azure',
    '/api/forgotten_password',
    '/api/reset_password',
    '/api/translations',
    '/api/translations/locales',
    '/api/countries',
    '/api/magiclink',
    '/api/kiosk/activate',
    '/api/kiosk/workspace',
    '/api/kiosk/login',
    '/api/kiosk/deactivate',
    '/api/kiosk/datetimes/start',
    '/api/kiosk/datetimes/stop',
    '/api/app/version',
];

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    public constructor(
        private _authService: AuthenticationService,
        private _domainService: DomainService,
        private _loaderService: NgxUiLoaderService,
        private _router: Router,
    ) {
    }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!navigator.onLine) {
            return NEVER;
        }

        let request = req.clone();
        let headers = request.headers.set('Accept', 'application/json');

        if (!req.url.includes('assets/i18n')) {
            if (req.method === 'POST' || req.method === 'GET' || req.method === 'DELETE') {
                headers = headers.set('Speybl-Frontend-Url', window.location.href);
            }
            let doNotPrependTenant = false;
            DO_NOT_PREPEND_TENANT_TO_THESE_ENDPOINTS.forEach((url: string) => {
                if (req.url.indexOf(url) > -1) {
                    doNotPrependTenant = true;
                    return;
                }
            });
            if (doNotPrependTenant) {
                request = req.clone({
                    url: environment.protocol + environment.serverUrl + req.url,
                    withCredentials: true,
                    headers
                });
            } else {
                request = req.clone({
                    url: environment.protocol + this._domainService.getTenantNameWithStringAppend('.') + environment.serverUrl + req.url,
                    withCredentials: true,
                    headers
                });
            }
        }

        return next.handle(request)
            .pipe(
                catchError(error => {
                    this._loaderService.stopLoader('master');

                    if (
                        error &&
                        error.error &&
                        (
                            error.error.message === 'bad uuid' ||
                            error.error.message === 'website not found' ||
                            error.error.message === 'hostname not found' ||
                            (
                                error.error.message === 'Společnost nebyla nalezena' &&
                                !error.url.includes('login')
                            )
                        )
                    ) {
                        this._authService.changeAuthStatus(false);
                    }

                    if (error instanceof HttpErrorResponse) {
                        if (error.status === 401) {
                            if (window.location.href.includes('kiosk')) {
                                this._router.navigate(['/kiosk/login']);
                            } else {
                                this._authService.changeAuthStatus(false);
                                window.location.href = '/auth/login';
                            }
                        }
                    }

                    return throwError(error);
                })
            );
    }
}
