import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { CompanyService } from 'src/app/setting/services/company.service';
import { SummernoteHelper } from '../../../../shared/common/SummernoteHelper';
import { CustomField } from '../../../../shared/models/CustomField';
import { AvailableWidgets } from '../../../models/AvailableWidgets';

@Component({
    selector: 'app-setting-appearance',
    templateUrl: './setting-appearance.component.html',
    styleUrls: ['./setting-appearance.component.css']
})
export class SettingAppearanceComponent implements OnInit, OnDestroy {
    public canViewCompanySettings$: Observable<boolean>;
    public canEditCompanySettings$: Observable<boolean>;
    public availableWidgets$: Observable<AvailableWidgets>;
    public questionnaireForm: UntypedFormGroup;
    public displayModeForm: UntypedFormGroup;
    public settings$: Observable<any>;
    public customFieldDefinitions: CustomField[] = [];

    public displayModeOptions = [
        'WHOLE_COMPANY',
        'SIBLINGS',
        'DESCENDANTS_AND_SIBLINGS',
        'DESCENDANTS',
        'SELF_ONLY',
        'CHILDREN',
    ];

    private _availableWidgetsSubscription: Subscription;

    public constructor(
        public summernoteHelper: SummernoteHelper,
        private _authService: AuthenticationService,
        private _companyService: CompanyService,
        private _fb: UntypedFormBuilder,
    ) { }

    public ngOnInit(): void {
        this.availableWidgets$ = this._companyService.availableWidgets$
            .pipe(tap(availableWidgets => {
                this.displayModeForm.patchValue({
                    'appearance.widgets.employee_table.settings.display_mode': availableWidgets.employee_table.settings.display_mode ?? 'WHOLE_COMPANY',
                });
            }));

        this.canEditCompanySettings$ = this._authService.hasPermissionTo('companySetting.edit')
            .pipe(map(permission => permission.can));

        this.canViewCompanySettings$ = this._authService.hasPermissionTo('companySetting.view')
            .pipe(map(permission => permission.can));

        this.questionnaireForm = this._fb.group({
            'appearance.questionnaire.identify_company_by': ['COMPANY'],
            'appearance.questionnaire.introduction_text': [''],
            'appearance.questionnaire.show_introduction_text': [''],
            'appearance.questionnaire.ending_text': [''],
            'appearance.questionnaire.show_ending_text': ['']
        });

        this.displayModeForm = this._fb.group({
            'appearance.widgets.employee_table.settings.display_mode': ['WHOLE_COMPANY'],
        });

        this.settings$ = this._companyService.getCompanySetting()
            .pipe(
                tap(settings => {
                    this.questionnaireForm.patchValue({
                        'appearance.questionnaire.identify_company_by': settings.appearance?.questionnaire?.identify_company_by ?? 'COMPANY',
                        'appearance.questionnaire.introduction_text': settings.appearance?.questionnaire?.introduction_text,
                        'appearance.questionnaire.show_introduction_text': settings.appearance?.questionnaire?.show_introduction_text === '1',
                        'appearance.questionnaire.ending_text': settings.appearance?.questionnaire?.ending_text,
                        'appearance.questionnaire.show_ending_text': settings.appearance?.questionnaire?.show_ending_text === '1'
                    });
                })
            );
    }

    public ngOnDestroy(): void {
        this._availableWidgetsSubscription?.unsubscribe();
    }

    public setCustomFieldDefinitions(customFieldDefinitions: CustomField[]): void {
        this.customFieldDefinitions = customFieldDefinitions;
    }

    public changeWidgets(type: string, category: string, subKey: string, event: any): void {
        const body = {
            key: `${type}.${category}.${subKey}`,
            value: event.target.checked
        };

        this._companyService.updateAvailableWidgets(body)
            .then(() => {
                this._availableWidgetsSubscription = this._companyService.getAvailableWidgets().subscribe();
            });
    }

    public onSubmitQustionnaire(): void {
        const value = this.questionnaireForm.value;

        value.settings_type = 'questionnaire';

        void this._companyService.saveCompanySetting(this.questionnaireForm.value);
    }

    public onSubmitDisplayMode(): void {
        const body = {
            key: `employee_table.settings.display_mode`,
            value: this.displayModeForm.value['appearance.widgets.employee_table.settings.display_mode']
        };

        this._companyService.updateAvailableWidgets(body)
            .then(() => {
                this._availableWidgetsSubscription = this._companyService.getAvailableWidgets().subscribe();
            });
    }

    public getEntities(availableWidgets: AvailableWidgets): string[] {
        return Object.keys(availableWidgets);
    }
}
