<form
  *ngIf="settings$ | async"
  autocomplete="off"
  [formGroup]="form"
  (ngSubmit)="onSubmit()"
>
  <div class="row">
    <div class="col-12">
      <div class="card shadow">
        <app-help name="settings.attendance.absence"></app-help>

        <div class="card-header py-3">
          <div class="row">
            <div class="col-sm-6 text-left my-auto">
              <p class="modal-title font-16 text-dark pt-2 pb-2">
                <span>{{ 'employees_datetimes.absence_list' | translate }}</span>
              </p>
            </div>
          </div>
        </div>

        <div class="card-body">
          <div class="row">
            <div class="col-12">
                <div class="form-group">
                    <label class="control-label">
                        <span>{{ 'settings.attendance_absence_types' | translate }}</span>
                    </label>
                    <ng-select
                        class="custom"
                        formControlName="company.attendance.absence_types"
                        name="company.attendance.absence_types"
                        [clearable]="false"
                        [items]="datetimeTypes$ | async"
                        bindLabel="name"
                        bindValue="employee_datetime_type_ID"
                        [multiple]="true"
                        [closeOnSelect]="false"
                    >
                    </ng-select>
                </div>
            </div>
              <div class="col-12 text-right">
                  <small class="text-muted"> {{ 'settings.attendance_absence_types_info' | translate }}</small>
              </div>
          </div>

          <div class="row mb-2">
            <div class="col-12">
                <div class="form-group mb-1">
                    <label class="control-label" for="company.attendance.absence_range">
                        <span>{{ 'settings.attendance_absence_range' | translate }}</span>
                    </label>
                    <input class="form-control"
                           formControlName="company.attendance.absence_range"
                           id="company.attendance.absence_range"
                           name="company.attendance.absence_range"
                           onlyNumber
                           type="number">
                </div>
            </div>
          </div>

            <div class="row">
                <div class="col-6">
                    <label class="control-label">{{ 'settings.attendance_absence_show_not_approved' | translate }}</label>
                </div>
                <div class="col-6 text-right align-self-center">
                    <div>
                        <input data-switch="success" formControlName="company.attendance.absence_show_not_approved" id="company.attendance.absence_show_not_approved" name="company.attendance.absence_show_not_approved" type="checkbox">
                        <label for="company.attendance.absence_show_not_approved">&nbsp;</label>
                    </div>
                </div>
            </div>

          <div class="row mt-2">
            <div class="col d-flex justify-content-end">
              <button class="btn btn-success">
                <span>{{ 'global.action_save' | translate }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
