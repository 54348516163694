<!--<editor-fold desc="MODALY">-->
<ng-template #filterFormOptions let-modal>
    <div class="modal-content">
        <div class="modal-header">
            <p class="modal-title font-16 mb-0 text-dark">
                <span>{{ 'employees_datetimes.adjust_filters' | translate }}</span>
            </p>
            <button (click)="modal.dismiss()" aria-label="Close" class="close">
        <span aria-hidden="true" class="font-24 text-muted">
          <i class="mdi mdi-close"></i>
        </span>
            </button>
        </div>

        <div class="modal-body">
            <form (ngSubmit)="onSubmit()" [formGroup]="filterForm" class="filter-form">
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label class="form-group">{{ 'employees_datetimes.filter_type' | translate }}</label>
                            <div>
                                <ng-select
                                    [closeOnSelect]="false"
                                    [items]="datetimeTypes$ | async"
                                    [multiple]="true"
                                    bindLabel="name"
                                    bindValue="employee_datetime_type_ID"
                                    class="custom"
                                    formControlName="datetime_type_IDs"
                                >
                                </ng-select>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label>{{ 'employees_datetimes.filter_employee' | translate }}</label>

                            <ng-select
                                [bindValue]="'employee_ID'"
                                [bindLabel]="'fullname'"
                                [clearable]="false"
                                [closeOnSelect]="false"
                                [items]="employees$ | async"
                                [multiple]="true"
                                class="custom"
                                formControlName="employee_IDs"
                            >
                            </ng-select>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 text-right">
                        <button class="btn btn-primary">
                            <span>{{ 'analysis.action_filter' | translate }}</span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>
<!--</editor-fold desc="MODALY">-->

<div [ngClass]="{'position-static': isStatic}" class="card shadow h-100">
    <app-help name="employee.detail.attendance.calendar"></app-help>
    <div class="card-header py-3">
        <div class="row">
            <div class="col text-left my-auto">
                <p class="modal-title font-16 text-dark pt-2 pb-2">
                    {{ 'employees_datetimes.calendar' | translate }}
                </p>
            </div>
            <div class="col-auto text-right">

                <ng-container>
                    <div class="btn-group btn-group-toggle mr-2" data-toggle="buttons">
                        <label class="btn btn-primary" style="min-width: 100px !important;" [class.active]="false">
                            <input type="radio" (change)="switchType('gannt')">{{ 'employees_datetimes.gantt' | translate }}
                        </label>
                        <label class="btn btn-primary" style="min-width: 100px !important;" [class.active]="true">
                            <input type="radio" checked >{{ 'employees_datetimes.full_calendar' | translate }}
                        </label>
                        <label class="btn btn-primary" style="min-width: 100px !important;" [class.active]="false">
                            <input type="radio" (change)="switchType('plan')">{{ 'employees_datetimes.daily_summary' | translate }}
                        </label>
                    </div>
                </ng-container>
                <button
                    (click)="openFilterFormOptions()"
                    class="btn btn-primary btn-icon"
                    type="button"
                    [formGroup]="filterForm"
                >
                    <i class="mdi mdi-filter-outline"></i>
                </button>
            </div>
        </div>
    </div>

    <div class="card-body">
        <full-calendar #calendarComponent [options]="calendarOptions" [events]="events"></full-calendar>
        <ng-container *ngIf="isLoading">
            <div class="item">
                <ngx-skeleton-loader [theme]="{
                    'margin': '15px 0'
                  }" appearance="line" count="18"></ngx-skeleton-loader>
            </div>
        </ng-container>
    </div>
</div>

<ng-template #loadingTemplate>

</ng-template>
