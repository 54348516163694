import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable } from 'rxjs';
import { ApiHelper } from 'src/app/shared/common/ApiHelper';
import { SwalHelper } from 'src/app/shared/common/SwalHelper';
import { ApiResponse } from 'src/app/shared/models/ApiResponse';
import { EmployeeAccess } from '../models/EmployeeAccess';

@Injectable({
    providedIn: 'root'
})
export class AccessService {
    public constructor(
        private _apiHelper: ApiHelper,
        private _http: HttpClient,
        private _loaderService: NgxUiLoaderService,
        private _swalHelper: SwalHelper
    ) { }

    public deleteAccess(employeeID: number, employeeAccessID: number): Promise<'done'> {
        return new Promise(async (resolve, reject) => {
            const deleteConfirmed = await this._swalHelper.showConfirmDeleteDialog();

            if (deleteConfirmed) {
                this._http.delete('/api/employees/' + employeeID + '/accesses/' + employeeAccessID)
                    .subscribe(
                        (response: any) => {
                            this._loaderService.stopLoader('master');

                            this._apiHelper.handleSuccessResponse(response);

                            resolve('done');
                        },
                        error => {
                            this._loaderService.stopLoader('master');

                            this._apiHelper.handleErrorResponse(error);
                        }
                    );
            } else {
                reject('error');
            }
        });
    }

    public getAllAccessesByEmployeeID(employeeID: number): Observable<Array<EmployeeAccess>> {
        return this._http.get<Array<EmployeeAccess>>('/api/employees/' + employeeID + '/accesses');
    }

    public getAccessByID(employeeID: number, employeeAccessID: number): Observable<EmployeeAccess> {
        return this._http.get<EmployeeAccess>('/api/employees/' + employeeID + '/accesses/' + employeeAccessID);
    }

    public saveAccess(access: EmployeeAccess, employeeAccessID?: number): Promise<'done'> {
        return new Promise(resolve => {
            if (employeeAccessID) {
                this._http.post<ApiResponse>('/api/employees/' + access.employee_ID + '/accesses/' + employeeAccessID, access)
                    .subscribe(
                        response => {
                            this._apiHelper.handleSuccessResponse(response);

                            resolve('done');
                        },
                        error => {
                            this._apiHelper.handleErrorResponse(error);
                        }
                    );
            } else {
                this._http.post<ApiResponse>('/api/employees/' + access.employee_ID + '/accesses', access)
                    .subscribe(
                        response => {
                            this._apiHelper.handleSuccessResponse(response);

                            resolve('done');
                        },
                        error => {
                            this._apiHelper.handleErrorResponse(error);
                        }
                    );
            }
        });
    }
}
