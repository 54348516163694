<ng-template
    #filterFormOptions
    let-modal
>
    <div class="modal-content">
        <div class="modal-header">
            <p class="modal-title font-16 mb-0 text-dark">
                <span>{{ 'employees.adjust_filters' | translate }}</span>
            </p>

            <button
                (click)="modal.dismiss()"
                aria-label="Close"
                class="close"
            >
        <span
            aria-hidden="true"
            class="font-24 text-muted"
        >
          <i class="mdi mdi-close"></i>
        </span>
            </button>
        </div>

        <div class="modal-body">
            <form
                (ngSubmit)="onSubmitFilter()"
                [formGroup]="filterForm"
                class="filter-form"
            >
                <fieldset>
                    <legend>{{ 'employees.filter_section_company_groups' | translate }}</legend>

                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label>{{ 'employees.filter_company_groups' | translate }}</label>

                                <ng-select
                                    [bindLabel]="'label'"
                                    [bindValue]="'company_group_ID'"
                                    [clearable]="false"
                                    [closeOnSelect]="false"
                                    [items]="companyGroups$ | async"
                                    [multiple]="true"
                                    class="custom"
                                    formControlName="companyGroups"
                                >
                                    <ng-template ng-multi-label-tmp>
                    <span
                        class="ng-placeholder"
                        style="display: block;"
                    >
                      <span>{{ 'employees.filter_company_groups_count' | translate }}</span>
                      <span> (</span>
                      <span>{{ getCount('companyGroups') }}</span>
                      <span>)</span>
                    </span>
                                    </ng-template>

                                    <ng-template
                                        let-index="index"
                                        let-item="item"
                                        let-item$="item$"
                                        ng-option-tmp
                                    >
                                        <input
                                            [checked]="item$.selected"
                                            id="item-{{index}}"
                                            type="checkbox"
                                        />

                                        <span>&nbsp;</span>
                                        <span>{{ item.name }}</span>
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                    </div>
                </fieldset>

                <fieldset>
                    <legend>{{ 'employees.filter_section_employee_branch' | translate }}</legend>
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label>{{ 'employees.filter_company_branch' | translate }}</label>

                                <ng-select
                                    [bindLabel]="'name'"
                                    [bindValue]="'company_branch_ID'"
                                    [clearable]="false"
                                    [closeOnSelect]="false"
                                    [items]="companyBranches$ | async"
                                    [multiple]="true"
                                    class="custom"
                                    formControlName="mainCompanyBranches"
                                >
                                    <ng-template ng-multi-label-tmp>
                    <span
                        class="ng-placeholder"
                        style="display: block;"
                    >
                      <span>{{ 'employees.filter_company_branch_count' | translate }}</span>
                      <span> (</span>
                      <span>{{ getCount('mainCompanyBranches') }}</span>
                      <span>)</span>
                    </span>
                                    </ng-template>

                                    <ng-template let-index="index" let-item="item" let-item$="item$" ng-option-tmp>
                                        <input [checked]="item$.selected" id="item-{{index}}" type="checkbox"/>
                                        <span>&nbsp;</span>
                                        <span>{{ item.name }}</span>
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                    </div>
                </fieldset>

                <fieldset>
                    <legend>{{ 'employees.filter_section_employee_position' | translate }}</legend>

                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label>{{ 'employees.filter_employee_position_types' | translate }}</label>

                                <ng-select
                                    [bindLabel]="'name'"
                                    [bindValue]="'name'"
                                    [clearable]="false"
                                    [closeOnSelect]="false"
                                    [items]="employeePositionTypes$ | async"
                                    [multiple]="true"
                                    class="custom"
                                    formControlName="employeePositionTypes"
                                >
                                    <ng-template ng-multi-label-tmp>
                    <span
                        class="ng-placeholder"
                        style="display: block;"
                    >
                      <span>{{ 'employees.filter_employee_position_types_count' | translate }}</span>
                      <span> (</span>
                      <span>{{ getCount('employeePositionTypes') }}</span>
                      <span>)</span>
                    </span>
                                    </ng-template>

                                    <ng-template let-index="index" let-item="item" let-item$="item$" ng-option-tmp>
                                        <input [checked]="item$.selected" id="item-{{index}}" type="checkbox"/>
                                        <span>&nbsp;</span>
                                        <span>{{ 'employees_positions.' + item.name | translate }}</span>
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label>{{ 'employees.filter_position_company_branch' | translate }}</label>

                                <ng-select
                                    [bindLabel]="'name'"
                                    [bindValue]="'company_branch_ID'"
                                    [clearable]="false"
                                    [closeOnSelect]="false"
                                    [items]="companyBranches$ | async"
                                    [multiple]="true"
                                    class="custom"
                                    formControlName="positionCompanyBranches"
                                >
                                    <ng-template ng-multi-label-tmp>
                    <span
                        class="ng-placeholder"
                        style="display: block;"
                    >
                      <span>{{ 'employees.filter_position_company_branch_count' | translate }}</span>
                      <span> (</span>
                      <span>{{ getCount('positionCompanyBranches') }}</span>
                      <span>)</span>
                    </span>
                                    </ng-template>

                                    <ng-template let-index="index" let-item="item" let-item$="item$" ng-option-tmp>
                                        <input [checked]="item$.selected" id="item-{{index}}" type="checkbox"/>
                                        <span>&nbsp;</span>
                                        <span>{{ item.name }}</span>
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                    </div>
                </fieldset>

                <fieldset>
                    <legend>{{ 'employees.filter_section_tags' | translate }}</legend>

                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label>{{ 'employees.filter_tags' | translate }}</label>

                                <ng-select
                                    [bindLabel]="'label'"
                                    [bindValue]="'tag_ID'"
                                    [clearable]="false"
                                    [closeOnSelect]="false"
                                    [items]="tags$ | async"
                                    [multiple]="true"
                                    class="custom"
                                    formControlName="tags"
                                >
                                    <ng-template ng-multi-label-tmp>
                    <span
                        class="ng-placeholder"
                        style="display: block;"
                    >
                      <span>{{ 'employees.filter_tags_count' | translate }}</span>
                      <span> (</span>
                      <span>{{ getCount('tags') }}</span>
                      <span>)</span>
                    </span>
                                    </ng-template>

                                    <ng-template
                                        let-index="index"
                                        let-item="item"
                                        let-item$="item$"
                                        ng-option-tmp
                                    >
                                        <input
                                            [checked]="item$.selected"
                                            id="item-{{index}}"
                                            type="checkbox"
                                        />

                                        <span>&nbsp;</span>
                                        <span>{{ item.label }}</span>
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                    </div>
                </fieldset>

                <fieldset>
                    <legend>{{ 'employees.filter_section_skills' | translate }}</legend>

                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label>{{ 'employees.filter_hard_skills' | translate }}</label>

                                <ng-select
                                    [bindLabel]="'label'"
                                    [bindValue]="'tag_ID'"
                                    [clearable]="false"
                                    [closeOnSelect]="false"
                                    [items]="hardSkillsTags$ | async"
                                    [multiple]="true"
                                    class="custom"
                                    formControlName="hardSkillsTags"
                                >
                                    <ng-template ng-multi-label-tmp>
                    <span
                        class="ng-placeholder"
                        style="display: block;"
                    >
                      <span>{{ 'employees.filter_hard_skills_count' | translate }}</span>
                      <span> (</span>
                      <span>{{ getCount('hardSkillsTags') }}</span>
                      <span>)</span>
                    </span>
                                    </ng-template>

                                    <ng-template
                                        let-index="index"
                                        let-item="item"
                                        let-item$="item$"
                                        ng-option-tmp
                                    >
                                        <input
                                            [checked]="item$.selected"
                                            id="item-{{index}}"
                                            type="checkbox"
                                        />

                                        <span>&nbsp;</span>
                                        <span>{{ item.label }}</span>
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label>{{ 'employees.filter_soft_skills' | translate }}</label>

                                <ng-select
                                    [bindLabel]="'label'"
                                    [bindValue]="'tag_ID'"
                                    [clearable]="false"
                                    [closeOnSelect]="false"
                                    [items]="softSkillsTags$ | async"
                                    [multiple]="true"
                                    class="custom"
                                    formControlName="softSkillsTags"
                                >
                                    <ng-template ng-multi-label-tmp>
                    <span
                        class="ng-placeholder"
                        style="display: block;"
                    >
                      <span>{{ 'employees.filter_soft_skills_count' | translate }}</span>
                      <span> (</span>
                      <span>{{ getCount('softSkillsTags') }}</span>
                      <span>)</span>
                    </span>
                                    </ng-template>

                                    <ng-template
                                        let-index="index"
                                        let-item="item"
                                        let-item$="item$"
                                        ng-option-tmp
                                    >
                                        <input
                                            [checked]="item$.selected"
                                            id="item-{{index}}"
                                            type="checkbox"
                                        />

                                        <span>&nbsp;</span>
                                        <span>{{ item.label }}</span>
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                    </div>
                </fieldset>

                <div class="row">
                    <div class="col-12 text-right">
                        <button class="btn btn-primary">
                            <span>{{ 'employees.action_filter' | translate }}</span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>

<div *ngIf="data$ | async as data; else loadingTemplate" class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-header py-3">
                <div class="row">
                    <div class="col-6">
                        <p class="modal-title font-16 text-dark pt-2 pb-2">{{year}} / {{month}}</p>
                    </div>
                    <div class="col-6 text-right">
                        <button (click)="openFilterFormOptions()"
                                [formGroup]="filterForm"
                                class="btn btn-primary btn-icon ml-2"
                                type="button"
                        >
                            <i class="mdi mdi-filter-outline"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="table-responsive">
                    <form #f="ngForm" (ngSubmit)="onSubmit(f.value)">
                    <table class="table table-centered table-striped mb-0">
                        <thead class="thead-light">
                            <tr>
                                <th> {{ 'employees.full_name' | translate }} </th>
                                <th> {{ 'employees.hours_by_position' | translate }} </th>
                                <th> {{ 'employees.worked_hours_real_normalized' | translate }} </th>
                                <th> {{ 'employees.hours_vacation' | translate }} </th>
                                <th> {{ 'employees.hours_infirmary' | translate }} </th>
                                <th> {{ 'employees.hours_sick_days' | translate }} </th>
                            </tr>
                        </thead>
                        <tbody>
                                <tr *ngFor="let e of data.employees">
                                    <ng-container [ngModelGroup]="e.employee_ID.toString()">
                                    <td [attr.data-order]="e.surname_for_order + ' ' +  e.name_for_order" class="table-user">
                                        <app-employee-box-avatar [avatar]="e.avatar" [canView]="e.permissions.view" [employeeID]="e.employee_ID" [fullname]="e.fullname" [position]="e.position"></app-employee-box-avatar>
                                    </td>
                                    <td>
                                        {{ e.properly_work_hours }}
                                    </td>
                                    <td>
                                        <span class="mr-1">{{e.real_work_hours}}  / </span>
                                        <input [(ngModel)]="e.properly_work_hours" name="properly_work_hours" type="hidden">
                                        <input [(ngModel)]="e.real_work_hours" name="real_work_hours" type="hidden">
                                        <input (wheel)="disableScroll($event)"
                                               [(ngModel)]="e.normalized_work_hours"
                                               [min]="0"
                                               [required]="true"
                                               class="form-control d-inline-block"
                                               name="normalized_work_hours"
                                               type="number">
                                    </td>
                                    <td>
                                        <input (wheel)="disableScroll($event)"
                                               [(ngModel)]="e.holiday_hours"  [min]="0"
                                               [required]="true"
                                               class="form-control"
                                               name="holiday_hours"
                                               type="number">
                                    </td>
                                    <td>
                                        <input (wheel)="disableScroll($event)"
                                               [(ngModel)]="e.sickness_hours" [min]="0"
                                               [required]="true"
                                               class="form-control"
                                               name="sickness_hours"
                                               type="number">
                                    </td>
                                    <td>
                                        <input (wheel)="disableScroll($event)"
                                               [(ngModel)]="e.sick_day_hours" [min]="0"
                                               [required]="true"
                                               class="form-control"
                                               name="sick_day_hours"
                                               type="number">
                                    </td>
                                </ng-container>
                                </tr>
                        </tbody>
                    </table>
                    <div class="row mt-4">
                        <div class="col-12 text-right">
                            <button [disabled]="f.invalid" class="btn btn-success mr-2"
                                type="submit">{{'numbers_series.action_save' | translate}}</button>
                        </div>
                    </div>
                </form>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #loadingTemplate>
    <div class="row">
        <div class="col-12">
            <div class="card shadow p-3">
                <div class="item">
                    <ngx-skeleton-loader [theme]="{
        'margin': '13px 0'
      }" appearance="line" count="10"></ngx-skeleton-loader>
                </div>
            </div>
        </div>
    </div>
</ng-template>
