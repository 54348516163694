import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { EmployeeDateTime } from 'src/app/employee/models/EmployeeDateTime';
import { AttendanceService } from 'src/app/employee/services/attendance.service';
import { FlatpickrHelper } from 'src/app/shared/common/FlatpickrHelper';
import { SummernoteHelper } from 'src/app/shared/common/SummernoteHelper';
import { FlatpickrLocaleService } from 'src/app/shared/services/flatpickr-locale.service';

@Component({
    selector: 'create-edit-employee-datetime-part-modal',
    templateUrl: './create-edit-employee-datetime-part-modal.component.html',
    styleUrls: ['./create-edit-employee-datetime-part-modal.component.css']
})
export class CreateEditEmployeeDatetimePartModalComponent implements OnInit {
    public parentDateTime: EmployeeDateTime;
    public dateTimeEditForm: UntypedFormGroup;
    public employeeDateTime: EmployeeDateTime;
    public datetimeTypes = [
        {value: 7, label: this._translateService.instant('employees_datetimes_types.PAUSE')},
    ];
    public submitted = false;
    public employeeID: number;
    public locale$ = this._flatpickrLocale.currentFlatpickrLocale$;

    public constructor(
        public fpHelper: FlatpickrHelper,
        public activeModal: NgbActiveModal,
        public summernoteHelper: SummernoteHelper,
        private _attendanceService: AttendanceService,
        private _fb: UntypedFormBuilder,
        private _flatpickrLocale: FlatpickrLocaleService,
        private _translateService: TranslateService
    ) { }

    public get f(): { [formControlName: string]: AbstractControl; } {
        return this.dateTimeEditForm.controls;
    }

    public ngOnInit(): void {
        this.init();
        console.log(this.parentDateTime);
    }

    public init(): void {
        this.dateTimeEditForm = this._fb.group({
            employee_datetime_type_ID: [7, Validators.required],
            from: ['', Validators.required],
            to: ['', Validators.required],
            employee_datetime_parent_ID: [this.parentDateTime.employee_datetime_ID],
            set_on: ['TIME', Validators.required],
        });

        if (this.employeeDateTime) {
            this.dateTimeEditForm.patchValue({
                employee_datetime_type_ID: this.employeeDateTime.type.employee_datetime_type_ID,
                from: this.employeeDateTime.from,
                to: this.employeeDateTime.to,
                set_on: this.employeeDateTime.set_on,
            });

            if (this.employeeDateTime.is_protected) {
                this.dateTimeEditForm.disable();
            }
        }
    }

    public deleteDateTime(): void {
        this._attendanceService.deleteEmployeeDateTimeByID(this.employeeID, this.employeeDateTime.employee_datetime_ID)
            .then(() => this.activeModal.close('cancel'))
            .catch(() => { });
    }

    public onSubmit(): void {
        this.submitted = true;

        if (!this.dateTimeEditForm.valid) {
            return;
        }

        const value = this.dateTimeEditForm.value;

        this._attendanceService.saveEmployeeDateTime(value, this.employeeID, this.employeeDateTime?.employee_datetime_ID, this.parentDateTime.scope)
            .then(() => this.activeModal.close());
    }
}
