import { Component, OnInit } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { AvailableFeatures } from '../../../../employee/models/AvailableFeatures';
import { SubscriptionService } from '../../../services/subscription.service';

@Component({
    selector: 'app-setting-attendance',
    templateUrl: './setting-attendance.component.html',
    styleUrls: ['./setting-attendance.component.css']
})
export class SettingAttendanceComponent implements OnInit {
    public canViewCompanyEmployeePolicies = false;
    public canViewCompanySettings = false;
    public canViewDatetimeProject = false;
    public availableFeatures$: Observable<AvailableFeatures>;

    private _fetchCompanySettings$ = new ReplaySubject<void>(1);

    public constructor(
        private _authService: AuthenticationService,
        private _subscriptionService: SubscriptionService,
    ) { }

    public ngOnInit(): void {
        this.availableFeatures$ = this._subscriptionService.availableFeatures$;
        this.canViewCompanyEmployeePolicies = this._authService.checkPermission('companyEmployeePolicy.view');
        this.canViewCompanySettings = this._authService.checkPermission('companySetting.view');
        this.canViewDatetimeProject = this._authService.checkPermission('employeeDatetimeProject.view');
    }

    public refresh(): void {
        this._fetchCompanySettings$.next();
    }
}
