<!--<editor-fold desc="Prev & next employee">-->
<ng-container *ngIf="employee;">
    <div class="prev-next-employee">
                <span *ngIf="employee.prev_next?.prev as prev">
                    <i class="mdi mdi-chevron-left"></i>
                    <span (click)="navigateToEntity(prev.employee_ID)">{{prev.fullname}}</span>
                </span>
        <span *ngIf="employee.prev_next?.prev && employee.prev_next?.next" class="mr-3 space"></span>
        <span *ngIf="employee.prev_next?.next as next">
                    <span (click)="navigateToEntity(next.employee_ID)">{{next.fullname}}</span>
                    <i class="mdi mdi-chevron-right"></i>
                </span>
    </div>
</ng-container>
<!--</editor-fold>-->

<div class="row mb-1 d-lg-none"
>
  <div class="col-12">
    <div
      ngbDropdown
      #dropdown="ngbDropdown"
      class="submenu-dropdown shadow"
    >
      <button
        ngbDropdownToggle
        class="btn btn-block btn-link"
        id="dropdownBasic2"
      >
        <span class="text-center">{{ pageTitle$ | async | translate }}</span>

        <span class="float-right mr-2">
          <i
            *ngIf="!dropdown.isOpen()"
            class="mdi mdi-chevron-down mdi-18px"
          ></i>
          <i
            *ngIf="dropdown.isOpen()"
            class="mdi mdi-chevron-up mdi-18px"
          ></i>
        </span>
      </button>

      <div
        ngbDropdownMenu
        aria-labelledby="dropdownBasic2"
        class="w-100"
        id="dropdownMenu"
      >
        <ng-container *ngFor="let r of availableRoutes">
          <a
            ngbDropdownItem
            class="btn btn-block"
            routerLinkActive="active"
            [routerLink]="r.routerLink">
            {{ r.title }}
          </a>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<div class="employee-detail-flex-wrap">
  <div class="employee-detail-menu-flex">
    <div class="row">
      <div class="col-12">
        <div *ngIf="availableRoutes.length > 0; else loadingMenuTemplate" class="card h-100 shadow">
          <div class="card-body px-4 py-0">
            <div *ngFor="let r of availableRoutes; last as isLast">
              <div
                class="row text-nowrap"
                [class.border-bottom]="!isLast"
              >
                <div class="col-6 py-2 py-md-3 align-self-center">
                  <a
                    class="text-muted"
                    routerLinkActive="text-dark"
                    [routerLink]="r.routerLink"
                  >
                    <h4 class="modal-title font-16 pt-2 pb-2">{{ r.title }}</h4>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="employee-detail-flex">
    <div>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

<ng-template #loadingMenuTemplate>
  <div class="card h-100 shadow">
    <div class="card-body px-4 py-0">
      <div *ngFor="let r of allRoutes; last as isLast">
        <div
                class="row"
                [class.border-bottom]="!isLast"
        >
          <div class="col-9 pt-4 pb-3 align-self-center">
              <ngx-skeleton-loader
                      [theme]="{ width: '100%', 'border-radius': '0', height: '15px' }"
              >
              </ngx-skeleton-loader>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
