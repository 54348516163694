import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable } from 'rxjs';
import { ListParameters, ListResponse, transformParameters } from 'src/app/api';
import { ApiHelper } from 'src/app/shared/common/ApiHelper';
import { SwalHelper } from 'src/app/shared/common/SwalHelper';
import { ApiResponse } from 'src/app/shared/models/ApiResponse';
import { DateTimeProject } from '../models/DatetimeProject';

@Injectable({
    providedIn: 'root'
})
export class DatetimeProjectService {
    public constructor(
        private _apiHelper: ApiHelper,
        private _http: HttpClient,
        private _loaderService: NgxUiLoaderService,
        private _swalHelper: SwalHelper
    ) { }

    public getDatetimeProjectsList(params: ListParameters<DateTimeProject>): Observable<ListResponse<DateTimeProject>> {
        return this._http.post<ListResponse<DateTimeProject>>('/api/employees/datetimes/projects/list', {...transformParameters(params)});
    }

    public getDatetimeProjectsForSelect(): Observable<DateTimeProject[]> {
        return this._http.get<DateTimeProject[]>('/api/employees/datetimes/projects');
    }

    public deleteDatetimeProject(employeeDatetimeProjectID: number): Promise<'done'> {
        return new Promise(async (resolve, reject) => {
            const deleteConfirmed = await this._swalHelper.showConfirmDeleteDialog();

            if (deleteConfirmed) {
                this._loaderService.startLoader('master');

                this._http.delete<ApiResponse>('/api/employees/datetimes/projects/' + employeeDatetimeProjectID)
                    .subscribe(
                        response => {
                            this._loaderService.stopLoader('master');

                            this._apiHelper.handleSuccessResponse(response);

                            resolve('done');
                        },
                        error => {
                            this._loaderService.stopLoader('master');

                            this._apiHelper.handleErrorResponse(error);
                        }
                    );
            } else {
                reject('error');
            }
        });
    }

    public saveDatetimeProject(form: DateTimeProject, employeeDatetimeProjectID?: number): Promise<'done'> {
        this._loaderService.startLoader('master');

        return new Promise(resolve => {
            let apiUrl = '/api/employees/datetimes/projects';

            if (employeeDatetimeProjectID) {
                apiUrl += `/${employeeDatetimeProjectID}`;
            }

            this._http.post<ApiResponse>(apiUrl, form)
                .subscribe(
                    response => {
                        this._apiHelper.handleSuccessResponse(response);

                        resolve('done');

                        this._loaderService.stopLoader('master');
                    },
                    error => {
                        this._apiHelper.handleErrorResponse(error);

                        this._loaderService.stopLoader('master');
                    }
                );
        });
    }
}
