<!--<editor-fold desc="MODALS">-->
<ng-template #contentOnboarding id="OnboardingModal" let-modal>
  <div class="modal-content">
    <div class="modal-header">
      <p class="modal-title font-16 mb-0 text-dark">
        <span>{{ 'employees_onboardings.assign' | translate }}</span>
      </p>

      <button
        (click)="modal.dismiss()"
        aria-label="Close"
        class="close"
      >
        <span
          aria-hidden="true"
          class="font-24 text-muted"
        >
          <i class="mdi mdi-close"></i>
        </span>
      </button>
    </div>

    <div class="modal-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card mb-0 shadow-none">
            <div class="card-body p-0">
              <form
                (ngSubmit)="onSubmitOnboarding()"
                [formGroup]="assignOnboardingsForm"
                autocomplete="off"
              >
                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <label class="control-label">
                        <span>{{ 'employees_onboardings.onboarding' | translate }}</span>
                      </label>

                      <ng-select
                        (change)="onboardingSelectionChanged()"
                        [items]="onboardings$ | async"
                        [multiple]="true"
                        [placeholder]="'employees_onboardings.create_new' | translate"
                        bindLabel="name"
                        bindValue="onboarding_ID"
                        class="custom"
                        formControlName="onboardingIDs"
                      ></ng-select>
                    </div>
                  </div>
                </div>

                <div
                    *ngIf="canCreateOnboarding$ | async"
                    class="row"
                >
                  <div class="col-12 text-right">
                    <button
                        (click)="openOnboardingCreateModal()"
                        class="btn btn-link p-0"
                        type="button"
                    >{{ 'onboardings.action_create' | translate }}</button>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <label class="control-label">
                        <span>{{ 'onboardings.start_date' | translate }}</span>
                      </label>

                      <input [locale]="locale$ | async"
                             allowInput="false"
                             altFormat="d.m.Y"
                             altInput="true"
                             class="form-control"
                             dateFormat="Y-m-d"
                             formControlName="start_date"
                             mwlFlatpickr [disableMobile]="true"
                             name="start_date"
                             type="text"
                      >
                    </div>
                  </div>
                  <div class="col-12 text-right">
                    <small class="text-muted">
                      {{ 'onboardings.start_date_description' | translate }}
                    </small>
                  </div>
                </div>

                <div
                  *ngIf="assignOnboardingsForm.controls.name"
                  class="row"
                >
                  <div class="col-12">
                    <div class="form-group">
                      <label class="control-label">
                        <span>{{ 'employees_onboardings.name' | translate }}</span>
                      </label>

                      <input
                        [placeholder]="'employees_onboardings.name' | translate"
                        class="form-control"
                        formControlName="name"
                        type="text"
                      >

                      <div *ngIf="submittedOnboardings && f.name.errors">
                        <p
                          *ngIf="f.name.errors.required"
                          class="text-danger validation-text"
                        >
                          <span>{{ 'angular_validation.field' | translate }} </span>
                          <span>{{ 'employees_onboardings.name' | translate }} </span>
                          <span>{{ 'angular_validation.required' | translate }}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  *ngIf="assignOnboardingsForm.controls.type"
                  class="row"
                >
                  <div class="col-12">
                    <div class="form-group">
                      <label class="control-label">
                        <span>{{ 'onboardings.type' | translate }}</span>
                      </label>

                      <ng-select
                        class="custom"
                        formControlName="type"
                        name="type"
                      >
                        <ng-option value="ONBOARDING">Onboarding</ng-option>
                        <ng-option value="OFFBOARDING">Offboarding</ng-option>
                      </ng-select>

                      <div *ngIf="submittedOnboardings && f.type.errors">
                        <p
                          *ngIf="f.type.errors.required"
                          class="text-danger validation-text"
                        >
                          <span>{{ 'angular_validation.field' | translate }} </span>
                          <span>{{ 'onboardings.type' | translate }} </span>
                          <span>{{ 'angular_validation.required' | translate }}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  *ngIf="assignOnboardingsForm.controls.description"
                  class="row"
                >
                  <div class="col-12">
                    <div class="form-group">
                      <label class="control-label">
                        <span>{{ 'employees_onboardings.description' | translate }}</span>
                      </label>

                      <textarea
                        class="form-control"
                        formControlName="description"
                      ></textarea>
                    </div>
                  </div>
                </div>

                <div class="row mt-2 text-right">
                  <div class="col-12">
                    <button class="btn btn-primary">
                      <span>{{ 'employees_onboardings.assign' | translate }}</span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<!--</editor-fold desc="MODALS">-->

<ng-container *ngIf="employeeOnboardings$ | async as onboardings">
  <ng-container *ngIf="onboardings.length">

    <div class="card shadow">
      <app-help name="employee.detail.onboarding.list"></app-help>

      <div class="card-header py-3">
        <div class="row">
          <div class="col-sm-6 text-left my-auto">
            <p class="modal-title font-16 pt-2 pb-2 text-dark">
              <span>{{ 'employees_onboardings.employee_onboarding' | translate }}</span>
            </p>
          </div>

          <div class="col-sm-6 text-right my-auto">
            <button
              (click)="openOnboardingAssignModal(contentOnboarding)"
              *ngIf="canEditOrRemove$ | async"
              [createButton]="'employees_onboardings.action_assign' | translate"
            ></button>
          </div>
        </div>
      </div>

      <div class="card-body px-3 pb-1">
        <div class="row">
          <div *ngFor="let onboarding of onboardings" class="col-4">
            <div (click)="openEmployeeOnboardingOverviewModal(onboarding)" class="card card-bordered card-link">
              <div class="card-body">
                <div class="">
                  <div class="link-text">{{ onboarding.name }}</div>
                  <div class="progress mt-2">
                    <div
                      [style.width.%]="onboarding.percent_progress"
                      aria-valuemax="100"
                      aria-valuemin="0"
                      aria-valuenow="progressBarWidth"
                      class="progress-bar"
                      role="progressbar"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
