<div *ngIf="!isLoading; else loadingTemplate" class="modal-content">
    <div class="modal-header">
        <p
            *ngIf="employeeDateTime"
            class="modal-title font-16 mb-0 text-dark"
        >{{ 'employees_datetimes.page_title_edit' | translate }}</p>

        <p
            *ngIf="!employeeDateTime"
            class="modal-title font-16 mb-0 text-dark"
        >{{ 'employees_datetimes.page_title_create' | translate }}</p>

        <button
            (click)="activeModal.dismiss()"
            aria-label="Close"
            class="close"
            type="button"
        >
      <span
          aria-hidden="true"
          class="font-24 text-muted"
      >
        <i class="mdi mdi-close"></i>
      </span>
        </button>
    </div>

    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <div class="card mb-0 shadow-none">
                    <div class="card-body p-0">
                        <div *ngIf="showEmployeeSelect" class="row">
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <label class="control-label required-label">
                                        <span>{{ 'employees_datetimes.employee' | translate }}</span>
                                    </label>

                                    <ng-select
                                        [clearable]="false"
                                        [closeOnSelect]="true"
                                        [items]="employees$ | async"
                                        [multiple]="false"
                                        bindLabel="fullname"
                                        bindValue="employee_ID"
                                        class="custom"
                                        required
                                        [(ngModel)]="employeeID"
                                        (change)="onEmployeeChange()"
                                    >
                                    </ng-select>
                                    <div class="mt-1" *ngIf="submitted && !employeeID">
                                        <p class="text-danger">
                                            <span>{{ 'angular_validation.field' | translate }} </span>
                                            <span>{{ 'employees_datetimes.employee' | translate }} </span>
                                            <span>{{ 'angular_validation.required' | translate }}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <form
                            (ngSubmit)="onSubmit()"
                            [formGroup]="dateTimeEditForm"
                            autocomplete="off"
                        >
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label class="control-label">{{ 'employees_datetimes.type' | translate }}</label>
                                        <ng-select
                                            (change)="onSelectedType($event)"
                                            [clearable]="false"
                                            [loading]="datetimeTypesLoading"
                                            [items]="datetimeTypes$ | async"
                                            [readonly]="disableType"
                                            bindLabel="name"
                                            bindValue="employee_datetime_type_ID"
                                            class="custom"
                                            formControlName="employee_datetime_type_ID"
                                            name="type"
                                        ></ng-select>

                                        <div *ngIf="submitted && f.employee_datetime_type_ID.errors">
                                            <p
                                                *ngIf="f.employee_datetime_type_ID.errors.required"
                                                class="text-danger validation-text"
                                            >
                                                <span>{{ 'angular_validation.field' | translate }} </span>
                                                <span>{{ 'employees_datetimes.type' | translate }} </span>
                                                <span>{{ 'angular_validation.required' | translate }}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="projectsEnabled" class="row">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label class="control-label">{{ 'employees_datetimes.projects.name' | translate }}</label>
                                        <ng-select
                                            [clearable]="true"
                                            [items]="datetimeProjects$ | async"
                                            bindLabel="name"
                                            bindValue="employee_datetime_project_ID"
                                            class="custom"
                                            formControlName="employee_datetime_project_ID"
                                            name="project"
                                        ></ng-select>
                                    </div>
                                </div>
                            </div>

                            <div
                                *ngIf="employeeDateTime || employee?.permissions?.approveOrDeny || isAdmin"
                                class="row"
                            >
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label class="control-label">{{ 'employees_datetimes.state' | translate }}</label>

                                        <ng-select
                                            class="custom"
                                            formControlName="state"
                                            name="state"
                                        >
                                            <ng-option value="APPROVED">
                                                <span>{{ 'employees_datetimes_states.type_APPROVED' | translate }}</span>
                                            </ng-option>

                                            <ng-option value="DENIED">
                                                <span>{{ 'employees_datetimes_states.type_DENIED' | translate }}</span>
                                            </ng-option>

                                            <ng-option value="NEW">
                                                <span>{{ 'employees_datetimes_states.type_NEW' | translate }}</span>
                                            </ng-option>
                                        </ng-select>

                                        <div *ngIf="submitted && f.state.errors">
                                            <p
                                                *ngIf="f.state.errors.required"
                                                class="text-danger"
                                            >
                                                <span>{{ 'angular_validation.field' | translate }} </span>
                                                <span>{{ 'employees_datetimes.state' | translate }} </span>
                                                <span>{{ 'angular_validation.required' | translate }}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <hr>

                            <div class="row mb-3">
                                <div class="col-12">
                                    <div id="set_on" class="btn-group btn-group-toggle d-flex" data-toggle="buttons">
                                        <label class="btn btn-primary" style="flex: 1 1 0; min-width: auto !important;" [class.active] = "f.set_on.value === 'TIME'">
                                            <input type="radio" (change)="onSetOnChange()" formControlName="set_on" name="set_on" value="TIME" checked>{{ 'employees_datetimes.set_on_TIME' | translate }}
                                        </label>
                                        <label class="btn btn-primary" style="flex: 1 1 0; min-width: auto !important;" [class.active] = "f.set_on.value === 'SHIFT'">
                                            <input type="radio" (change)="onSetOnChange()" formControlName="set_on" name="set_on" value="SHIFT">{{ 'employees_datetimes.set_on_SHIFT' | translate }}
                                        </label>
                                        <label class="btn btn-primary" style="flex: 1 1 0; min-width: auto !important;" [class.active] = "f.set_on.value === 'HALF_SHIFT'">
                                            <input type="radio" (change)="onSetOnChange()" formControlName="set_on" name="set_on" value="HALF_SHIFT">{{ 'employees_datetimes.set_on_HALF_SHIFT' | translate }}
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label class="control-label">{{ 'employees_datetimes.from' | translate }}</label>

                                        <input
                                            *ngIf="enableTime"
                                            [enableTime]="true"
                                            [locale]="locale$ | async"
                                            allowInput="false"
                                            altFormat="d.m.Y H:i"
                                            altInput="true"
                                            class="form-control"
                                            dateFormat="Y-m-d H:i:ss"
                                            formControlName="from"
                                            mwlFlatpickr [disableMobile]="true"
                                            name="from"
                                            time24hr="true"
                                            type="text"
                                            [disableMobile]="true"
                                            (flatpickrClose)="fpHelper.applyAltInputValue($event, true)"
                                        >

                                        <input
                                            *ngIf="!enableTime"
                                            [enableTime]="false"
                                            [locale]="locale$ | async"
                                            allowInput="false"
                                            altFormat="d.m.Y"
                                            altInput="true"
                                            class="form-control"
                                            dateFormat="Y-m-d"
                                            formControlName="from"
                                            mwlFlatpickr [disableMobile]="true"
                                            name="from"
                                            time24hr="true"
                                            type="text"
                                            [disableMobile]="true"
                                            (flatpickrClose)="fpHelper.applyAltInputValue($event, false)"
                                        >

                                        <div *ngIf="submitted && f.from.errors">
                                            <p
                                                *ngIf="f.from.errors.required"
                                                class="text-danger"
                                            >
                                                <span>{{ 'angular_validation.field' | translate }} </span>
                                                <span>{{ 'employees_datetimes.from' | translate }} </span>
                                                <span>{{ 'angular_validation.required' | translate }}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label class="control-label">{{ 'employees_datetimes.to' | translate }}</label>

                                        <input
                                            *ngIf="enableTime"
                                            [locale]="locale$ | async"
                                            allowInput="false"
                                            altFormat="d.m.Y H:i"
                                            altInput="true"
                                            class="form-control"
                                            dateFormat="Y-m-d H:i:ss"
                                            enableTime="true"
                                            formControlName="to"
                                            mwlFlatpickr [disableMobile]="true"
                                            name="to"
                                            time24hr="true"
                                            type="text"
                                            (flatpickrClose)="fpHelper.applyAltInputValue($event, true)"
                                        >

                                        <input
                                            *ngIf="!enableTime"
                                            [enableTime]="false"
                                            [locale]="locale$ | async"
                                            allowInput="false"
                                            altFormat="d.m.Y"
                                            altInput="true"
                                            class="form-control"
                                            dateFormat="Y-m-d"
                                            formControlName="to"
                                            mwlFlatpickr [disableMobile]="true"
                                            name="to"
                                            time24hr="true"
                                            type="text"
                                            (flatpickrClose)="fpHelper.applyAltInputValue($event, false)"
                                        >

                                        <div *ngIf="submitted && f.to.errors">
                                            <p
                                                *ngIf="f.to.errors.required"
                                                class="text-danger"
                                            >
                                                <span>{{ 'angular_validation.field' | translate }} </span>
                                                <span>{{ 'employees_datetimes.to' | translate }} </span>
                                                <span>{{ 'angular_validation.required' | translate }}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-12 text-left mt-2">
                                    <p>
                                        <span class="text-muted"> {{ 'employees_datetimes.hours_count' | translate }}: </span>
                                        <span
                                            *ngIf="employeeDatetimeInfo$ | async as info"
                                            class="text-dark"
                                        >{{ info.drawn_units }}</span>
                                    </p>

                                    <ng-container *ngIf="employeeDatetimeInfo$ | async as info">
                                    <p class="mb-0">
                                        <span class="text-muted"> {{ 'employees_datetimes.hours_left' | translate }}: </span>
                                        <span [ngClass]="{'text-danger': info.overflow_alert, 'text-dark': !info.overflow_alert}">
                                            {{ info.remaining_units }}
                                        </span>
                                    </p>

                                    <div class="mb-2">
                                        <small class="text-muted"> {{ 'employees_datetimes.hours_left_explanation' | translate }} </small>
                                    </div>

                                    <div *ngIf="info.has_waiting_units" class="mb-2">
                                        <span class="text-muted"> {{ 'employees_datetimes.waiting_to_approve' | translate }}: </span>
                                        <span class="text-dark">
                                            {{ info.waiting_units }}
                                        </span>
                                    </div>
                                    </ng-container>

                                    <p>
                                        <span class="text-muted"> {{ 'employees_datetimes.number_of_bank_holidays' | translate }} </span>
                                        <span
                                            *ngIf="employeeDatetimeInfo$ | async as info"
                                            class="text-dark"
                                        >{{ info.holidays_count }}
                                            <span class="text-muted"> / </span> {{ info.weekend_days_count }}</span>
                                    </p>

                                    <p class="">
                                        <span class="text-muted"> {{ 'employees_datetimes.datetime_can_be_approved_by' | translate }} </span>
                                        <ng-container *ngIf="isApprovable">
                                        <span
                                            *ngIf="approvers$ | async as approvers"
                                            class="text-dark"
                                        >{{ approvers.owner_fullname }}</span>
                                        </ng-container>
                                        <ng-container *ngIf="!isApprovable">
                                            <span class="text-dark">{{ 'employees_datetimes.do_not_require_approval' | translate }}</span>
                                        </ng-container>
                                    </p>
                                </div>
                            </div>

                            <hr>

                            <div class="row mt-2">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label class="control-label">{{ 'employees_datetimes.report' | translate }}</label>

                                        <input
                                            [ngxSummernote]="summernoteHelper.getSummernoteConfig()"
                                            [ngxSummernoteDisabled]="!editEnabled"
                                            formControlName="report"
                                            name="report"
                                            type="text"
                                        >

                                        <div *ngIf="submitted && f.report.errors">
                                            <p
                                                *ngIf="f.report.errors.required"
                                                class="text-danger"
                                            >
                                                <span>{{ 'angular_validation.field' | translate }} </span>
                                                <span>{{ 'employees_datetimes.report' | translate }} </span>
                                                <span>{{ 'angular_validation.required' | translate }}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label class="control-label">{{ 'employees_datetimes.note' | translate }}</label>

                                        <input
                                            class="form-control"
                                            formControlName="note"
                                            name="note"
                                        >
                                    </div>
                                </div>
                            </div>

                            <div class="row mb-3">
                                <div class="col-12">
                                    <div *ngIf="canCreateWithConfirmation && showOverflowAlert" class="alert alert-warning w-100">
                                        <p class="mb-0">
                                            <span>{{ 'employees_datetimes_types.overflow_alert' | translate }}</span>
                                        </p>
                                    </div>
                                    <div *ngIf="!canCreateWithConfirmation && !canCreate && showOverflowAlert" class="alert alert-danger w-100">
                                        <p class="mb-0">
                                            <span class="text-danger">{{ 'employees_datetimes_types.overflow_alert' | translate }}</span>
                                        </p>
                                    </div>
                                    <div *ngIf="!editEnabled && showLockedAlert" class="alert alert-warning w-100">
                                        <p class="mb-0">
                                            <span>{{ 'employees_datetimes.locked_alert' | translate }}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="canCreateWithConfirmation" class="row">
                                <div class="col-6">
                                    <div class="form-group">
                                        <label class="control-label" for="approve_forbid_exceeded">
                                            <span>{{ 'employees_datetimes_types.approve_forbid_exceeded' | translate }}</span>
                                        </label>
                                    </div>
                                </div>

                                <div class="col-6 text-right">
                                    <div>
                                        <input data-switch="success" (change)="onConfirmedChange($event)" id="approve_forbid_exceeded" name="approve_forbid_exceeded" type="checkbox">
                                        <label for="approve_forbid_exceeded">&nbsp;</label>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="editEnabled" class="row">
                                <div class="col d-flex justify-content-end">
                                    <button (click)="deleteDateTime()" *ngIf="employeeDateTime && employeeDateTime?.permissions?.delete" class="btn btn-link text-muted pr-0 mr-2" type="button">
                                        <u>{{ 'employees_datetimes.action_delete' | translate }}</u>
                                    </button>
                                    <a class="btn btn-success mr-2" style="min-width: auto !important; padding-left: 1.5rem !important; padding-right: 1.5rem !important;" *ngIf="employeeDateTime && employeeID" [routerLink]="['/employee', employeeID, 'attendance', 'detail', employeeDateTime.employee_datetime_ID]">
                                        {{ 'employees_datetimes.action_detail' | translate }}
                                    </a>
                                    <button class="btn btn-success" [disabled]="!canCreate && (!canCreateWithConfirmation || !isConfirmed)" type="submit">
                                        {{ 'employees_datetimes.action_save' | translate }}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #loadingTemplate>
    <app-modal-loader></app-modal-loader>
</ng-template>
