import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CompanyService } from 'src/app/setting/services/company.service';

@Component({
    selector: 'app-meal-vouchers-setting',
    templateUrl: './meal-vouchers-setting.component.html',
    styleUrls: ['./meal-vouchers-setting.component.css']
})
export class MealVouchersSettingComponent implements OnInit {
    public form: UntypedFormGroup;
    public settings$: Observable<any>;

    public constructor(
        private _companyService: CompanyService,
        private _fb: UntypedFormBuilder,
    ) { }

    public get f(): { [formControlName: string]: AbstractControl; } {
        return this.form.controls;
    }

    public ngOnInit(): void {
        this.form = this._fb.group({
            'company.attendance.meal_vouchers_active': [false],
            'company.attendance.meal_vouchers_hours': [0],
        });

        this.settings$ = this._companyService.getCompanySetting()
            .pipe(
                tap(settings => {
                    this.form.patchValue({
                        'company.attendance.meal_vouchers_active': settings.company_settings.attendance?.meal_vouchers_active || false,
                        'company.attendance.meal_vouchers_hours': settings.company_settings.attendance?.meal_vouchers_hours || 0,
                    });
                })
            );
    }

    public onSubmit(): void {
        const value = this.form.value;

        value.settings_type = 'attendance';

        void this._companyService.saveCompanySetting(this.form.value);
    }
}
