<div class="row">
  <div class="col-12">
    <div class="card shadow">
      <app-help name="settings.users.roles.list"></app-help>

      <div class="card-header py-3">
        <div class="row">
          <div class="col-sm-6 text-left my-auto">
            <p class="modal-title font-16 text-dark pt-2 pb-2">
              <span>{{ 'roles.page_title_index' | translate }}</span>
            </p>
          </div>
        </div>
      </div>

      <form #form="ngForm" (ngSubmit)="onSubmit(form)">
        <div class="card-body">
        <div class="row">
          <div class="col-12">
            <div
              *ngIf="roles$ | async; let review"
              class="table-responsive"
            >
                <div class="row">
                    <div class="col-6">
                        <label class="control-label mr-2" for="editEnabled">{{ 'permissions.perform_changes' | translate }}</label>
                    </div>
                    <div class="col-6 text-right">
                        <input
                            [checked]="editEnabled"
                            (change)="toggleEditEnabled(review)"
                            data-switch="success"
                            id="editEnabled"
                            name="editEnabled"
                            type="checkbox"
                        >
                        <label for="editEnabled">&nbsp;</label>
                    </div>
                </div>
              <table
                *ngIf="review.roles && review.sections"
                class="table table-centered table-hover mb-0 roles-review-table"
                style="line-height: 36px;"
              >
                <tbody>
                    <tr *ngIf="editEnabled">
                        <td class=""> <span class="ml-2"> {{ 'global.action_edit' | translate }} </span></td>
                        <td
                            *ngFor="let role of review.roles"
                            class="text-center border-left"
                            style="width: 150px;"
                        >
                            <ng-container *ngIf="role.permissions.edit && (processedRole === null || processedRole?.role_ID === role.role_ID)">
                                <button *ngIf="role.role_ID !== 0"
                                        (click)="setProcessedRole(role)"
                                        class="btn btn-icon btn-primary btn-sm editButton"
                                        type="button"
                                ><i class="mdi mdi-pencil"></i></button>
                                <button *ngIf="role.role_ID === 0"
                                        (click)="setProcessedRole(role)"
                                        class="btn btn-icon btn-primary btn-sm editButton"
                                        type="button"
                                ><i class="mdi mdi-plus"></i></button>
                            </ng-container>
                            <ng-container *ngIf="role.permissions.delete && processedRole === null">
                                <button (click)="deleteRole(role.role_ID)"
                                        class="ml-1 btn btn-icon btn-danger btn-sm deleteButton"
                                        type="button"
                                ><i class="mdi mdi-delete"></i></button>
                            </ng-container>
                        </td>

                    </tr>
                  <ng-container *ngFor="let section of review.sections; trackBy:trackSections" [ngModelGroup]="section.section_name">
                    <tr>
                      <td
                        [attr.colspan]="review.roles.length + 1"
                        class="pl-md-3 font-weight-bolder section-heading bg-primary text-white"
                      >
                        <span>{{ 'roles.section' | translate }}</span>
                        <span>&nbsp;</span>
                        <span>{{ 'global.quote_opening' | translate }}</span>
                        <span>{{ section.section_name_translate }}</span>
                        <span>{{ 'global.quote_closing' | translate }}</span>
                      </td>
                    </tr>

                    <ng-container *ngFor="let module of section.modules; trackBy:trackModules; let isFirst = first" [ngModelGroup]="module.module_name">
                      <tr>
                        <td
                          [attr.colspan]="review.roles.length + 1"
                          class="bg-light font-weight-semibold pl-md-3 module-heading"
                        >
                          <span>{{ 'roles.module' | translate }}</span>
                          <span>&nbsp;</span>
                          <span>{{ 'global.quote_opening' | translate }}</span>
                          <span>{{ section.section_name_translate }}</span>
                          <span>{{ 'global.quote_closing' | translate }}</span>
                          <span>&nbsp;</span>
                          <span>/</span>
                          <span>&nbsp;</span>
                          <span>{{ 'global.quote_opening' | translate }}</span>
                          <span>{{ module.module_name_translate }}</span>
                          <span>{{ 'global.quote_closing' | translate }}</span>
                        </td>
                      </tr>
                      <tr>
                        <td class=""></td>
                        <td
                          *ngFor="let role of review.roles"
                          class="text-center border-left"
                          style="width: 150px;"
                        >
                            <span *ngIf="role.role_ID !== 0" class="role-name">{{ role.name }}</span>
                            <span *ngIf="role.role_ID === 0 && processedRole?.role_ID === 0" class="role-name">
                                <input *ngIf="isFirst" class="text-center" [ngClass]="{'invalid': submitted && !roleNameInputValue}" (input)="setRoleNameInputValue($event)" [value]="roleNameInputValue" type="text">
                                <span *ngIf="!isFirst"> {{ roleNameInputValue }} </span>
                            </span>
                        </td>

                      </tr>

                      <tr *ngFor="let action of module.actions; trackBy:trackActions">
                        <td class="pl-md-3 pr-3 action-name">{{ action.action_name_translate }}</td>

                        <td
                          *ngFor="let permission of action.permissions; trackBy:trackPermissions; let i = index"
                          class="text-center border-left"
                        >
                            <div *ngIf="processedRole?.role_ID === review.roles[i]?.role_ID && editEnabled" class="btn-group btn-group-toggle d-flex radio-buttons" data-toggle="buttons">
                                <label [ngbTooltip]="'permissions.all' | translate" class="btn btn-icon" [class.active]="form.value[section.section_name][module.module_name][action.action_name] === 'all'">
                                    <input type="radio" [name]="action.action_name" [ngModel]="permission.scope" value="all" checked> <i class="font-24 text-green mdi mdi-check"></i>
                                </label>
                                <label [ngbTooltip]="'permissions.structure' | translate" *ngIf="action.available_actions.includes('structure')" class="btn btn-icon" [class.active]="form.value[section.section_name][module.module_name][action.action_name] === 'structure'">
                                    <input type="radio" [name]="action.action_name" [ngModel]="permission.scope" value="structure"> <i class="font-24 text-success mdi mdi-graph-outline"></i>
                                </label>
                                <label [ngbTooltip]="'permissions.own' | translate" *ngIf="action.available_actions.includes('own')" class="btn btn-icon" [class.active]="form.value[section.section_name][module.module_name][action.action_name] === 'own'">
                                    <input type="radio" [name]="action.action_name" [ngModel]="permission.scope" value="own"> <i class="font-24 text-success mdi mdi-account-check"></i>
                                </label>
                                <label [ngbTooltip]="'permissions.none' | translate" class="btn btn-icon" [class.active]="form.value[section.section_name][module.module_name][action.action_name] === 'none'">
                                    <input type="radio" [name]="action.action_name" [ngModel]="permission.scope" value="none"> <i class="font-24 text-danger mdi mdi-cancel"></i>
                                </label>
                            </div>
                          <span *ngIf="processedRole?.role_ID !== review.roles[i]?.role_ID && review.roles[i]?.role_ID !== 0" [attr.title]="permission.scope_translate">
                            <i *ngIf="permission.scope === 'all'" [ngbTooltip]="'permissions.all' | translate" class="font-24 text-green mdi mdi-check"></i>
                            <i *ngIf="permission.scope === 'structure'" [ngbTooltip]="'permissions.structure' | translate" class="font-24 text-success mdi mdi-graph-outline"></i>
                            <i *ngIf="permission.scope === 'own'" [ngbTooltip]="'permissions.own' | translate" class="font-24 text-success mdi mdi-account-check"></i>
                            <i *ngIf="permission.scope === 'none'" [ngbTooltip]="'permissions.none' | translate" class="font-24 text-danger mdi mdi-cancel"></i>
                          </span>
                        </td>
                      </tr>
                    </ng-container>
                  </ng-container>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12">
            <p>{{ 'permissions.explanations' | translate }} </p>
            <ul class="icon-description-list">
              <li>
                <i class="font-16 text-green mdi mdi-check"></i>
                <span class="pl-1">{{ 'permissions.all' | translate }}</span>
              </li>

              <li>
                <i class="font-16 text-danger mdi mdi-cancel"></i>
                <span class="pl-1">{{ 'permissions.none' | translate }}</span>
              </li>

              <li>
                <i class="font-16 text-success mdi mdi-graph-outline"></i>
                <span class="pl-1">{{ 'permissions.structure' | translate }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div *ngIf="processedRole"
             class="submit-button shadow px-1 px-sm-3 px-md-3 pr-lg-4 pl-lg-4"
        >
            <p class="text-dark m-0 d-flex align-items-center text-left mr-2 mr-md-0">
                <span> {{ 'permissions.changes_were_applied' | translate }} </span>
            </p>
            <div>
                <button class="btn btn-link text-muted pr-0 mr-4" type="button" (click)="processedRole = null">
                    <u>{{ 'global.action_cancel' | translate }}</u>
                </button>
                <button submitButton class="btn btn-primary">
                    {{ 'global.action_save' | translate }}
                </button>
            </div>
        </div>
      </div>
      </form>
    </div>
  </div>
</div>
