<div class="row">
  <div class="col-12">
    <div class="card shadow">
      <app-help name="import.list"></app-help>

      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <div class="table-responsive">
              <app-data-table
                [disableInPlaceFiltering]="true"
                [disableInPlaceSorting]="true"
                [loading]="loading"
                [rows]="rows"
                [search]="search"
                [sort]="sort"
                [trackByFn]="trackByFn"
                (searchChanged)="searchChanged($event)"
                (sortChanged)="sortChanged($event)"
              >
                  <ng-template
                      #headerButton
                      *ngIf="canCreate$ | async"
                  >
                      <button
                          class="ml-2"
                          [createButton]="'imports.action_create' | translate"
                          [routerLink]="['/import', 'preview']"
                      ></button>
                  </ng-template>
                <ng-template #headerRow>
                  <th
                    appDataTableHeaderCell
                    [disableSort]="true"
                  >{{ 'imports.table_entity' | translate }}</th>

                  <th
                    appDataTableHeaderCell
                    [disableSort]="true"
                  >{{ 'imports.table_rows' | translate }}</th>

                  <th
                    appDataTableHeaderCell
                    [disableSort]="true"
                  >{{ 'imports.table_template' | translate }}</th>

                  <th
                    appDataTableHeaderCell
                    [disableSort]="true"
                  >{{ 'imports.table_user' | translate }}</th>

                  <th appDataTableHeaderCell="created_at">{{ 'imports.table_created_at' | translate }}</th>

                  <th
                    appDataTableHeaderCell
                    class="text-right"
                    [disableSort]="true"
                  >{{ 'imports.table_action' | translate }}</th>
                </ng-template>

                <ng-template
                  #dataRow
                  let-row
                >
                  <td appDataTableDataCell>{{ 'imports.table_entity_' + row.entity | translate }}</td>

                  <td appDataTableDataCell>{{ row.items }}</td>

                  <td appDataTableDataCell>
                    <app-value-or-dash>{{ row.template_ID }}</app-value-or-dash>
                  </td>

                  <td appDataTableDataCell>{{ row.author?.fullname }}</td>

                  <td appDataTableDataCell>{{ row.created_at | date: 'dd.MM.yyyy hh:mm' }}</td>

                  <td
                    appDataTableDataCell
                    class="text-right"
                  >
                    <a
                      *ngIf="canView$ | async"
                      class="btn btn-white btn-icon btn-sm"
                      [attr.title]="'imports.action_view' | translate"
                      [routerLink]="['/import', 'overview', row.import_ID]"
                    >
                      <i class="mdi mdi-eye-outline mdi-24px text-muted"></i>
                    </a>
                  </td>
                </ng-template>
              </app-data-table>
            </div>
          </div>
        </div>

        <div class="row mt-3 flex-column-reverse flex-sm-row">
          <div class="col-12 col-sm-2">
            <app-items-per-page-select
              [itemsPerPage]="itemsPerPage"
              [total]="itemsTotal"
              (itemsPerPageChanged)="itemsPerPageChanged($event)"
            ></app-items-per-page-select>
          </div>

          <div class="col-12 col-sm-10">
            <app-pagination
              [itemsPerPage]="itemsPerPage"
              [page]="page"
              [total]="itemsTotal"
              (pageChanged)="pageChanged($event)"
            ></app-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
