<form
  *ngIf="settings$ | async"
  autocomplete="off"
  [formGroup]="form"
  (ngSubmit)="onSubmit()"
>
  <div class="row">
    <div class="col-12">
      <div class="card shadow">
        <app-help name="settings.attendance.shift_duration"></app-help>

        <div class="card-header py-3">
          <div class="row">
            <div class="col-sm-6 text-left my-auto">
              <p class="modal-title font-16 text-dark pt-2 pb-2">
                <span>{{ 'settings.page_title_view_attendance_settings_meal_vouchers' | translate }}</span>
              </p>
            </div>
          </div>
        </div>

        <div class="card-body">
          <div class="row">
            <div class="col-6">
                <label class="control-label">{{ 'settings.meal_vouchers_active' | translate }}</label>
            </div>
            <div class="col-6 text-right align-self-center">
                <div>
                    <input #vouchersActive data-switch="success" formControlName="company.attendance.meal_vouchers_active" id="company.attendance.meal_vouchers_active" name="company.attendance.meal_vouchers_active" type="checkbox">
                    <label for="company.attendance.meal_vouchers_active">&nbsp;</label>
                </div>
            </div>
          </div>

          <div *ngIf="vouchersActive.checked" class="row">
            <div class="col-12 form-group">
                <label class="control-label">{{ 'settings.meal_vouchers_hours' | translate }}</label>
                <input class="form-control" type="number" formControlName="company.attendance.meal_vouchers_hours" id="company.attendance.meal_vouchers_hours" name="company.attendance.meal_vouchers_hours">
            </div>
          </div>

          <div class="row mt-2">
            <div class="col d-flex justify-content-end">
              <button class="btn btn-success">
                <span>{{ 'global.action_save' | translate }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
