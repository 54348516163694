import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../../../../core/services/authentication.service';
import { UserService } from '../../../../setting/services/user.service';
import { FlatpickrHelper } from '../../../../shared/common/FlatpickrHelper';
import { WidgetTemplate } from '../../../../shared/models/WidgetTemplate';
import { FlatpickrLocaleService } from '../../../../shared/services/flatpickr-locale.service';
import { InputVisibilityService } from '../../../../shared/services/InputVisibilityService';
import { WidgetTemplatesService } from '../../../../shared/services/widget-templates.service';
import { Employee } from '../../../models/Employee';
import { EmployeePersonalData } from '../../../models/EmployeePersonalData';

@Component({
    selector: 'app-personal-data-form',
    templateUrl: './personal-data-form.component.html',
    styleUrls: ['./personal-data-form.component.css']
})
export class PersonalDataFormComponent implements OnInit {

    @Input() public submitted = false;
    @Input() public isQuestionnaire = false;
    @Input() public employeePersonalData: EmployeePersonalData;
    @Input() public employee: Employee;
    @Input() public questionnairePersonalDataObject: Employee;

    public numberPicker = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    public employeesGenders = ['MALE', 'FEMALE', 'UNDEFINED'];
    public emailTaken = false;
    public personalInTaken = false;
    public inTaken = false;
    public form: UntypedFormGroup;
    public locale$ = this._flatpickrLocale.currentFlatpickrLocale$;
    public formConfig: WidgetTemplate['personal_data'];

    private _inputTimeout: any;
    private _debounceTime = 500;

    constructor(
        public fpHelper: FlatpickrHelper,
        private _fb: UntypedFormBuilder,
        private _widgetTemplatesService: WidgetTemplatesService,
        private _authService: AuthenticationService,
        private _flatpickrLocale: FlatpickrLocaleService,
        private _inputVisibilityService: InputVisibilityService,
        private _userService: UserService,
    ) { }

    public get f(): { [formControlName: string]: any; } {
        return this.form.controls;
    }

    public get hiddenInputs(): boolean {
        if (this.isQuestionnaire) {
            return false;
        }
        return this._inputVisibilityService.hiddenInputs;
    }

    public ngOnInit(): void {
        this.formConfig = this._widgetTemplatesService.defaultWidgetTemplate.personal_data;

        this.form = this._fb.group({
            born_date: [''],
            born_place: [''],
            personal_in: [''],
            gender: ['UNDEFINED'],
            health_insurer: [''],
            insurance_number: [''],
            health_condition: [''],
            nationality: [null],
            id_card_number: [null],
            passport_number: [null],
            driving_licence_number: [null],
            personal_email: ['', Validators.email],
            personal_phone: [''],
            bank_account_number: [''],
            bank_account_bank_code: [''],
            bank_account_iban: [''],
            has_pension: [false],
            pension_type: [null],
            pension_amount: [0],
            has_salary_deduction: [false],
            salary_deduction_type: [null],
            salary_deduction_amount: [0],
            has_employment_agency: [false],
            has_second_pension_pillar: [false],
            is_student: [false],
            has_student_confirmation: [false],
            number_of_children: [0],
            number_of_dependents: [0],
            in: [''],
            tin: [''],
            has_food_license: [false],
        });

        if (this.formConfig && !this._authService.checkPermission(['employeePersonalData.edit', 'employeePersonalData.editStructure'])) {
            this._widgetTemplatesService.presetFormByConfig(this.form, this.formConfig);
            this.onTogglesChange(this.form.value.has_pension, 'pension_type', 'pension_amount');
            this.onTogglesChange(this.form.value.has_salary_deduction, 'salary_deduction_type', 'salary_deduction_amount');
        }

        if (this.isQuestionnaire && this.questionnairePersonalDataObject) {
            this.form.patchValue(this.questionnairePersonalDataObject);
        } else {
            this.form.patchValue(this.employeePersonalData);
        }

        if (this.hiddenInputs) {
            this.form.disable();
        }
    }

    public inputChanged($event: any): void {
        clearTimeout(this._inputTimeout);
        this._inputTimeout = setTimeout(() => {
            if ($event.target.value !== '') {
                this.onInputChange($event.target.name, $event.target.value);
            }
        }, this._debounceTime);
    }

    public onInputChange(name: any, value: any): void {
        if (!this.isQuestionnaire) {
            this._userService.checkPropertyExistence(name, value)
                .then((response) => {
                    switch (name) {
                        case 'personal_in': {
                            this.personalInTaken = response.show_warning && (typeof this.employee === undefined || this.employee?.user_ID !== response.user_ID);
                            break;
                        }
                        case 'in': {
                            this.inTaken = response.show_warning && (typeof this.employee === undefined || this.employee?.user_ID !== response.user_ID);
                            break;
                        }
                    }
                })
                .catch(() => { });
        }
    }

    public onTogglesChange(checked: any, firstInputName: string, secondInputName: string): void {
        const shouldFirstInputBeRequired = this.formConfig[firstInputName] === 'REQUIRED';
        const shouldsecondInputBeRequired = this.formConfig[secondInputName] === 'REQUIRED';
        if (checked) {
            if (shouldFirstInputBeRequired) {
                this.form.get(firstInputName)?.setValidators([Validators.required]);
            }
            if (shouldsecondInputBeRequired) {
                this.form.get(secondInputName)?.setValidators([Validators.required]);
            }
        } else {
            this.form.get(firstInputName)?.removeValidators(Validators.required);
            this.form.get(secondInputName)?.removeValidators(Validators.required);
        }

        this.form.get(firstInputName)?.updateValueAndValidity();
        this.form.get(secondInputName)?.updateValueAndValidity();
    }
}
