import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { localStorageSafe } from '../functions';
import { ApiResponse } from '../models/ApiResponse';

@Injectable()
export class ApiHelper {
    public serverErrorSwalOptions: SweetAlertOptions = {
        toast: false,
        timer: 5000,
        timerProgressBar: true,
        showConfirmButton: false,
        icon: 'info',
    };

    public errorSwalOptions: SweetAlertOptions = {
        toast: true,
        timer: 5000,
        timerProgressBar: true,
        showConfirmButton: false,
        icon: 'error',
        showClass: {
            icon: 'mr-2'
        }
    };

    public successSwalOptions: SweetAlertOptions = {
        toast: true,
        timer: 5000,
        timerProgressBar: true,
        showConfirmButton: false,
        icon: 'success',
        position: 'bottom-start',
        showClass: {
            icon: 'mr-2'
        }
    };

    public newVersionSwalOptions: SweetAlertOptions = {
        toast: true,
        timer: 5000,
        timerProgressBar: true,
        showConfirmButton: false,
        icon: 'info',
        position: 'bottom-start',
        showClass: {
            icon: 'mr-2'
        }
    };

    public inactivitySwalOptions: SweetAlertOptions = {
        toast: true,
        timerProgressBar: true,
        showConfirmButton: false,
        icon: 'info',
        position: 'bottom-start',
        showClass: {
            popup: 'swal2-show-inactivity',
            backdrop: 'swal2-backdrop-show-inactivity',
            icon: 'swal2-icon-show-inactivity mr-2'
        },
    };

    public loadingSwalOptions: SweetAlertOptions = {
        toast: true,
        timerProgressBar: true,
        showConfirmButton: false,
        position: 'bottom-start',
        showClass: {
            icon: 'mr-2'
        },
        customClass: {
            icon: 'swal2-icon-loader'
        },
        iconHtml: '<div class="spinner-border spinner-border-light spinner-border-slow  text-primary" role="status"><span class="sr-only">Loading...</span></div>'
    };

    public constructor(private _translateService: TranslateService) {
    }

    public handleLoading(message = null): void {
        let translatedMessage = message;
        if (message) {
            translatedMessage = this._translateService.instant(message);
        } else {
            translatedMessage = this._translateService.instant('global.file_loading');
        }
        void Swal.fire({
            ...this.loadingSwalOptions,
            text: translatedMessage
        });
    }

    public stopLoading(): void {
        Swal.close();
    }

    public handleInactivityLogout(): void {
        const translatedMessage: string = this._translateService.instant('global.auto_logout_alert');

        localStorageSafe.removeItem('inactivity_logout_message');

        void Swal.fire({
            ...this.inactivitySwalOptions,
            text: translatedMessage
        });
    }

    public handleNewVersionRefreshed(): void {
        const newVersionRefreshed = localStorage.getItem('new_version_loaded') === 'true';
        if (newVersionRefreshed) {
            const translatedMessage: string = this._translateService.instant('swal.new_version_done');

            localStorageSafe.removeItem('new_version_loaded');

            void Swal.fire({
                ...this.newVersionSwalOptions,
                text: translatedMessage
            });
        }
    }

    public handleSuccessResponse(response: ApiResponse, position: any = 'bottom-start'): void {
        const translatedMessage: string = this._translateService.instant(response.message);
        this.successSwalOptions.position = position;

        void Swal.fire({
            ...this.successSwalOptions,
            text: translatedMessage
        });
    }

    public handleErrorResponse(response: ApiResponse): void {
        let translatedMessage: string;
        const translatedTitle = this._translateService.instant('global.error_response_title');
        const translatedText = this._translateService.instant('global.error_response_text');
        const inputErrorMessage = this._buildInputErrorMessage(response);

        if (inputErrorMessage) {
            translatedMessage = inputErrorMessage;
        } else {
            translatedMessage = this._translateService.instant(response?.error?.message || 'global.unknown_error');
        }

        if (response?.status !== 500) {
            void Swal.fire({
                ...this.errorSwalOptions,
                text: translatedMessage
            });
        } else {
            void Swal.fire({
                ...this.serverErrorSwalOptions,
                title: translatedTitle,
                text: translatedText
            });
        }
    }

    public showErrorMessage(message: string): void {
        void Swal.fire({
            ...this.errorSwalOptions,
            text: message
        });
    }

    public showSuccessMessage(message: string): void {
        this.successSwalOptions.position = 'bottom-start';
        void Swal.fire({
            ...this.successSwalOptions,
            text: message
        });
    }

    private _buildInputErrorMessage(response: ApiResponse): string | null {
        let message = null;

        const errors = response?.error?.errors;

        const inputErrors: Array<string> = [];

        let errorsByInput: Array<string>;

        if (errors) {
            for (const input in errors) {
                if (!errors.hasOwnProperty(input)) {
                    continue;
                }

                errorsByInput = errors[input];

                if (errorsByInput instanceof Array) {
                    inputErrors.push(errorsByInput.join('\n'));
                }
            }

            if (inputErrors.length) {
                message = inputErrors.join('\n');
            }
        }

        return message;
    }
}
