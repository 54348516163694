<div *ngIf="kpi$ | async as kpi; else loadingTemplate">
  <ng-template
    #content
    let-modal
  >
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title font-16 mb-0 text-dark">
          <span>{{ 'kpis.assign_employee' | translate }}</span>
        </p>

        <button
          (click)="modal.dismiss()"
          aria-label="Close"
          class="close"
          type="button"
        >
          <span
            aria-hidden="true"
            class="font-24 text-muted"
          >
            <i class="mdi mdi-close"></i>
          </span>
        </button>
      </div>

      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <div class="card mb-0 shadow-none">
              <div class="card-body p-0">
                <form
                  #f="ngForm"
                  (ngSubmit)="onSubmit(f.form, kpi)"
                >
                  <div class="row">
                    <div class="col-12">
                      <div class="form-group">
                        <label class="control-label">
                          <span>{{ 'kpis.employees' | translate }}</span>
                        </label>

                        <ng-select
                          [items]="availableEmployees$ | async"
                          [multiple]="true"
                          bindLabel="fullname"
                          bindValue="employee_ID"
                          class="custom"
                          name="employeesIDs"
                          ngModel
                          required
                        >
                        </ng-select>
                      </div>
                    </div>
                  </div>

                  <div class="row mt-2">
                    <div class="col-6">
                      <button class="btn btn-primary">
                        <span>{{ 'kpis.action_save' | translate }}</span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <div class="row">
    <div class="col-lg-6 mb-3">
      <div class="card h-100 shadow">
        <app-help name="kpi.detail.info"></app-help>
        <div class="card-header py-3">
          <div class="row">
            <div class="col-6 col-sm-6 col-md-7">
              <p class="modal-title font-16 text-dark pt-2 pb-2">
                <span>{{ 'global.basic_info' | translate }}</span>
              </p>
            </div>

            <div class="col-6 col-sm-6 col-md-5 text-right align-self-center">
              <button *ngIf="canEdit"
                (click)="openEditModal(kpi)"
                [updateButton]="'kpis.action_edit' | translate"
              ></button>
            </div>
          </div>
        </div>

        <div class="card-body px-4">
          <div class="row border-bottom text-nowrap">
            <div class="col-12 col-sm-12 col-md-5 py-1 py-md-2">
              <span class="text-dark">{{ 'kpis.name' | translate }}</span>
            </div>

            <div class="col-12 col-sm-9 col-md-6 py-1 py-md-2">
              <span>{{ kpi.name }}</span>
            </div>
          </div>

          <div class="row border-bottom text-nowrap">
            <div class="col-12 col-sm-12 col-md-5 py-1 py-md-2">
              <span class="text-dark">{{ 'kpis.threshold_1' | translate }}</span>
            </div>

            <div class="col-12 col-sm-9 col-md-6 py-1 py-md-2">
              <span *ngIf="kpi.threshold_1">{{ kpi.threshold_1 }} %</span>
              <span *ngIf="!kpi.threshold_1">---</span>
            </div>
          </div>

          <div class="row border-bottom text-nowrap">
            <div class="col-12 col-sm-12 col-md-5 py-1 py-md-2">
              <span class="text-dark">{{ 'kpis.threshold_2' | translate }}</span>
            </div>

            <div class="col-12 col-sm-9 col-md-6 py-1 py-md-2">
              <span *ngIf="kpi.threshold_2">{{ kpi.threshold_2 }} %</span>
              <span *ngIf="!kpi.threshold_2">---</span>
            </div>
          </div>

          <div class="row border-bottom text-nowrap">
            <div class="col-12 col-sm-12 col-md-5 py-1 py-md-2">
              <span class="text-dark">{{ 'internal_documents.created_at' | translate }}</span>
            </div>

            <div class="col-12 col-sm-9 col-md-6 py-1 py-md-2">
              <span>{{ kpi.created_at | date: 'dd.MM.yyyy' }}</span>
            </div>
          </div>

          <div class="row border-bottom text-nowrap">
            <div class="col-12 col-sm-12 col-md-5 py-1 py-md-2">
              <span class="text-dark">{{ 'internal_documents.updated_at' | translate }}</span>
            </div>

            <div class="col-12 col-sm-9 col-md-6 py-1 py-md-2">
              <span>{{ kpi?.updated_at | date: 'dd.MM.yyyy' }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-6 mb-3">
      <div class="card h-100 shadow">
        <app-help name="kpi.detail.breakpoints"></app-help>
        <div class="card-header py-3">
          <div class="row">
            <div class="col-6 col-sm-6 col-md-7">
              <p class="modal-title font-16 text-dark pt-2 pb-2">
                <span>{{ 'kpis.thresholds' | translate }}</span>
              </p>
            </div>
          </div>
        </div>

        <div class="card-body h-100 px-4">
          <div class="row h-100">
            <div class="col-12 my-auto">
              <div
                class="progress"
                style="height: 20px;"
              >
                <!-- levy prazdny box -->
                <div
                  [style.width.%]="kpi.progress.progress_1.percents"
                  class="progress-bar bg-light"
                ></div>

                <div
                  *ngIf="kpi.threshold_1 !== null"
                  [style.width.%]="kpi.progress.progress_2.percents"
                  class="progress-bar bg-danger"
                  role="progressbar"
                >
                  <span class="percents-value">0 %</span>

                  <span class="absolute-value">{{ kpi.progress.progress_2.absolute | number: '1.0-2' }}</span>
                </div>

                <div
                  [ngClass]="{ 'bg-warning': kpi.progress.progress_4.percents, 'bg-green': !kpi.progress.progress_4.percents }"
                  [style.width.%]="kpi.progress.progress_3.percents"
                  class="progress-bar"
                  role="progressbar"
                >
                  <span class="percents-value">{{ kpi.threshold_1 || 0 }} %</span>
                  <span class="absolute-value">{{ kpi.progress.progress_3.absolute | number: '1.0-2' }}</span>
                </div>

                <div
                  *ngIf="kpi.progress.progress_4.percents"
                  [style.width.%]="kpi.progress.progress_4.percents"
                  class="progress-bar bg-green"
                  role="progressbar"
                >
                  <span class="percents-value">{{ kpi.threshold_2 }} %</span>
                  <span class="absolute-value">{{ kpi.progress.progress_4.absolute | number: '1.0-2' }}</span>
                </div>


                <!-- pravy prazdny box -->
                <div
                  [style.width.%]="kpi.progress.progress_5.percents"
                  class="progress-bar bg-light"
                >
                  <span class="percents-value">100 %</span>
                  <span class="absolute-value">{{ kpi.progress.progress_5.absolute | number: '1.0-2' }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="card shadow">
        <app-help name="kpi.detail.metrics"></app-help>
        <div class="card-header py-3">
          <div class="row">
            <div class="col-6">
              <p class="modal-title font-16 text-dark pt-2 pb-2">
                <span>{{ 'kpis.metrics' | translate }}</span>
              </p>
            </div>

            <div class="col-6 text-right">
              <button *ngIf="canEdit"
                (click)="openKPIMetricEditModal(null, kpi)"
                [createButton]="'kpis.metrics_create' | translate"
              ></button>
            </div>
          </div>
        </div>

        <div class="card-body">
          <div class="table-responsive">
            <table class="table table-hover table-centered mb-0">
              <thead>
                <tr>
                  <th>{{ 'kpis.metrics_name' | translate }}</th>
                  <th>{{ 'kpis.metrics_type' | translate }}</th>
                  <th>{{ 'kpis.metrics_min' | translate }}</th>
                  <th>{{ 'kpis.metrics_max' | translate }}</th>
                  <th>{{ 'kpis.metrics_weight' | translate }}</th>
                  <th class="text-right">{{ 'kpis.metrics_action' | translate }}</th>
                </tr>
              </thead>

              <tbody>
                <ng-container *ngIf="kpi.metrics?.length else noMetricsTemplate">
                  <tr *ngFor="let m of kpi.metrics">
                    <td class="text-nowrap">{{ m.name }}</td>
                    <td class="text-nowrap">{{ 'kpis_metrics.type_' + m.type | translate }}</td>
                    <td class="text-nowrap">{{ m.min }}</td>
                    <td class="text-nowrap">{{ m.max }}</td>
                    <td class="text-nowrap">{{m.weight}} %</td>
                    <td class="text-nowrap text-right">
                      <button
                        (click)="openKPIMetricEditModal(m, kpi)"
                        class="btn btn-white btn-icon btn-sm mr-1"
                      >
                        <i class="mdi mdi-eye-outline mdi-24px text-muted"></i>
                      </button>
                    </td>
                  </tr>
                    <tr>
                        <td colspan="4">
                            &nbsp;
                        </td>
                        <td [ngClass]="{'text-danger': sumOfWeights !== 100, 'text-green': sumOfWeights === 100}">
                            <div  [ngbTooltip]="'kpis_metrics.metric_weight_must_equal_100' | translate"
                                  container="body"
                                  [disableTooltip]="sumOfWeights === 100"
                                  placement="top-left">
                                <i class="mdi mdi-sigma">

                                </i> <span>
                                {{ sumOfWeights }} % </span>
                            </div>
                        </td>
                        <td>
                            &nbsp;
                        </td>
                    </tr>
                </ng-container>

                <ng-template #noMetricsTemplate>
                  <tr class="text-center">
                    <td colspan="6">{{ 'global.empty_widget_error' | translate }}</td>
                  </tr>
                </ng-template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="card shadow">
        <app-help name="kpi.detail.employees"></app-help>
        <div class="card-header py-3">
          <div class="row">
            <div class="col-6">
              <p class="modal-title font-16 text-dark pt-2 pb-2">
                <span>{{ 'kpis.employees' | translate }}</span>
              </p>
            </div>

            <div class="col-6 text-right">
              <button *ngIf="canEdit"
                (click)="openAssignModal(content)"
                [createButton]="'kpis.assign_employee' | translate"
              ></button>
            </div>
          </div>
        </div>

        <div class="card-body">
          <div class="table-responsive">
            <table class="table table-hover table-centered mb-0">
              <thead>
                <tr>
                  <th>{{ 'employees.full_name' | translate }}</th>
                  <th>{{ 'employees_datetimes.date' | translate }}</th>
                  <th>
                    <span>{{ 'kpis.score_average' | translate }}</span>
                    <span> (%)</span>
                  </th>
                  <th>
                    <span>{{ 'kpis.score_last' | translate }}</span>
                    <span> (%)</span>
                  </th>
                  <th class="text-right">{{ 'kpis.table_action' | translate }}</th>
                </tr>
              </thead>

              <tbody>
                <ng-container *ngIf="kpi.employees?.length else noEmployeesTemplate">
                  <tr *ngFor="let e of kpi.employees">
                    <td class="text-nowrap"><app-employee-box-avatar [employee]="e"></app-employee-box-avatar></td>
                    <td class="text-nowrap">{{ e.created_at | date: 'dd.MM.yyyy' }}</td>
                    <td class="text-nowrap">
                      {{ e.avg_score | number: '1.0-2' }}
                      <i (click)="openRadarChart('avg', e, kpi)" class="ml-2 cursor-pointer mdi-24px text-muted mdi mdi-chart-line"> </i>
                    </td>
                    <td class="text-nowrap">
                      {{ e.last_score | number: '1.0-2' }}
                      <i (click)="openRadarChart('latest', e, kpi)" class="ml-2 cursor-pointer mdi-24px text-muted mdi mdi-chart-line"> </i>
                    </td>
                    <td class="text-nowrap text-right">
                      <button
                        (click)="openModificationModal(e, kpi)"
                        class="btn btn-white btn-icon btn-sm mr-1"
                        title="{{ 'kpis.action_edit' | translate }}"
                      >
                        <i class="mdi mdi-eye-outline mdi-24px text-muted"></i>
                      </button>

                      <button *ngIf="canEdit"
                        (click)="unAssignEmployeeFromKPI(e.employee_ID)"
                        class="btn btn-white btn-icon btn-sm"
                        title="{{ 'kpis.action_unassign' | translate }}"
                      >
                        <i class="mdi mdi-close mdi-24px text-muted"></i>
                      </button>
                    </td>
                  </tr>
                </ng-container>

                <ng-template #noEmployeesTemplate>
                  <tr class="text-center">
                    <td colspan="5">{{ 'global.empty_widget_error' | translate }}</td>
                  </tr>
                </ng-template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="card shadow">
        <app-help name="kpi.detail.review"></app-help>
        <div class="card-header py-3">
          <div class="row">
            <div class="col-6">
              <p class="modal-title font-16 text-dark pt-2 pb-2">
                <span>{{ 'kpis.to_review' | translate }}</span>
              </p>
            </div>
          </div>
        </div>

        <div class="card-body">
          <form
            #f="ngForm"
            (ngSubmit)="onSubmitReviews(f.form)"
          >
            <div [ngModelGroup]="'employees'" class="w-100 mb-2" style="overflow-x: scroll;">
              <table class="table table-hover table-centered mb-0 text-nowrap">
                <thead>
                  <tr>
                    <th [width]="40">
                      {{ 'employees.select_employee' | translate }}
                    </th>
                    <th [width]="200">{{ 'employees.full_name' | translate }}</th>
                    <th *ngFor="let m of kpi.metrics" [width]="150">{{ m.name }}</th>
                  </tr>
                </thead>

                <tbody>
                  <ng-container *ngIf="kpi.employees?.length else noEmployeesTemplate">
                    <tr
                      *ngFor="let e of kpi.employees"
                      [ngClass]="{'unselected-employee' : !selectedEmployees[e.employee_ID]}"
                      [ngModelGroup]="e.employee_ID.toString()"
                    >
                      <td [width]="40"
                          class="select-box">
                        <input (change)="selectEmployee(e.employee_ID, $event)"
                               [checked]="selectedEmployees[e.employee_ID]"
                               type="checkbox">
                      </td>
                      <td [width]="200" class="text-nowrap"><app-employee-box-avatar [employee]="e"></app-employee-box-avatar></td>

                      <td *ngFor="let m of kpi.metrics"
                          [ngModelGroup]="m.kpi_metric_ID.toString()"
                          [width]="150"
                      >
                        <ng-container *ngIf="m.type === 'binominal'">
                          <div class="custom-control custom-radio">
                            <input
                              class="custom-control-input"
                              id="YES-{{ m.kpi_metric_ID }}-{{ e.employee_ID }}"
                              name="value_rating-{{ m.kpi_metric_ID }}-{{ e.employee_ID }}"
                              ngModel
                              type="radio"
                              value="100"
                            >

                            <label
                              class="custom-control-label font-weight-normal"
                              for="YES-{{ m.kpi_metric_ID }}-{{ e.employee_ID }}"
                            >{{ 'kpis.review_yes' | translate }}</label>
                          </div>

                          <div class="custom-control custom-radio">
                            <input
                              class="custom-control-input"
                              id="NO-{{ m.kpi_metric_ID }}-{{ e.employee_ID }}"
                              name="value_rating-{{ m.kpi_metric_ID }}-{{ e.employee_ID }}"
                              ngModel
                              type="radio"
                              value="0"
                            >

                            <label
                              class="custom-control-label font-weight-normal"
                              for="NO-{{ m.kpi_metric_ID }}-{{ e.employee_ID }}"
                            >{{ 'kpis.review_no' | translate }}</label>
                          </div>
                        </ng-container>

                        <input
                          *ngIf="m.type === 'text'"
                          class="form-control"
                          name="value_text-{{ m.kpi_metric_ID }}-{{ e.employee_ID }}"
                          ngModel
                          style="width: 200px;"
                          type="text"
                        >

                        <ng-select
                          #value="ngModel"
                          *ngIf="m.type === 'rating'"
                          [clearable]="false"
                          appendTo="body"
                          class="custom"
                          name="value_rating-{{ m.kpi_metric_ID }}-{{ e.employee_ID }}"
                          ngModel
                        >
                          <ng-option
                            *ngFor="let r of m.range"
                            value="{{ r }}"
                          >
                            <span [ngClass]="{
                              'text-danger': m.threshold_count > 1 && r < m.threshold_1_absolute_value,
                              'text-warning': m.threshold_count == 2 && r < m.threshold_2_absolute_value,
                              'text-green': m.threshold_count == 2 && r > m.threshold_2_absolute_value,
                              'text-info': m.threshold_count == 1 && r > m.threshold_1_absolute_value
                            }">{{ r }}</span>
                          </ng-option>
                        </ng-select>

                        <input
                          *ngIf="m.type === 'number'"
                          class="form-control"
                          name="value_rating-{{ m.kpi_metric_ID }}-{{ e.employee_ID }}"
                          ngModel
                          type="number"
                        >
                      </td>
                    </tr>
                  </ng-container>

                  <ng-template #noEmployeesTemplate>
                    <tr class="text-center">
                      <td colspan="2">{{ 'global.empty_widget_error' | translate }}</td>
                    </tr>
                  </ng-template>
                </tbody>
              </table>
            </div>
            <div class="row">
              <div class="col-12 col-md-4 col-lg-3">
                <div class="form-group">
                  <label class="control-label">
                    <span>{{ 'employees.review_date' | translate }}</span>
                  </label>
                  <input
                      (flatpickrClose)="fpHelper.applyAltInputValue($event, false)"
                      allowInput="false"
                      altFormat="d.m.Y"
                      altInput="true"
                      class="form-control"
                      dateFormat="Y-m-d"
                      mwlFlatpickr [disableMobile]="true"
                      name="date"
                      ngModel
                      type="text"
                  >
                </div>
              </div>
            </div>

            <div
              *ngIf="kpi.employees?.length"
              class="row"
            >
              <div class="col-12 text-right">
                <button *ngIf="canEdit" class="btn btn-success">
                  <span>{{ 'kpis.action_save' | translate }}</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #loadingTemplate>
    <div class="row">
        <div class="col-12 col-lg-6">
            <div class="card shadow p-3">
                <ngx-skeleton-loader [theme]="{ width: '100%', 'margin': '25px 0', 'display': 'block' }"
                                     appearance="line"
                                     count="7">
                </ngx-skeleton-loader>
            </div>
        </div>
        <div class="col-12 col-lg-6">
            <div class="card shadow p-3">
                <ngx-skeleton-loader [theme]="{ width: '100%', 'margin': '25px 0', 'display': 'block' }"
                                     appearance="line"
                                     count="7">
                </ngx-skeleton-loader>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card shadow p-3">
                <ngx-skeleton-loader [theme]="{ width: '100%', 'margin': '25px 0', 'display': 'block' }"
                                     appearance="line"
                                     count="7">
                </ngx-skeleton-loader>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card shadow p-3">
                <ngx-skeleton-loader [theme]="{ width: '100%', 'margin': '25px 0', 'display': 'block' }"
                                     appearance="line"
                                     count="7">
                </ngx-skeleton-loader>
            </div>
        </div>
    </div>
</ng-template>
