import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';
import { ListParameters, ListResponse, transformParameters } from 'src/app/api';
import { NotificationConfig } from '../../setting/models/NotificationSetting';
import { ApiHelper } from '../../shared/common/ApiHelper';
import { Notification } from '../models/Notification';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    public notifications$: Observable<Notification[]>;
    public settings$: Observable<NotificationConfig>;
    public showUnreadBadge = false;

    public set badge(showBadge: boolean) {
        this.showUnreadBadge = showBadge;
    }

    public set notifications(notifications: Notification[]) {
        this._notifications$.next(notifications);
    }

    private _settings$ = new ReplaySubject<NotificationConfig>(1);
    private _notifications$ = new BehaviorSubject<Notification[]>([]);

    public constructor(
        private _http: HttpClient,
        private _apiHelper: ApiHelper
    ) {
        this.notifications$ = this._notifications$;
        this.settings$ = this._settings$;
    }

    public updateNotificationConfig(value: NotificationConfig): void {
        this._settings$.next(value);
    }

    public hasActiveNotification(key: string): Observable<boolean> {
        return this.settings$.pipe(
            map(settings => {
                let result = false;
                settings.company_settings.filter(s => s.key.includes(key)).forEach(setting => {
                    if (setting.value) {
                        result = true;
                    }
                });
                return result;
            }));
    }

    public getAllNotitificationsByLoggedInUser(): void {
        this._http.get<Notification[]>('/api/notifications')
            .subscribe((response: any) => {
                const unreadNotification = response.find(n => !n.read_at);
                this.badge = !!unreadNotification;
                this.notifications = response;
            });
    }

    public getNotificationsList(params: ListParameters<Notification>): Observable<ListResponse<Notification>> {
        return this._http.post<ListResponse<Notification>>('/api/notifications/list', {...transformParameters(params)});
    }

    public setViewAt(): Promise<string> {
        return new Promise(resolve => {
            this._http.post('/api/notifications/set-view-at', {})
                .subscribe(() => resolve('done'));
        });
    }

    public getNotificationSettings(): Observable<NotificationConfig> {
        return this._http.get<NotificationConfig>('/api/company-settings/notifications')
            .pipe(
                tap(data => this._settings$.next(data)),
                shareReplay()
            );
    }

    public getWeeklyReportsSetting(): Observable<any> {
        return this._http.get<any>('/api/company-settings/notifications/weekly-report-recipients');
    }

    // dynamic route without need to change BE, just add new scope
    public getReportsSetting(scope: string): Observable<any> {
        return this._http.get<any>('/api/company-settings/notifications/recipients/' + scope);
    }

    public updateWeeklyReportsSetting(data: any): Promise<'done'> {
        return new Promise(resolve => {
            this._http.post<any>('/api/company-settings/notifications/weekly-report-recipients', data).subscribe(
                response => {
                    this._apiHelper.handleSuccessResponse(response);
                    resolve('done');
                },
                error => {
                    this._apiHelper.handleErrorResponse(error);
                }
            );
        });
    }

    // dynamic route without need to change BE, just add new scope
    public updateReportsSetting(data: any, scope: string): Promise<'done'> {
        return new Promise(resolve => {
            this._http.post<any>('/api/company-settings/notifications/recipients/' + scope, data).subscribe(
                response => {
                    this._apiHelper.handleSuccessResponse(response);
                    resolve('done');
                },
                error => {
                    this._apiHelper.handleErrorResponse(error);
                }
            );
        });
    }

    public updateNotificationsSetting(data: any): Promise<'done'> {
        return new Promise(resolve => {
            this._http.post<any>('/api/company-settings/notifications/update', data).subscribe(
                response => {
                    this._apiHelper.handleSuccessResponse(response);
                    resolve('done');
                },
                error => {
                    this._apiHelper.handleErrorResponse(error);
                }
            );
        });
    }
}
