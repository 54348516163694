import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Observable, Subject, Subscription } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';
import { AttendanceApprovers } from 'src/app/employee/models/AttendanceApprovers';
import { Employee } from 'src/app/employee/models/Employee';
import { EmployeeService } from 'src/app/employee/services/employee.service';
import { AuthenticationService } from '../../../../core/services/authentication.service';
import { CompanyEmployeePolicy } from '../../../../policy/models/CompanyEmployeePolicy';
import { CompanyEmployeePolicyAdjustement } from '../../../../policy/models/CompanyEmployeePolicyAdjustement';
import { PolicyService } from '../../../../policy/services/policy.service';
import { AvailableWidgets } from '../../../../setting/models/AvailableWidgets';
import { CompanyService } from '../../../../setting/services/company.service';
import { SubscriptionService } from '../../../../setting/services/subscription.service';
import { AvailableFeatures } from '../../../models/AvailableFeatures';
import { ChangeEmployeeInCompanyFromModalComponent } from '../../partials/change-employee-in-company-from-modal/change-employee-in-company-from-modal.component';

@Component({
    selector: 'app-employee-datetimes',
    templateUrl: './employee-datetimes.component.html',
    styleUrls: ['./employee-datetimes.component.css']
})
export class EmployeeDatetimesComponent implements OnInit, OnDestroy {
    public refreshData = new Subject<void>();
    public employee$: Observable<Employee>;
    public approvers$: Observable<AttendanceApprovers>;
    public approversResult: Array<Employee>;
    public defaultAppend: Array<Employee>;
    public selectedEmployees: Array<Employee>;
    public employeeAdjustments$: Observable<Array<CompanyEmployeePolicyAdjustement>>;
    public employeeID: number;
    public employeePolicy$: Observable<CompanyEmployeePolicy>;
    public availableWidgets$: Observable<AvailableWidgets>;
    public availableFeatures$: Observable<AvailableFeatures>;
    public from: string;
    public attendanceApproversForm: UntypedFormGroup;
    public permissions: Array<string>;
    public watchingMyself = false;
    public collapseForm = true;
    public autoApprove = false;

    private _subscription: Subscription;

    public constructor(
        private _employeeService: EmployeeService,
        private _companyService: CompanyService,
        private _policyService: PolicyService,
        private _route: ActivatedRoute,
        private _formBuilder: UntypedFormBuilder,
        private _authService: AuthenticationService,
        private _cdr: ChangeDetectorRef,
        private _modalService: NgbModal,
        private _subscriptionService: SubscriptionService,
    ) { }

    public ngOnDestroy(): void {
        this._subscription?.unsubscribe();
    }

    public ngOnInit(): void {
        this.availableWidgets$ = this._companyService.availableWidgets$;
        this.availableFeatures$ = this._subscriptionService.availableFeatures$;

        this.permissions = this._authService?.permissions?.map(perm => perm.name);

        this.attendanceApproversForm = this._formBuilder.group({
            auto_approve: [false],
            approvers: [null],
            method: [null]
        });
        this.employeeID = parseInt(this._route.parent.snapshot.paramMap.get('id'), 10);

        this._subscription = this._route.parent.params.subscribe(params => {
            this.employeeID = parseInt(params.id, 10);
            const loggedEmployeeID = this._authService?.employee?.employee_ID;
            this.watchingMyself = loggedEmployeeID === this.employeeID;

            this.from = moment().startOf('month').format('YYYY-MM-DD');

            this._cdr.detectChanges();

            this.refreshEmployee();
            this.refreshApprovers();
            this.getAdjustments();
            this.getPolicy();
        });
    }

    public refreshEmployee(): void {
        this.employee$ = this._employeeService.getEmployeeInfoByEmployeeID(this.employeeID, 'extend')
            .pipe(
                shareReplay()
            );
    }

    public onSubmit(): void {
        const values = this.attendanceApproversForm.value;
        let approvers = values.approvers;
        let method = values.method ? 'APPEND' : 'OVERWRITE';

        // if form is collapsed, set default values to reset settings
        if (this.collapseForm) {
            method = 'APPEND';
            approvers = null;
        }

        this._employeeService.setApprovers(this.employeeID, approvers, method, values.auto_approve)
            .then(() => this.refreshApprovers());
    }

    public refeshData(): void {
        this.refreshData.next();
    }

    public refreshApprovers(): void {
        this.collapseForm = true;
        this.approvers$ = this._employeeService.getAttendaceApproversByEmployee(this.employeeID)
            .pipe(
                tap(approvers => {
                    // get default leader when in Append mode
                    this.defaultAppend = approvers.active.filter(e => !approvers.selected.some((e2) => e.employee_ID === e2.employee_ID));
                    this.approversResult = approvers.active;
                    this.selectedEmployees = approvers.selected;
                    this.attendanceApproversForm.patchValue({
                        method: approvers.method !== 'OVERWRITE',
                        approvers: approvers.selected.map(approver => approver.employee_ID),
                        auto_approve: approvers.auto_approve
                    });
                    this.autoApprove = approvers.auto_approve;
                    if (this.selectedEmployees.length > 0) {
                        this.collapseForm = false;
                    }
                }),
                shareReplay()
            );
    }

    public onApproversChange(event: any): void {
        const method = this.attendanceApproversForm.value.method ? 'APPEND' : 'OVERWRITE';
        // if select event, refresh selected employees
        if (event !== null) {
            this.selectedEmployees = event;
        }
        this.approversResult = [...this.selectedEmployees];

        if (method === 'APPEND') {
            this.defaultAppend.forEach(em => {
                if (this.approversResult.findIndex(em2 => em.employee_ID === em2.employee_ID) === -1) {
                    this.approversResult.push(em);
                }
            });
        } else {
            // chceme odebrat lidi, kteri jsou v default append, ale nejsou v selected employees
            const peopleToRemove = [];
            this.defaultAppend.forEach(e => {
                // pridat default append do people to remove, pokud neni v selected employees
                if (!this.selectedEmployees.some((e2) => e.employee_ID === e2.employee_ID)) {
                    peopleToRemove.push(e);
                }
            });
            /// kdyz je true, zachova v poli
            this.approversResult = this.approversResult.filter(e => !peopleToRemove.some((e2) => e.employee_ID === e2.employee_ID));
        }
    }

    public getAdjustments(): void {
        this.employeeAdjustments$ = this._policyService.getAllPolicyAdjustementsByEmployeeID(this.employeeID);
    }

    public setFormCollapse(): void {
        this.collapseForm = !this.collapseForm;
    }

    public setAutoApprove(): void {
        this.autoApprove = !this.autoApprove;
        this.attendanceApproversForm.patchValue({
            auto_approve: this.autoApprove
        });
    }

    public getPolicy(): void {
        this.employeePolicy$ = this._policyService.getPolicyByEmployeeID(this.employeeID)
            .pipe(
                map((policies: Array<CompanyEmployeePolicy>) => {
                    if (policies.length) {
                        return policies[0];
                    } else {
                        return {} as CompanyEmployeePolicy;
                    }
                })
            );
    }

    public checkPermission(name: string, action = 'view'): boolean {
        if (action === 'view') {
            return (this.watchingMyself || this.permissions.includes(name + '.view') || this.permissions.includes(name + '.viewStructure'));
        }
        if (action === 'edit') {
            return (this.watchingMyself || this.permissions.includes(name + '.edit') || this.permissions.includes(name + '.editStructure'));
        }
    }

    public openEmployeeInCompanyFromModal(employee: Employee): void {
        const modalRef = this._modalService.open(ChangeEmployeeInCompanyFromModalComponent, {centered: true});

        modalRef.componentInstance.employee = employee;

        modalRef.result
            .then(
                () => this.refreshEmployee(),
                () => {}
            );
    }
}
