import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MsalService } from '@azure/msal-angular';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Integration } from 'src/app/core/models/Integration';
import { IntegrationService } from 'src/app/core/services/integration.service';
import { IntegrationConfig } from '../../../../core/models/IntegrationConfig';
import { AuthenticationService } from '../../../../core/services/authentication.service';
import { EmployeeDatetimeType } from '../../../models/EmployeeDatetimeType';
import { BranchStoreService } from '../../../services/branch-store.service';
import { DatetimesTypesStoreService } from '../../../services/datetimes-types-store.service';

@Component({
    selector: 'app-integration-modal',
    templateUrl: './integration-modal.component.html',
    styleUrls: ['./integration-modal.component.css']
})
export class IntegrationModal implements OnInit, OnDestroy {
    public integration: Integration;
    public microsoftAzureForm: UntypedFormGroup;
    public googleLoginForm: UntypedFormGroup;
    public appleLoginForm: UntypedFormGroup;
    public calendarExportForm: UntypedFormGroup;
    public microsoftOneDriveForm: UntypedFormGroup;
    public microsoftTodoForm: UntypedFormGroup;
    public microsoftTeamsForm: UntypedFormGroup;
    public abraForm: UntypedFormGroup;
    public kioskModeForm: UntypedFormGroup;
    public giritonForm: UntypedFormGroup;
    public usedForm: UntypedFormGroup;
    public signiForm: UntypedFormGroup;
    public slackForm: UntypedFormGroup;
    public integrationConfig: IntegrationConfig;
    public integrationConfig$: Observable<IntegrationConfig>;
    public datetimeTypes$: Observable<EmployeeDatetimeType[]>;
    public isCollapsed: boolean;
    public isSecondSectionCollapsed: boolean;
    public msalLoading = false;
    public submitted = false;
    public loggedUser = this._authService.loggedUser;
    public slackCode: string;
    public slackStep = null;
    public signiSelectableItems = {
        signing_order: [
            {value: 'one_at_a_time', label: this._translateService.instant('integrations.signi.one_at_a_time')},
            {value: 'all_at_once', label: this._translateService.instant('integrations.signi.all_at_once')},
            {value: 'proposers_before_counterparties', label: this._translateService.instant('integrations.signi.proposers_before_counterparties')},
        ],
        proposer: [
            {value: 'owner', label: this._translateService.instant('integrations.signi.owner')},
            {value: 'responsible', label: this._translateService.instant('integrations.signi.responsible')},
        ],
        owner_sign_type: [
            {value: 'sign', label: this._translateService.instant('integrations.signi.sign')},
            {value: 'sign_certificate', label: this._translateService.instant('integrations.signi.sign_certificate')},
            {value: 'sign_bank_id_sign', label: this._translateService.instant('integrations.signi.sign_bank_id_sign')},
        ],
        responsible_sign_type: [
            {value: 'sign', label: this._translateService.instant('integrations.signi.sign')},
            {value: 'sign_certificate', label: this._translateService.instant('integrations.signi.sign_certificate')},
            {value: 'sign_bank_id_sign', label: this._translateService.instant('integrations.signi.sign_bank_id_sign')},
        ]
    };
    public calendarExportSelectableItems = {
        alert_type: [
            {value: 'none', label: this._translateService.instant('integrations.calendar-export.alert_type-none')},
            {value: 'day_before', label: this._translateService.instant('integrations.calendar-export.alert_type-day_before')},
            {value: 'week_day_before', label: this._translateService.instant('integrations.calendar-export.alert_type-week_day_before')},
        ]
    };
    public readonly Array = Array;

    private _msalSubscription: Subscription;

    constructor(
        private _fb: UntypedFormBuilder,
        private _integrationService: IntegrationService,
        private _translateService: TranslateService,
        private _msalService: MsalService,
        private _authService: AuthenticationService,
        public branchStoreService: BranchStoreService,
        public activeModal: NgbActiveModal,
        private _datetimeTypesStore: DatetimesTypesStoreService,
        protected _changeDetectorRef: ChangeDetectorRef
    ) { }

    public get apiKeys(): FormArray {
        return this.giritonForm?.get('config.api_keys') as FormArray;
    }

    public get kioskCodeLength(): { [formControlName: string]: AbstractControl; } {
        return this.kioskModeForm.get('config.code_length').errors;
    }

    public apiKeyControl(index: number): AbstractControl {
        return this.apiKeys.get(index.toString() + '.api_key');
    }

    public ngOnDestroy(): void {
        this._msalSubscription?.unsubscribe();
    }

    public ngOnInit(): void {
        this.init();
    }

    public setFormData(): void {
        switch (this.integrationConfig.key) {
            case 'microsoft-0365-login':
                this.microsoftAzureForm.patchValue({
                    is_active: this.integrationConfig?.is_active,
                    config: {
                        login_allowed_for_set_tenant_id_only: this.integrationConfig?.config?.login_allowed_for_set_tenant_id_only,
                        tenant_ID: this.integrationConfig?.config?.tenant_ID,
                        login_forced: this.integrationConfig?.config?.login_forced
                    }
                });
                this.usedForm = this.microsoftAzureForm;
                break;
            case 'google-login':
                this.googleLoginForm.patchValue({
                    is_active: this.integrationConfig?.is_active,
                    config: {
                        login_forced: this.integrationConfig?.config?.login_forced
                    }
                });
                this.usedForm = this.googleLoginForm;
                break;
            case 'apple-login':
                this.appleLoginForm.patchValue({
                    is_active: this.integrationConfig?.is_active
                });
                this.usedForm = this.appleLoginForm;
                break;
            case 'calendar-export':
                this.calendarExportForm.patchValue({
                    is_active: this.integrationConfig?.is_active,
                    config: {
                        alert_type: this.integrationConfig?.config?.alert_type,
                        events_active: this.integrationConfig?.config?.events_active === '1',
                        absence_active: this.integrationConfig?.config?.absence_active === '1',
                        events_range: this.integrationConfig?.config?.events_range ?? 7,
                        absence_range: this.integrationConfig?.config?.absence_range ?? 7
                    }
                });
                this.usedForm = this.calendarExportForm;
                break;
            case 'microsoft-one-drive':
                this.microsoftOneDriveForm.patchValue({
                    is_active: this.integrationConfig?.is_active,
                    config: {
                        sync_unsigned_signable: this.integrationConfig?.config?.sync_unsigned_signable,
                        sync_old_files: this.integrationConfig?.config?.sync_old_files ?? true,
                        upload: this.integrationConfig?.config?.upload,
                    }
                });
                this.usedForm = this.microsoftOneDriveForm;

                if (this.integrationConfig?.config?.sync_old_files) {
                    this.usedForm.get('config.sync_old_files').disable();
                }
                break;
            case 'giriton':
                this.giritonForm.patchValue({
                    is_active: this.integrationConfig?.is_active
                });
                const keys = Object.entries(this.integrationConfig?.config?.api_keys ?? []);

                if (keys?.length > 0) {
                    this.apiKeys.clear();
                }

                for (const [key, value] of keys) {
                    this.addNewApiKey(key, value?.company_branch_ID);
                }

                this.usedForm = this.giritonForm;
                break;
            case 'microsoft-todo':
                this.microsoftTodoForm.patchValue({
                    api_key: this.integrationConfig?.config?.api_key
                });
                this.usedForm = this.microsoftTodoForm;
                break;
            case 'microsoft-teams':
                this.microsoftTeamsForm.patchValue({
                    is_active: this.integrationConfig?.is_active
                });
                this.usedForm = this.microsoftTeamsForm;
                break;
            case 'kiosk-mode':
                this.kioskModeForm.patchValue({
                    is_active: this.integrationConfig?.is_active,
                    config: {
                        simple: this.integrationConfig?.config?.simple ?? false,
                        code_length: this.integrationConfig?.config?.code_length ?? 6,
                        datetime_types: this.integrationConfig?.config?.datetime_types
                    }
                });
                this.usedForm = this.kioskModeForm;
                break;
            case 'signi':
                this.signiForm.patchValue({
                    is_active: this.integrationConfig?.is_active,
                    config: {
                        access_token: this.integrationConfig?.config?.access_token,
                        signing_order: this.integrationConfig?.config?.signing_order,
                        proposer: this.integrationConfig?.config?.proposer,
                        owner_sign_type: this.integrationConfig?.config?.owner_sign_type,
                        responsible_sign_type: this.integrationConfig?.config?.responsible_sign_type,
                    }
                });
                this.usedForm = this.signiForm;
                break;
            case 'slack':
                this.slackForm.patchValue({
                    is_active: this.integrationConfig?.is_active
                });
                this.usedForm = this.slackForm;
                break;
            case 'abra':
                this.abraForm.patchValue({
                    is_active: this.integrationConfig?.is_active,
                    config: {
                        server: this.integrationConfig?.config?.server,
                        port: this.integrationConfig?.config?.port,
                        company: this.integrationConfig?.config?.company,
                        api_user: this.integrationConfig?.config?.api_user,
                        api_password: this.integrationConfig?.config?.api_password,
                    }
                });
                this.usedForm = this.abraForm;
                break;
            default:
                break;
        }
        this._changeDetectorRef.detectChanges();
    }

    public setCollapse(str: string, form?: UntypedFormGroup): void {
        if (str === 'is_active' && form) {
            form.value.is_active = !form.value.is_active;
            this.isCollapsed = form.value.is_active;
        }

        if (str === 'events_active' && form) {
            form.value.config.events_active = !form.value.config.events_active;
        }

        if (str === 'absence_active' && form) {
            form.value.config.absence_active = !form.value.config.absence_active;
        }

        if (str === 'tenant_ID') {
            this.microsoftAzureForm.value.config.login_allowed_for_set_tenant_id_only = !this.microsoftAzureForm.value.config.login_allowed_for_set_tenant_id_only;
            this.isSecondSectionCollapsed = this.microsoftAzureForm.value.config.login_allowed_for_set_tenant_id_only;
        }
        this._changeDetectorRef.detectChanges();
    }

    public init(): void {
        this.microsoftAzureForm = this._fb.group({
            is_active: [false],
            config: this._fb.group({
                login_allowed_for_set_tenant_id_only: [false],
                tenant_ID: [null],
                login_forced: [false]
            })
        });

        this.microsoftOneDriveForm = this._fb.group({
            is_active: [false],
            config: this._fb.group({
                sync_unsigned_signable: [false],
                sync_old_files: [true],
                upload: this._fb.group({
                    employee_file: [true],
                    internal_document: [false],
                    payroll: [false],
                    property_file: [false]
                })
            })
        });

        this.giritonForm = this._fb.group({
            is_active: [false],
            config: this._fb.group({
                api_keys: this._fb.array([
                    this._fb.group({
                        api_key: [null, Validators.required],
                        company_branch_ID: [null]
                    }),
                ])
            })
        });

        this.microsoftTodoForm = this._fb.group({
            is_active: [false],
            config: {
                api_key: [null]
            }
        });

        this.kioskModeForm = this._fb.group({
            is_active: [false],
            config: this._fb.group({
                simple: [false],
                code_length: [6, [Validators.min(4), Validators.max(16)]],
                datetime_types: [null]
            })
        });

        this.googleLoginForm = this._fb.group({
            is_active: [false],
            config: this._fb.group({
                login_forced: [false]
            })
        });

        this.appleLoginForm = this._fb.group({
            is_active: [false],
        });

        this.microsoftTeamsForm = this._fb.group({
            is_active: [false],
        });

        this.slackForm = this._fb.group({
            is_active: [false],
        });

        this.signiForm = this._fb.group({
            is_active: [false],
            config: this._fb.group({
                access_token: [null],
                signing_order: [null],
                proposer: [null],
                owner_sign_type: [null],
                responsible_sign_type: [null]
            })
        });

        this.calendarExportForm = this._fb.group({
            is_active: [false],
            config: this._fb.group({
                events_active: [false],
                events_range: [7, [Validators.min(1), Validators.max(365)]],
                absence_active: [false],
                absence_range: [7, [Validators.min(1), Validators.max(365)]],
                alert_type: [null],
            })
        });

        this.abraForm = this._fb.group({
            is_active: [false],
            config: this._fb.group({
                server: [null],
                port: [5434],
                company: [null],
                api_user: [null],
                api_password: [null]
            })
        });

        this.integrationConfig$ = this._integrationService.getIntegrationConfig(this.integration.key)
            .pipe(
                tap((config) => {
                    this.integrationConfig = config;
                    this.isCollapsed = this.integrationConfig?.is_active;
                    this.isSecondSectionCollapsed = this.integrationConfig?.config?.login_allowed_for_set_tenant_id_only;
                    this.setFormData();
                    this.processSlackSync();
                }));

        this.datetimeTypes$ = this._datetimeTypesStore.datetimeTypes$
            .pipe(map((datetimeTypes) => datetimeTypes.filter((datetimeType) => datetimeType.internal_type !== 'AT_WORK')));
    }

    public processSlackSync(): void {
        if (this.slackCode && this.integrationConfig.key === 'slack') {
            this.slackStep = 1;

            this._integrationService.generateSlackAccessToken(this.slackCode)
                .then(() => {
                    this.slackStep = 2;
                    this._integrationService.syncSlackUsers()
                        .then(() => {
                            this.slackStep = 3;
                            this.slackCode = null;
                        }, () => { this.slackStep = null; });
                }, () => { this.slackStep = null; });
        }
    }

    public onSubmit(action = '', closeModal = false): void {
        this.submitted = true;

        if (this.usedForm?.invalid) {
            return;
        }

        if (!this.usedForm) {
            this.activeModal.dismiss();
            return;
        }

        if (this.integrationConfig.key === 'giriton') {
            const apiKeys = {};
            this.apiKeys.controls.forEach((control) => {
                apiKeys[control.value.api_key] = {company_branch_ID: control.value.company_branch_ID};
            });
            this.usedForm.value.config.api_keys = apiKeys;
        }

        if (action === 'unpair') {
            this.usedForm.addControl('msal', this._fb.control({}));
            this.usedForm.patchValue({
                is_active: false,
                msal: {
                    tenant_ID: '',
                    user_ID: '',
                    name: '',
                    username: '',
                }
            });
        }

        const formValue = {...this.usedForm.value};

        if (this.integration.key === 'calendar-export') {
            formValue.config = {
                ...formValue.config,
                events_active: formValue.config.events_active ? '1' : '0',
                absence_active: formValue.config.absence_active ? '1' : '0'
            };
        }

        this._integrationService.saveIntegration(formValue, this.integrationConfig.key)
            .then(() => {
                this.msalLoading = false;
                if (closeModal) {
                    this.activeModal.close('close');
                } else {
                    this.init();
                }
            });
    }

    public openMsalLogin(): void {
        this.msalLoading = true;
        this._msalSubscription = this._msalService.loginPopup({
            scopes: ['offline_access', 'files.readwrite', 'files.readwrite.all'],
            prompt: 'consent',
            extraQueryParameters: {
                ui_locales: this._translateService.currentLang
            }
        })
            .subscribe(response => {
                this.usedForm.addControl('msal', this._fb.control({}));
                this.usedForm.patchValue({
                    msal: {
                        tenant_ID: response?.account?.tenantId,
                        user_ID: response?.account?.localAccountId,
                        name: response?.account?.name,
                        username: response?.account?.username,
                    }
                });

                this.onSubmit('', false);
            }, () => {
                this.msalLoading = false;
            });
    }

    public addNewApiKey(key = null, branchID = null): void {
        this.apiKeys.push(this._fb.group({
            api_key: [key, Validators.required],
            company_branch_ID: [branchID]
        }));
    }

    public removeApiKey(index: number): void {
        this.apiKeys.removeAt(index);
    }
}
