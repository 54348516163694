    <div class="card shadow">
      <div class="card-body">
        <ng-container>
          <app-upload
                  (addedFile)="addedFile($event)"
                  (success)="success()"
                  [allowedExtensions]="'.xlsx, .xls'"
                  [allowTakingPicture]="false"
                  [clear$]="clearFileQueue$"
                  [process$]="processFileQueue$"
                  [showPreviewContainer]="false"
                  class="mb-4"
          ></app-upload>
        </ng-container>
      </div>
    </div>
