<div class="modal-content">
	<div class="modal-header">
        <p class="modal-title font-16 text-dark">{{'employees_policies_adjustements.page_title_edit' | translate}}</p>
		<button (click)="activeModal.dismiss()" type="button" class="close" aria-label="Close">
			<span class="font-24 text-muted" aria-hidden="true"><i class="mdi mdi-close"></i></span>
		  </button>
	</div>
	<div class="modal-body">
		<div class="row">
			<div class="col-md-12">
				<div class="card mb-0 shadow-none">
					<div class="card-body p-0">
                        <form [formGroup]="adjustmentEditForm" (ngSubmit)="onSubmit()">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label class="control-label">{{'employees_policies_adjustements.holiday_days' | translate}}</label>
                                        <input formControlName="holiday_hours" type="text" class="form-control" name="holiday_hours">
                                        <div *ngIf="submitted && f.holiday_hours.errors">
											<p *ngIf="f.holiday_hours.errors.required" class="text-danger validation-text">
												{{ 'angular_validation.field' | translate }} {{'employees_policies_adjustements.holiday_days' | translate}}
												{{ 'angular_validation.required' | translate }}
											</p>
										</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label class="control-label">{{'employees_policies_adjustements.sick_days' | translate}}</label>
                                        <input formControlName="sick_day_hours" type="text" class="form-control" name="sick_day_hours">
                                        <div *ngIf="submitted && f.sick_day_hours.errors">
											<p *ngIf="f.sick_day_hours.errors.required" class="text-danger validation-text">
												{{ 'angular_validation.field' | translate }} {{'employees_policies_adjustements.sick_days' | translate}}
												{{ 'angular_validation.required' | translate }}
											</p>
										</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label class="control-label">{{'employees_policies_adjustements.home_office_days' | translate}}</label>
                                        <input formControlName="home_office_hours" type="text" class="form-control" name="home_office_hours">
                                        <div *ngIf="submitted && f.home_office_hours.errors">
											<p *ngIf="f.home_office_hours.errors.required" class="text-danger validation-text">
												{{ 'angular_validation.field' | translate }} {{'employees_policies_adjustements.home_office_days' | translate}}
												{{ 'angular_validation.required' | translate }}
											</p>
										</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label class="control-label">{{'employees_policies_adjustements.holiday_transfer_days' | translate}}</label>
                                        <input formControlName="holiday_transfer_hours" type="text" class="form-control" name="holiday_transfer_hours">
                                        <div *ngIf="submitted && f.holiday_transfer_hours.errors">
											<p *ngIf="f.holiday_transfer_hours.errors.required" class="text-danger validation-text">
												{{ 'angular_validation.field' | translate }} {{'employees_policies_adjustements.holiday_transfer_days' | translate}}
												{{ 'angular_validation.required' | translate }}
											</p>
										</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group">
										<label class="control-label">{{ 'employees_policies_adjustements.effective_from' | translate }}</label>
										<input formControlName="effective_from" type="text" mwlFlatpickr [disableMobile]="true" allowInput="false"
                                        altInput="true"
										altFormat="d.m.Y"
										dateFormat="Y-m-d"
										[locale]="locale$ | async"
										name="effective_from" class="form-control">
										<div *ngIf="submitted && f.effective_from.errors">
											<p *ngIf="f.effective_from.errors.required" class="text-danger validation-text">
												{{ 'angular_validation.field' | translate }} {{'employees_policies_adjustements.effective_from' | translate}}
												{{ 'angular_validation.required' | translate }}
											</p>
										</div>
									</div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label class="control-label">{{ 'employees_policies_adjustements.description' | translate }}</label>
                                        <input [ngxSummernote]="summernoteHelper.getSummernoteConfig()" formControlName="description" type="text" name="description">
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-6 text-left">
                                    <button class="btn btn-success" type="submit">{{'employees_policies_adjustements.action_save' | translate}}</button>
                                </div>
                                <div class="col-6 text-right my-auto">
                                    <u *ngIf="companyEmployeeAdjustementID" (click)="deleteAdjustement()" class="text-muted">{{'employees_policies_adjustements.action_delete'  | translate}}</u>
                                </div>
                            </div>
                        </form>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
