import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable } from 'rxjs';
import { ApiHelper } from 'src/app/shared/common/ApiHelper';
import { SwalHelper } from 'src/app/shared/common/SwalHelper';
import { ApiResponse } from 'src/app/shared/models/ApiResponse';
import { CustomerAddress } from 'src/app/crm/models/CustomerAddress';
import { EmployeeAddress } from '../models/EmployeeAddress';

@Injectable({
    providedIn: 'root'
})
export class AddressService {
    public constructor(
        private _apiHelper: ApiHelper,
        private _http: HttpClient,
        private _loaderService: NgxUiLoaderService,
        private _swalHelper: SwalHelper
    ) { }

    public deleteAddress(employeeID: number, employeeAddressID: number): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            const deleteConfirmed = await this._swalHelper.showConfirmDeleteDialog();

            if (deleteConfirmed) {
                this._http.delete<ApiResponse>('/api/employees/' + employeeID + '/addresses/' + employeeAddressID)
                    .subscribe(
                        response => {
                            this._loaderService.stopLoader('master');

                            this._apiHelper.handleSuccessResponse(response);

                            resolve(true);
                        },
                        error => {
                            this._loaderService.stopLoader('master');

                            this._apiHelper.handleErrorResponse(error);

                            reject('error');
                        }
                    );
            } else {
                reject('cancelled');
            }
        });
    }

    public deleteCustomerAddress(customerID: number, customerAddressID: number): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            const deleteConfirmed = await this._swalHelper.showConfirmDeleteDialog();

            if (deleteConfirmed) {
                this._http.delete<ApiResponse>('/api/customers/' + customerID + '/addresses/' + customerAddressID)
                    .subscribe(
                        response => {
                            this._loaderService.stopLoader('master');

                            this._apiHelper.handleSuccessResponse(response);

                            resolve(true);
                        },
                        error => {
                            this._loaderService.stopLoader('master');

                            this._apiHelper.handleErrorResponse(error);

                            reject('error');
                        }
                    );
            } else {
                reject('cancelled');
            }
        });
    }

    public getAddressForCustomerByID(customerID: number, customerAddressID: number): Observable<CustomerAddress> {
        return this._http.get<CustomerAddress>('/api/customers/' + customerID + '/addresses/' + customerAddressID);
    }

    public getAllAddressesForCustomer(customerID: number): Observable<Array<CustomerAddress>> {
        return this._http.get<Array<CustomerAddress>>('/api/customers/' + customerID + '/addresses');
    }

    public getAddressForEmployeeByID(employeeID: number, employeeAddressID: number): Observable<EmployeeAddress> {
        return this._http.get<EmployeeAddress>('/api/employees/' + employeeID + '/addresses/' + employeeAddressID);
    }

    public getAllAddressesForEmployee(employeeID: number): Observable<Array<EmployeeAddress>> {
        return this._http.get<Array<EmployeeAddress>>('/api/employees/' + employeeID + '/addresses');
    }

    public saveAddress(form: EmployeeAddress, employeeID: number, employeeAddressID?: number): Promise<'done'> {
        this._loaderService.startLoader('master');

        return new Promise((resolve, reject) => {
            let uri = `/api/employees/${employeeID}/addresses`;

            if (employeeAddressID) {
                uri += `/${employeeAddressID}`;
            }

            this._http.post<ApiResponse>(uri, form)
                .subscribe(
                    response => {
                        this._loaderService.stopLoader('master');

                        this._apiHelper.handleSuccessResponse(response);

                        resolve('done');
                    },
                    error => {
                        this._loaderService.stopLoader('master');

                        this._apiHelper.handleErrorResponse(error);

                        reject('error');
                    }
                );
        });
    }

    public saveCustomerAddress(form: CustomerAddress, customerID: number, customerAddressID?: number): Promise<'done'> {
        this._loaderService.startLoader('master');

        return new Promise((resolve, reject) => {
            let uri = `/api/customers/${customerID}/addresses`;

            if (customerAddressID) {
                uri += `/${customerAddressID}`;
            }

            this._http.post<ApiResponse>(uri, form)
                .subscribe(
                    response => {
                        this._loaderService.stopLoader('master');

                        this._apiHelper.handleSuccessResponse(response);

                        resolve('done');
                    },
                    error => {
                        this._loaderService.stopLoader('master');

                        this._apiHelper.handleErrorResponse(error);

                        reject('error');
                    }
                );
        });
    }
}
