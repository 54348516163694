import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, NgZone, OnDestroy, OnInit, Renderer2, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import { CalendarApi, CalendarOptions, DatesSetArg } from '@fullcalendar/core';
import csLocale from '@fullcalendar/core/locales/cs';
import enLocale from '@fullcalendar/core/locales/en-gb';
import skLocale from '@fullcalendar/core/locales/sk';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { AttendanceService } from 'src/app/employee/services/attendance.service';
import { FlatpickrHelper } from 'src/app/shared/common/FlatpickrHelper';
import { AuthenticationService } from '../../../../core/services/authentication.service';
import { CreateEditEmployeeDatetimeModalComponent } from '../../../../employee/components/partials/create-edit-employee-datetime-modal/create-edit-employee-datetime-modal.component';
import { Employee } from '../../../../employee/models/Employee';
import { EmployeeDateTime } from '../../../../employee/models/EmployeeDateTime';
import { EmployeeService } from '../../../../employee/services/employee.service';
import { EmployeeDatetimeType } from '../../../../setting/models/EmployeeDatetimeType';
import { DatetimesTypesStoreService } from '../../../../setting/services/datetimes-types-store.service';
import { UserService } from '../../../../setting/services/user.service';
import { FatherOfListComponent, Sort } from '../../../../ui';
import { getOS, localStorageSafe } from '../../../functions';
import { FullCalendarEvent } from '../../../models/FullCalendarEvent';

@Component({
    selector: 'app-full-calendar',
    templateUrl: './full-calendar.component.html',
    styleUrls: ['./full-calendar.component.css']
})
export class FullCalendarComponent extends FatherOfListComponent<any> implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('filterFormOptions', {static: false}) public filterFormOptions: TemplateRef<ElementRef>;
    @ViewChild('calendarComponent', {static: false}) public calendarComponent: any;

    @Input() public isShift = false;
    @Input() public isStatic = false;

    public lsName = 'fullCalendarAttendanceShifts';
    public sort: Sort<any>;
    public os = getOS();
    public from: string;
    public to: string;
    public isLoading = false;
    public calendarApi: CalendarApi;
    public events: FullCalendarEvent[];
    public filterForm: UntypedFormGroup;
    public datetimeTypes$: Observable<EmployeeDatetimeType[]>;
    public employees$: Observable<Employee[]>;
    public calendarOptions: CalendarOptions = {
        initialView: 'timeGridWeek',
        plugins: [dayGridPlugin, bootstrap5Plugin, interactionPlugin, timeGridPlugin],
        headerToolbar: {start: 'prev', center: 'today dayGridMonth timeGridWeek', end: 'next'},
        locales: [enLocale, csLocale, skLocale],
        locale: this.translateService.currentLang,
        themeSystem: 'standard',
        buttonIcons: {prev: 'mdi mdi mdi-arrow-left', next: 'mdi mdi mdi-arrow-right'},
        selectable: true,
        datesSet: (info: DatesSetArg) => {
            // when dates of calendar are changed
            this.from = info.startStr.substring(0, 10);
            this.to = info.endStr.substring(0, 10);
            this.refreshEvents();
        },
        eventClick: (info) => {
            if (info.event._def.extendedProps.permissions.view) {
                const datetimeID = parseInt(info.event._def.extendedProps.employee_datetime_ID, 10);
                const employeeID = info.event._def.extendedProps.employee_ID;
                this.openDatetimeEditModal(info.event.id, employeeID, datetimeID);
            }
        },
        select: (info) => {
            let start = info.startStr;
            let end = info.endStr;
            if (info.allDay) {
                const day = parseInt(end.split('-')[2], 10);
                end = `${end.split('-')[0]}-${end.split('-')[1]}-${day - 1}`;
            } else {
                // remove time shift
                start = start.split('+')[0];
                end = end.split('+')[0];
            }
            this.openDatetimeEditModal(null, null, null, start, end, !info.allDay);
        },
        eventDrop: (info) => {
            let start = info.event.startStr;
            let end = info.event.endStr;
            if (info.event.allDay) {
                end = moment(info.event._instance.range.end).subtract(1, 'days').format('YYYY-MM-DD');
            } else {
                // remove time shift
                start = start.split('+')[0];
                end = end.split('+')[0];
            }

            // check if ctrl or cmd key is pressed, if so, duplicate event
            let isCreate = false;
            if ((info.jsEvent.metaKey && this.os === 'macos') || (info.jsEvent.ctrlKey && this.os === 'windows')) {
                isCreate = true;
            } else {
                // remove duplicated event when draggind and copying, because user released ctrl or cmd key before drop
                const duplicateEventToRemove = this.calendarApi.getEvents().find(ev => ev.extendedProps.employee_datetime_ID === info.event.extendedProps.employee_datetime_ID && ev.extendedProps.is_duplicate);
                if (duplicateEventToRemove) {
                    duplicateEventToRemove.remove();
                }
            }

            const datetimeToSave = {
                employee_ID: info.event._def.extendedProps.employee_ID,
                employee_datetime_ID: isCreate ? null : parseInt(info.event._def.extendedProps.employee_datetime_ID, 10),
                employee_datetime_type_ID: info.event._def.extendedProps.employee_datetime_type_ID,
                from: start,
                to: end,
                set_on: info.event.allDay ? 'SHIFT' : 'TIME',
            } as EmployeeDateTime;
            
            this._attendanceService.saveEmployeeDateTime(datetimeToSave, info.event._def.extendedProps.employee_ID, datetimeToSave.employee_datetime_ID, this.isShift ? 'SHIFT' : 'ATTENDANCE')
                .then((response) => {
                        if (isCreate) {
                            info.event.setExtendedProp('employee_datetime_ID', response.employee_datetime.employee_datetime_ID);
                        }
                    },
                    () => {}
                );
        },
        eventDragStart: (info) => {
            // if ctrl or cmd key is pressed, open copy clicked event
            if ((info.jsEvent.metaKey && this.os === 'macos') || (info.jsEvent.ctrlKey && this.os === 'windows')) {
                const duplicatedEvent = {...info.event.toPlainObject(), editable: true, extendedProps: {...info.event.extendedProps, is_duplicate: true}};
                this.calendarApi.addEvent(duplicatedEvent);
            }
        },
        eventDidMount(info) {
            /*if (info.event.extendedProps?.is_duplicate) {
                info.event.setProp('display', 'background');
                info.el.classList.add('full-calendar-duplicated-event');
            }*/
        }
    };

    private _filterFormModalRef: NgbModalRef;
    private _subscription: Subscription;

    public constructor(
        public fpHelper: FlatpickrHelper,
        public translateService: TranslateService,
        private _attendanceService: AttendanceService,
        private _fb: UntypedFormBuilder,
        private _modalService: NgbModal,
        private _router: Router,
        private _renderer: Renderer2,
        private _datetimeTypesStore: DatetimesTypesStoreService,
        private _employeeService: EmployeeService,
        protected _authService: AuthenticationService,
        protected _userService: UserService,
        protected _ngZone: NgZone,
        protected _cdr: ChangeDetectorRef
    ) {
        super(_ngZone, _cdr, _authService, _userService);
    }

    public ngOnDestroy(): void {
        this._subscription?.unsubscribe();
    }

    public ngOnInit(): void {
        this.filterForm = this._fb.group({
            datetime_type_IDs: [null],
            employee_IDs: [null],
        });

        this.employees$ = this._employeeService.getAllEmployees();
        this.datetimeTypes$ = this._datetimeTypesStore.datetimeTypes$
            .pipe(
                map(types => {
                    return types.filter(type => type.is_shift === this.isShift);
                }));

        this.filterForm[`defaultValue`] = this.filterForm.value;
        if (this._router.url.includes('attendance/chart')) {
            this.lsName = 'fullCalendarAttendance';
        }
        this.useLocalStorage(this.filterForm, this.lsName);
    }

    public ngAfterViewInit() {
        this.setButtonsStyle();
    }

    public setButtonsStyle(): void {
        const buttons = document.querySelectorAll('div.fc-toolbar button.fc-button');

        buttons.forEach(button => {
            this._renderer.removeClass(button, 'fc-button-primary');
            this._renderer.removeClass(button, 'fc-button');
            this._renderer.addClass(button, 'btn');
            this._renderer.addClass(button, 'btn-primary');
            if (button.classList.contains('fc-prev-button') || button.classList.contains('fc-next-button')) {
                this._renderer.removeClass(button, 'fc-prev-button');
                this._renderer.removeClass(button, 'fc-next-button');
                this._renderer.addClass(button, 'btn-icon');
                return;
            }
            button.setAttribute('style', 'min-width: 100px !important;');
        });
    }

    public openFilterFormOptions(): void {
        this._filterFormModalRef = this._modalService.open(this.filterFormOptions);
    }

    public refreshEvents(): void {
        const body = {
            start: this.from,
            end: this.to,
            datetime_type_IDs: this.filterForm.value.datetime_type_IDs,
            employee_IDs: this.filterForm.value.employee_IDs,
        };

        this.setLoading();
        this.setButtonsStyle();
        this._subscription = this._attendanceService.getEventsForFullCalendar(body, this.isShift ? 'SHIFT' : 'ATTENDANCE')
            .subscribe((events: FullCalendarEvent[]) => {
                events.forEach(event => {
                    if (event.extendedProps.permissions.view) {
                        event.editable = true;
                    }
                });
                this.events = events;
                if (!this.calendarApi) {
                    this.calendarApi = this.calendarComponent.getApi();
                }
                this.unsetLoading();
            });
    }

    public openDatetimeEditModal(eventID?: any, employeeID?: number, employeeDatetimeID?: number, start?: string, end?: string, timeSet = false): void {
        if (!employeeDatetimeID) {
            const modalRef = this._modalService.open(CreateEditEmployeeDatetimeModalComponent, {centered: true});

            modalRef.componentInstance.employeeID = employeeID;
            modalRef.componentInstance.start = start;
            modalRef.componentInstance.end = end;
            modalRef.componentInstance.isShift = this.isShift;
            modalRef.componentInstance.isPlan = true;
            modalRef.componentInstance.presetTime = timeSet;

            modalRef.result
                .then(
                    () => {
                        this._attendanceService.refreshTimestamps$.next();
                        this.refreshEvents();
                    }, () => {}
                );
        } else {
            const modalRef = this._modalService.open(CreateEditEmployeeDatetimeModalComponent, {centered: true});
            modalRef.componentInstance.employeeID = employeeID;
            modalRef.componentInstance.isShift = this.isShift;
            modalRef.componentInstance.isLoading = true;
            modalRef.componentInstance.isPlan = true;

            this._attendanceService.getEmployeeDateTimeByID(employeeID, employeeDatetimeID, 'extend')
                .subscribe(dateTime => {
                    modalRef.componentInstance.employeeDateTime = dateTime;
                    modalRef.componentInstance.init();
                    modalRef.componentInstance.isLoading = false;

                    modalRef.result
                        .then(
                            (result) => {
                                this._attendanceService.refreshTimestamps$.next();
                                if (result === 'cancel') {
                                    const clickedEvent = this.calendarApi.getEvents().find(ev => ev.extendedProps.employee_datetime_ID === employeeDatetimeID);
                                    clickedEvent.remove();
                                } else {
                                    this.refreshEvents();
                                }
                            },
                            () => {}
                        );
                });
        }
    }

    public switchType(type: string): void {
        const url = `/employee/attendance/${this.isShift ? 'shifts/chart' : 'chart'}?type=${type}`;
        void this._router.navigateByUrl(url);
        localStorageSafe.setItem(`${this.isShift ? 'shifts' : 'attendance'}_chart_type`, type);
    }

    public setLoading(): void {
        this.isLoading = true;
        this._cdr.detectChanges();
        const harness = document.querySelector('.fc-view-harness.fc-view-harness-active');
        if (harness) {
            this._renderer.addClass(harness, 'fc-harness-loading');
        }
    }

    public unsetLoading(): void {
        this.isLoading = false;
        this._cdr.detectChanges();
        const harness = document.querySelector('.fc-view-harness.fc-view-harness-active');
        if (harness) {
            this._renderer.removeClass(harness, 'fc-harness-loading');
        }
    }

    public onSubmit(): void {
        this._filterFormModalRef?.close();
        this.saveFormConfigToLocalStorage({...this.filterForm.value}, 'form');
        this.refreshEvents();
    }

    // only for inheritance
    protected _fetchListData(): void {
    }

    // only for inheritance
    public trackByFn(index: number, item: any): number | string {
        return undefined;
    }
}
