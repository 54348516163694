import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest, Observable, ReplaySubject, Subscription } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { Employee } from 'src/app/employee/models/Employee';
import { Salary } from 'src/app/employee/models/Salary';
import { SalaryService } from 'src/app/employee/services/salary.service';
import { Receivable } from 'src/app/receivable/state/receivable.model';
import { ReceivableService } from 'src/app/receivable/state/receivable.service';
import { Bonus } from '../../../../benefit-bonus/bonus/bonus.model';
import { BonusQuery } from '../../../../benefit-bonus/bonus/bonus.query';
import { BonusService } from '../../../../benefit-bonus/bonus/bonus.service';
import { AvailableWidgets } from '../../../../setting/models/AvailableWidgets';
import { CompanyService } from '../../../../setting/services/company.service';
import { SubscriptionService } from '../../../../setting/services/subscription.service';
import { InputVisibilityService } from '../../../../shared/services/InputVisibilityService';
import { AvailableFeatures } from '../../../models/AvailableFeatures';
import { RealWage } from '../../../models/RealWage';
import { CreateEditEmployeeBonusModalComponent } from '../../partials/create-edit-employee-bonus-modal/create-edit-employee-bonus-modal.component';
import { CreateEditEmployeeRealWageModalComponent } from '../../partials/create-edit-employee-real-wage-modal/create-edit-employee-real-wage-modal.component';
import { CreateEditEmployeeReceivableModalComponent } from '../../partials/create-edit-employee-receivable-modal/create-edit-employee-receivable-modal.component';

@Component({
    selector: 'app-employee-salaries-payrolls',
    templateUrl: './employee-salaries-payrolls.component.html',
    styleUrls: ['./employee-salaries-payrolls.component.css']
})
export class EmployeeSalariesPayrollsComponent implements OnInit, OnDestroy {
    @Input() public employee$: Observable<Employee>;

    public bonuses$: Observable<Array<Bonus>>;
    public canCreateOrEditReceivable$: Observable<boolean>;
    public canEditBonus$: Observable<boolean>;
    public canEditRealWage$: Observable<boolean>;
    public employeeID: number;
    public employeeReceivables$: Observable<Array<Receivable>>;
    public employeeSalaries$: Observable<Array<Salary>>;
    public employeeRealWages$: Observable<Array<RealWage>>;
    public availableWidgets$: Observable<AvailableWidgets>;
    public availableFeatures$: Observable<AvailableFeatures>;
    public permissions: Array<string>;
    public watchingMyself = false;

    public get hiddenInputs(): boolean {
        return this._inputVisibilityService.hiddenInputs;
    }

    private _fetchReceivables$ = new ReplaySubject<void>(1);
    private _fetchSalaries$ = new ReplaySubject<void>(1);
    private _fetchRealWages$ = new ReplaySubject<void>(1);
    private _subscription: Subscription;

    public constructor(
        private _authService: AuthenticationService,
        private _bonusQuery: BonusQuery,
        private _bonusService: BonusService,
        private _companyService: CompanyService,
        private _modalService: NgbModal,
        private _receivableService: ReceivableService,
        private _route: ActivatedRoute,
        private _salaryService: SalaryService,
        private _cdr: ChangeDetectorRef,
        private _inputVisibilityService: InputVisibilityService,
        private _subscriptionService: SubscriptionService
    ) { }

    public ngOnDestroy(): void {
        this._subscription?.unsubscribe();
    }

    public ngOnInit(): void {
        this.availableWidgets$ = this._companyService.availableWidgets$;
        this.availableFeatures$ = this._subscriptionService.availableFeatures$;
        this.permissions = this._authService?.permissions?.map(perm => perm.name);
        this.canEditBonus$ = this._authService.hasPermissionTo('employeeBonus.edit').pipe(map(permission => permission.can));
        this.canEditRealWage$ = this._authService.hasPermissionTo('employeeRealWage.edit').pipe(map(permission => permission.can));
        this.canCreateOrEditReceivable$ = combineLatest([
            this._authService.hasPermissionTo('employee.edit').pipe(map(permission => permission.can)),
            this._authService.hasPermissionTo('employee.editStructure').pipe(map(permission => permission.can)),
        ])
            .pipe(
                map(([canEditEmployee, canEditEmployeeStructure]) => canEditEmployee || canEditEmployeeStructure),
                shareReplay()
            );

        this._subscription = this._route.parent.params.subscribe(params => {
            this.employeeID = parseInt(params.id, 10);

            const loggedEmployeeID = this._authService?.employee?.employee_ID;
            this.watchingMyself = loggedEmployeeID === this.employeeID;
            this._cdr.detectChanges();

            this.employeeReceivables$ = this._fetchReceivables$
                .pipe(
                    switchMap(() => this._receivableService.getAllByEmployeeId(this.employeeID)),
                    shareReplay()
                );

            this.employeeSalaries$ = this._fetchSalaries$
                .pipe(
                    switchMap(() => this._salaryService.getAllSalariesByEmployeeID(this.employeeID)),
                    shareReplay()
                );

            this.employeeRealWages$ = this._fetchRealWages$
                .pipe(
                    switchMap(() => this._salaryService.getAllRealWagesByEmployeeID(this.employeeID)),
                    shareReplay()
                );

            if (this.checkPermission('employeeBonus')) {
                this._bonusService.get(this.employeeID)
                    .subscribe();

                this.bonuses$ = this._bonusQuery.selectAll({
                    filterBy: ({employee_ID}) => employee_ID === this.employeeID
                });
            }
        });

        this.refreshEmployeeReceivables();
        this.refreshEmployeeSalaries();
        this.refreshEmployeeRealWages();
    }

    public openReceivableEditModal(r: Receivable): void {
        const modalRef = this._modalService.open(CreateEditEmployeeReceivableModalComponent, {centered: true});

        modalRef.componentInstance.employeeID = this.employeeID;
        modalRef.componentInstance.receivable = r ? r : null;

        modalRef.result
            .then(
                () => this.refreshEmployeeReceivables(),
                () => { }
            );
    }

    public openBonusEditModal(bonus: Bonus): void {
        const modalRef = this._modalService.open(CreateEditEmployeeBonusModalComponent, {centered: true});

        modalRef.componentInstance.employeeID = this.employeeID;
        modalRef.componentInstance.bonus = bonus;
    }

    public openRealWageModal(realWage: RealWage): void {
        const modalRef = this._modalService.open(CreateEditEmployeeRealWageModalComponent, {centered: true});

        modalRef.componentInstance.employeeID = this.employeeID;
        modalRef.componentInstance.realWage = realWage;

        modalRef.result.then(
            () => this.refreshEmployeeRealWages(),
            () => { }
        );
    }

    public refreshEmployeeReceivables(): void {
        this._fetchReceivables$.next();
    }

    public refreshEmployeeSalaries(): void {
        this._fetchSalaries$.next();
    }

    public refreshEmployeeRealWages(): void {
        this._fetchRealWages$.next();
    }

    public checkPermission(name: string): boolean {
        return (this.watchingMyself || this.permissions.includes(name + '.view') || this.permissions.includes(name + '.viewStructure'));
    }

    public checkRealWagePermission(): boolean {
        return (this.watchingMyself && this.permissions.includes('employeeRealWage.viewOwn')) || this.permissions.includes('employeeRealWage.view') || this.permissions.includes('employeeRealWage.viewStructure');
    }

    public trackByBonus(index: number, bonus: Bonus): number | string {
        return bonus.employee_bonus_ID;
    }
}
