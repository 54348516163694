import { Component, Input, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { ApiHelper } from '../../../common/ApiHelper';
import { localStorageSafe } from '../../../functions';

@Component({
    selector: 'app-export',
    templateUrl: './export.component.html',
    styleUrls: ['./export.component.css']
})
export class ExportComponent implements OnDestroy {
    @Input() public stream$: Observable<any>;

    private _streamSubscription: Subscription;

    public constructor(private _apiHelper: ApiHelper) { }

    public ngOnDestroy(): void {
        this._streamSubscription?.unsubscribe();
    }

    public exportData(extension = 'csv'): void {
        localStorageSafe.setItem('export_extension', extension);
        this._apiHelper.handleLoading();
        this._streamSubscription = this.stream$.subscribe(() => this._apiHelper.stopLoading());
    }
}
