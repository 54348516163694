import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { DocumentModule } from '../document/document.module';
import { EmployeeModule } from '../employee/employee.module';
import { ImportModule } from '../import/import.module';
import { PolicyModule } from '../policy/policy.module';
import { SharedModule } from '../shared/shared.module';
import { UIButtonModule, UIDataTableModule, UIFileUploadModule, UIItemsPerPageSelectModule, UIPaginationModule, UIValueOrDashModule } from '../ui';
import { CreateEditBranchComponent } from './components/partials/create-edit-branch/create-edit-branch.component';
import { CreateEditCompanyPositionComponent } from './components/partials/create-edit-company-position/create-edit-company-position.component';
import { CreateEditDatetimeTypeComponent } from './components/partials/create-edit-datetime-type/create-edit-datetime-type.component';
import { CreateEditDocumentTemplateComponent } from './components/partials/create-edit-document-template/create-edit-document-template.component';
import { CreateEditWidgetTemplateModalComponent } from './components/partials/create-edit-widget-template-modal/create-edit-widget-template-modal.component';
import { CreateMultipleAttendanceComponent } from './components/partials/create-multiple-attendance/create-multiple-attendance.component';
import { IntegrationModal } from './components/partials/integration-modal/integration-modal.component';
import { SettingGuideBasicModalComponent } from './components/partials/setting-guide-basic-modal/setting-guide-basic-modal.component';
import { AbsenceSettingsComponent } from './components/sections/absence-settings/absence-settings.component';
import { AttendanceIndicatorSettingsComponent } from './components/sections/attendance-indicator-settings/attendance-indicator-settings.component';
import { AttendanceSettingsComponent } from './components/sections/attendance-settings/attendance-settings.component';
import { BranchListComponent } from './components/sections/branch-list/branch-list.component';
import { CompanyPositionsListComponent } from './components/sections/company-positions-list/company-positions-list.component';
import { CompanySettingsAccountComponent } from './components/sections/company-settings-account/company-settings-account.component';
import { CompanySettingsAddressComponent } from './components/sections/company-settings-address/company-settings-address.component';
import { CompanySettingsBasicComponent } from './components/sections/company-settings-basic/company-settings-basic.component';
import { CompanySettingsCurrencyComponent } from './components/sections/company-settings-currency/company-settings-currency.component';
import { CompanySettingsFacturationComponent } from './components/sections/company-settings-facturation/company-settings-facturation.component';
import { CompanySettingsOtherComponent } from './components/sections/company-settings-other/company-settings-other.component';
import { CompanySettingsComponent } from './components/sections/company-settings/company-settings.component';
import { CreateEditCustomFieldModalComponent } from './components/sections/create-edit-custom-field-modal/create-edit-custom-field-modal.component';
import { CreateEditDocumentTemplateHtmlComponent } from './components/sections/create-edit-document-template-html/create-edit=document-template-html.component';
import { CreateEditNumberSeriesModalComponent } from './components/sections/create-edit-number-series-modal/create-edit-number-series-modal.component';
import { CreateEditRoleComponent } from './components/sections/create-edit-role/create-edit-role.component';
import { CreateEditUserModalComponent } from './components/sections/create-edit-user-modal/create-edit-user-modal.component';
import { CustomFieldListComponent } from './components/sections/custom-field-list/custom-field-list.component';
import { DatetimeProjectsListComponent } from './components/sections/datetime-projects-list/datetime-projects-list.component';
import { DatetimeTypesListComponent } from './components/sections/datetime-types-list/datetime-types-list.component';
import { DocumentTemplateDetailComponent } from './components/sections/document-template-detail/document-template-detail.component';
import { MealVouchersSettingComponent } from './components/sections/meal-vouchers-setting/meal-vouchers-setting.component';
import { MultiDocumentTemplateGeneratorComponent } from './components/sections/multi-document-template-generator/multi-document-template-generator.component';
import { NotificationCenterComponent } from './components/sections/notification-center/notification-center.component';
import { NumberSeriesListComponent } from './components/sections/number-series-list/number-series-list.component';
import { RolesListComponent } from './components/sections/roles-list/roles-list.component';
import { RolesReviewComponent } from './components/sections/roles-review/roles-review.component';
import { SettingAppearanceComponent } from './components/sections/setting-appearance/setting-appearance.component';
import { SettingAttendanceComponent } from './components/sections/setting-attendance/setting-attendance.component';
import { SettingCRMComponent } from './components/sections/setting-crm/setting-crm.component';
import { SettingDetailComponent } from './components/sections/setting-detail/setting-detail.component';
import { SettingGeneralComponent } from './components/sections/setting-general/setting-general.component';
import { SettingGuideComponent } from './components/sections/setting-guide/setting-guide.component';
import { SettingIntegrationComponent } from './components/sections/setting-integration/setting-integration.component';
import { SettingMenuComponent } from './components/sections/setting-menu/setting-menu.component';
import { SettingOtherComponent } from './components/sections/setting-other/setting-other.component';
import { SettingPositionsBranchesComponent } from './components/sections/setting-positions-branches/setting-positions-branches.component';
import { SettingSalariesComponent } from './components/sections/setting-salaries/setting-salaries.component';
import { SettingSecurityComponent } from './components/sections/setting-security/setting-security.component';
import { SettingUserAccountsComponent } from './components/sections/setting-user-accounts/setting-user-accounts.component';
import { ShiftDurationSettingComponent } from './components/sections/shift-duration-setting/shift-duration-setting.component';
import { SubscriptionSettingsBillingInfoComponent } from './components/sections/subscription-settings-billing-info/subscription-settings-billing-info.component';
import { SubscriptionSettingsFeaturesComponent } from './components/sections/subscription-settings-features/subscription-settings-features.component';
import { SubscriptionSettingsHistoryComponent } from './components/sections/subscription-settings-history/subscription-settings-history.component';
import { SubscriptionSettingsOverviewComponent } from './components/sections/subscription-settings-overview/subscription-settings-overview.component';
import { SubscriptionSettingsComponent } from './components/sections/subscription-settings/subscription-settings.component';
import { UserListComponent } from './components/sections/user-list/user-list.component';
import { WidgetTemplatesListComponent } from './components/sections/widget-templates-list/widget-templates-list.component';
import { CreateEditCurrencyModalComponent } from './partials/create-edit-currency-modal/create-edit-currency-modal.component';
import { CreateEditDatetimeProjectModalComponent } from './partials/create-edit-datetime-project-modal/create-edit-datetime-project-modal.component';
import { SettingRoutingModule } from './setting-routing.module';

@NgModule({
    declarations: [
        AttendanceSettingsComponent,
        CreateEditRoleComponent,
        RolesListComponent,
        RolesReviewComponent,
        NumberSeriesListComponent,
        CreateEditCustomFieldModalComponent,
        CompanySettingsComponent,
        UserListComponent,
        CreateEditUserModalComponent,
        CompanySettingsBasicComponent,
        CompanySettingsAddressComponent,
        CompanySettingsAccountComponent,
        CompanySettingsOtherComponent,
        SubscriptionSettingsComponent,
        SubscriptionSettingsOverviewComponent,
        SubscriptionSettingsHistoryComponent,
        SubscriptionSettingsBillingInfoComponent,
        SettingAttendanceComponent,
        SettingDetailComponent,
        SettingGeneralComponent,
        SettingIntegrationComponent,
        SettingUserAccountsComponent,
        SettingOtherComponent,
        BranchListComponent,
        CreateEditBranchComponent,
        DatetimeTypesListComponent,
        CreateEditDatetimeTypeComponent,
        SettingCRMComponent,
        CreateEditCurrencyModalComponent,
        CompanySettingsFacturationComponent,
        SettingGuideComponent,
        SettingGuideBasicModalComponent,
        SettingSalariesComponent,
        CompanySettingsCurrencyComponent,
        IntegrationModal,
        NotificationCenterComponent,
        DocumentTemplateDetailComponent,
        MultiDocumentTemplateGeneratorComponent,
        SettingSecurityComponent,
        SettingAppearanceComponent,
        CreateEditDocumentTemplateComponent,
        AttendanceIndicatorSettingsComponent,
        CustomFieldListComponent,
        CreateEditNumberSeriesModalComponent,
        SubscriptionSettingsFeaturesComponent,
        SettingMenuComponent,
        SettingPositionsBranchesComponent,
        CompanyPositionsListComponent,
        CreateEditCompanyPositionComponent,
        CreateEditDocumentTemplateHtmlComponent,
        DatetimeProjectsListComponent,
        CreateEditDatetimeProjectModalComponent,
        WidgetTemplatesListComponent,
        CreateEditWidgetTemplateModalComponent,
        CreateMultipleAttendanceComponent,
        ShiftDurationSettingComponent,
        MealVouchersSettingComponent,
        AbsenceSettingsComponent
    ],
    imports: [
        CommonModule,
        SettingRoutingModule,
        ColorPickerModule,
        SharedModule,
        PolicyModule,
        ImportModule,
        UIButtonModule,
        UIDataTableModule,
        UIFileUploadModule,
        UIItemsPerPageSelectModule,
        UIPaginationModule,
        UIValueOrDashModule,
        DocumentModule,
        NgxSkeletonLoaderModule,
        EmployeeModule
    ]
})
export class SettingModule {}
