import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CompanyService } from 'src/app/setting/services/company.service';
import { Employee } from '../../../../employee/models/Employee';
import { EmployeeService } from '../../../../employee/services/employee.service';
import { EmployeeDatetimeType } from '../../../models/EmployeeDatetimeType';
import { DatetimesTypesStoreService } from '../../../services/datetimes-types-store.service';

@Component({
    selector: 'app-shift-duration-setting',
    templateUrl: './shift-duration-setting.component.html',
    styleUrls: ['./shift-duration-setting.component.css']
})
export class ShiftDurationSettingComponent implements OnInit {
    public form: UntypedFormGroup;
    public settings$: Observable<any>;
    public employees$: Observable<Employee[]>;
    public datetimeTypes$: Observable<EmployeeDatetimeType[]>;

    public constructor(
        private _companyService: CompanyService,
        private _employeeService: EmployeeService,
        private _datetimeTypesStore: DatetimesTypesStoreService,
        private _fb: UntypedFormBuilder,
    ) { }

    public get f(): { [formControlName: string]: AbstractControl; } {
        return this.form.controls;
    }

    public ngOnInit(): void {
        this.form = this._fb.group({
            'company.attendance.use_shift_duration': [false],
            'company.attendance.shift_duration_excluded_datetime_types': [null],
            'company.attendance.shift_duration_excluded_employees': [null],
        });

        this.employees$ = this._employeeService.getAllEmployees(false, 'select');
        this.datetimeTypes$ = this._datetimeTypesStore.datetimeTypes$;

        this.settings$ = this._companyService.getCompanySetting()
            .pipe(
                tap(settings => {
                    this.form.patchValue({
                        'company.attendance.use_shift_duration': settings.company_settings.attendance?.use_shift_duration || false,
                        'company.attendance.shift_duration_excluded_datetime_types': settings.company_settings.attendance?.shift_duration_excluded_datetime_types || null,
                        'company.attendance.shift_duration_excluded_employees': settings.company_settings.attendance?.shift_duration_excluded_employees || null,
                    });
                })
            );
    }

    public onSubmit(): void {
        const value = this.form.value;

        value.settings_type = 'attendance';

        void this._companyService.saveCompanySetting(this.form.value);
    }
}
