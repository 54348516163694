import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable, } from 'rxjs';
import { ApiHelper } from 'src/app/shared/common/ApiHelper';
import { AxisChart } from 'src/app/shared/common/AxisChart';
import { SwalHelper } from 'src/app/shared/common/SwalHelper';
import { ApiResponse } from 'src/app/shared/models/ApiResponse';
import { RealWage } from '../models/RealWage';
import { Salary } from '../models/Salary';

@Injectable({
    providedIn: 'root'
})
export class SalaryService {

    constructor(
        private http: HttpClient,
        private loaderService: NgxUiLoaderService,
        private apiHelper: ApiHelper,
        private swalHelper: SwalHelper
    ) { }

    public getAllSalariesByEmployeeID(employeeID: number, withDocumentTemplateData = false): Observable<Salary[]> {
        let params = new HttpParams();
        if (withDocumentTemplateData) {
            params = params.set('scope', 'document_template');
        }
        return this.http.get<Salary[]>('/api/employees/' + employeeID + '/salaries?widget=EMPLOYEE_SALARY', {params});
    }

    public getAllRealWagesByEmployeeID(employeeID: number): Observable<RealWage[]> {
        return this.http.get<RealWage[]>('/api/employees/' + employeeID + '/real-wages');
    }

    public getSalaryByID(employeeID: number, employeesalaryID: number): Observable<Salary> {
        return this.http.get<Salary>('/api/employees/' + employeeID + '/salaries/' + employeesalaryID);
    }

    public getSalaryInfoByEmployeeID(employeeID: number): Observable<AxisChart> {
        return this.http.get<AxisChart>(`/api/employees/${employeeID}/salaries/info`);
    }

    public saveEmployeeSalaryByEmployeeID(form: Salary, employeeID: number, salaryID?: number): Promise<string> {
        this.loaderService.start();
        return new Promise(resolve => {
            if (salaryID) {
                this.http.post('/api/employees/' + employeeID + '/salaries/' + salaryID, form).subscribe((response: ApiResponse) => {
                    this.apiHelper.handleSuccessResponse(response);
                    resolve('done');
                    this.loaderService.stop();
                }, error => {
                    this.apiHelper.handleErrorResponse(error);
                    this.loaderService.stop();
                });
            } else {
                this.http.post('/api/employees/' + employeeID + '/salaries', form).subscribe((response: ApiResponse) => {
                    this.apiHelper.handleSuccessResponse(response);
                    resolve('done');
                    this.loaderService.stop();
                }, error => {
                    this.apiHelper.handleErrorResponse(error);
                    this.loaderService.stop();
                });
            }
        });
    }

    public deleteEmployeeSalaryByID(employeeID: number, salaryID: number) {
        return new Promise(async (resolve, reject) => {
            const deleteConfirmed = await this.swalHelper.showConfirmDeleteDialog();
            if (deleteConfirmed) {
                this.http.delete('/api/employees/' + employeeID + '/salaries/' + salaryID).subscribe((response: any) => {
                    this.loaderService.stop();
                    this.apiHelper.handleSuccessResponse(response);
                    resolve('done');
                }, error => {
                    this.loaderService.stop();
                    this.apiHelper.handleErrorResponse(error);
                });
            } else {
                reject('error');
            }
        });
    }

    public saveRealWageByEmployeeID(form: RealWage, employeeID: number): Promise<string> {
        this.loaderService.start();
        return new Promise(resolve => {
            if (form.employee_real_wage_ID) {
                this.http.post('/api/employees/' + employeeID + '/real-wages/' + form.employee_real_wage_ID, form).subscribe((response: ApiResponse) => {
                    this.apiHelper.handleSuccessResponse(response);
                    resolve('done');
                    this.loaderService.stop();
                }, error => {
                    this.apiHelper.handleErrorResponse(error);
                    this.loaderService.stop();
                });
            } else {
                this.http.post('/api/employees/' + employeeID + '/real-wages', form).subscribe((response: ApiResponse) => {
                    this.apiHelper.handleSuccessResponse(response);
                    resolve('done');
                    this.loaderService.stop();
                }, error => {
                    this.apiHelper.handleErrorResponse(error);
                    this.loaderService.stop();
                });
            }
        });
    }

    public deleteRealWageByID(employeeID: number, realWageID: number) {
        return new Promise(async (resolve, reject) => {
            const deleteConfirmed = await this.swalHelper.showConfirmDeleteDialog();
            if (deleteConfirmed) {
                this.http.delete('/api/employees/' + employeeID + '/real-wages/' + realWageID).subscribe((response: any) => {
                    this.loaderService.stop();
                    this.apiHelper.handleSuccessResponse(response);
                    resolve('done');
                }, error => {
                    this.loaderService.stop();
                    this.apiHelper.handleErrorResponse(error);
                });
            } else {
                reject('error');
            }
        });
    }

    public importRealWages(file: File): Promise<string> {
        const formData = new FormData();
        formData.append('file', file);
        return new Promise((resolve, reject) => {
            this.http.post('/api/imports/real-wages', formData).subscribe((response: ApiResponse) => {
                this.apiHelper.handleSuccessResponse(response);
                resolve('done');
                this.loaderService.stop();
            }, error => {
                this.apiHelper.handleErrorResponse(error);
                reject('error');
                this.loaderService.stop();
            });
        });
    }
}
