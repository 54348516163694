<div *ngIf="employeePersonalData$ | async as personalData; else loadingPersonalDataTemplate"
     [ngClass]="requiredUpdates && requiredUpdates.personal_info === 'REQUESTED' ? 'notify-dot' : ''"
     class="card h-100 shadow">
    <app-help name="employee.detail.personal_data"></app-help>

    <div class="card-header py-3">
        <div class="row">
            <div class="col-6 text-left my-auto">
                <p class="modal-title font-16 text-dark pt-2 pb-2">
                    <span>{{ 'employees.personal_data.page_title_index' | translate }}</span>
                </p>
            </div>

            <div *ngIf="widgetTemplatesService.canSeeButton('PersonalData', 'Edit', employee.permissions)" class="col-6 text-right my-auto">
                <button
                    (click)="openEditModal(personalData)"
                    [updateButton]="'employees.personal_data.action_create' | translate"
                ></button>
            </div>
        </div>
    </div>

    <div class="card-body px-4 pb-0">
        <ng-container>
            <ng-container *ngIf="entityRequests">
                <ng-container *ngFor="let request of entityRequests">
                    <div *ngIf="request?.entity_type === 'App\\Models\\EmployeePersonalData'" class="alert alert-warning mb-3 w-100 shadow">
                        <span>{{ 'employees.waiting_to_be_processed_part_1' | translate }}</span>
                        <span><u (click)="openEntityUpdateRequestModal(request)"> {{ 'employees.require_update_detail_action'| translate }}</u> </span>
                        <span>{{ 'employees.waiting_to_be_processed_part_2' | translate }}</span>
                    </div>
                </ng-container>
            </ng-container>
            <div *ngIf="requiredUpdates && requiredUpdates.personal_info === 'REQUESTED'" class="alert alert-warning mb-3 w-100 shadow">
                <span> {{ 'employees.require_update_description' | translate }} </span>
                <span><u (click)="openEditModal(personalData)"> {{ 'employees.require_update_edit_action'| translate }}</u>. </span>
                <u (click)="refuseUpdateData()"> {{ 'employees.require_update_cancel_action' | translate }}</u>.
            </div>
            <!--Short form-->
            <div *ngIf="isCollapsed">
                <div class="row border-bottom">
                    <div class="col-12 col-sm-12 col-md-3 py-1 py-md-2">
                        <span class="text-dark"> {{ 'employees.personal_data.section_personal_info' | translate }} </span>
                    </div>
                    <div [ngClass]="{'hidden-sensitive-value-wrapper': hiddenInputs}" class="col-12 col-sm-9 col-md-9 py-1 py-md-2 sensitive">
                        <span *ngIf="personalData.gender !== 'UNDEFINED'"> {{ ('employees.gender_' + personalData.gender) | translate }}, </span>
                        <ng-container *ngIf="personalData.born_place || personalData.born_date">
                            <span>{{ 'employees.personal_data.has_born' | translate }}: </span>
                            <span *ngIf="personalData.born_date">{{ personalData.born_date | date: 'dd.MM.yyyy' }}, </span>
                            <span *ngIf="personalData.born_place">{{ personalData.born_place }}, </span>
                        </ng-container>
                        <span *ngIf="personalData.personal_in">
                        {{ 'employees.personal_in' | translate }}:
                            {{ personalData.personal_in }},
                    </span>
                        <span *ngIf="personalData.health_insurer">
                        {{ 'employees.health_insurer' | translate }}:
                            {{ personalData.health_insurer }},
                    </span>
                        <span *ngIf="personalData.insurance_number">
                        {{ 'employees.insurance_number' | translate }}:
                            {{ personalData.insurance_number }},
                    </span>
                        <span *ngIf="personalData.health_condition">
                        {{ 'employees.health_condition' | translate }}:
                            {{ personalData.health_condition }},
                    </span>
                        <span *ngIf="personalData.nationality">
                        {{ 'employees.nationality' | translate }}:
                            {{ personalData.nationality }},
                    </span>
                        <span *ngIf="personalData.id_card_number">
                        {{ 'employees.id_card_number' | translate }}:
                            {{ personalData.id_card_number }},
                    </span>
                        <span *ngIf="personalData.passport_number">
                        {{ 'employees.passport_number' | translate }}:
                            {{ personalData.passport_number }},
                    </span>
                        <span *ngIf="personalData.driving_licence_number">
                        {{ 'employees.driving_licence_number' | translate }}:
                            {{ personalData.driving_licence_number }}
                    </span>
                    </div>
                </div>
                <div class="row border-bottom">
                    <div class="col-12 col-sm-12 col-md-3 py-1 py-md-2">
                        <span class="text-dark"> {{ 'employees.personal_data.section_contact_info' | translate }} </span>
                    </div>
                    <div [ngClass]="{'hidden-sensitive-value-wrapper': hiddenInputs}" class="col-12 col-sm-9 col-md-9 py-1 py-md-2 sensitive">
                    <span *ngIf="personalData.personal_email">
                        {{ 'employees.personal_email' | translate }}:
                        {{ personalData.personal_email }},
                    </span>
                        <span *ngIf="personalData.personal_phone">
                        {{ 'employees.personal_phone' | translate }}:
                            {{ personalData.personal_phone }}
                    </span>
                    </div>
                </div>
                <div class="row border-bottom">
                    <div class="col-12 col-sm-12 col-md-3 py-1 py-md-2">
                        <span class="text-dark"> {{ 'employees.personal_data.section_bank_info' | translate }} </span>
                    </div>
                    <div [ngClass]="{'hidden-sensitive-value-wrapper': hiddenInputs}" class="col-12 col-sm-9 col-md-9 py-1 py-md-2 sensitive">
                    <span *ngIf="personalData.bank_account_iban">
                        {{ 'employees.bank_account_iban' | translate }}:
                        {{ personalData.bank_account_iban }},
                    </span>
                        <span *ngIf="personalData.bank_account_number && personalData.bank_account_bank_code">
                        {{ 'employees.bank_account_number' | translate }}:
                            {{ personalData.bank_account_number + '/' + personalData.bank_account_bank_code }}
                    </span>
                    </div>
                </div>
                <div class="row border-bottom">
                    <div class="col-12 col-sm-12 col-md-3 py-1 py-md-2">
                        <span class="text-dark"> {{ 'employees.personal_data.section_tax_info' | translate }} </span>
                    </div>
                    <div [ngClass]="{'hidden-sensitive-value-wrapper': hiddenInputs}" class="col-12 col-sm-9 col-md-9 py-1 py-md-2 sensitive">
                    <span *ngIf="personalData.has_pension">
                        {{ 'employees.has_pension' | translate }}:
                        {{ personalData.pension_type }},
                        {{ personalData.pension_amount | localizedCurrency  }},
                    </span>
                        <span *ngIf="personalData.has_salary_deduction">
                        {{ 'employees.has_salary_deduction' | translate }}:
                            {{ personalData.salary_deduction_type }},
                            {{ personalData.salary_deduction_amount | localizedCurrency  }},
                    </span>
                        <span *ngIf="personalData.has_employment_agency">
                        {{ 'employees.has_employment_agency' | translate }},
                    </span>
                        <span *ngIf="personalData.has_second_pension_pillar">
                        {{ 'employees.has_second_pension_pillar' | translate }},
                    </span>
                        <span *ngIf="personalData.is_student">
                        {{ 'employees.is_student' | translate }},
                    </span>
                        <span *ngIf="personalData.has_student_confirmation">
                        {{ 'employees.has_student_confirmation' | translate }},
                    </span>
                        <span *ngIf="personalData.number_of_children">
                        {{ 'employees.number_of_children' | translate }}:
                            {{ personalData.number_of_children }},
                    </span>
                        <span *ngIf="personalData.number_of_dependents">
                        {{ 'employees.number_of_dependents' | translate }}:
                            {{ personalData.number_of_dependents }}
                    </span>
                    </div>
                </div>
                <div class="row border-bottom">
                    <div class="col-12 col-sm-12 col-md-3 py-1 py-md-2">
                        <span class="text-dark"> {{ 'employees.personal_data.section_other_info' | translate }} </span>
                    </div>
                    <div [ngClass]="{'hidden-sensitive-value-wrapper': hiddenInputs}" class="col-12 col-sm-9 col-md-9 py-1 py-md-2 sensitive">
                    <span *ngIf="personalData.has_food_license">
                        {{ 'employees.has_food_license' | translate }},
                    </span>
                        <span *ngIf="personalData.in">
                        {{ 'employees.in' | translate }}:
                            {{ personalData.in }},
                    </span>
                        <span *ngIf="personalData.tin">
                        {{ 'employees.tin' | translate }}:
                            {{ personalData.tin }}
                    </span>
                    </div>
                </div>
                <div class="row border-bottom">
                    <div class="col-12 col-sm-12 col-md-3 py-1 py-md-2">
                        <span class="text-dark"> {{ 'employees.personal_data.custom_fields' | translate }} </span>
                    </div>
                    <div class="col-12 col-sm-9 col-md-6 py-1 py-md-2 sensitive">
                        <ng-container *ngFor="let field of personalData.custom_fields; last as isLast">
                            <ng-container *ngIf="field.type !== 'TEXTAREA' && field.type !== 'RICHTEXT'">
                                <ng-container [ngSwitch]="field.type">
                                    <span> {{ field.label }}: </span>
                                    <span *ngSwitchCase="'PERCENT'"> {{ field.value }} %</span>
                                    <span *ngSwitchCase="'AMOUNT'"> {{ field.value | localizedCurrency }}</span>
                                    <span *ngSwitchCase="'DATE'"> {{ field.value | date: 'dd.MM.yyyy' }}</span>
                                    <span *ngSwitchCase="'DATETIME'"> {{ field.value | date: 'dd.MM.yyyy HH:mm' }}</span>
                                    <span *ngSwitchCase="'TOGGLE'">  {{ (field.value ? 'global.YES' : 'global.NO') | translate }}</span>
                                    <span *ngSwitchDefault>{{ field.value }}</span>
                                    <span *ngIf="!isLast">, </span>
                                </ng-container>
                            </ng-container>
                        </ng-container>

                    </div>
                </div>
            </div>


            <!--When expanded all data-->
            <div #collapse="ngbCollapse"
                 [(ngbCollapse)]="isCollapsed">
                <fieldset>
                    <legend class="text-dark personal-data-legend"> {{ 'employees.personal_data.section_personal_info' | translate }} </legend>
                    <div class="row border-bottom">
                        <div class="col-12 col-sm-12 col-md-3 py-1 py-md-2">
                            <span class="text-dark"> {{ 'employees.gender' | translate }} </span>
                        </div>
                        <div [ngClass]="{'hidden-sensitive-value-wrapper': hiddenInputs}" class="col-12 col-sm-9 col-md-6 py-1 py-md-2 sensitive">
                            <span> {{ ('employees.gender_' + personalData.gender) | translate }}</span>
                        </div>
                    </div>
                    <div class="row border-bottom">
                        <div class="col-12 col-sm-12 col-md-3 py-1 py-md-2">
                            <span class="text-dark"> {{ 'employees.born_date' | translate }} </span>
                        </div>
                        <div [ngClass]="{'hidden-sensitive-value-wrapper': hiddenInputs}" class="col-12 col-sm-9 col-md-6 py-1 py-md-2 sensitive">
                            <span> {{ personalData.born_date | date: 'dd.MM.yyyy' }}</span>
                        </div>
                    </div>
                    <div class="row border-bottom">
                        <div class="col-12 col-sm-12 col-md-3 py-1 py-md-2">
                            <span class="text-dark"> {{ 'employees.born_place' | translate }} </span>
                        </div>
                        <div [ngClass]="{'hidden-sensitive-value-wrapper': hiddenInputs}" class="col-12 col-sm-9 col-md-6 py-1 py-md-2 sensitive">
                            <span> {{ personalData.born_place }}</span>
                        </div>
                    </div>
                    <div class="row border-bottom">
                        <div class="col-12 col-sm-12 col-md-3 py-1 py-md-2">
                            <span class="text-dark"> {{ 'employees.personal_in' | translate }} </span>
                        </div>
                        <div [ngClass]="{'hidden-sensitive-value-wrapper': hiddenInputs}" class="col-12 col-sm-9 col-md-6 py-1 py-md-2 sensitive">
                            <span> {{ personalData.personal_in }}</span>
                        </div>
                    </div>
                    <div class="row border-bottom">
                        <div class="col-12 col-sm-12 col-md-3 py-1 py-md-2">
                            <span class="text-dark"> {{ 'employees.health_insurer' | translate }} </span>
                        </div>
                        <div [ngClass]="{'hidden-sensitive-value-wrapper': hiddenInputs}" class="col-12 col-sm-9 col-md-6 py-1 py-md-2 sensitive">
                            <span> {{ personalData.health_insurer }}</span>
                        </div>
                    </div>
                    <div class="row border-bottom">
                        <div class="col-12 col-sm-12 col-md-3 py-1 py-md-2">
                            <span class="text-dark"> {{ 'employees.insurance_number' | translate }} </span>
                        </div>
                        <div [ngClass]="{'hidden-sensitive-value-wrapper': hiddenInputs}" class="col-12 col-sm-9 col-md-6 py-1 py-md-2 sensitive">
                            <span> {{ personalData.insurance_number }}</span>
                        </div>
                    </div>
                    <div class="row border-bottom">
                        <div class="col-12 col-sm-12 col-md-3 py-1 py-md-2">
                            <span class="text-dark"> {{ 'employees.health_condition' | translate }} </span>
                        </div>
                        <div [ngClass]="{'hidden-sensitive-value-wrapper': hiddenInputs}" class="col-12 col-sm-9 col-md-6 py-1 py-md-2 sensitive">
                            <span> {{ personalData.health_condition }}</span>
                        </div>
                    </div>
                    <div class="row border-bottom">
                        <div class="col-12 col-sm-12 col-md-3 py-1 py-md-2">
                            <span class="text-dark"> {{ 'employees.nationality' | translate }} </span>
                        </div>
                        <div [ngClass]="{'hidden-sensitive-value-wrapper': hiddenInputs}" class="col-12 col-sm-9 col-md-6 py-1 py-md-2 sensitive">
                            <span> {{ personalData.nationality }}</span>
                        </div>
                    </div>
                    <div class="row border-bottom">
                        <div class="col-12 col-sm-12 col-md-3 py-1 py-md-2">
                            <span class="text-dark"> {{ 'employees.id_card_number' | translate }} </span>
                        </div>
                        <div [ngClass]="{'hidden-sensitive-value-wrapper': hiddenInputs}" class="col-12 col-sm-9 col-md-6 py-1 py-md-2 sensitive">
                            <span> {{ personalData.id_card_number }}</span>
                        </div>
                    </div>
                    <div class="row border-bottom">
                        <div class="col-12 col-sm-12 col-md-3 py-1 py-md-2">
                            <span class="text-dark"> {{ 'employees.passport_number' | translate }} </span>
                        </div>
                        <div [ngClass]="{'hidden-sensitive-value-wrapper': hiddenInputs}" class="col-12 col-sm-9 col-md-6 py-1 py-md-2 sensitive">
                            <span> {{ personalData.passport_number }}</span>
                        </div>
                    </div>
                    <div class="row border-bottom">
                        <div class="col-12 col-sm-12 col-md-3 py-1 py-md-2">
                            <span class="text-dark"> {{ 'employees.driving_licence_number' | translate }} </span>
                        </div>
                        <div [ngClass]="{'hidden-sensitive-value-wrapper': hiddenInputs}" class="col-12 col-sm-9 col-md-6 py-1 py-md-2 sensitive">
                            <span> {{ personalData.driving_licence_number }}</span>
                        </div>
                    </div>
                </fieldset>
                <fieldset class="mt-3">
                    <legend class="text-dark personal-data-legend"> {{ 'employees.personal_data.section_contact_info' | translate }} </legend>
                    <div class="row border-bottom">
                        <div class="col-12 col-sm-12 col-md-3 py-1 py-md-2">
                            <span class="text-dark"> {{ 'employees.personal_email' | translate }} </span>
                        </div>
                        <div [ngClass]="{'hidden-sensitive-value-wrapper': hiddenInputs}" class="col-12 col-sm-9 col-md-6 py-1 py-md-2 sensitive">
                            <span> {{ personalData.personal_email }}</span>
                        </div>
                    </div>
                    <div class="row border-bottom">
                        <div class="col-12 col-sm-12 col-md-3 py-1 py-md-2">
                            <span class="text-dark"> {{ 'employees.personal_phone' | translate }} </span>
                        </div>
                        <div [ngClass]="{'hidden-sensitive-value-wrapper': hiddenInputs}" class="col-12 col-sm-9 col-md-6 py-1 py-md-2 sensitive">
                            <span> {{ personalData.personal_phone }}</span>
                        </div>
                    </div>
                </fieldset>
                <fieldset class="mt-3">
                    <legend class="text-dark personal-data-legend"> {{ 'employees.personal_data.section_bank_info' | translate }} </legend>
                    <div class="row border-bottom">
                        <div class="col-12 col-sm-12 col-md-3 py-1 py-md-2">
                            <span class="text-dark"> {{ 'employees.bank_account_iban' | translate }} </span>
                        </div>
                        <div [ngClass]="{'hidden-sensitive-value-wrapper': hiddenInputs}" class="col-12 col-sm-9 col-md-6 py-1 py-md-2 sensitive">
                            <span> {{ personalData.bank_account_iban }}</span>
                        </div>
                    </div>
                    <div class="row border-bottom">
                        <div class="col-12 col-sm-12 col-md-3 py-1 py-md-2">
                            <span class="text-dark"> {{ 'employees.bank_account_number' | translate }} </span>
                        </div>
                        <div [ngClass]="{'hidden-sensitive-value-wrapper': hiddenInputs}" class="col-12 col-sm-9 col-md-6 py-1 py-md-2 sensitive">
                        <span *ngIf="personalData.bank_account_number && personalData.bank_account_bank_code">
                            {{ personalData.bank_account_number }}/{{ personalData.bank_account_bank_code }}
                        </span>
                        </div>
                    </div>
                </fieldset>
                <fieldset class="mt-3">
                    <legend class="text-dark personal-data-legend"> {{ 'employees.personal_data.section_tax_info' | translate }} </legend>
                    <div class="row border-bottom">
                        <div class="col-12 col-sm-12 col-md-3 py-1 py-md-2">
                            <span class="text-dark"> {{ 'employees.has_pension' | translate }} </span>
                        </div>
                        <div [ngClass]="{'hidden-sensitive-value-wrapper': hiddenInputs}" class="col-12 col-sm-9 col-md-6 py-1 py-md-2 sensitive">
                            <span> {{ (personalData.has_pension ? 'global.YES' : 'global.NO') | translate }}</span>
                        </div>
                    </div>
                    <div class="row border-bottom">
                        <div class="col-12 col-sm-12 col-md-3 py-1 py-md-2">
                            <span class="text-dark"> {{ 'employees.pension_type' | translate }} </span>
                        </div>
                        <div [ngClass]="{'hidden-sensitive-value-wrapper': hiddenInputs}" class="col-12 col-sm-9 col-md-6 py-1 py-md-2 sensitive">
                            <span> {{ personalData.pension_type }}</span>
                        </div>
                    </div>
                    <div class="row border-bottom">
                        <div class="col-12 col-sm-12 col-md-3 py-1 py-md-2">
                            <span class="text-dark"> {{ 'employees.pension_amount' | translate }} </span>
                        </div>
                        <div [ngClass]="{'hidden-sensitive-value-wrapper': hiddenInputs}" class="col-12 col-sm-9 col-md-6 py-1 py-md-2 sensitive">
                            <span> {{ personalData.pension_amount | localizedCurrency }}</span>
                        </div>
                    </div>
                    <div class="row border-bottom">
                        <div class="col-12 col-sm-12 col-md-3 py-1 py-md-2">
                            <span class="text-dark"> {{ 'employees.has_salary_deduction' | translate }} </span>
                        </div>
                        <div [ngClass]="{'hidden-sensitive-value-wrapper': hiddenInputs}" class="col-12 col-sm-9 col-md-6 py-1 py-md-2 sensitive">
                            <span> {{ (personalData.has_salary_deduction ? 'global.YES' : 'global.NO') | translate }}</span>
                        </div>
                    </div>
                    <div class="row border-bottom">
                        <div class="col-12 col-sm-12 col-md-3 py-1 py-md-2">
                            <span class="text-dark"> {{ 'employees.salary_deduction_type' | translate }} </span>
                        </div>
                        <div [ngClass]="{'hidden-sensitive-value-wrapper': hiddenInputs}" class="col-12 col-sm-9 col-md-6 py-1 py-md-2 sensitive">
                            <span> {{ personalData.salary_deduction_type }}</span>
                        </div>
                    </div>
                    <div class="row border-bottom">
                        <div class="col-12 col-sm-12 col-md-3 py-1 py-md-2">
                            <span class="text-dark"> {{ 'employees.salary_deduction_amount' | translate }} </span>
                        </div>
                        <div [ngClass]="{'hidden-sensitive-value-wrapper': hiddenInputs}" class="col-12 col-sm-9 col-md-6 py-1 py-md-2 sensitive">
                            <span> {{ personalData.salary_deduction_amount | localizedCurrency }}</span>
                        </div>
                    </div>
                    <div class="row border-bottom">
                        <div class="col-12 col-sm-12 col-md-3 py-1 py-md-2">
                            <span class="text-dark"> {{ 'employees.has_employment_agency' | translate }} </span>
                        </div>
                        <div [ngClass]="{'hidden-sensitive-value-wrapper': hiddenInputs}" class="col-12 col-sm-9 col-md-6 py-1 py-md-2 sensitive">
                            <span> {{ (personalData.has_employment_agency ? 'global.YES' : 'global.NO') | translate }}</span>
                        </div>
                    </div>
                    <div class="row border-bottom">
                        <div class="col-12 col-sm-12 col-md-3 py-1 py-md-2">
                            <span class="text-dark"> {{ 'employees.has_second_pension_pillar' | translate }} </span>
                        </div>
                        <div [ngClass]="{'hidden-sensitive-value-wrapper': hiddenInputs}" class="col-12 col-sm-9 col-md-6 py-1 py-md-2 sensitive">
                            <span> {{ (personalData.has_second_pension_pillar ? 'global.YES' : 'global.NO') | translate }}</span>
                        </div>
                    </div>
                    <div class="row border-bottom">
                        <div class="col-12 col-sm-12 col-md-3 py-1 py-md-2">
                            <span class="text-dark"> {{ 'employees.is_student' | translate }} </span>
                        </div>
                        <div [ngClass]="{'hidden-sensitive-value-wrapper': hiddenInputs}" class="col-12 col-sm-9 col-md-6 py-1 py-md-2 sensitive">
                            <span> {{ (personalData.is_student ? 'global.YES' : 'global.NO') | translate }}</span>
                        </div>
                    </div>
                    <div class="row border-bottom">
                        <div class="col-12 col-sm-12 col-md-3 py-1 py-md-2">
                            <span class="text-dark"> {{ 'employees.has_student_confirmation' | translate }} </span>
                        </div>
                        <div [ngClass]="{'hidden-sensitive-value-wrapper': hiddenInputs}" class="col-12 col-sm-9 col-md-6 py-1 py-md-2 sensitive">
                            <span> {{ (personalData.has_student_confirmation ? 'global.YES' : 'global.NO') | translate }}</span>
                        </div>
                    </div>
                    <div class="row border-bottom">
                        <div class="col-12 col-sm-12 col-md-3 py-1 py-md-2">
                            <span class="text-dark"> {{ 'employees.number_of_children' | translate }} </span>
                        </div>
                        <div [ngClass]="{'hidden-sensitive-value-wrapper': hiddenInputs}" class="col-12 col-sm-9 col-md-6 py-1 py-md-2 sensitive">
                            <span> {{ personalData.number_of_children }}</span>
                        </div>
                    </div>
                    <div class="row border-bottom">
                        <div class="col-12 col-sm-12 col-md-3 py-1 py-md-2">
                            <span class="text-dark"> {{ 'employees.number_of_dependents' | translate }} </span>
                        </div>
                        <div [ngClass]="{'hidden-sensitive-value-wrapper': hiddenInputs}" class="col-12 col-sm-9 col-md-6 py-1 py-md-2 sensitive">
                            <span> {{ personalData.number_of_dependents }}</span>
                        </div>
                    </div>
                </fieldset>
                <fieldset class="mt-3">
                    <legend class="text-dark personal-data-legend"> {{ 'employees.personal_data.section_other_info' | translate }} </legend>
                    <div class="row border-bottom">
                        <div class="col-12 col-sm-12 col-md-3 py-1 py-md-2">
                            <span class="text-dark"> {{ 'employees.has_food_license' | translate }} </span>
                        </div>
                        <div [ngClass]="{'hidden-sensitive-value-wrapper': hiddenInputs}" class="col-12 col-sm-9 col-md-6 py-1 py-md-2 sensitive">
                            <span> {{ (personalData.has_food_license ? 'global.YES' : 'global.NO') | translate }}</span>
                        </div>
                    </div>
                    <div class="row border-bottom">
                        <div class="col-12 col-sm-12 col-md-3 py-1 py-md-2">
                            <span class="text-dark"> {{ 'employees.in' | translate }} </span>
                        </div>
                        <div [ngClass]="{'hidden-sensitive-value-wrapper': hiddenInputs}" class="col-12 col-sm-9 col-md-6 py-1 py-md-2 sensitive">
                            <span> {{ personalData.in }}</span>
                        </div>
                    </div>
                    <div class="row border-bottom">
                        <div class="col-12 col-sm-12 col-md-3 py-1 py-md-2">
                            <span class="text-dark"> {{ 'employees.tin' | translate }} </span>
                        </div>
                        <div [ngClass]="{'hidden-sensitive-value-wrapper': hiddenInputs}" class="col-12 col-sm-9 col-md-6 py-1 py-md-2 sensitive">
                            <span> {{ personalData.tin }}</span>
                        </div>
                    </div>
                </fieldset>
                <fieldset *ngIf="personalData.custom_fields.length > 0" class="mt-3">
                    <legend class="text-dark personal-data-legend"> {{ 'employees.personal_data.custom_fields' | translate }} </legend>
                    <div *ngFor="let field of personalData.custom_fields" class="row border-bottom">
                        <div class="col-12 col-sm-12 col-md-3 py-1 py-md-2">
                            <span class="text-dark"> {{ field.label }} </span>
                        </div>
                        <div class="col-12 col-sm-9 col-md-6 py-1 py-md-2 sensitive text-cropped">
                            <ng-container [ngSwitch]="field.type">
                                <span *ngSwitchCase="'RICHTEXT'" [innerHTML]="field.value"></span>
                                <span *ngSwitchCase="'PERCENT'"> {{ field.value }} % </span>
                                <span *ngSwitchCase="'AMOUNT'"> {{ field.value | localizedCurrency }} </span>
                                <span *ngSwitchCase="'DATE'"> {{ field.value | date: 'dd.MM.yyyy' }} </span>
                                <span *ngSwitchCase="'DATETIME'"> {{ field.value | date: 'dd.MM.yyyy HH:mm' }} </span>
                                <span *ngSwitchCase="'TOGGLE'">  {{ (field.value ? 'global.YES' : 'global.NO') | translate }} </span>
                                <span *ngSwitchDefault> {{ field.value }} </span>
                            </ng-container>
                        </div>
                    </div>
                </fieldset>
            </div>
            <div class="row text-center">
                <div class="col-12 py-1 py-md-2">
                    <button (click)="collapse.toggle()"
                            [attr.aria-expanded]="!isCollapsed"
                            aria-controls="collapse"
                            class="btn btn-link text-muted"
                            type="button">
                        <u *ngIf="isCollapsed">{{ 'employees.show_more' | translate }}</u>
                        <u *ngIf="!isCollapsed">{{ 'employees.show_less' | translate }}</u>
                    </button>
                </div>
            </div>
        </ng-container>
    </div>
</div>

<ng-template #loadingPersonalDataTemplate>
    <div class="card shadow p-3">
        <div class="item">
            <ngx-skeleton-loader [theme]="{
      'margin': '15px 0'
    }" appearance="line" count="5"></ngx-skeleton-loader>
        </div>

    </div>
</ng-template>
