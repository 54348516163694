import { NgModule } from '@angular/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SharedModule } from '../shared/shared.module';
import { UIButtonModule, UIDataTableModule, UIFileUploadModule, UIItemsPerPageSelectModule, UIPaginationModule } from '../ui';
import { CreateEditEmployeeReviewModalComponent } from './components/partials/create-edit-employee-review-modal/create-edit-employee-review-modal.component';
import { CreateEditKpiMetricModalComponent } from './components/partials/create-edit-kpi-metric-modal/create-edit-kpi-metric-modal.component';
import { CreateEditKpiModalComponent } from './components/partials/create-edit-kpi-modal/create-edit-kpi-modal.component';
import { CreateEditKPIMetricModificationModalComponent } from './components/partials/create-edit-kpimetric-modification-modal/create-edit-kpimetric-modification-modal.component';
import { KpiRadarChartModalComponent } from './components/partials/kpi-radar-chart-modal-component/kpi-radar-chart-modal-component';
import { KpiListComponent } from './components/sections/kpi-list/kpi-list.component';
import { KpiOverviewComponent } from './components/sections/kpi-overview/kpi-overview.component';
import { KpiRoutingModule } from './kpi-routing.module';

@NgModule({
    declarations: [
        KpiListComponent,
        CreateEditKpiModalComponent,
        CreateEditEmployeeReviewModalComponent,
        KpiOverviewComponent,
        CreateEditKPIMetricModificationModalComponent,
        CreateEditKpiMetricModalComponent,
        KpiRadarChartModalComponent
    ],
    imports: [
        KpiRoutingModule,
        SharedModule,
        UIButtonModule,
        UIDataTableModule,
        UIItemsPerPageSelectModule,
        UIPaginationModule,
        NgxSkeletonLoaderModule,
        UIFileUploadModule
    ],
    exports: [
        CreateEditEmployeeReviewModalComponent,
        KpiRadarChartModalComponent
    ]
})
export class KpiModule {}
